import React from 'react'
import { Doughnut } from 'react-chartjs-2'

export default (props) => {
  const {
    height = 250,
    colors,
    width,
    labels,
    halfCircle,
    datasets,
    centeredText,
    ...otherProps
  } = props
  const optionsAll = {
    cutoutPercentage: 90,
    legend: {
      display: false,
    },
    circumference: halfCircle ? Math.PI : 2 * Math.PI,
    rotation: halfCircle ? 1.0 * Math.PI : -0.5 * Math.PI,
    percentageInnerCutout: 10,
    layout: {
      padding: 20,
    },
  }

  const data = {
    labels: labels,
    text: '23%',
    datasets: [
      {
        label: 'My First dataset',
        fill: false,
        lineTension: 0,
        backgroundColor: colors,
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: 'rgba(75,192,192,1)',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: 'rgba(75,192,192,1)',
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: datasets,
        weight: 1000,
        //   hoverBorderWidth:8
      },
    ],
  }
  return (
    <div style={{ display: 'flex' }}>
      <div style={{ position: 'relative', margin: '0 auto' }}>
        <div
          style={{
            textAlign: 'center',
            margin: '0 0 0 8px',
            position: 'absolute',
            top: halfCircle ? 'unset' : '50%',
            left:  halfCircle ?'46%' : '48%',
            bottom: halfCircle ? 0 : 'unset',
            transform: `translate(-50%, ${halfCircle ? '0' : '-50%'})`,
          }}
        >
          {centeredText}
        </div>
        <Doughnut data={data} options={optionsAll} height={height} width={width}/>
      </div>
    </div>
  )
}
