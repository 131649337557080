import React, { useRef, useState, useEffect } from 'react'
import moment from 'moment'
import { map, isEmpty, get } from 'lodash'
import { Grid, Typography, Box, Tab, Tabs, } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import activityTypes from './activityTypes'
import DropDown from '../components/Dropdown'
import AGIcon from '../components/AGIcon'
import AGButton from '../components/AgButton'
import Feedback from '../containers/Feedback'
import CommanClasses from '../themes/CommonClasses'
import Mustache from 'mustache'
import { useLazyQuery } from '@apollo/client'
import { activities } from '../graphql/activitiesGQL'
import { GetExportedData } from '../graphql/getExportedDataGQL'
import { getBreweryId } from '../Services/local'
import ActivityCard from '../components/ActivityCard'
const customStyles = makeStyles(CommanClasses)
const useStyles = makeStyles((theme) => ({
  activitiesBox: {
    height: 'calc(100vh - 90px)',
    overflowY: 'auto',
    [theme.breakpoints.up('md')]: {
      // position: 'fixed'
      width: '100%'
    },
  },
  tabStyle: {
    textTransform: 'initial',
    minWidth: 0,
  },
}))

export default (props) => {
  const { type, clubId, clubPhoto, mugClubIds } = props
  const common = customStyles(CommanClasses)
  const classes = useStyles()
  const pagination = useRef({})
  const breweryId = getBreweryId()
  const [allActivitiesList, setActivitiesList] = useState([])
  const [value, setValue] = useState(0)
  const feedbackList = ["All Feedback", "Thumbs Up", "Thumbs Down"]
  const [selectFeedback, setSelectFeedback] = useState("All Feedback")
  const [
    activitiesList,
    { error: getMemberActivitiesError, loading: getMemberActivitiesLoading },
  ] = useLazyQuery(activities, {
    onCompleted: (resp) => {
      if (!resp || !resp.activity) return

      const edges = resp.activity.edges || []
      const hasMore = resp.activity.pageInfo?.hasMore
      const cursor = edges.length ? edges[edges.length - 1].cursor : ''

      const mapUserActivities = map(edges, (res) => {
        let activity = { ...res.activity }
        if (!activityTypes[activity.typeOfActivity]) {
          console.log("Activity Type does not exist", activity.typeOfActivity)
          return false
        }
        activity.activityDate = moment(activity.activityDate).format('MMM Do, YYYY hh:mm a')
        let typeMatch = activityTypes[activity.typeOfActivity].type.includes('MERCHANT_ACTIVITY')
        let fields = {}
        if (activity.meta.fieldsUpdated) {
          fields = JSON.parse(activity.meta.fieldsUpdated)
          if (!isEmpty(fields)) {
            Object.keys(fields).map(function (key) {
              if (key.toString().match(/expiry|date/i)) {
                return Object.assign(fields, { [key]: moment(fields[key]).format('MMM Do, YYYY') })
              }
              else {
                return fields
              }
            })
            activity.up = fields
          }
        }

        if (activity.meta.fieldsAdded) {
          fields = JSON.parse(activity.meta.fieldsAdded)
          if (!isEmpty(fields)) {
            Object.keys(fields).map(function (key) {
              if (key.toString().match(/expiry|date/i)) {
                return Object.assign(fields, { [key]: moment(fields[key]).format('MMM Do, YYYY') })
              }
              else {
                return fields
              }
            })
            activity.af = fields
          }
        }

        const template = Mustache.render(activityTypes[activity.typeOfActivity].template, activity)

        return ({
          avatar: type !== 'CLUB' ? activity?.userInfo?.photosConnection?.edges?.[0]?.image?.url : clubPhoto,
          template,
          typeOfActivity: activity.typeOfActivity,
        })
      })
      if (pagination.current.hasMore) {
        setActivitiesList([...allActivitiesList, ...mapUserActivities])
      } else {
        setActivitiesList([...mapUserActivities])
      }
      pagination.current = { hasMore, cursor }
    },
    onError: (error) => {
      console.log('error', error)
    },
    notifyOnNetworkStatusChange: true,
  })

  const getActivityResults = () => {
    let variables = {
      breweryId: breweryId,
      activityTarget: type,
      mugClubIds,
      page: {
        after: pagination.current?.cursor || '',
        maxResultCount: 20,
      },
    }
    if (clubId && type == 'CLUB') {
      variables = {
        ...variables,
        membershipProgram: clubId,
      }
      activitiesList({ variables })
    } else if (type != 'CLUB') {
      activitiesList({ variables })
    }
  }

  useEffect(() => {
    getActivityResults()
  }, [type, clubId, mugClubIds])

  // useEffect(() => {
  //   let variables = {
  //     breweryId: breweryId,
  //     activityTarget: type,
  //     page: {
  //       after: pagination.current?.cursor || '',
  //       maxResultCount: 20,
  //     },
  //   }
  //   getRefreshedData(variables)
  // }, [mugClubIds])

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const [exportedData, {loading: exportingFeedback}] = useLazyQuery(GetExportedData, {
    onCompleted: (resp) => {
      if (resp) {
        if (get(resp, 'getExportedData.fileUrl')) {
          window.open(resp.getExportedData.fileUrl)
        }
      }
    },
    fetchPolicy: 'network-only'
  })

  const handleFeedBackExport = () => {
    let variables = {
      breweryId,
      typeOfExport: 'FEEDBACK_EXPORT'
    }
    exportedData({ variables })
  }

  return (
    <Box className={[classes.activitiesBox, common.bgWhite, common.borderRadiusM].join(' ')} >
      <Box>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor='primary'
          textColor='primary'
        // centered
        >
          <Tab
            disableFocusRipple={true}
            disableRipple={true}
            classes={{ root: classes.tabStyle }}
            label={
              <Typography
                variant='h6'
                className={value === 0 ? common.HeeboMedium : ''}
              >
                Feedback
              </Typography>
            }
          />
          <Tab
            disableFocusRipple={true}
            disableRipple={true}
            classes={{ root: classes.tabStyle }}
            label={
              <Typography
                variant='h6'
                className={value === 1 ? common.HeeboMedium : ''}
              >
                Member Activities
              </Typography>
            }
          />
        </Tabs>
      </Box>
      <Grid item xs={12}>
        <Box p={1}>
          {value === 0 ?
            <Box mt={2} >
              <Grid container justifyContent={'space-between'}>
                <Grid item xs>
                  <DropDown
                    size={'small'}
                    icon={<AGIcon name={'icon-Up'} size={6} />}
                    color='primary'
                    variant={'outlined'}
                    placement='bottom'
                    initialValue="All Feedback"
                    list={feedbackList}
                    selectedValue={(e) => {
                      console.log('eeee?', e)
                      setSelectFeedback(e)
                    }}
                  />
                </Grid>
                <Grid item >
                  <AGButton
                    outlined='true'
                    label={"Export"}
                    loading = {exportingFeedback}
                    color='#FA735F'
                    onClick={() => handleFeedBackExport()}
                  /></Grid>
              </Grid>
              <Box mt={2}>
                <Feedback breweryId={breweryId} selectedFeedbackValue={selectFeedback == "Thumbs Up" ? "Up" : selectFeedback == "Thumbs Down" ? "Down" : " "} />
              </Box>
            </Box> : <Box>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  container
                  direction={'column'}
                  justify='space-between'
                >
                  <Grid item xs={12}>
                    {map(allActivitiesList, (data, i) => (
                      data && <ActivityCard avatar={data.avatar} template={data.template} key={i} />
                    ))}
                    {!allActivitiesList?.length && (
                      <Typography className={common.textLight}> No activity found </Typography>
                    )}
                    {pagination.current.hasMore ? (
                      <Typography
                        className={[common.textCenter, common.pointer].join(' ')}
                        onClick={() => getActivityResults()}
                      >
                        {'View More'}
                      </Typography>
                    ) : (
                      ''
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Box>}
        </Box>
      </Grid>
    </Box>
  )
}
