import { gql } from '@apollo/client'

export const UPLOAD_BREWERY_PHOTOS = gql`
	mutation uploadBreweryPhotos(
        $id:ID!
        $addPhotos: [PhotoUpload!]!			
	) {
		updateBrewery(
            id: $id
            addPhotos: $addPhotos
		)
			{
				success
				brewery {
					photosConnection {
						edges {
							image {
								id
								url
							}
						}
					}
				}
			}
	}`


export const GET_IMAGE_KIT_UPLOAD_TOKEN = gql`
		query getImageKitUploadToken {
			getImageKitUploadToken {
				success
				token
				expire
				signature
			}
		}
	`

export const UPDATE_BREWERY_PHOTO_EDGE_VIDEO_URL = gql`
		mutation updateBreweryPhotoEdgeVideoUrl(
			$breweryId: String!
			$imageId: String!
			$videoUrl: String!
		) {
			updateBreweryPhotoEdgeVideoUrl(
				breweryId: $breweryId
				imageId: $imageId
				videoUrl: $videoUrl
			) {
				success
			}
	}
	`