import gql from "graphql-tag";

export const DELETE_OFFER = gql`
  mutation deleteOffer($offerId: ID!) {
    deleteOffer(offerId: $offerId){
        success
        offerId
    }
  }
`;
