import gql from 'graphql-tag'

export const GetClubDetails = gql`
  query GetClubDetails(
    $breweryId: ID!
  ) {
    brewery(id: $breweryId) {
      success
      brewery {
        name
        isMinimalSetupDone
        status
        contactEmail
        mugClubs {
          id
          name
          benefits
          benefitsMultiple
          badgeIconName
          badgeIconColor
          clubRenewalCadence
          clubLimit
          memberCount
          autoRenewable
          annualCost
          description
          brewery {
            name
          }
          breweriesConnecton {
            id
            name
          }
        }
      }
    }
  }
`

export const GetBreweryStatus = gql`
  query GetBreweryStatus(
    $breweryId: ID!
  ) {
    brewery(id: $breweryId) {
      success
      brewery {
        name
        status
        contactEmail
      }
    }
  }
`
