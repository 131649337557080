import { makeVar } from '@apollo/client'
import moment from 'moment'

let apolloClient, breweryId, breweryName, merchantUser, breweries, clubs

export const storeApollo = (client) => (apolloClient = client)
export const getApollo = () => apolloClient

export const storeBreweryId = (data) => (breweryId = data)
export const getBreweryId = () => breweryId

export const storeBreweryName = (data) => (breweryName = data)
export const getBreweryName = () => breweryName

export const storeMerchantUser = data => (merchantUser = data)
export const getMerchantUser = () => merchantUser

export const storeBreweries = data => (breweries = data)
export const getBreweries = () => breweries

export const storeAndGetClubs = makeVar([])

export const imageWidthParams = (width) => `?${width ? 'tr=w-' + (width * 2) + '&' : ''}width=640`

let yr = []
for (let i = 2020; i <= moment().startOf('year').format('YYYY'); i++) {
	yr.push(i)
}
export const __years = yr
export const __thisYear = moment().format('YYYY')

export const lessMenuArr = [
	{ href: '/', name: 'icon-Dashboard', icon: 'DashboardOutlinedIcon', size: 18 },
	{ href: '/clubs', name: 'icon-Club', icon: 'SecurityOutlinedIcon', size: 20 },
	{ href: '/user', name: 'icon-Members', icon: 'PeopleAltOutlinedIcon', size: 19 },
	{ href: '/promotions', name: 'icon-Promotion', icon: 'CardGiftcardOutlinedIcon', size: 16 },
	{ href: '/settings', name: 'icon-Settings', icon: 'SettingsOutlinedIcon', size: 18 },
]

export const fullMenuArr = [
	{ href: '/', name: 'icon-Dashboard', icon: 'DashboardOutlinedIcon', size: 18 },
	{ href: '/clubs', name: 'icon-Club', icon: 'SecurityOutlinedIcon', size: 20 },
	{ href: '/user', name: 'icon-Members', icon: 'PeopleAltOutlinedIcon', size: 19 },
	{ href: '/promotions', name: 'icon-Promotion', icon: 'CardGiftcardOutlinedIcon', size: 16 },
	{ href: '/settings', name: 'icon-Settings', icon: 'SettingsOutlinedIcon', size: 18 },
]

export const menu = makeVar(fullMenuArr)