import React, { useEffect, useState } from 'react'
import { useLocation, useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles'
import { useAuth0 } from '@auth0/auth0-react'
import CommanClasses from '../../themes/CommonClasses'
import { Box, TextField, Grid, Typography, Hidden, IconButton, Divider } from '@material-ui/core'
import InputLabel from '../../components/InputLabel'
import WorkingHoursTwo from '../../components/WorkingHoursTwo'
import AgButton from '../../components/AgButton'
import { map, groupBy, omit, isEmpty } from 'lodash'
import { UPDATE_BUSINESS_INFO } from '../../graphql/Settings/updateBusinessInfoGQL'
import { ADD_BUSINESS_INFO } from '../../graphql/Settings/addBusinessInfoGQL'
import { putMerchantUserGql } from '../../graphql/putMerchantUserGql'
import { useMutation } from '@apollo/client'
import moment from 'moment'
import { getMerchantUser, storeBreweryId, storeBreweryName } from '../../Services/local'
import { storeAllDetails, storeCurrentBreweryDetails } from '../../Services/commonFunctions'
import Color from '../../themes/Colors'
import { SnackbarProvider, useSnackbar } from 'notistack'
const businessTypes = ['Select', 'BREWERY', 'TAP_ROOM', 'OTHER', 'WINERY', 'DISTILLERY', 'RETAIL', 'MEADERY', 'CIDERY']
const bNameMaxLenth = 60
const Label = (props) => {
  const common = customStyles(CommanClasses)

  return (
    <Grid container>
      <Grid item>
        <Typography
          className={['HeeboLight', common.textDark, common.textS].join(' ')}
          gutterBottom>
          {props.name}
        </Typography>
      </Grid>
      {props.mandatory ? <Grid item className={common.textDanger}>&nbsp;*</Grid> : ''}
    </Grid>
  )
}

let dummyHours = [
  { openTime: '00:00', closeTime: '00:00', localOpenTime: '11:00', localCloseTime: '01:00', dayOfWeek: 'MONDAY' },
  { openTime: '00:00', closeTime: '00:00', localOpenTime: '11:00', localCloseTime: '01:00', dayOfWeek: 'TUESDAY' },
  { openTime: '00:00', closeTime: '00:00', localOpenTime: '11:00', localCloseTime: '01:00', dayOfWeek: 'WEDNESDAY' },
  { openTime: '00:00', closeTime: '00:00', localOpenTime: '11:00', localCloseTime: '01:00', dayOfWeek: 'THURSDAY' },
  { openTime: '00:00', closeTime: '00:00', localOpenTime: '11:00', localCloseTime: '01:00', dayOfWeek: 'FRIDAY' },
  { openTime: '00:00', closeTime: '00:00', localOpenTime: '11:00', localCloseTime: '01:00', dayOfWeek: 'SATURDAY' },
  { openTime: '00:00', closeTime: '00:00', localOpenTime: '11:00', localCloseTime: '01:00', dayOfWeek: 'SUNDAY' },
]

function BusinessInfo(props) {
  const location = useLocation();
  const { bIData, } = props || {}
  const { breweryIds } = props
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar()
  const common = customStyles(CommanClasses)
  const classes = useStyles()
  const [cMode, setCMode] = useState('read')
  const [newBreweryDetails, setNewBreweryDetails] = useState({})
  const merchantUser = getMerchantUser()

  console.log('BUSINESS INFO>>>>>>>', breweryIds)

  const { user, isLoading, error, getAccessTokenSilently } = useAuth0({
    audience: process?.env?.REACT_APP_API_HOST,
    scope: 'openid profile email',
  })

  useEffect(() => {
    const getAccessToken = async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          audience: process.env.REACT_APP_API_HOST,
          scope: 'read:current_user',
        })
        console.log('accessToken??', accessToken)
      } catch (err) {
        throw err
      }
    }
    getAccessToken()
  }, [])


  const takeMetoSettings = (isEdit,isNew) => {
    history.push({
      pathname: "/settings",
      state: { editBusiness: isEdit, isNew: isNew },
    });
  }

  const [putMerchantUser, { data: meData, error: meDataError, loading: meDataLoading }] = useMutation(putMerchantUserGql, {
    onCompleted: (resp) => {
      if (resp) {
        storeAllDetails(resp)
        if(!isEmpty(newBreweryDetails && newBreweryDetails?.id && newBreweryDetails?.name)){
          storeBreweryId(newBreweryDetails.id)
          storeBreweryName(newBreweryDetails.name)
          setNewBreweryDetails({})
        } 
        takeMetoSettings(false, false)
      }
    }
  })

  useEffect(() => {
    if (props.mode === 'edit_businessinfo') {
      setCMode('edit_businessinfo')
    } else {
      setCMode('read')
    }
    if (location.state?.editBusiness) {
      setCMode('edit_businessinfo')
      setBusinessData({ hours: dummyHours })
    }

    console.log(location.state?.editBusiness)
  }, [props.mode, location])

  const cleanedHours = map(bIData?.brewery?.brewery?.hours, (hr) =>
    omit(hr, ['__typename'])
  )
  console.log('bIData >>>>>>>>>>>>>>>>>', bIData)
  //This state object is depended on settingPercentage calculation,errorStatus,checkValidation
  const [businessData, setBusinessData] = useState({
    id: breweryIds || '',
    businessName: bIData?.brewery?.brewery?.name || '',
    businessType: bIData?.brewery?.brewery?.businessType || '',
    streetAddress: bIData?.brewery?.brewery?.hierarchicalLocation[4] || '',
    city: bIData?.brewery?.brewery?.hierarchicalLocation[2] || '',
    state: bIData?.brewery?.brewery?.hierarchicalLocation[1] || '',
    zipCode: bIData?.brewery?.brewery?.hierarchicalLocation[3] || '',
    email: bIData?.brewery?.brewery?.contactEmail || '',
    phoneNumber: bIData?.brewery?.brewery?.contactPhone || '',
    alternatePhoneNumber: bIData?.brewery?.brewery?.alternatePhoneNumber || '',
    description: bIData?.brewery?.brewery?.description || '',
    website: bIData?.brewery?.brewery?.website || '',
    // hours: bIData?.brewery?.brewery?.hours || [],
    hours: cleanedHours.length !== 0 ? cleanedHours : dummyHours || [],
    fullAddress: `${bIData?.brewery?.brewery?.hierarchicalLocation[4]}, ${bIData?.brewery?.brewery?.hierarchicalLocation[2]}, ${bIData?.brewery?.brewery?.hierarchicalLocation[1]}, ${bIData?.brewery?.brewery?.hierarchicalLocation[3]}, INDIA` || ''
  })

  const calcPercentage = (obj) => {
    let objData = obj

    // settingPercentage in progress circle in parent(Stepper)
    var bn = objData?.businessName !== '' ? 1 : 0
    var bt = objData?.businessType !== '' ? 1 : 0
    var sa = objData?.streetAddress !== '' ? 1 : 0
    var city = objData?.city !== '' ? 1 : 0
    var state = objData?.state !== '' ? 1 : 0
    var zipCode = objData?.zipCode !== '' ? 1 : 0
    var email = objData?.email !== '' ? 1 : 0
    var pn = objData?.phoneNumber !== 0 ? 1 : 0
    var apn = objData?.alternatePhoneNumber !== 0 ? 1 : 0
    var desc = objData?.description !== '' ? 1 : 0
    var website = objData?.website !== '' ? 1 : 0
    let total = bn + bt + sa + city + state + zipCode + email + pn + apn + desc + website
    let percent = (total / 9) * 100
    props.value(percent)
    // ---------
  }

  const handleChangeBusinessHours = (arrayChanged) => {
    setBusinessData({ ...businessData, hours: arrayChanged })
  }

  // ------------Sorting Hours Starts-----------------------------------------
  const hoursData = businessData.hours
  const formatDay = (str) => {
    return (str?.charAt(0)?.toUpperCase() + str?.slice(1)?.toLowerCase())?.slice(0, 2)
  }
  const formatTime = (timeStr) => {
    return moment(timeStr, ['h:mm A']).format('hh:mm A')
  }
  const getFormattedHours = () => {
    let formattedData = []
    map(groupBy(hoursData, 'localOpenTime'), (localOpenTime) => {
      let clsTm = groupBy(localOpenTime, 'localCloseTime')
      map(clsTm, (localCloseTime, localCloseTimeKey) => {
        let obj = ''
        for (var i = 0; i < localCloseTime.length; i++) {
          let child = ''
          if (i === localCloseTime.length - 1) {
            child = formatDay(localCloseTime[i].dayOfWeek)
          } else {
            child = formatDay(localCloseTime[i].dayOfWeek) + ' '
          }
          obj = obj + child
        }

        formattedData = [
          ...formattedData,
          {
            day: obj,
            time:
              formatTime(localCloseTime[0].localOpenTime) +
              ' - ' +
              formatTime(
                localCloseTime[localCloseTime.length - 1].localCloseTime,
              ),
          },
        ]
      })
    })
    //sorting by day starts
    const sorter = {
      // sunday: 0, // << if sunday is first day of week
      mo: 1,
      tu: 2,
      we: 3,
      th: 4,
      fr: 5,
      sa: 6,
      su: 7,
    }
    formattedData.sort(function sortByDay(a, b) {
      let day1 = a?.day?.split(' ')?.[0]?.toLowerCase()
      let day2 = b?.day?.split(' ')?.[0]?.toLowerCase()
      return sorter[day1] - sorter[day2]
    })
    //sorting by day ends
    //console.log('FORMATTED=', formattedData)
    return formattedData
  }
  let formatedTheTime = getFormattedHours()
  // --------------Sorting Hours Ends---------------------------------------

  const [errorStatus, setErrorStatus] = useState({
    businessName: false,
    businessType: false,
    streetAddress: false,
    city: false,
    state: false,
    zipCode: false,
    email: false,
    phoneNumber: false,
    alternatePhoneNumber: false,
    description: false,
    website: false,
    hours: false,
  })

  const handleOnChange = (name, value) => {
    setBusinessData({ ...businessData, [name]: value })
    calcPercentage({ ...businessData, [name]: value })
    // ------------
    if (value === '' || value === undefined || value === 'Select') {
      setErrorStatus({ ...errorStatus, [name]: true })
    } else {
      setErrorStatus({ ...errorStatus, [name]: false })
    }
  }

  const [updatebIInfo, bInfoResp] = useMutation(UPDATE_BUSINESS_INFO, {
    onCompleted: (resp) => {
      console.log('resp updatebIInfo??', resp)
      if (resp) {
        console.log('response update')
        props.refetchBreweryData()
        updateData()

      }
    },
    onError: (err) => {
      console.log('error in update query')
    }
  })

  const [addIInfo, addbInfoResp] = useMutation(ADD_BUSINESS_INFO, {
    onCompleted: (resp) => {
      console.log('resp addbIInfo??', resp)
      if (resp) {
        // console.log('response update')
        // // props.refetchBreweryData()
        updateData()
        if(resp?.createBrewery?.brewery?.id){
          setNewBreweryDetails({
            id: resp?.createBrewery?.brewery?.id,
            name: resp?.createBrewery?.brewery?.name
          })
        }
      }
    },
    onError: (err) => {
      console.log('error in add query')
    }
  })
  const checkValidation = () => {
    let validationStatus = true
    //object fields representing businessData
    let checkerArray = [businessData.businessName, businessData.businessType, businessData.streetAddress, businessData.city, businessData.state, businessData.zipCode, businessData.phoneNumber, businessData.description, businessData.website]
    //object fields representing errorStatus
    let fieldNameArray = ['businessName', 'businessType', 'streetAddress', 'city', 'state', 'zipCode',  'phoneNumber', 'description', 'website']
    //New obj to be set in errorStatus
    let errorCollectionObj = { ...errorStatus }
    //Loop through all the fields to find missing
    for (var i = 0; i < checkerArray.length; i++) {
      if (checkerArray[i] === '' || checkerArray[i] === undefined) {
        validationStatus = false
        let updateField = fieldNameArray[i]
        errorCollectionObj = { ...errorCollectionObj, [updateField]: true }
      }
      else if (i === 6) {
        if (checkerArray[i]?.replaceAll('-', '').length !== 10) {
          validationStatus = false
          let updateField = fieldNameArray[i]
          errorCollectionObj = { ...errorCollectionObj, [updateField]: true }
          enqueueSnackbar('Add a valid phone number')
        }
      }
      else if (i === 8) {
        let url
  
        try {
          url = new URL(checkerArray[i])
        } catch (err) {
          console.log('errored as??', err)
        }

        if (!url?.protocol.includes('http')) {
          validationStatus = false
          let updateField = fieldNameArray[i]
          errorCollectionObj = { ...errorCollectionObj, [updateField]: true }
          enqueueSnackbar('Url must contain http:// or https://')
        }
      }
    }
    //Sets the new errorStatus obj
    setErrorStatus(errorCollectionObj)
    //Validation result boolean
    return validationStatus
  }

  const handleOnSave = () => {
    let valid = checkValidation()
    if (valid) {
      //save the values
      // console.log('data to be updated businessData=', businessData)
      if (businessData.id) {
        updatebIInfo({
          variables: {
            ...businessData,
            fullAddress: `${businessData.streetAddress}, ${businessData.city}, ${businessData.state}, ${businessData.zipCode}`
          }
        })
      } else {
        console.log('businessData------------', businessData)
        addIInfo({
          variables: {
            ...businessData,
            merchantUserOwners: [merchantUser.id],
            email: merchantUser.email,
            fullAddress: `${businessData.streetAddress}, ${businessData.city}, ${businessData.state}, ${businessData.zipCode}`
          }
        })
      }

      props.refetchBreweryData()
      setCMode('read')
    } else {
      console.log('Datas still left to be filled')
      console.log('datas not updated')
    }

  }

  const updateData = (resp) => {
    putMerchantUser({
      variables: {
        auth0Sub: user?.sub,
        email: user?.email,
      }
    })

    if (resp) {
      storeCurrentBreweryDetails(resp)
    }

    
  }

  const formatPhoneNumber = (str) => {
    //Filter only numbers from the input
    let cleaned = ('' + str).replace(/\D/g, '')

    //Check if the input is of correct
    let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d+)/)

    if (match) {
      //Remove the matched extension code
      //Change this to format for any country code.
      // let intlCode = (match[1] ? '+1 ' : '')
      return [match[2], '-', match[3], '-', match[4]].join('')
    }

    return cleaned
  }

  const readData = () => (
    <Grid container direction='column' /*onClick={() => setCMode('edit_businessinfo')}*/>
      {/* below hidden is only visible in medium screens */}
      <Hidden only={['sm', 'xs']}>
        <Grid item container direction='row'>
          <Grid item md={2}>
            <Box display='flex' my={1}>
              <Box mr={6}><InputLabel label='Business Name' read readValue={businessData.businessName || 'data not ava'} /></Box>
            </Box>
          </Grid>
          <Grid item md={2}>
            <Box display='flex' my={1}>
              <Box mr={6}><InputLabel label='Business Type' read readValue={businessData.businessType} /></Box>
            </Box>
          </Grid>
          <Grid item md={2}>
            <Box display='flex' my={1}>
              <Box mr={6}><InputLabel label='Address' read readValue={`${businessData.streetAddress} ${businessData.city} ${businessData.state}`} /></Box>
            </Box>
          </Grid>
          <Grid item md={2}>
            <Box display='flex' my={1}>
              <Box mr={6}><InputLabel label='Zip code' read readValue={businessData.zipCode} /></Box>
            </Box>
          </Grid>
          <Grid item md={2}>
            <Box display='flex' my={1}>
              <Box mr={6}><InputLabel label='Email' read readValue={businessData.email} /></Box>
            </Box>
          </Grid>


          {/* --------------------------- */}
        </Grid>
        <Grid item container direction='row'>
          <Grid item md={2}>
            <Box display='flex' my={1}>
              <Box mr={6}><InputLabel label='Phone Number' read readValue={`+1 ${formatPhoneNumber(businessData.phoneNumber)}`} /></Box>
            </Box>
          </Grid>
          <Grid item md={2}>
            <Box display='flex' my={1}>
              <Box mr={6}><InputLabel label='Alternate Phone Number' read readValue={`+1 ${formatPhoneNumber(businessData.alternatePhoneNumber)}`} /></Box>
            </Box>
          </Grid>
          <Grid item md={2} sm={12} xs={12}>
            <Box my={1} display='flex'>
              <Box pr={1}><InputLabel label='Url' wrap={true} read readValue={businessData.website} /></Box>
            </Box>
          </Grid>
          <Grid item md={3} sm={12} xs={12}>
            <Box my={1} display='flex'>
              <Box pr={1}><InputLabel label='Description' read readValue={businessData.description} /></Box>
            </Box>
          </Grid>
          <Grid item>
            <Box my={1} display='flex' >
              <Box mr={0}><Label name='Working Hours' />
                {/* Basically this has to catagorize into own group based on the working hours */}
                <Box display='flex'>
                  {formatedTheTime.map((obj, objid) => (
                    <Box mr={1.25} key={objid}>
                      <Box>{(obj.day)?.split(' ').map((da, daid) => (
                        <IconButton key={daid} className={[common.bgAliceBlue, common.textDark, common.textM, common.HeeboMedium, classes.sSize, classes.fab].join(' ')}>
                          {da}
                        </IconButton>
                      ))}</Box>
                      <Typography className={[common.HeeboRegular, common.textDark, common.textM].join(' ')}>{obj.time}</Typography>
                    </Box>
                  ))}
                </Box>
              </Box>

            </Box>
          </Grid>

        </Grid>
      </Hidden>
      {/* below hidden is only visible in small screens */}
      <Hidden only={['md', 'lg', 'xl']}>
        <Grid item container direction='row'>
          <Grid item /*md={6}*/ sm={12} xs={12}>
            <Box display='flex' my={1}>
              <Box mr={2}><InputLabel label='Business Name' read readValue={businessData.businessName} /></Box>
              <Box mr={1}><InputLabel label='Business Type' read readValue={businessData.businessType} /></Box>

            </Box>
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <Box display='flex' my={1}>
              <Box mr={1}><InputLabel label='Address' read readValue={`${businessData.streetAddress} ${businessData.city} ${businessData.state}`} /></Box>
            </Box>
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <Box display='flex' my={1}>
              <Box mr={1}><InputLabel label='Zip Code' read readValue={businessData.zipCode} /></Box>
            </Box>
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <Box display='flex' my={1}>
              <Box mr={1}><InputLabel label='Email' read readValue={businessData.email} /></Box>
              <Box mr={2}><InputLabel label='Phone Number' read readValue={`+1 ${formatPhoneNumber(businessData.phoneNumber)}`} /></Box>
            </Box>
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <Box display='flex' my={1}>

              <Box mr={2}><InputLabel label='Alternate Phone Number' read readValue={`+1 ${formatPhoneNumber(businessData.alternatePhoneNumber)}`} /></Box>
              <Box mr={1}><InputLabel label='Url'wrap={true} read readValue={businessData.website} /></Box>
            </Box>
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <Box display='flex' my={1}>
              <Box mr={1}><InputLabel label='Description' read readValue={businessData.description} /></Box>
            </Box>
          </Grid>
        </Grid>
        <Grid item container direction='row'>
          <Grid item md={6} sm={12} xs={12}>
            <Box my={1}>
              <Box mr={0}><Label name='Working Hours' /></Box>
              <Box mr={0}>
                {/* Basically this has to catagorize into own group based on the working hours */}
                {formatedTheTime.map((obj, objid) => (
                  <Box mr={1.25} display='flex' key={objid}>
                    <Box flexGrow={1}>
                      {(obj.day)?.split(' ').map((da, daid) => (
                        <IconButton key={daid} className={[common.bgAliceBlue, common.textDark, common.textM, common.HeeboMedium, classes.sSize, classes.fab].join(' ')}>
                          {da}
                        </IconButton>
                      ))}
                    </Box>
                    <Typography className={[common.HeeboRegular, common.textDark, common.textM].join(' ')}>{obj.time}</Typography>
                  </Box>
                ))}

              </Box>

            </Box>
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <Box my={1}>
              {/* <InputLabel label='Lunch Break' read readValue={'2:00 pm  to  2:30 pm'} /> */}
            </Box>
          </Grid>
        </Grid>
      </Hidden>
    </Grid>
  )
  const editUI = () => (
    <Grid container>
      {/* below hidden is only visible in medium screens */}
      <Hidden only={['sm', 'xs']}>
        <Grid item style={{ width: '60%' }}>
          <Box pr={10} >
            <Box py={1} mb={3}>
              <Typography className={[common.HeeboRegular, common.textDark].join(' ')} >Tell your customers about you and your business. People love an origin story so tell them how you got started and why you started making beer.</Typography>
            </Box>
            <Box mb={2}>
              <Grid container justify='space-between'>
                <Grid item md={9} sm={12} xs={12}>
                  <Box pr={2}>
                    <InputLabel label='Business Name' maxLength={bNameMaxLenth} name='businessName' onChangeValue={(name, value) => handleOnChange(name, value)} value={businessData.businessName} autoFocus error={errorStatus.businessName} />
                  </Box>
                </Grid>
                <Grid item md={3} sm={12} xs={12}>
                  <Box pl={0}>
                    <InputLabel label='Business Type' name='businessType' select options={businessTypes} onChangeValue={(name, value) => handleOnChange(name, value)} value={businessData.businessType} error={errorStatus.businessType} />
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box py={1}>
              <Grid container>
                <Grid item md={12} sm={12} xs={12}>
                  <InputLabel label='Street Address' name='streetAddress' onChangeValue={(name, value) => handleOnChange(name, value)} value={businessData.streetAddress} error={errorStatus.streetAddress} />
                </Grid>
              </Grid>
            </Box>
            <Box py={1}>
              <Grid container justify='space-between'>
                <Grid item md={4} sm={12} xs={12}>
                  <Box pr={2}>
                    <InputLabel label='City' name='city' onChangeValue={(name, value) => handleOnChange(name, value)} value={businessData.city} error={errorStatus.city} />
                  </Box>
                </Grid>
                <Grid item md={4} sm={12} xs={12}>
                  <Box pr={2}>
                    <InputLabel label='State' name='state' onChangeValue={(name, value) => handleOnChange(name, value)} value={businessData.state} error={errorStatus.state} />
                  </Box>
                </Grid>
                <Grid item md={4} sm={12} xs={12}>
                  <Box pr={0}>
                    <InputLabel label='Zip Code' name='zipCode' onChangeValue={(name, value) => handleOnChange(name, value)} value={businessData.zipCode} error={errorStatus.zipCode} />
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box py={1}>
              <Grid container justify='space-between'>
                <Grid item md={4} sm={12} xs={12}>
                  <Box pr={2}>
                    {/* <InputLabel label='Email' name='email' onChangeValue={(name, value) => handleOnChange(name, value)} value={businessData.email} error={errorStatus.email} /> */}
                    <Label name='Email' />
                    <TextField
                      disabled
                      className={[classes.textField].join(' ')}
                      variant='outlined'
                      size='small'
                      fullWidth
                      name='email'
                      value={businessData.email || merchantUser.email}
                    />
                  </Box>
                </Grid>
                <Grid item md={4} sm={12} xs={12}>
                  <Box pr={2}>
                    {/* <InputLabel label='Phone Number' name='phoneNumber' onChangeValue={(name, value) => handleOnChange(name, value)} value={businessData.phoneNumber} error={errorStatus.phoneNumber} /> */}
                    <Label name='Phone Number' />
                    <Box display='flex' alignItems='center'>
                      <Typography>+1&nbsp;</Typography>
                      <TextField
                        inputProps={{ maxLength: 12 }}
                        className={[classes.textField].join(' ')}
                        variant='outlined'
                        size='small'
                        fullWidth
                        onChange={(e) =>
                          handleOnChange(e.target.name, e.target.value)
                        }
                        name={'phoneNumber'}
                        value={formatPhoneNumber(businessData.phoneNumber)}
                        error={errorStatus.phoneNumber}
                        helperText={errorStatus.phoneNumber && 'Required *'}
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid item md={4} sm={12} xs={12}>
                  <Box pr={2}>
                    <Label name='Alternate Phone Number' />
                    <Box display='flex' alignItems='center'>
                      <Typography>+1&nbsp;</Typography>
                      <TextField
                        inputProps={{ maxLength: 12 }}
                        className={[classes.textField].join(' ')}
                        variant='outlined'
                        size='small'
                        fullWidth
                        onChange={(e) =>
                          handleOnChange(e.target.name, e.target.value)
                        }
                        name={'alternatePhoneNumber'}
                        value={formatPhoneNumber(businessData.alternatePhoneNumber)}
                      />
                    </Box></Box>
                  {/* <Box pr={0}><InputLabel label='Alternate Phone Number' name='alternatePhoneNumber' onChangeValue={(name, value) => handleOnChange(name, value)} value={businessData.alternatePhoneNumber} error={errorStatus.alternatePhoneNumber} /></Box> */}
                </Grid>
              </Grid>
            </Box>
            <Box py={1} mb={1.25}>
              <Grid container>
                <Grid item md={12} sm={12} xs={12}>
                  <Box pr={2}>
                    <InputLabel label='Url' name='website'  onChangeValue={(name, value) => handleOnChange(name, value)} value={businessData.website} error={errorStatus.website} />
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box py={1} mb={1.25}>
              <Grid container>
                <Grid item md={12} sm={12} xs={12}>
                  <InputLabel label='Description' name='description' onChangeValue={(name, value) => handleOnChange(name, value)} value={businessData.description} error={errorStatus.description} multiline={4} />
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
        <Grid item style={{ width: '40%' }} >
          <Box>
            <WorkingHoursTwo hours={businessData.hours} businessHours={(arrChgd) => handleChangeBusinessHours(arrChgd)} />
          </Box>
        </Grid>
      </Hidden>
      {/* below hidden is only visible in small screens */}
      <Hidden only={['md', 'lg', 'xl']}>
        <Grid item sm={12}>
          <Box pr={0} >
            <Box py={1} mb={3}>
              <Typography className={[common.HeeboRegular, common.textDark].join(' ')} >Tell your customers about you and your business. People love an origin story so tell them how you got started and why you started making beer.</Typography>
            </Box>
            <Box mb={2}>
              <Grid container  >
                <Grid item md={9} sm={12} xs={12}>
                  <Box pr={0}><InputLabel label='Business Name' maxLength={bNameMaxLenth} name='businessName' onChangeValue={(name, value) => handleOnChange(name, value)} value={businessData.businessName} error={errorStatus.businessName} /></Box>
                </Grid>
                <Grid item md={3} sm={12} xs={12}>
                  <Box pl={0}><InputLabel label='Business Type' select options={businessTypes} name='businessType' onChangeValue={(name, value) => handleOnChange(name, value)} value={businessData.businessType} error={errorStatus.businessType} /></Box>
                </Grid>
              </Grid>
            </Box>
            <Box py={1}>
              <Grid container>
                <Grid item md={12} sm={12} xs={12}>
                  <InputLabel label='Street Address' name='streetAddress' onChangeValue={(name, value) => handleOnChange(name, value)} value={businessData.streetAddress} error={errorStatus.streetAddress} />
                </Grid>
              </Grid>
            </Box>
            <Box py={1}>
              <Grid container justify='space-between'>
                <Grid item md={4} sm={12} xs={12}>
                  <Box pr={0}><InputLabel label='City' name='city' onChangeValue={(name, value) => handleOnChange(name, value)} value={businessData.city} error={errorStatus.city} /></Box>
                </Grid>
                <Grid item md={4} sm={12} xs={12}>
                  <Box pr={0}><InputLabel label='State' name='state' onChangeValue={(name, value) => handleOnChange(name, value)} value={businessData.state} error={errorStatus.state} /></Box>
                </Grid>
                <Grid item md={4} sm={12} xs={12}>
                  <Box pr={0}><InputLabel label='Zip Code' name='zipCode' onChangeValue={(name, value) => handleOnChange(name, value)} value={businessData.zipCode} error={errorStatus.zipCode} /></Box>
                </Grid>
              </Grid>
            </Box>
            <Box py={1}>
              <Grid container justify='space-between'>
                <Grid item md={4} sm={12} xs={12}>
                  <Box pr={0}><InputLabel label='Email' name='email' onChangeValue={(name, value) => handleOnChange(name, value)} value={businessData.email} error={errorStatus.email} /></Box>
                </Grid>
                <Grid item md={4} sm={12} xs={12}>
                  <Box pr={0}><InputLabel label='Phone Number' name='phoneNumber' onChangeValue={(name, value) => handleOnChange(name, value)} value={businessData.phoneNumber} error={errorStatus.phoneNumber} /></Box>
                </Grid>
                <Grid item md={4} sm={12} xs={12}>
                  <Box pr={2}>
                    <Label name='Alternate Phone Number' />
                    <Box display='flex' alignItems='center'>
                      {/* <Typography>+1&nbsp;</Typography> */}
                      <TextField
                        inputProps={{ maxLength: 12 }}
                        className={[classes.textField].join(' ')}
                        variant='outlined'
                        size='small'
                        fullWidth
                        onChange={(e) =>
                          handleOnChange(e.target.name, e.target.value)
                        }
                        name={'alternatePhoneNumber'}
                        value={formatPhoneNumber(businessData.alternatePhoneNumber)}
                        error={errorStatus.alternatePhoneNumber}
                        helperText={errorStatus.alternatePhoneNumber && 'Required *'}
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid item md={4} sm={12} xs={12}>
                  <Box py={0} >
                    <InputLabel label='Url' name='website' onChangeValue={(name, value) => handleOnChange(name, value)} value={businessData.website} error={errorStatus.website} />
                  </Box>
                </Grid>
                <Grid item md={4} sm={12} xs={12}>
                  <Box pr={0}><InputLabel label='Description' name='description' onChangeValue={(name, value) => handleOnChange(name, value)} value={businessData.description} error={errorStatus.description} multiline={3} /></Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
        <Grid item sm={12} >
          {/* <WorkingHours hours={businessData.hours} businessHours={(arrChgd) => handleChangeBusinessHours(arrChgd)} /> */}
          <WorkingHoursTwo hours={businessData.hours} businessHours={(arrChgd) => handleChangeBusinessHours(arrChgd)} />
        </Grid>
      </Hidden>
      {/* <div className={classes.footer} >
        <Divider />
      </div>
      {Footer()} */}
    </Grid>
  )
  return (
    <Box my={props.margin} >
      <Grid container justify='space-between' alignItems='center'>
        <Typography className={[common.HeeboMedium, common.textXxl, common.textPrimary, classes.bottomMarginHeader].join(' ')}>{'Business info'}</Typography>
        {cMode === 'edit_businessinfo' ?
          <AgButton label='Save' smblue='true' onClick={() => { handleOnSave() }} />
          :
          <AgButton label='Edit' smblue='true' onClick={() => { setCMode('edit_businessinfo') }} />
        }
      </Grid>
      <Divider style={{ marginTop: 10, marginBottom: 10 }} />
      <Box>
        {cMode === 'edit_businessinfo' ?
          editUI()
          :
          readData()
        }
      </Box>
    </Box >
  )
}

const customStyles = makeStyles(CommanClasses)

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 0,
    marginBottom: 0,
  },
  rightMargin: {
    marginRight: 100,
  },
  fab: {
    marginRight: 5, marginBottom: 7, boxShadow: 'none',
  },
  sSize: {
    width: 25,
    height: 25,
    // ----
    borderRadius: '50%',
    boxShadow: 'none',
  },
  footer: {
    width: '100%',
    marginBottom: 10,
  },
  iconButton: {
    borderRadius: '50%',
    boxShadow: 'none',
    width: 35,
    height: 35,
  },
  textField: {
    background: `${Color.white} 0% 0% no-repeat padding-box`,
    // border: `1px ${Color.inputBorder}`,
    borderColor: Color.inputBorder,
    borderRadius: 5,
    opacity: 1,
    '& .MuiOutlinedInput-root': {
      border: `1px ${Color.inputBorder}`,
      borderRadius: 5,
    }
  },
}))

export default BusinessInfo
