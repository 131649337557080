import React, { useState } from 'react'
import { useLocation } from "react-router-dom";
import Color from '../../themes/Colors'
import { Grid, Typography, Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CommanClasses from '../../themes/CommonClasses'
import Drawer from '../../components/Drawer'
import BgImg from '../../assets/images/undraw_having_fun_iais.svg'
import Image from '../../components/Image'
import AgButton from '../../components/AgButton'
import ClubsManageDrawer from '../../containers/ClubsManageDrawer'
import { upperCase } from 'lodash'

const Clubs = (props) => {
  const {
    margin,
		breweryId,
    clubDetails,
    refetchGetClubFields
  } = props || {}
  const common = customStyles(CommanClasses)
  const classes = useStyles()
  const [showManageClub, setShowManageClub] = useState(false)

  const handleCloseClubsManageDrawer = () => {
    setShowManageClub(!showManageClub)
    refetchGetClubFields()
  }

  return (
    <Box my={margin} className={classes.root1}>
      <Grid container>
        <Grid item md={5} sm={12}>
          <Box>
            <Box mb={3.75}>
              {/* {
                clubDetails?.id ?
                  <Grid
                    container
                    direction='row'>
                    <Typography
                      className={[
                        common.HeeboRegular,
                        common.textDark
                      ].join(' ')}
                    >
                      Give your biggest fans new ways to engage -
                    </Typography>
                    <Typography
                      className={[
                        common.HeeboRegular,
                        common.textPrimary
                      ].join(' ')}>
                      {upperCase(clubDetails?.name)}
                    </Typography>
                  </Grid>
                  : */}
                  <Typography className={[common.HeeboRegular, common.textDark].join(' ')} >
                    Give your biggest fans new ways to engage
                  </Typography>
              {/* } */}
            </Box>
            <Box mb={3.75}>
              <Typography className={[common.HeeboLight, common.textM, common.textDark].join(' ')}>
                Indulj clubs allows you create a membership program and easily manage it. We make member join and renewal possible anytime day or night. Plus, your customers can use the app to purchase their membership. You can also keep notes on your customers and drive renewals with targeted promos.If you’re new to clubs and memberships, there’s a sample club for you to get started.
            </Typography>
            </Box>
            <Box display='flex'>
              <AgButton 
                label={'Add Club'}
                // label={clubDetails?.id ? 'Edit Club' : 'Add Club'}
                smblue='true'
                disabled={!breweryId}
                onClick={() => {
                  setShowManageClub(true)
                }}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item md={7}>
          <Grid container justify='flex-end'>
            <Image src={BgImg} xxxl />
          </Grid>
        </Grid>
      </Grid>
      <Drawer
        open={showManageClub}
        content={
          <ClubsManageDrawer basepath='settings'  closeDrawer={handleCloseClubsManageDrawer} mode='edit' />
        }
        closeDrawer={handleCloseClubsManageDrawer}
      />
    </Box>
  )
}

const customStyles = makeStyles(CommanClasses)

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: Color.white,
  },
  root1: {
  },
  rootCard: {
    width: 868,
    marginLeft: 0,
    marginRight: 10,
    marginTop: 10,
    marginBottom: 10,
    border: `1px solid ${Color.inputBorder}`,
  },
  smRootCard: {
    width: '100%',
    marginLeft: 0,
    marginRight: 10,
    marginTop: 10,
    marginBottom: 10,
    border: `1px solid ${Color.inputBorder}`,
  },
  mb60: { marginBottom: 60 },
  mb20: { marginBottom: 20 },
}))

export default Clubs