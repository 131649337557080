import React from 'react'
import { Grid, Typography, Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CommanClasses from '../themes/CommonClasses'
import AGCard from './AGCard'

const customStyles = makeStyles(CommanClasses)

export default (props) => {
  const { header, number, type, percentage, timeState, ...otherProps } = props
  const common = customStyles(CommanClasses)

  return (
    <Box className={common.h_100}>
      <AGCard
        fullHeight
        cardHeader={header}
        cardContent={
          <Grid container justify='space-between' alignItems='stretch'>
            <Grid item>
              <Box mt={1} className={common.h_100}>
                <Typography variant='h4' component='h4' className={common.HeeboMedium}>
                  {number}
                  <Typography className={[common.textDark, common.HeeboLight, common.textM].join(' ')} component='span'>{' '} {timeState}</Typography>
                </Typography>
              </Box>
            </Grid>
          </Grid>
        }
      />
    </Box>
  )
}
