import React from 'react'
import Avatar from '@material-ui/core/Avatar'
import { makeStyles } from '@material-ui/core/styles'
import { intersection, keys, get, map, isArray } from 'lodash'

const useStyles = makeStyles((theme) => ({
  sm: { height: 40, width: 40 },
  md: { height: 50, width: 50 },
  lg: { height: 80, width: 80 },
  mlg: { height: 92, width: 92 },
  xlg: { height: 82, width: 140 },
  lx: { height: 135, width: 170 },
  mlx: { height: 120, width: 160 },
  mbmlx: { height: 120, width: 140 },
  xl: { height: 120, width: 180 },
  xlx: { height: 163, width: '100%' },
  mxl: { height: 194, width: 245 },
  xxl: { height: 233, width: '100%' },
  xxxl: { height: 250, width: 353 },
  fullWidth: { width: '100%' },
  fullHeight: { height: '100%' },
  radiusNone: { borderRadius: 0 },
  radiusSm: { borderRadius: 5 },
  radiusMd: { borderRadius: 10 },
  radiusLg: { borderRadius: 15 },
  radiusXl: { borderRadius: 20 },
  radiusXxl: { borderRadius: 25 },
  active: { padding: '3px', border: '2px solid #41D691' },
  imageRoot: { borderRadius: 'inherit' }
}))

export default (props) => {
  const classes = useStyles()
  const { src, alt, circle, onClick, onHover, onLeave, ...otherProps } = props
  let myclasses = map(map(Object.keys(otherProps), key => key), data => classes[data])
  return <Avatar onClick={onClick} src={src?.includes('blob:http') || src} alt={alt} variant={circle ? 'circle' : 'square'} classes={{ img: classes.imageRoot }} className={myclasses.join(' ')} onMouseEnter={onHover} onMouseLeave={onLeave} />
}
