import { gql } from '@apollo/client'

const GET_IMAGE_KIT_UPLOAD_TOKEN = gql`
	query getImageKitUploadToken {
		getImageKitUploadToken {
			success
			token
			expire
			signature
		}
	}
`

export const imageKitUploadApi = async ({ apolloClient, fileName, file }) => {
	//upload the binary file to the imageKit and get the url
	const { data: getImageKitUploadTokenData } = await apolloClient.query({
		query: GET_IMAGE_KIT_UPLOAD_TOKEN,
		fetchPolicy: 'network-only'
	})

	const { signature, expire, token } = getImageKitUploadTokenData.getImageKitUploadToken

	try {
		const formData = new FormData()
		formData.append('publicKey', process.env.REACT_APP_IMAGE_KIT_PUBLIC_KEY)
		formData.append('signature', signature)
		formData.append('expire', expire)
		formData.append('token', token)
		formData.append('fileName', fileName)
		formData.append('file', file)

		const rawResponse = await fetch(process.env.REACT_APP_IMAGE_KIT_UPLOAD_API_URL, {
			method: 'POST',
			body: formData
		})

		const resp = await rawResponse.json()

		return resp
	} catch (err) {
		console.log('error in fetch??', err)
	}
}