import gql from 'graphql-tag'

export const INVITE_COMMUNITY_MEMBERS = gql`
  mutation inviteCommunityMembers(
    $sendNewMembershipCode: Boolean
    $to: [String!]!
    $breweryId: ID
    $membershipProgramId: ID
    ) {
    inviteCommunityMembers(
      sendNewMembershipCode: $sendNewMembershipCode
      to: $to
      breweryId: $breweryId
      membershipProgramId: $membershipProgramId
    ) {
      success
      updated
    }
  }
`
