import { gql } from '@apollo/client'

export const UPDATE_IS_MINIMAL_SETUP_DONE = gql`
	mutation updateBusinessInfo(
		$id:ID!
		$isMinimalSetupDone: Boolean
	) {
		updateBrewery(
			id: $id
			isMinimalSetupDone: $isMinimalSetupDone
		)
			{
				success
				brewery {
					name
				}
			}
	}`