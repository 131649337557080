import React, { useEffect, useRef } from 'react'
import {
  Box,
  Grid,
  Typography,
  Button,
  Checkbox,
  Radio,
  Switch,
  FormHelperText,
  CircularProgress,
  Accordion,
  AccordionDetails,
  AccordionSummary
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CommanClasses from '../themes/CommonClasses'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Color from '../themes/Colors'
import InputLabel from '../components/InputLabel'
import AddOutlinedIcon from '@material-ui/icons/AddOutlined'
import { findIndex, isEmpty, map, omit, startCase, get, filter, includes, find, uniq, isArray, keys } from 'lodash'
import Chips from '../components/AgChip'
import TimePicker2 from '../components/TimePicker2'
import DatePicker from '../components/DatePicker'
import AgButton from '../components/AgButton'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Check from '@material-ui/icons/Check'
import StepConnector from '@material-ui/core/StepConnector'
import { useState } from 'react'
import { getBreweryId, getBreweries, storeAndGetClubs, imageWidthParams } from '../Services/local'
import { useMutation, useQuery, useApolloClient } from '@apollo/client'
import { BREWERY_DOMAIN_WHITE_BOARD } from '../graphql/breweryDomainWhiteboard'
import moment from 'moment'
import { CREATE_OFFER } from '../graphql/createOfferGQL'
import Text from '../components/AgText'
import AGIcon from '../components/AGIcon'
import Image from '../components/Image'
import Pic from '../assets/images/ClubsRelated/Image2.png'
import { UPDATE_OFFER } from '../graphql/updateofferGQL'
import { useSnackbar } from 'notistack'
import { imageKitUploadApi } from '../Services/externalApi'
import ImageSelector from '../components/ImageSelector/ImageSelector'

const promoNameMaxLenth = 60

const ManageOfferDrawer = (props) => {
  const {
    benefitsMultiple = [],
    closeDrawer,
    existingOffer = {},
    mode = 'read',
  } = props || {}

  const { enqueueSnackbar } = useSnackbar()
  const apolloClient = useApolloClient()
  const breweryId = getBreweryId()

  const common = customStyles(CommanClasses)
  const classes = useStyles()
  const [clubSelected, setClubSelected] = useState('')
  const [locationBrewId, setLocationBrewId] = useState([])
  const [uploadingFile, setUploadingFile] = useState(false)
  const [expanded, setExpanded] = React.useState(false);
  const [uploadComplte, setUploadComplte] = React.useState('');

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };


  console.log('SELECTED BREW  ID IN CREATE PROMO>>>', locationBrewId)

  const breweries = getBreweries()
  const allClubsData = storeAndGetClubs()

  const { data: { breweryDomainWhiteboard = {} } = {} } = useQuery(
    BREWERY_DOMAIN_WHITE_BOARD
  )

  const breweriesList = map(breweries, (data) => {
    return { name: data?.brewery?.name, id: data?.brewery?.id, clubs: data?.brewery?.mugClubs }
  })

  const [clubsList, setClubsList] = useState([])
  const allClubsList = map(allClubsData, (data) => {
    return { name: data?.name, id: data?.id }
  })

  // const [getClubsDatas, { data: clubInfo }] = useLazyQuery(GetClubDetails, {
  //   variables: {
  //     breweryId,
  //   },
  //   onCompleted: (resp) => {
  //     if (resp) {
  //       setClubData(resp);
  //     }
  //   },
  // });

  // useEffect(() => {
  //   getClubsDatas({
  //     variables: {
  //       breweryId,
  //     },
  //   });
  // }, [breweryId]);


  const promoTargets = ['Everyone', 'Members', 'Upcoming Expiries', 'Preferences']

  const [createOffer, { loading: creatingOffer }] = useMutation(CREATE_OFFER, {
    onCompleted: (resp) => {
      setDisable(false)
      if (resp) {
        enqueueSnackbar('Promo created successfully.', { variant: 'success' })
      }
      if (resp && !isEmpty(benefitsMultiple)) {
        handleNext()
      } else {
        closeDrawer()
      }
    },
    onError: (err) => {
      console.log('err??', err)
    },
  })

  const [updateOffer, { loading: updatingOffer }] = useMutation(UPDATE_OFFER, {
    onCompleted: (resp) => {
      setDisable(false)
      if (resp) {
        console.log(resp)
        enqueueSnackbar('Promo updated successfully.', { variant: 'success' })
        props.getUpdatedData()
        closeDrawer()
      }
    },
    onError: (err) => {
      console.log('err??', err)
    },
  })

  const ap = JSON.parse(breweryDomainWhiteboard?.edges?.[0]?.value || '{}')

  const otherRequiredPref = {
    beerTypes: [],
    foodTypes: [],
    venueTypes: [],
    spiritsTypes: [],
    additionalTypes: [],
    coffeeTypes: [],
    meadTypes: [],
    wineTypes: [],
    ciderTypes: []
  }

  const allPreferences = !isEmpty(ap) ? { ...ap } : {}

  const offerImagePickerRef = useRef(null)

  const [possibleRedemption, setPossibleRedemption] = useState(
    existingOffer?.possibleRedemptions ? true : false
  )
  const [selectedRadio, setSelectedRadio] = useState(
    existingOffer?.redeemable ? 'redeem' : 'external_link'
  )
  const [toggleSwitchSchePromo, setToggleSwitchSchePromo] = useState(
    existingOffer?.scheduledDate && existingOffer?.expiringDate ? true : false
  )
  const [activeStep, setActiveStep] = useState(0)
  const [selectBeerPref, setSelectBeerPref] = useState(
    !isEmpty(existingOffer?.targetPreferences)
  )

  const [selectClubs, setSelectClubs] = useState(false)
  const [disable, setDisable] = useState(false)

  const [offerImage, setOfferImage] = useState(existingOffer?.banner?.url)
  const [drawerMode, setDrawerMode] = useState(
    !isEmpty(existingOffer) ? mode : ''
  )
  const [errorCollections, setErrorCollections] = useState({})
  const [cropedUrl, setCropedUrl] = useState()
  const [cropedFile, setCropedFile] = useState({})

  const handleCropedURL = (e) => {
    setCropedUrl(e)
  }

  const ImageKitUpload = async (file) => {
    setUploadingFile(true)

    const { url: imageUrl } = await imageKitUploadApi({
      apolloClient,
      fileName: `promoImage_${new Date().getTime()}.${file?.type?.split('/')?.[1]}`,
      file
    })
    setUploadComplte(imageUrl)
    setUploadingFile(false)

    setOfferImage(imageUrl)
    // setOfferData({ ...offerData, banner: { imageUrl } })
    setErrorCollections({ ...errorCollections, offerImage: '' })
    setCropedUrl('')
  }

  const handleCropedFile = async (e) => {
    if (!e) {
      return
    }
    let today = new Date()
    e.lastModified = today.getTime()
    e.lastModifiedDate = today
    e.webkitRelativePath = ""
    var file = new File([e], "name");

    if (file) {
      setCropedFile(file)
      // ImageKitUpload(file)
    }
  }


  function isValidURL(str) {
    var pattern = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
      'i'
    ) // fragment locator
    return !!pattern.test(str)
  }

  useEffect(() => {
    if (existingOffer?.targetClasses?.[0]?.membershipProgramMember || existingOffer?.targetClasses?.[0]?.membershipExpiration) {
      setSelectClubs(true)
    }
  }, [existingOffer])

  const isValidatedOfferData = () => {
    let errorCollectionsTmp = {}

    let errored = false

    if (!offerImage) {
      errored = true
      errorCollectionsTmp = {
        ...errorCollectionsTmp,
        offerImage: 'Please select promotion image to upload.',
      }
    }

    if (!offerData?.title) {
      errored = true
      errorCollectionsTmp = {
        ...errorCollectionsTmp,
        title: 'Please enter promotion name.',
      }
    }

    // if (!offerData?.subtitle) {
    //   errored = true
    //   errorCollectionsTmp = {
    //     ...errorCollectionsTmp,
    //     subtitle: 'Please enter promotion subtitle.',
    //   }
    // }

    if (!offerData?.body?.[0]?.markdown) {
      errored = true
      errorCollectionsTmp = {
        ...errorCollectionsTmp,
        body: 'Please enter promotion description.',
      }
    }

    if (selectBeerPref &&
      !(offerData?.targetPreferences?.beerTypes?.length
        || offerData?.targetPreferences?.foodTypes?.length
        || offerData?.targetPreferences?.spiritsTypes?.length
        || offerData?.targetPreferences?.venueTypes?.length
        || offerData?.targetPreferences?.additionalTypes?.length
        || offerData?.targetPreferences?.coffeeTypes?.length
        || offerData?.targetPreferences?.meadTypes?.length
        || offerData?.targetPreferences?.wineTypes?.length
        || offerData?.targetPreferences?.ciderTypes?.length
      )) {
      errored = true
      errorCollectionsTmp = {
        ...errorCollectionsTmp,
        targetPreferences: 'Please select targeted preferences below.',
      }
    }

    if (possibleRedemption && !offerData?.possibleRedemptions) {
      errored = true
      errorCollectionsTmp = {
        ...errorCollectionsTmp,
        possibleRedemptions: 'Please enter possible redemptions.',
      }
    }

    if (selectedRadio === 'external_link') {
      if (!offerData?.ctaLink) {
        errored = true
        errorCollectionsTmp = {
          ...errorCollectionsTmp,
          ctaLink: 'Please enter cta link.',
        }
      } else if (!isValidURL(offerData?.ctaLink)) {
        errored = true
        errorCollectionsTmp = {
          ...errorCollectionsTmp,
          ctaLink: 'Please enter valid cta link.',
        }
      }
    }

    if (selectClubs && !offerData?.membershipPrograms?.length) {
      errored = true
      errorCollectionsTmp = {
        ...errorCollectionsTmp,
        membershipPrograms: 'Please select clubs.',
      }
    }

    if (!selectClubs && !offerData?.hostBreweries?.length) {
      errored = true
      errorCollectionsTmp = {
        ...errorCollectionsTmp,
        hostBreweries: 'Please select brewery.',
      }
    }

    setErrorCollections(errorCollectionsTmp)

    return !errored
  };

  let offerDefaultObj = {
    active: true,
    title: isEmpty(benefitsMultiple) ? '' : benefitsMultiple[activeStep],
    body: [
      {
        markdown: '',
      },
    ],
    targetClasses: isEmpty(benefitsMultiple)
      ? []
      : [{ membershipProgramMember: 'MUG_CLUB' }],
    targetPreferences: null,
    ctaLink: '',
    redeemable: false,
    hostBreweries: [],
    membershipPrograms: []

  }

  if (!isEmpty(existingOffer)) {
    offerDefaultObj = {
      ...offerDefaultObj,
      active: existingOffer?.active,
      title: existingOffer?.title,
      subtitle: existingOffer?.subtitle,
      ctaLink: existingOffer?.ctaLink,
      targetClasses: existingOffer.targetClasses,
      redeemable: existingOffer?.redeemable,
      scheduledDate: existingOffer?.scheduledDate
        ? existingOffer?.scheduledDate
        : null,
      expiringDate: existingOffer?.expiringDate
        ? existingOffer?.expiringDate
        : null,
      hostBreweries: filter(breweriesList, bi => includes(existingOffer?.hostBreweries, bi.id)) || [],
      membershipPrograms: filter(allClubsList, ci => includes(existingOffer?.membershipPrograms, ci.id)) || []
    }

    if (!isEmpty(existingOffer?.body)) {
      offerDefaultObj = {
        ...offerDefaultObj,
        body: [
          {
            markdown: existingOffer?.body?.[0]?.markdown,
          },
        ],
      }
    }

    // if (!isEmpty(existingOffer?.targetClasses)) {
    //   offerDefaultObj = {
    //     ...offerDefaultObj,
    //     targetClasses: [
    //       {
    //         membershipProgramMember:
    //           existingOffer?.targetClasses?.[0]?.membershipProgramMember,
    //       },
    //     ],
    //   }
    // }

    if (!isEmpty(existingOffer?.targetPreferences)) {
      offerDefaultObj = {
        ...offerDefaultObj,
        targetPreferences: omit(existingOffer?.targetPreferences, [
          '__typename',
        ]),
      }
    }

    if (existingOffer?.possibleRedemptions) {
      offerDefaultObj = {
        ...offerDefaultObj,
        possibleRedemptions: existingOffer?.possibleRedemptions,
      }
    }

    //separate assignment for edit or clone
    if (existingOffer?.id) {
      offerDefaultObj = {
        ...offerDefaultObj,
        id: existingOffer?.id,
      }
    }
  }

  const isPromotionTargeted = () => {
    if (
      existingOffer?.targetClasses?.[0]?.membershipExpiration ||
      existingOffer?.targetClasses?.[0]?.membershipProgramMember
    ) {
      return {
        targetClasses: existingOffer?.targetClasses,
      }
    } else if (!isEmpty(existingOffer?.targetPreferences)) {
      return {
        targetPreferences: existingOffer?.targetPreferences,
      }
    } else {
      return false
    }
  }

  console.log('offerDefaultObj??', offerDefaultObj)

  const [offerData, setOfferData] = useState(offerDefaultObj)
  console.log('PROMO SCREEN', offerData)

  // const breweriesList = map(breweries, (data) => {
  //   return { name: data?.brewery?.name, id: data?.brewery?.id }
  // })

  console.log('$$$$$', breweriesList.name, '=============')

  // const clubsList = map(allClubsData, (data) => {
  //   return { name: data?.name, id: data?.id }
  // })


  const handleLocations = (e, v) => {
    let selectedHostBreweriesList = []
    // if(e === 'Location' && v?.length){
    //   selectedHostBreweriesList= [v[v.length - 1]]
    // } else {
    const index = findIndex(offerData.hostBreweries, ({ id }) => v[v?.length - 1]?.id === id);
    if (index === -1) {
      selectedHostBreweriesList = v
      // setOfferData({ ...offerData, hostBreweries: v })
    } else {
      const slicedArr = [...offerData.hostBreweries.slice(0, index), ...offerData.hostBreweries.slice(index + 1)];
      selectedHostBreweriesList = slicedArr
      // setOfferData({ ...offerData, hostBreweries: slicedArr })
    }
    // }
    setOfferData({ ...offerData, hostBreweries: selectedHostBreweriesList })
    let clubsListToDisplay = []
    if (selectedHostBreweriesList?.length) {
      for (let k = 0; k < selectedHostBreweriesList.length; k++) {
        if (selectedHostBreweriesList[k]?.clubs) {
          clubsListToDisplay = [...clubsListToDisplay, ...selectedHostBreweriesList[k].clubs]
        }
      }
    }
    let cloneClubsList = map(clubsListToDisplay, (data) => {
      return { name: data?.name, id: data?.id }
    })
    setClubsList(uniq(cloneClubsList, "id") || [])
  }


  useEffect(() => {
    if (existingOffer?.hostBreweries) {
      let ehBrew = filter(breweriesList, bi => includes(existingOffer?.hostBreweries, bi.id)) || []
      let clist = []
      for (let i = 0; i < ehBrew.length; i++) {
        if (ehBrew[i]?.clubs) {
          clist = [...clist, ...ehBrew[i].clubs]
        }
      }
      setClubsList(clist)
    }
  }, [existingOffer])

  const handleClubs = (e, v) => {
    // console.log("c??", e.target.value);
    console.log(e, v)

    const index = findIndex(offerData.membershipPrograms, ({ id }) => v[v?.length - 1]?.id === id);
    if (index === -1) {
      setOfferData({ ...offerData, membershipPrograms: v })
    } else {
      const slicedArr = [...offerData.membershipPrograms.slice(0, index), ...offerData.membershipPrograms.slice(index + 1)];
      setOfferData({ ...offerData, membershipPrograms: slicedArr })
    }
    // setClubSelected(v)
    // setErrorCollections({ ...errorCollections, membershipProgram: '' })
    // setOfferData({ ...offerData, membershipProgram: v })


    // if (c.length && find(selectedClubTags, data => data.id === c[c.length - 1].id)) {
    //   // let newT = filter(selectedClubTags, dt => dt.id !== c[c.length - 1].id)
    //   // setSelectedClubTags(newT)
    //   setClbsData({
    //     ...clbsData,
    //     // breweriesAssociatedIds: newT.map((d) => d.id)
    //   })
    // } else {
    //   setSelectedClubTags(c)
    //   setClbsData({
    //     ...clbsData,
    //     // breweriesAssociatedIds: c.map((d) => d.id)
    //   })
    // }
  };

  const handleNext = () => {
    if (activeStep === benefitsMultiple.length - 1) {
      //last step close drawer
      closeDrawer()
    } else {
      //before last step

      //set default offer data
      offerDefaultObj = omit(
        {
          ...offerDefaultObj,
          title: isEmpty(benefitsMultiple)
            ? ''
            : benefitsMultiple[activeStep + 1],
        },
        ['possibleRedemptions', 'scheduledDate', 'expiringDate', 'ctaLink']
      )

      setOfferData(offerDefaultObj)

      //reseting selected image & data
      setOfferImage('')
      setPossibleRedemption(false)
      setToggleSwitchSchePromo(false)
      setSelectedRadio('external_link')

      //increment step
      setActiveStep(activeStep + 1)
    }
  }

  console.log("breweries ??", breweries)

  const handleCreateOffer = () => {
    if (isValidatedOfferData()) {
      setDisable(true)
      let variables = { ...offerData, banner: { imageUrl: offerImage } }
      if (!isEmpty(offerData?.targetPreferences)) {
        let tpKeys = keys(offerData?.targetPreferences)
        let targetp = {}
        for (let k = 0; k < tpKeys.length; k++) {
          if (isArray(offerData?.targetPreferences[tpKeys[k]])) {
            targetp = { ...targetp, [tpKeys[k]]: offerData?.targetPreferences[tpKeys[k]] }
          } else {
            targetp = { ...targetp, [tpKeys[k]]: [] }
          }
        }
        variables = {
          ...variables,
          targetPreferences: {
            ...otherRequiredPref, ...targetp,
          }
        }
      }

      //Clubs Dropdown
      if (
        getSelectedTarget() === 'Members' ||
        getSelectedTarget() === 'Upcoming Expiries'
      ) {
        variables = {
          ...variables,
          membershipProgram: clubsList.find(cl => cl.name === clubSelected)?.id, //TODO: change for multiple clubs
        }
      } else {
        variables = {
          ...variables,
          membershipProgram: '',
        }
      }

      //Locations dropdown
      if (
        getSelectedTarget() === 'Everyone' ||
        getSelectedTarget() === 'Brewery Preferences'
      ) {
        variables = {
          ...variables,
          locations: locationBrewId,
        }
      } else {
        variables = {
          ...variables,
          locations: '',
        }
      }

      if (variables?.targetClasses?.[0]?.membershipExpiration?.expiration) {
        variables = {
          ...variables,
          targetPreferences: null,
          targetClasses: [
            {
              membershipExpiration: {
                program: 'MUG_CLUB',
                expiration: {
                  beforeDateTime: new Date(
                    new Date().setMonth(new Date().getMonth() + 1)
                  ).toString(),
                },
              },
            },
          ],
        }
      }

      if (variables?.targetClasses?.[0]?.membershipProgramMember) {
        variables = {
          ...variables,
          targetPreferences: null,
          targetClasses: [
            {
              membershipProgramMember: 'MUG_CLUB'
            }
          ],
        }
      }

      //format date & times
      if (variables?.scheduledDate) {
        variables = {
          ...variables,
          scheduledDate: moment(variables?.scheduledDate).utc().format(),
        }
      }

      if (variables?.expiringDate) {
        variables = {
          ...variables,
          expiringDate: moment(variables?.expiringDate).utc().format(),
        }
      }

      //if scheduledDate is in the furute or expiringDate is in the past then set promo as inactive
      if (
        variables?.scheduledDate &&
        variables?.expiringDate &&
        moment(variables?.scheduledDate).isValid() &&
        moment(variables?.expiringDate).isValid() &&
        (moment(variables?.scheduledDate).isAfter() ||
          !moment(variables?.expiringDate).isAfter())
      ) {
        variables = {
          ...variables,
          active: false,
        }
      }

      let breweryRelatedClubs = []
      const hostBreweries = map(offerData.hostBreweries, data => data.id) || []

      for (let i = 0; i < hostBreweries.length; i++) {
        let hostbrew = map(find(breweries, bi => bi.brewery.id === hostBreweries[i]).brewery.mugClubs, m => m.id) || []
        breweryRelatedClubs = [...breweryRelatedClubs, ...hostbrew]
      }
      console.log("breweryRelatedClubs ?", uniq(breweryRelatedClubs))

      if (selectClubs) {
        variables = {
          ...variables,
          membershipPrograms: map(offerData.membershipPrograms, data => data.id) || [],
          hostBreweries: hostBreweries
        }
      } else {
        variables = {
          ...variables,
          hostBreweries: hostBreweries || [],
          membershipPrograms: []
        }
      }

      //calling the mutation
      if (!offerData?.id) {
        createOffer({ variables })
      } else {
        updateOffer({ variables })
      }
    } else {
      setDisable(false)
    }
  }

  const handleRadioBtn = (value) => {
    if (value === 'external_link') {
      if (possibleRedemption) {
        enqueueSnackbar(
          'Uncheck redemptions limit before selecting external link'
        )
        return;
      } else {
        setOfferData({ ...offerData, redeemable: false, ctaLink: '' })
      }
    } else if (value === 'redeem') {
      setOfferData({ ...offerData, redeemable: true, ctaLink: '' })
    }
    setSelectedRadio(value)
  };

  const handleOfferStatus = (name, value) => {
    // reseting the cases changes

    setSelectBeerPref(false)

    switch (value) {
      case 'Active':
        if (
          offerData?.scheduledDate &&
          offerData?.expiringDate &&
          moment(offerData?.scheduledDate).isValid() &&
          moment(offerData?.expiringDate).isValid() &&
          (moment(offerData?.scheduledDate).isAfter() ||
            !moment(offerData?.expiringDate).isAfter())
        ) {
          if (moment(offerData?.scheduledDate).isAfter()) {
            enqueueSnackbar(
              'Promo will remain inactive till the start date, you can change the start date if you want it active from today.',
              { variant: 'warning' }
            )
          } else if (!moment(offerData?.expiringDate).isAfter()) {
            enqueueSnackbar(
              'Promo will remain inactive as the end date is a past date, you can change the end date if you want it active from today.',
              { variant: 'warning' }
            )
          }
        } else {
          setOfferData({
            ...offerData,
            active: true,
          })
        }
        break

      case 'Inactive':
        if (
          offerData?.scheduledDate &&
          offerData?.expiringDate &&
          moment(offerData?.scheduledDate).isValid() &&
          moment(offerData?.expiringDate).isValid() &&
          (!moment(offerData?.scheduledDate).isAfter() ||
            moment(offerData?.expiringDate).isAfter())
        ) {
          if (!moment(offerData?.scheduledDate).isAfter()) {
            enqueueSnackbar(
              'Please change the Schedule Start Date to change promo status to inactive.',
              { variant: 'warning' }
            )
          } else if (moment(offerData?.expiringDate).isAfter()) {
            enqueueSnackbar(
              'Please change the Schedule End Date to change promo status to inactive.',
              { variant: 'warning' }
            )
          }
        } else {
          setOfferData({
            ...offerData,
            active: false,
          })
        }
        break

      default:
        return
    }
  }
  const handleTargetClasses = (name, value) => {
    // reseting the cases changes
    console.log('THESETDROPDOWNVALUE>>>>', value)
    setErrorCollections({ ...errorCollections, targetPreferences: '' })
    setSelectBeerPref(false)

    switch (value) {
      case 'Everyone':
        setOfferData({
          ...offerData,
          targetPreferences: null,
          targetClasses: [],
          hostBreweries: [],
          membershipPrograms: []
        })
        setSelectClubs(false)
        break;

      case 'Members':
        setOfferData({
          ...offerData,
          targetPreferences: null,
          targetClasses: [{ membershipProgramMember: 'MUG_CLUB' }],
          hostBreweries: [],
          membershipPrograms: []
        })
        setSelectClubs(true)
        break;

      case 'Upcoming Expiries':
        setOfferData({
          ...offerData,
          hostBreweries: [],
          membershipPrograms: [],
          targetPreferences: null,
          targetClasses: [
            {
              membershipExpiration: {
                program: 'MUG_CLUB',
                expiration: {
                  beforeDateTime: new Date(
                    new Date().setMonth(new Date().getMonth() + 1)
                  ).toString(),
                },
              },
            },
          ],
        })
        setSelectClubs(true)
        break;

      case 'Preferences':
        setOfferData({
          ...offerData,
          targetClasses: [],
          hostBreweries: [],
          membershipPrograms: []
        })
        setSelectBeerPref(true)
        setSelectClubs(false)
        break;

      default:
        return
    }
  }

  const getSelectedTarget = () => {
    console.log('offerData?.targetClasses??', offerData?.targetClasses)
    console.log('offerData?. ??', offerData)
    console.log('existingOffer?. ??', existingOffer)
    if (isEmpty(offerData?.targetClasses) && !selectBeerPref) {
      return 'Everyone'
    } else if (
      !isEmpty(offerData?.targetClasses?.[0]?.membershipProgramMember)
    ) {
      return 'Members'
    } else if (
      !isEmpty(offerData?.targetClasses?.[0]?.membershipExpiration?.expiration)
    ) {
      return 'Upcoming Expiries'
    } else if (selectBeerPref) {
      return 'Preferences'
    }
  }

  const getSelectedStatus = () => {
    console.log('offerData?.targetClasses??', offerData?.targetClasses)
    if (offerData?.active) {
      return 'Active'
    } else {
      return 'Inactive'
    }
  }

  // const selectedPrefDefault = (profileDetails) => {
  //   let temHolder = {}
  //   map(allPreferences, (ap, apKey) => {
  //     if (isArray(ap)) {
  //       temHolder = { ...temHolder, [apKey]: [] }
  //     }
  //   })
  //   if (profileDetails) {
  //     map(
  //       profileDetails?.me?.communityMember?.consumerBreweryPreferences,
  //       (cbp, cbp_key) => {
  //         if (isArray(cbp)) {
  //           temHolder = { ...temHolder, [cbp_key]: cbp }
  //         }
  //       },
  //     )
  //   }
  //   return temHolder
  // }

  const handleSetSelectedPref = (all_pre_key, child_pre) => {
    const selected_index = findIndex(
      offerData?.targetPreferences?.[all_pre_key],
      (ap) => ap === child_pre.token
    )

    if (selected_index > -1) {
      const targetPreferences = {
        ...offerData?.targetPreferences,
        [all_pre_key]: [
          ...offerData?.targetPreferences?.[all_pre_key].slice(
            0,
            selected_index
          ),
          ...offerData?.targetPreferences?.[all_pre_key].slice(
            selected_index + 1
          ),
        ],
      }

      setOfferData({ ...offerData, targetPreferences })
    } else {
      const targetPreferences = {
        ...(offerData?.targetPreferences || {}),
        [all_pre_key]: [
          ...(offerData?.targetPreferences?.[all_pre_key] || []),
          child_pre.token,
        ],
      }

      setOfferData({ ...offerData, targetPreferences })
    }
  }

  const isSelected = (all_pre_key, child_pre) => {
    const selected_index = findIndex(
      offerData?.targetPreferences?.[all_pre_key] || [],
      (ap) => ap === child_pre.token
    )
    if (selected_index > -1) {
      return true
    } else {
      return false
    }
  }

  let orborder = 'orborder'
  let pelborder = 'pelborder'
  let orngborder = 'orngborder'
  let drkborder = 'drkborder'

  const colorsCombination = {
    // beerTypes: { orBorder: true },
    // foodTypes: { pelBorder: true },
    // spiritsTypes: { orngborder: true },
    // venueTypes: { drkBorder: true },
    // additionalTypes: { orBorder: true },
    beerTypes: { orborder },
    foodTypes: { pelborder },
    spiritsTypes: { orngborder },
    venueTypes: { drkborder },
    additionalTypes: { orborder },
    coffeeTypes: { orborder },
    meadTypes: { orborder },
    wineTypes: { orborder },
    ciderTypes: { orborder },
  }

  const randomColor = () => {
    var keys = Object.keys(colorsCombination)
    return colorsCombination[keys[(keys.length * Math.random()) << 0]]
  };

  const handleColor = (all_pre_key, child_pre) => {
    if (!isSelected(all_pre_key, child_pre)) {
      return { noBorder: true }
    } else {
      // all_pre_key = lowerCase(all_pre_key)
      let foundColor = randomColor()
      console.log('all_pre_key, child_pre??', all_pre_key, child_pre)

      map(colorsCombination, (cc, cc_key) => {
        if (all_pre_key.includes(cc_key)) {
          foundColor = cc
        }
      })

      return foundColor
    }
  }

  const handleOfferImagePicker = async (e) => {
    const file = e.target.files[0]
    if (!file) {
      return
    }
    ImageKitUpload(file)
  };

  const handleCloneOffer = () => {
    setOfferImage('')
    setOfferData({ ...omit(offerData, ['id']), hostBrewery: breweryId }) //removing id
    setDrawerMode('')
  };

  const renderView = (
    <div>
      {drawerMode !== 'read' ? (
        <div>
          {!isEmpty(benefitsMultiple) && (
            <Box
              py={1.25}
              px={2.5}
              mb={2.5}
              style={{
                background: '#F2F6FE 0% 0% no-repeat padding-box',
                borderRadius: 15,
                opacity: 1,
              }}
            >
              {activeStep === benefitsMultiple.length ? (
                <Typography
                  className={[
                    common.HeeboMedium,
                    common.textM,
                    common.textDark,
                  ].join(' ')}
                >
                  All steps completed - you&aposre finished
                </Typography>
              ) : (
                <Box display="flex">
                  <Typography
                    className={[
                      common.HeeboLight,
                      common.textM,
                      common.textDark,
                    ].join(' ')}
                  >
                    Benefit:{' '}
                  </Typography>
                  <Typography
                    className={[
                      common.HeeboMedium,
                      common.textM,
                      common.textDark,
                    ].join(' ')}
                  >
                    {benefitsMultiple[activeStep]}
                  </Typography>
                </Box>
              )}

              <Stepper
                alternativeLabel
                activeStep={activeStep}
                connector={<QontoConnector />}
                className={[classes.stepper].join(' ')}
              >
                {map(benefitsMultiple, (benefit, i) => (
                  <Step key={i}>
                    <StepLabel StepIconComponent={QontoStepIcon}></StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box>
          )}

          <Box mb={2.5}>
            {uploadingFile ? <div></div> : offerImage && isEmpty(cropedUrl) ? (
              <div>
                <Image
                  src={offerImage ? offerImage + imageWidthParams(418) : Pic}
                  xxl
                  radiusMd
                // onClick={() =>
                //   offerImagePickerRef.current?.click(handleOfferImagePicker)
                // }
                />

              </div>

            ) : (
              <div></div>
            )}
            <input
              ref={offerImagePickerRef}
              type="file"
              name="offerImage"
              hidden
              onChange={handleOfferImagePicker}
              accept="image/png, .jpeg, .jpg, image/gif"
            />

          </Box>
          <Box mb={2.5}>
            <ImageSelector
              cropedUrl={e => handleCropedURL(e)}
              cropedFile={(e) => handleCropedFile(e)}
              url={uploadComplte}
              buttonText={offerImage?.length ? 'Update Promo Image' : 'Add Promo Image'} />
          </Box>

          <Box mb={2.5}>
            {!isEmpty(cropedUrl) ?
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Button
                    variant="contained"
                    disableRipple={true}
                    className={classes.confirmButton}
                    onClick={() => {
                      setCropedUrl()
                      setUploadComplte(new Date().getTime())
                    }}
                  >
                    {'Cancel'}
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Button
                    variant="contained"
                    disableRipple={true}
                    className={classes.confirmButton}
                    onClick={() =>
                      ImageKitUpload(cropedFile)
                    }
                  >
                    {uploadingFile ? <CircularProgress /> : 'Confirm Upload'}
                  </Button>
                </Grid>
              </Grid>
              :
              <></>}
            {errorCollections?.offerImage && (
              <FormHelperText error>
                {errorCollections?.offerImage}
              </FormHelperText>
            )}
          </Box>


          <Box mb={2.5}>
            <InputLabel
              label="Promotion Name"
              value={offerData?.title}
              autoFocus
              maxLength={promoNameMaxLenth}
              error={false}
              onChangeValue={(name, value) => {
                setErrorCollections({ ...errorCollections, title: '' })
                setOfferData({ ...offerData, title: value })
              }}
            />
            {errorCollections?.title && (
              <FormHelperText error>{errorCollections?.title}</FormHelperText>
            )}
          </Box>
          <Box mb={2.5}>
            <InputLabel
              label="Subtitle"
              value={offerData?.subtitle}
              error={false}
              onChangeValue={(name, value) => {
                // setErrorCollections({ ...errorCollections, subtitle: '' })
                setOfferData({ ...offerData, subtitle: value })
              }}
            />
            {/* {errorCollections?.subtitle && (
              <FormHelperText error>{errorCollections?.subtitle}</FormHelperText>
            )} */}
          </Box>
          <Box mb={2.5}>
            <InputLabel
              label="Description"
              value={offerData?.body?.[0]?.markdown}
              multiline={4}
              error={false}
              onChangeValue={(name, value) => {
                setErrorCollections({ ...errorCollections, body: '' })
                setOfferData({ ...offerData, body: [{ markdown: value }] })
              }}
            />
            {errorCollections?.body && (
              <FormHelperText error>{errorCollections?.body}</FormHelperText>
            )}
          </Box>
          <Box mb={2.5}>
            <InputLabel
              select
              label="Promotion Target"
              value={getSelectedTarget()}
              options={promoTargets}
              onChangeValue={handleTargetClasses}
            />
            {errorCollections?.targetPreferences && (
              <FormHelperText error>
                {errorCollections?.targetPreferences}
              </FormHelperText>
            )}
          </Box>
          <Box mb={2.5} >
            {selectBeerPref &&
              map(allPreferences, (all_pre, all_pre_key) => {
                return (
                  <Accordion key={all_pre_key} expanded={expanded === `panel${all_pre_key}`} onChange={handleChange(`panel${all_pre_key}`)}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`panel${all_pre_key}bh-content`}
                      id={`panel${all_pre_key}bh-header`}
                    >
                      <Typography
                        className={[
                          common.HeeboLight,
                          common.textDark,
                          common.textS,
                          classes.mb10,
                        ].join(' ')}
                      >
                        {startCase(all_pre_key).split(' ')[0]}
                      </Typography>

                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container>
                        {map(all_pre, (child_pre, child_pre_key) => (
                          <Box
                            key={child_pre_key}
                            mr={1.25}
                            className={[classes.mb10].join(' ')}
                          >
                            <Chips
                              onClick={() => {
                                setErrorCollections({
                                  ...errorCollections,
                                  targetPreferences: '',
                                })
                                handleSetSelectedPref(all_pre_key, child_pre)
                              }}
                              label={child_pre?.label}
                              {...handleColor(all_pre_key, child_pre)}
                            />
                          </Box>
                        ))}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                )
              })}
          </Box>

          <Box mb={2.5}>
            <InputLabel
              select
              label="Promo Status"
              value={getSelectedStatus()}
              options={['Active', 'Inactive']}
              onChangeValue={handleOfferStatus}
            />
          </Box>

          <Box mb={2.5} display="flex" alignItems="center">
            <Checkbox
              className={[classes.checkbox].join(' ')}
              color="primary"
              checked={possibleRedemption}
              onClick={() => {
                //set redeem as true also
                if (!possibleRedemption) {
                  setErrorCollections({
                    ...errorCollections,
                    ctaLink: '',
                  })
                  handleRadioBtn('redeem')
                } else {
                  setOfferData({
                    ...offerData,
                    possibleRedemptions: 0,
                    ctaLink: '',
                  })
                }
                setPossibleRedemption(!possibleRedemption)
              }}
            />
            <Typography
              className={[
                common.HeeboLight,
                common.textS,
                common.textDark,
              ].join(' ')}
            >
              {'Set Redemptions Limit'}
            </Typography>
          </Box>

          {possibleRedemption && (
            <Box mb={2.5}>
              <InputLabel
                label="Total Possible Redemptions"
                type="number"
                value={offerData?.possibleRedemptions?.toString()}
                onChangeValue={(name, value) => {
                  setErrorCollections({
                    ...errorCollections,
                    possibleRedemptions: '',
                  })
                  setOfferData({
                    ...offerData,
                    possibleRedemptions: parseInt(value || '0'),
                  })
                }}
              />
              {errorCollections?.possibleRedemptions && (
                <FormHelperText error>
                  {errorCollections?.possibleRedemptions}
                </FormHelperText>
              )}
            </Box>
          )}

          <Box mb={2.5}>
            <Typography
              className={[
                common.HeeboLight,
                common.textDark,
                common.textS,
                classes.mb10,
              ].join(' ')}
            >
              Promo Redemption
            </Typography>
            <Box display="flex" alignItems="center">
              {map(
                [
                  {
                    label: 'External Link',
                    value: 'external_link',
                  },
                  {
                    label: 'Redeem',
                    value: 'redeem',
                  },
                ],
                ({ label, value }, indx) => (
                  <Box mr={3.75} display="flex" alignItems="center">
                    <Radio
                      className={[classes.radio].join(' ')}
                      color="primary"
                      onChange={() => {
                        setErrorCollections({
                          ...errorCollections,
                          ctaLink: '',
                        })
                        handleRadioBtn(value)
                      }}
                      checked={selectedRadio === value}
                    />
                    <Typography
                      className={[
                        common.HeeboLight,
                        common.textS,
                        common.textDark,
                      ].join(' ')}
                    >
                      {label}
                    </Typography>
                  </Box>
                )
              )}
            </Box>
          </Box>

          {selectedRadio === 'external_link' && (
            <Box mb={2.5}>
              <InputLabel
                label="URL"
                name={'ctaLink'}
                type="url"
                value={offerData?.ctaLink}
                onChangeValue={(name, value) => {
                  setErrorCollections({ ...errorCollections, ctaLink: '' })
                  setOfferData({ ...offerData, ctaLink: value })
                }}
              />
              {errorCollections?.ctaLink && (
                <FormHelperText error>
                  {errorCollections?.ctaLink}
                </FormHelperText>
              )}
            </Box>
          )}

          <Box mb={2.5}>
            <InputLabel
              select
              label={getSelectedTarget() === 'Members' || getSelectedTarget() === 'Upcoming Expiries' ? 'Location' : 'Locations'}
              name={getSelectedTarget() === 'Members' || getSelectedTarget() === 'Upcoming Expiries' ? 'Location' : 'Locations'}
              multiple={true}
              value={offerData.hostBreweries}
              options={breweriesList}
              onChangeValue={(e, v) => {
                setErrorCollections({ ...errorCollections, hostBreweries: '' })
                handleLocations(e, v)
              }}
            />
            {errorCollections?.hostBreweries && (
              <FormHelperText error>
                {errorCollections?.hostBreweries}
              </FormHelperText>
            )}
          </Box>

          {(getSelectedTarget() === 'Members' || getSelectedTarget() === 'Upcoming Expiries') && offerData.hostBreweries?.length ? (
            <Box mb={2.5}>
              <InputLabel
                select={clubsList.length ? true : false}
                label="Clubs"
                multiple={true}
                value={clubsList.length ? offerData.membershipPrograms : 'No Clubs Found'}
                options={clubsList}
                onChangeValue={(e, v) => {
                  setErrorCollections({ ...errorCollections, membershipPrograms: '' })
                  handleClubs(e, v)
                }}
              />
              {errorCollections?.membershipPrograms && (
                <FormHelperText error>{errorCollections?.membershipPrograms}</FormHelperText>
              )}
            </Box>
          ) : ''}

          <Box mb={2.5}>
            <Grid container justify="space-between" alignItems="center">
              <Typography
                className={[
                  common.HeeboLight,
                  common.textM,
                  common.textDark,
                ].join(' ')}
              >
                {'Schedule Promotion'}
              </Typography>
              <IOSSwitch
                checked={toggleSwitchSchePromo}
                onChange={() => {
                  setOfferData({
                    ...offerData,
                    scheduledDate: toggleSwitchSchePromo ? null : new Date(),
                    expiringDate: toggleSwitchSchePromo ? null : new Date(),
                  })
                  setToggleSwitchSchePromo(!toggleSwitchSchePromo)
                }}
              />
            </Grid>
          </Box>

          {toggleSwitchSchePromo && (
            <Box mb={3.75}>
              <Box mb={1.25}>
                <Box display="flex" alignItems="center">
                  <Box pr={2}>
                    <Typography
                      className={[
                        common.HeeboLight,
                        common.textS,
                        common.textDark,
                        classes.mb5,
                      ].join(' ')}
                    >
                      Start Date
                    </Typography>
                    <DatePicker
                      onChange={(dateValue) => {
                        const scheduledDate = moment(
                          moment(dateValue).format('L') +
                          ' ' +
                          moment(
                            moment(offerData?.scheduledDate).isValid()
                              ? offerData?.scheduledDate
                              : new Date()
                          ).format('LTS')
                        )
                          .utc()
                          .format()

                        console.log('scheduledDate??', scheduledDate)

                        //show snackbar if scheduledDate is in the future and promo status is set as active
                        if (
                          moment(scheduledDate).isAfter() &&
                          offerData?.active
                        ) {
                          setOfferData({
                            ...offerData,
                            active: false,
                            scheduledDate,
                          })
                          enqueueSnackbar(
                            'Promo will become active on the start date.',
                            { variant: 'warning' }
                          )
                        } else {
                          setOfferData({ ...offerData, scheduledDate })
                        }
                      }}
                      selectedDate={offerData?.scheduledDate || new Date()}
                    />
                  </Box>
                  <Box pl={2}>
                    <Typography
                      className={[
                        common.HeeboLight,
                        common.textS,
                        common.textDark,
                        classes.mb5,
                      ].join(' ')}
                    >
                      Start Time
                    </Typography>
                    <TimePicker2
                      onChange={(timeValue) => {
                        const scheduledDate = moment(
                          moment(
                            moment(offerData?.scheduledDate).isValid()
                              ? offerData?.scheduledDate
                              : new Date()
                          ).format('L') +
                          ' ' +
                          moment(timeValue).format('LTS')
                        )
                          .utc()
                          .format()

                        console.log('scheduledDate??', scheduledDate)

                        //show snackbar if scheduledDate is in the future and promo status is set as active
                        if (
                          moment(scheduledDate).isAfter() &&
                          offerData?.active
                        ) {
                          setOfferData({
                            ...offerData,
                            active: false,
                            scheduledDate,
                          })
                          enqueueSnackbar(
                            'Promo will become active on the start date.',
                            { variant: 'warning' }
                          )
                        } else {
                          setOfferData({ ...offerData, scheduledDate })
                        }
                      }}
                      value={offerData?.scheduledDate || new Date()}
                    />
                  </Box>
                </Box>
              </Box>

              <Box mb={1.25}>
                <Box display="flex" alignItems="center">
                  <Box pr={2}>
                    <Typography
                      className={[
                        common.HeeboLight,
                        common.textS,
                        common.textDark,
                        classes.mb5,
                      ].join(' ')}
                    >
                      End Date
                    </Typography>
                    <DatePicker
                      minDate={offerData?.scheduledDate || new Date()}
                      onChange={(dateValue) => {
                        const expiringDate = moment(
                          moment(dateValue).format('L') +
                          ' ' +
                          moment(
                            moment(offerData?.expiringDate).isValid()
                              ? offerData?.expiringDate
                              : new Date()
                          ).format('LTS')
                        )
                          .utc()
                          .format()

                        console.log('expiringDate??', expiringDate)

                        //show snackbar if expiringDate is in the past and promo status is set as active
                        if (
                          !moment(expiringDate).isAfter() &&
                          offerData?.active
                        ) {
                          setOfferData({
                            ...offerData,
                            active: false,
                            expiringDate,
                          })
                          enqueueSnackbar(
                            'Promo set to inactive as the end date is a past date, you can change the end date if you want it active from today.',
                            { variant: 'warning' }
                          )
                        } else {
                          setOfferData({ ...offerData, expiringDate })
                        }
                      }}
                      selectedDate={offerData?.expiringDate || new Date()}
                    />
                  </Box>
                  <Box pl={2}>
                    <Typography
                      className={[
                        common.HeeboLight,
                        common.textS,
                        common.textDark,
                        classes.mb5,
                      ].join(' ')}
                    >
                      End Time
                    </Typography>
                    <TimePicker2
                      onChange={(timeValue) => {
                        const expiringDate = moment(
                          moment(
                            moment(offerData?.expiringDate).isValid()
                              ? offerData?.expiringDate
                              : new Date()
                          ).format('L') +
                          ' ' +
                          moment(timeValue).format('LTS')
                        )
                          .utc()
                          .format()

                        console.log('expiringDate??', expiringDate)

                        //show snackbar if expiringDate is in the past and promo status is set as active
                        if (
                          !moment(expiringDate).isAfter() &&
                          offerData?.active
                        ) {
                          setOfferData({
                            ...offerData,
                            active: false,
                            expiringDate,
                          })
                          enqueueSnackbar(
                            'Promo set to inactive as the end date is a past date, you can change the end date if you want it active from today',
                            { variant: 'warning' }
                          )
                        } else {
                          setOfferData({ ...offerData, expiringDate })
                        }
                      }}
                      value={offerData?.expiringDate || new Date()}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          )}

          {!isEmpty(benefitsMultiple) ? (
            <Box display="flex" style={{ width: '100%' }}>
              <Box pr={1} style={{ width: '50%' }}>
                <AgButton
                  outlined
                  label={'Skip'}
                  textColor={'#FFFFFF'}
                  color={'#FA735F'}
                  fullWidth
                  onClick={handleNext}
                />
              </Box>
              <Box pl={1} style={{ width: '50%' }}>
                <AgButton
                  label={'Create Promotion'}
                  textColor={'#FFFFFF'}
                  color={'#FA735F'}
                  fullWidth
                  // loading={uploadingFile}
                  disabled={disable}
                  onClick={handleCreateOffer}
                />
              </Box>
            </Box>
          ) : (
            <AgButton
              label={!offerData?.id ? 'Create Promotion' : 'Update Promotion'}
              textColor={'#FFFFFF'}
              color={'#FA735F'}
              fullWidth
              disabled={disable}
              // loading={uploadingFile}
              onClick={handleCreateOffer}
            />
          )}
        </div>
      ) : (
        <div>
          <Box>
            <Box mb={2.5}>
              <Image src={offerImage + imageWidthParams(418)} xxl radiusMd />
            </Box>
            {/* <Box mb={2.5}>
              <Grid container justify='space-between' alignItems='center'>
                <Typography
                  className={[
                    common.HeeboLight,
                    common.textM,
                    common.textDark,
                  ].join(' ')}
                >
                  {'Deactivate Promotion'}
                </Typography>
                <IOSSwitch
                  checked={offerData?.active}
                  onChange={() => togglePromoActivation()}
                  name='deactivatePromotion'
                />
              </Grid>
            </Box> */}
            <Box mb={2.5}>
              <InputLabel
                read
                label="Subtitle"
                readValue={offerDefaultObj?.subtitle || 'Update Subtitle'}
              />
            </Box>
            <Box mb={2.5}>
              <InputLabel
                read
                label="Description"
                readValue={
                  offerDefaultObj?.body?.[0]?.markdown || 'Update descriptions'
                }
              />
            </Box>
            <Box mb={2.5}>
              <InputLabel
                read
                label="Redeemed:"
                readValue={
                  existingOffer?.possibleRedemptions
                    ? (existingOffer?.totalRedeemed || 0) +
                    '/' +
                    existingOffer?.possibleRedemptions +
                    ' (' +
                    (
                      ((existingOffer?.totalRedeemed || 0) /
                        existingOffer?.possibleRedemptions) *
                      100
                    )?.toFixed(2) +
                    '%' +
                    ')'
                    : existingOffer?.totalRedeemed || 0
                }
              />
            </Box>
            <Box mb={2.5}>
              <Typography
                className={[
                  common.HeeboLight,
                  common.textS,
                  common.textDark,
                  classes.mb10,
                ].join(' ')}
              >
                {'Promotion Target'}
              </Typography>
              {!isPromotionTargeted() ? (
                <Typography
                  className={[
                    common.HeeboRegular,
                    common.textS,
                    common.textDark,
                    classes.mb10,
                  ].join(' ')}
                >
                  {'Everyone'}
                </Typography>
              ) : isPromotionTargeted()?.targetClasses ? (
                <Typography
                  className={[
                    common.HeeboRegular,
                    common.textS,
                    common.textDark,
                    classes.mb10,
                  ].join(' ')}
                >
                  {isPromotionTargeted()?.targetClasses?.[0]
                    ?.membershipProgramMember === 'MUG_CLUB'
                    ? 'Members'
                    : isPromotionTargeted()?.targetClasses?.[0]
                      ?.membershipExpiration?.program === 'MUG_CLUB'
                      ? 'Upcoming Expiries'
                      : ''}
                </Typography>
              ) : isPromotionTargeted()?.targetPreferences ? (
                <>
                  <Typography
                    className={[
                      common.HeeboRegular,
                      common.textS,
                      common.textDark,
                      classes.mb10,
                    ].join(' ')}
                  >
                    {'Preferences'}
                  </Typography>
                  <Grid container direction="row">
                    {map(
                      isPromotionTargeted()?.targetPreferences?.beerTypes,
                      (beer, f) => (
                        <Box mr={1.25} className={[classes.mb10].join(' ')}>
                          <Chips label={beer} orBorder />
                        </Box>
                      )
                    )}
                  </Grid>
                </>
              ) : (
                ''
              )}
            </Box>
            {existingOffer?.ctaLink && (
              <Box mb={2.5}>
                <InputLabel
                  read
                  label="Promo Redemption"
                  readValue={existingOffer?.ctaLink}
                  url
                />
              </Box>
            )}

            {!isEmpty(existingOffer?.scheduledDate) &
              !isEmpty(existingOffer?.expiringDate) ? (
              <Box mb={2.5} display="flex">
                <Box mr={6.25}>
                  <InputLabel
                    read
                    label="Schedule Start Date & Time"
                    readValue={moment(existingOffer?.scheduledDate).format(
                      'lll'
                    )}
                  />
                </Box>
                <Box mr={6.25}>
                  <InputLabel
                    read
                    label="Schedule End Date & Time"
                    readValue={moment(existingOffer?.expiringDate).format(
                      'lll'
                    )}
                  />
                </Box>
              </Box>
            ) : (
              ''
            )}

            <Box mb={1.25}>
              <AgButton
                outlined
                label={'Edit'}
                textColor={'#FFFFFF'}
                color={'#FA735F'}
                fullWidth
                onClick={() => setDrawerMode('')}
              />
            </Box>
            <Box mb={1.25}>
              <AgButton
                label={'Clone'}
                textColor={'#FFFFFF'}
                color={'#FA735F'}
                fullWidth
                onClick={handleCloneOffer}
              />
            </Box>
          </Box>
        </div>
      )}
    </div>
  )

  if (isEmpty(benefitsMultiple)) {
    return (
      <Box px={2} pb={2}>
        <Grid container className={common.relativePosition}>
          <Grid
            container
            xs={12}
            justify="space-between"
            className={[common.bgWhite, classes.drawerBody].join(' ')}
          >
            <Grid item xs>
              <Text
                label={
                  <Box display="flex">
                    <Typography
                      className={[
                        common.HeeboMedium,
                        common.textPrimary,
                        common.textXl,
                        classes.mr20,
                      ].join(' ')}
                    >
                      {drawerMode === 'read'
                        ? existingOffer?.title
                        : !existingOffer?.id
                          ? 'Create Promotion'
                          : 'Edit Promotion'}
                    </Typography>
                    {drawerMode === 'read' ? (
                      <Box
                        ml={2}
                        component="span"
                        py={0.5}
                        px={2}
                        className={[
                          common.borderRadiusXs,
                          common.textWhite,
                          common.textM,
                          offerData?.active ? common.bgSuccess : common.bgPink,
                          classes.statusBox,
                        ].join(' ')}
                      >
                        {offerData?.active ? 'Active' : 'Inactive'}
                      </Box>
                    ) : (
                      ''
                    )}
                  </Box>
                }
              />
            </Grid>
            <Grid item>
              <Box pl={1} pt={0.5}>
                <AGIcon
                  name={'icon-Close'}
                  onClick={props.closeDrawer}
                  color={Color.light}
                  size="14"
                />
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Box my={2}>{renderView}</Box>
          </Grid>
        </Grid>
      </Box>
    )
  }

  return renderView
};

const customStyles = makeStyles(CommanClasses)

const useStyles = makeStyles((theme) => ({
  confirmButton: {
    width: '100%',
    background: `${Color.main} 0% 0% no-repeat padding-box`,
    border: `1px ${Color.inputBorder}`,
    borderRadius: 15,
    opacity: 1,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: Color.main,
      boxShadow: 'none',
    },
  },
  button: {
    width: 423,
    height: 233,
    background: `${Color.main} 0% 0% no-repeat padding-box`,
    border: `1px ${Color.inputBorder}`,
    borderRadius: 15,
    opacity: 1,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: Color.main,
      boxShadow: 'none',
    },
  },
  drawerBody: {
    position: 'sticky',
    top: 0,
    zIndex: 2,
    padding: '16px 0',
  },
  textField: {
    background: `${Color.white} 0% 0% no-repeat padding-box`,
    borderColor: Color.inputBorder,
    borderRadius: 5,
    opacity: 1,
    '& .MuiOutlinedInput-root': {
      border: `1px ${Color.inputBorder}`,
      borderRadius: 5,
    },
  },
  mb20: { marginBottom: 20 },
  mb10: { marginBottom: 10 },
  mb5: { marginBottom: 5 },
  stepper: {
    marginTop: 10,
    background: '#F2F6FE 0% 0% no-repeat padding-box',
    padding: 0,
  },
  checkbox: {
    borderRadius: '5px',
    marginRight: 7,
    padding: 0,
  },
  radio: {
    padding: 0,
    marginRight: 5,
  },
  root: {
    width: '100%',
  },
  statusBox: {
    maxHeight: 20,
  },
  chip: {
    margin: theme.spacing(1),
  },
}))

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#EA952D',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#EA952D',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  )
})

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 7.5px)',
    right: 'calc(50% + 7.5px)',
  },
  active: {
    '& $line': {
      borderColor: '#DEE2F1',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#DEE2F1',
    },
  },
  line: {
    borderColor: '#F2F6FE',
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector)

const useQontoStepIconStyles = makeStyles({
  root: {
    color: '#DEE2F1',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    width: '100%',
  },
  active: {
    color: '#FB6A55',
  },
  circle: {
    width: 15,
    height: 15,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  circle2: {
    width: 15,
    height: 15,
    borderRadius: '50%',
    backgroundColor: '#ffffff',
  },
  completed: {
    color: '#41D691',
    zIndex: 1,
    fontSize: 15,
  },
})

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles()
  const { active, completed } = props

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? (
        <div className={classes.circle2}>
          <Check className={classes.completed} />
        </div>
      ) : (
        <div className={classes.circle} />
      )}
    </div>
  )
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
}

export default ManageOfferDrawer
