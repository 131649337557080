import React, { useState, useEffect } from 'react'
import { Box, Grid } from '@material-ui/core'
import { map, get } from 'lodash'
import { useLazyQuery } from '@apollo/client'
import SimpleTextCard from '../components/SimpleTextCard'
import { GetClubFields } from '../graphql/clubDetailsGQL'
import { GetMembershipRevenueFields } from '../graphql/dashboardFileds'
import LineChart from '../components/LineChart'
import AGCard from '../components/AGCard'
import Color from '../themes/Colors'
import AGIcon from '../components/AGIcon'
import moment from 'moment'
import DropDown from '../components/Dropdown'
import { __years } from '../Services/local'

export default (props) => {
  const { breweryId, selectedBreweryClubIds } = props
  const windowWidth = window.innerWidth

  const sizeVal = windowWidth < 600 ? 200 : 190

  //Revenue Amount//
  const [result, setResult] = useState()
  const [revenueData, setRevenueData] = useState()

  let revenueStartDate = '2015-03-19T09:47:22Z' //it should fixed date

  const [getRevenue, { data: membershipRevenue }] = useLazyQuery(
    GetMembershipRevenueFields,
    {
      onCompleted: (resp) => {
        setResult(resp)
      },
    }
  )
  ///Revenue Graph///
  const [allGraphData, setAllGraphdata] = useState([])
  const [filterGraphStr, setFilterGraphStr] = useState(moment().year())

  useEffect(() => {
    if (breweryId) {
      getRevenue({
        variables: {
          breweryId,
          revenueStartDate,
          revenueEndDate: moment().utc().format(),
        },
      })
    }
  }, [breweryId])

  useEffect(() => {
    let year = filterGraphStr.toString() || new Date().getFullYear().toString()
    
    if (breweryId) {
      getClubFields({
        variables: {
          breweryId,
          startDate: moment(year).startOf("year").format(),
          endDate: moment(year).endOf("year").format(),
          mugClubs: selectedBreweryClubIds
        },
      })
    }
  }, [breweryId, filterGraphStr, selectedBreweryClubIds])

  let revenue = membershipRevenue?.brewery?.brewery?.platformRevenue
  if (revenue) {
    revenue = revenue.replace('USD', '')//USD 0.00
  }




  let defaultCounts = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]

  const [getClubFields, { data: clubDatas }] = useLazyQuery(GetClubFields, {
    variables: {
      breweryId,
    },
    onCompleted: (resp) => {
      if (!resp) {
        return
      }

      let output = []

      map(get(resp, 'brewery.brewery.mugClubs'), (dt) => {
        map(get(dt, "amountCollected.amountCollectedStats"), (d) => {
          // console.log("D...", d);

          if (!output[d.year]) {
            output[d.year] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
          }

          output[d.year][getM(d.month)] =
            (output[d.year][getM(d.month)] || 0) +
            parseFloat(d.amountCollected.replace(/USD\s+/, ''))
        })
      })

      setAllGraphdata(output)
    },
    notifyOnNetworkStatusChange: true,
  })

  const setSelectedYear = (year) => {
    setFilterGraphStr(year)
  }

  const getM = (m) => {
    const monthIdx = [
      'jan',
      'feb',
      'mar',
      'apr',
      'may',
      'jun',
      'jul',
      'aug',
      'sep',
      'oct',
      'nov',
      'dec',
    ]

    const idx = monthIdx.findIndex((m1) => m1 == m?.toLowerCase())

    console.log('Month is', idx)

    return idx
  };

  return (
    <AGCard
      fullHeight
      cardHeader={
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <Box mr={1}>Membership Revenue</Box>
          </Grid>
          <Grid item>
            <Box>
              <DropDown
                size={'medium'}
                icon={<AGIcon name={'icon-Up'} size={6} />}
                color="primary"
                variant={'outlined'}
                placement="bottom"
                initialValue={filterGraphStr}
                list={__years}
                selectedValue={(e) => setSelectedYear(e)}
              />
            </Box>
          </Grid>
        </Grid>
      }
      cardContent={
        <Grid container>
          <Grid item lg={3}>
            <Box mt={1}>
              <SimpleTextCard
                numberPrimaryColor
                secondary
                number={`$ ${revenue || 0}`}
                text={'Total Revenue'}
                richTextColor={Color.success}
                variant={'column'}
              />
            </Box>
          </Grid>
          <Grid item lg={9}>
            <Box mt={1}>
              <LineChart
                colors={['#273179', '#FF826E']}
                stepSize={10000}
                height={sizeVal}
                yAxesLabel={'Revenue in (USD)'}
                xAxesLabel={'Months'}
                labels={[
                  'Jan',
                  'Feb',
                  'Mar',
                  'Apr',
                  'May',
                  'Jun',
                  'Jul',
                  'Aug',
                  'Sep',
                  'Oct',
                  'Nov',
                  'Dec',
                ]}
                datasets={[
                  {
                    label: 'Revenue',
                    data: allGraphData[filterGraphStr] || defaultCounts,
                  },
                ]}
              />
            </Box>
          </Grid>
        </Grid>
      }
    />
  )
};
