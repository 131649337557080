import gql from 'graphql-tag'

export const getOffersStatsCount = gql`
  query offersStatsCount(
    $hostBreweries: [String]
    $membershipProgramIds: [String]
    $startDate: String
    $endDate: String
  ) {
    offersStatsCount(hostBreweries: $hostBreweries, membershipProgramIds: $membershipProgramIds,startDate: $startDate, endDate: $endDate ) {
        activeOffersCount
        inActiveOffersCount
        completedOffersCount
        totalOffersCount
        totalRedeemed
        redemptionStats {
          totalRedeemed
        }
    }
  }
`
