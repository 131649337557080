import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { BrowserRouter } from 'react-router-dom'
import AuthenticatedApp from './AuthenticatedApp'
import UnauthenticatedApp from './UnauthenticatedApp'
import { LinearProgress } from '@material-ui/core'

export const Routes = () => {
  const { isLoading, error, isAuthenticated } = useAuth0()

  if (isLoading) {
    return <><LinearProgress /></>
  }

  // if (error) {
  //   console.error('error from useAuth0??', error)
  //   return <>Errored.</>
  // }

  return (
    <BrowserRouter>
      {isAuthenticated ? <AuthenticatedApp /> : <UnauthenticatedApp />}
    </BrowserRouter>
  )
}
