import { gql } from '@apollo/client'

export const UPDATE_OFFER = gql`
	mutation updateOffer(
		$id: ID!
		$hostBreweries: [String]
		$scheduledDate: String
		$expiringDate: String
    	$membershipPrograms: [String]
		$title: String
		$subtitle: String
		$body: [TextComponentInput!]
		$ctaLink: String
		$ctaText: String
		$possibleRedemptions: Int
		$redeemable: Boolean
		$targetPreferences: ConsumerBreweryPreferencesInput
		$targetClasses: [TargetClassInput!]
		$active: Boolean
		$banner: PhotoUpload
	) {
		updateOffer(
		id: $id
		scheduledDate: $scheduledDate
		expiringDate: $expiringDate
    	membershipPrograms: $membershipPrograms
		title: $title
		subtitle: $subtitle
		body: $body
		ctaLink: $ctaLink
		ctaText: $ctaText
		possibleRedemptions: $possibleRedemptions
		redeemable: $redeemable
		targetPreferences: $targetPreferences
		targetClasses: $targetClasses
		active: $active
		banner: $banner
		hostBreweries: $hostBreweries
		)
			{
				success
			}
	}`