import React from 'react'
import { Card, Grid, Typography, Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CommanClasses from '../../themes/CommonClasses'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import Image from '../../components/Image'
import { map } from 'lodash'
import Color from '../../themes/Colors'
import MyPic from '../../assets/images/undraw_word_of_mouth_v1j9.svg'
import AgButton from '../../components/AgButton'
const customStyles = makeStyles(CommanClasses)
const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: Color.white
    },
    card: {
        width: 435,
        margin: 10,
    },
}))
function Payments() {
    const common = customStyles(CommanClasses)
    const classes = useStyles()
    return (
        <Box className={classes.root} p={0}>
            <Grid container alignItems='center'>
                <Grid item md={5}>
                    <Image src={MyPic} xxxl />
                </Grid>
                <Grid item>
                    <Typography className={[common.HeeboRegular, common.textM, common.textDark].join(' ')}>
                        Setup to receive your payments. <br />You can add payment details for your Subscription and for receiving customer payments
                    </Typography>
                    <Box mt={3}><AgButton label='Setup Payment' smblue /></Box>
                </Grid>
            </Grid>
        </Box>
    )
}

export default Payments
