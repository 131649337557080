import React, { useRef, useState, useEffect } from 'react'
import moment from 'moment'
import { OffersIndexQueryDocument } from '../graphql/Offers/OffersIndexQuery'
import { useLazyQuery, useMutation } from '@apollo/client'
import { map, omit, flow, partialRight, keyBy, values,get, filter, isEmpty } from 'lodash'
import { getBreweryId, imageWidthParams } from '../Services/local'
import { DELETE_OFFER } from '../graphql/deleteOfferGQL'
import { UPDATE_OFFER } from '../graphql/updateofferGQL'
import DropDown from '../components/Dropdown'
import {
  Grid,
  Box,
  Typography,
  Toolbar,
  Tooltip,
  Chip,
  LinearProgress,
  Icon,
  TableCell,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Button
} from '@material-ui/core'
import Image from '../components/Image'
import { makeStyles } from '@material-ui/core/styles'
import CommanClasses from '../themes/CommonClasses'
import AGIcon from '../components/AGIcon'
import Popup from '../components/Popup'
import Drawer from '../components/Drawer'
import Color from '../themes/Colors'
import ManageOfferDrawer from '../containers/ManageOfferDrawer'
import { useSnackbar } from 'notistack'

const today = moment().utc().format()
const expdate = moment().utc().add(1, 'months').format()
const recentDate = moment().utc()
  .subtract(1, 'months')
  .format()

export default (props) => {
  const { refreshData, selectedClubIds, breweryId, type, updateOffersCount } = props || {}

  const { enqueueSnackbar } = useSnackbar()

  const promotionList = ['All Promotions', 'Members Only', 'Non Members']
  const common = customStyles(CommanClasses)
  const classes = useStyles(CommanClasses)

  const [drawerState, setDrawerState] = useState(false)
  const [selectedOffer, setSelectedOffer] = useState({})
  const [filterAction, setFilterAction] = useState('All')
  const [offerDrawerMode, setOfferDrawerMode] = useState('')
  const [promoSelect, setPromoSelect] = useState('All Promotions')
  const paginationData = useRef({})
  const [allPromoData, setAllPromoData] = useState([])
  const [selectedFilter, setSelectedFilter] = useState('All')
  const [promoActions, setPromoActions] = useState()
  const [ids, setIds] = useState({
    breweryId: breweryId,
    membershipProgramIds: selectedClubIds
  })
  const [drawerMode, setDrawerMode] = useState('read')
  const [drawerData, setDrawerData] = useState({})
  const [timeFilter, setTimeFilter] = useState("All Time")

  const { items: filterPromoData, requestSort, sortConfig } = useSortableData(allPromoData)
  useEffect(() => {
    setIds({
      breweryId: breweryId,
      membershipProgramIds: selectedClubIds
    })
  }, [breweryId, selectedClubIds])

  const lastUniqBy = iteratee => flow(
    partialRight(keyBy, iteratee),
    values
  )

  const [
    geAlltOffersData,
    { error: allOffersError, loading: allOffersLoading, refetch: getUpdatedData },
  ] = useLazyQuery(OffersIndexQueryDocument, {
    onCompleted: (resp) => {
      if (!resp) { return }

      const edges = resp?.brewery?.brewery?.activeOffersConnection?.edges || []
      const hasMore = resp?.brewery?.brewery?.activeOffersConnection?.pageInfo?.hasMore || false
      const cursor = edges.length ? edges[edges.length - 1].cursor : ''
      setAllPromoData(lastUniqBy('breweryOfferCampaign.id')([...allPromoData, ...edges]))
      paginationData.current = { hasMore, cursor }
    },
    onError: (error) => {
      console.log('error', error)
    },
    notifyOnNetworkStatusChange: true,
  })

  const [deleteOffer, deleteOfferResp] = useMutation(
    DELETE_OFFER,
    {
      onCompleted: (resp) => {
        if (resp) {
          updateOffersCount(true)
          setAllPromoData([...allPromoData.filter(ap => ap.breweryOfferCampaign.id !== resp?.deleteOffer?.offerId)])
          console.log(resp)
        }
      },
      onError: (err) => {
        console.log('err??', err)
      },
    }
  )

  useEffect(() => {
    if(refreshData){
      getUpdatedData()
    }
  },[refreshData])

  const getPromoResults = () => {
    let variables = {
      id: ids.breweryId,
      // membershipProgramIds: selectedClubIds || [],
      page: {
        after: paginationData.current?.cursor || '',
        maxResultCount: 20,
      },
    }
    if (type?.name === 'Clubs') {
      variables = { ...variables, membershipProgramIds: ids.membershipProgramIds || [] }
    } else {
      variables = { ...variables, hostBreweries: [ids.breweryId] || [] }
    }

    if (selectedFilter === 'All') {
      geAlltOffersData({
        variables: {
          ...variables
        }
      })
    } else if (selectedFilter === 'Recent') {
      geAlltOffersData({
        variables: {
          ...variables,
          order: { orderBy: 'START_DATE', order: 'DESC' }
        }
      })
    } else if (selectedFilter === 'Popular') {
      geAlltOffersData({
        variables: {
          ...variables,
          popular: true,
          active: true
        }
      })

    } else if (selectedFilter === 'Expiring') {
      geAlltOffersData({
        variables: {
          ...variables,
          startDate: today,
          endDate: expdate

        }
      })

    } else if (selectedFilter === 'Inactive') {
      geAlltOffersData({
        variables: {
          ...variables,
          active: false
        }
      })

    } else {
      geAlltOffersData({
        variables: {
          ...variables,
          ...durationWiseDate[selectedFilter]
        }
      })
    }
  }

  const getOfferData = (e) => {
    let variables = {
      id: ids.breweryId,
      ...durationWiseDate[e],
      page: {
        after: paginationData.current?.cursor || '',
        maxResultCount: 20,
      },
    }

    if (type?.name === 'Clubs') {
      variables = { ...variables, membershipProgramIds: ids.membershipProgramIds || [] }
    }

    geAlltOffersData({
      variables: { ...variables },
    })
  }

  useEffect(() => {
    setAllPromoData([])
    paginationData.current = {}
    getPromoResults()

  }, [selectedFilter, ids, type])

  // useEffect(() => {
  //   getOfferData(selectedFilter)
  // }, [selectedFilter, ids])

  const handlePromoFilter = (e) => {
    setSelectedFilter(e)
    setFilterAction(e)
  }

  const handleCloseOfferDrawer = () => {
    setDrawerState(!drawerState)
    setOfferDrawerMode('')
    setSelectedOffer({})
  }
  // "All Promotions", "Members Only", "Non Members"
  const getPromoTargetedTo = offerData => {
    let selectBeerPref = false

    map(offerData?.targetPreferences, tp => {
      if (!isEmpty(tp)) {
        selectBeerPref = true
      }
    })

    if (isEmpty(offerData?.targetClasses) && !selectBeerPref) {
      return 'Non Members'
    } else if (
      !isEmpty(offerData?.targetClasses?.[0]?.membershipProgramMember)
    ) {
      return 'Members Only'
    } else if (
      !isEmpty(
        offerData?.targetClasses?.[0]?.membershipExpiration?.expiration
      )
    ) {
      return 'Upcoming Expiries'
    } else if (selectBeerPref) {
      return 'Beer Preferences'
    }
  }

  const handleCardClick = (e, { breweryOfferCampaign }) => {
    setSelectedOffer(breweryOfferCampaign)
    setDrawerState(!drawerState)
    setOfferDrawerMode('read')
  }

  const handleOfferAction = (action, offerItem) => {
    console.log('action, offerItem 1??', action, offerItem)

    const offerData = offerItem?.breweryOfferCampaign || {}
    const { id } = offerData

    if (action === 'Delete') {
      if (allPromoData?.length > 1) {
        deleteOffer({ variables: { offerId: id } })
      } else {
        enqueueSnackbar('You are not allowed to delete the last offer.', { variant: 'error' })
      }
    } else if (action === 'Deactivate') {
      if (allPromoData?.length > 1) {
        updateOffer({ variables: { id: id, active: false } })
      } else {
        enqueueSnackbar('You are not allowed to deactivate the last offer.', { variant: 'error' })
      }
    } else if (action === 'Activate') {
      updateOffer({ variables: { id: id, active: true } })
    } else if (action === 'Edit') {
      setSelectedOffer(offerItem?.breweryOfferCampaign)
      setOfferDrawerMode('')
      setDrawerState(!drawerState)
    } else if (action === 'Clone') {
      let freshOffer = omit(offerItem?.breweryOfferCampaign, ['id', 'banner'])  //removing id & promo banner
      setSelectedOffer(freshOffer)
      setOfferDrawerMode('')
      setDrawerState(!drawerState)
    }
  }

  const handlePromoAction = (action, offerItem) => {
    console.log('action, offerItem 2??', action, offerItem)

    const offerData = offerItem?.breweryOfferCampaign || {}

    switch (action) {
      case 'Activate':
        if (offerData?.scheduledDate && offerData?.expiringDate && moment(offerData?.scheduledDate).isValid() && moment(offerData?.expiringDate).isValid() && (moment(offerData?.scheduledDate).isAfter() || !moment(offerData?.expiringDate).isAfter())) {
          if (moment(offerData?.scheduledDate).isAfter()) {
            enqueueSnackbar('Promo will remain inactive till the start date, you can change the start date if you want it active from today.', { variant: 'warning' })
          } else if (!moment(offerData?.expiringDate).isAfter()) {
            enqueueSnackbar('Promo will remain inactive as the end date is a past date, you can change the end date if you want it active from today.', { variant: 'warning' })
          }
        } else {
          handleOfferAction(action, offerItem)
          setPromoActions(action)
        }
        break

      case 'Deactivate':
        if (offerData?.scheduledDate && offerData?.expiringDate && moment(offerData?.scheduledDate).isValid() && moment(offerData?.expiringDate).isValid() && (!moment(offerData?.scheduledDate).isAfter() || moment(offerData?.expiringDate).isAfter())) {
          if (!moment(offerData?.scheduledDate).isAfter()) {
            enqueueSnackbar('Please change the Schedule Start Date to change promo status to inactive.', { variant: 'warning' })
          } else if (moment(offerData?.expiringDate).isAfter()) {
            enqueueSnackbar('Please change the Schedule End Date to change promo status to inactive.', { variant: 'warning' })
          }
        } else {
          handleOfferAction(action, offerItem)
          setPromoActions(action)
        }
        break

      default:
        handleOfferAction(action, offerItem)
        setPromoActions(action)
    }
  }

  const [updateOffer, updateOfferResp] = useMutation(
    UPDATE_OFFER,
    {
      onCompleted: (resp) => {
        if (resp) {
          // getPromoResults()
          updateOffersCount(true)
          getUpdatedData()
          console.log(resp)
        }
      },
      onError: (err) => {
        console.log('err??', err)
      },
    }
  )

  const renderOffers = () => {
    const headCells = [
      {
        id: '',
        label: '',
      },
      {
        id: 'title',
        label: 'Promo Name',
      },
      {
        id: 'subtitle',
        label: 'Description',
      },
      {
        id: 'redeemed',
        label: 'Redeemed',
      },
      {
        id: 'scheduledDate',
        label: 'Schedule Start Date & Time',
      },
      {
        id: 'expiringDate',
        label: 'Schedule End Date & Time',
      },
      {
        id: ' ',
        label: ' ',
      },
    ]

    return (
      <Paper className={classes.root} elevation={0}>
        <TableContainer className={classes.container}>
          <Table
            stickyHeader
            aria-label='sticky table'
            id='mytable'
            classes={{ root: classes.tableStyle }}
            className={common.relativePosition}
          >
            <TableHead>
              <TableRow>
                {headCells.map((column, idx) => (
                  <TableCell
                    className={[
                      classes.bottomStyle,
                      idx === 0
                        ? classes.firstCell
                        : idx + 1 === headCells.length
                          ? classes.lastCell
                          : 'null',
                    ].join(' ')}
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                    id={column.id}
                    onClick={() => console.log("promo Sort.......", requestSort(column.id))}
                    style={{ backgroundColor: Color.infoDark }}
                  >
                    <Typography
                      className={[common.textM, common.pointer].join(' ')}
                    >
                      {column.label}
                    </Typography>
                  </TableCell>
                ))}

              </TableRow>
            </TableHead>
            <TableBody>
              {map([...(filter(filterPromoData, apd => promoSelect === 'All Promotions' || getPromoTargetedTo(apd.breweryOfferCampaign) === promoSelect) || [])], (offerItem, idx) => (
                <TableRow
                  onClick={(e) => handleCardClick(e, offerItem)}
                  role='checkbox'
                  tabIndex={-1}
                  key={idx}
                  className={common.pointer}
                >
                  <CustomizedCell
                    firstCell
                    value={
                      <Image
                        src={offerItem?.breweryOfferCampaign?.banner?.url + imageWidthParams(50)}
                        alt={'offer image'}
                        md
                        radiusSm
                      // circle
                      />
                    }
                  />
                  <CustomizedCell value={
                    <Typography className={common.HeeboMedium}>{offerItem?.breweryOfferCampaign?.title}</Typography>
                  }
                  />
                  <CustomizedCell value={get(offerItem, 'breweryOfferCampaign.body[0].markdown') || ''} />
                  <CustomizedCell value={
                    <Typography className={[common.textDark].join(' ')}>{offerItem?.breweryOfferCampaign?.totalRedeemed}</Typography>
                  } />
                  <CustomizedCell
                    value={moment(offerItem?.breweryOfferCampaign?.scheduledDate).isValid() ? moment(offerItem?.breweryOfferCampaign?.scheduledDate).format('MMM Do, YYYY') : '-'}
                  />
                  <CustomizedCell
                    value={moment(offerItem?.breweryOfferCampaign?.expiringDate).isValid() ? moment(offerItem?.breweryOfferCampaign?.expiringDate).format('MMM Do, YYYY') : '-'}
                  />
                  <CustomizedCell
                    lastCell
                    value={
                      <Popup
                        action={
                          <AGIcon id={'child'} name={'icon-More'} size='4' />
                        }
                        placement={'left-start'}
                        initialValue={promoActions}
                        list={[offerItem?.breweryOfferCampaign?.active ? 'Deactivate' : 'Activate', 'Clone', 'Edit', 'Delete']}
                        selectedValue={(e) => handlePromoAction(e, offerItem)}
                      />
                    }
                  />
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {!filterPromoData?.length && !allOffersError && !allOffersLoading ? (
          <Typography className='transition'>{'No Offer found'}</Typography>
        ) : (
          ''
        )}
        {allOffersError ? (
          <Typography
            variant='h6'
            className={[
              common.HeeboMedium,
              common.textCenter,
              common.pointer,
              common.textDanger,
            ].join(' ')}
          >
            {'Oops Error!'}
          </Typography>
        ) : (
          ''
        )}
        {/* {paginationData?.current?.hasMore && (
          <Typography
            className={[
              common.HeeboMedium,
              common.textCenter,
              common.pointer,
            ].join(' ')}
            onClick={getPromoResults}
          >
            {'View more'}
          </Typography>
        )} */}
        {/* <Drawer
          open={drawerState}
          heading={
            <AgText
              label={
                <Box display='flex' alignItems='center'>
                  <Typography
                    className={[
                      common.HeeboMedium,
                      common.textPrimary,
                      common.textXl,
                      classes.mr20,
                    ].join(' ')}
                  >
                    {drawerMode === 'read' ? drawerData?.name : 'Edit Member'}
                  </Typography>
                  <Box component={'span'} ml={0.5}>
                    <Button
                      className={[
                        drawerData.status === 'Active'
                          ? common.bgSuccess
                          : common.bgPink,
                        common.textWhite,
                        common.textM,
                        classes.statusBox,
                      ].join(' ')}
                    >
                      {drawerData.status}
                    </Button>
                  </Box>
                </Box>
              }
            />
          }
          content={
            <AllMembersManageDrawer
              refreshuserList={props.refreshuserList}
              data={drawerData}
              modeChange={(e) => setDrawerMode(e)}
              mode={drawerMode}
              closeDrawer={() => setDrawerState(!drawerState)}
            />
          }
          closeDrawer={() => setDrawerState(!drawerState)}
          footer={
            <AgButton
              label={drawerMode === 'read' ? 'Edit' : 'Update'}
              textColor={'#FFFFFF'}
              color={'#FA735F'}
              fullWidth
              onClick={() => {
                drawerMode === 'read'
                  ? setDrawerMode('edit')
                  : setDrawerMode('read')
                // setDrawerState(!drawerState)
              }}
            />
          }
        /> */}
        <Drawer
          open={drawerState}
          content={
            <ManageOfferDrawer closeDrawer={handleCloseOfferDrawer} getUpdatedData={() => getUpdatedData()} existingOffer={selectedOffer} mode={offerDrawerMode} />
          }
          closeDrawer={handleCloseOfferDrawer}
        />
      </Paper>
    )
  }
  const getTimeStamp = (data) => moment(data).utc().format()
  const durationWiseDate = {
    'All Time': { // Every time
    },
    'Today': { // current day
      startDate: getTimeStamp(moment().clone().startOf('day').format('YYYY-MM-DD')),
      endDate: getTimeStamp(moment().clone().endOf('day').format('YYYY-MM-DD'))
    },
    'This Month': { // current month
      startDate: getTimeStamp(moment().clone().startOf('month').format('YYYY-MM-DD')),
      endDate: getTimeStamp(moment().clone().endOf('month').format('YYYY-MM-DD'))
    },
    'This Week': { // current Week
      startDate: getTimeStamp(moment().clone().startOf('weeks').format('YYYY-MM-DD')),
      endDate: getTimeStamp(moment().clone().endOf('weeks').format('YYYY-MM-DD'))
    },
  }
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Toolbar disableGutters={true}>
          <Grid container className={classes.tablesubHTopeaders} spacing={2}>
            <Grid item>
              <Box mt={0.7}>
                <Typography className={[common.textDark, common.HeeboMedium].join(' ')}>All Promotions</Typography>
              </Box>
            </Grid>
            <Grid item>
              <DropDown
                size={'small'}
                icon={<AGIcon name={'icon-Up'} size={6} />}
                color='primary'
                variant={'text'}
                placement='bottom'
                initialValue={'All Time'}
                list={Object.keys(durationWiseDate)}
                selectedValue={(e) => {
                  console.log('eeee?', e)
                  setSelectedFilter(e)
                }}
              />
            </Grid>
          </Grid>
          <Box style={{ display: 'flex' }}>
            <Box component='span' mr={1}>
              {['All', 'All Time', 'Today', 'This Month', 'This Week'].includes(selectedFilter) ? ('') : (
                // {filterAction !== 'All' && (
                <Chip
                  className={classes.chipStyle}
                  variant='outlined'
                  label={`${filterAction} Promos`}
                  onDelete={() => handlePromoFilter('All')}
                  deleteIcon={
                    <Box pt={0.5}>
                      <AGIcon name={'icon-Close'} size='10' />
                    </Box>
                  }
                />
              )}
            </Box>
            <Box component='span' mt={0.5}>
              <Popup
                action={
                  <Tooltip title='Filter list'>
                    <Icon
                      className={[
                        classes.buttonBg,
                        common.bgWhite,
                        common.pointer,
                      ].join(' ')}
                    >
                      <AGIcon
                        name={'icon-Sort'}
                        color={Color.primary}
                        size='14'
                      />
                    </Icon>
                  </Tooltip>
                }
                placement={'left-start'}
                initialValue={'All'}
                list={['Recent', 'Popular', 'Expiring', 'Inactive']}
                selectedValue={(e) => handlePromoFilter(e)}
                className={['promotions_target1'].join(' ')}
              />
            </Box>
          </Box>
        </Toolbar>

        {
          allOffersLoading ?
            <LinearProgress /> : ''
        }

      </Grid>
      <Grid item container spacing={1} className={common.bgWhite}>
        <Grid item >
          {map(promotionList, (data, idx) => (
            <Grid container>
              <Grid item>
                <Box px={0.6} py={1}>
                  <Box key={idx} style={{ width: 243, border: promoSelect === data ? `1px solid ${Color.primary}` : `1px solid ${Color.white}` }} className={[common.bgExLight, common.borderRadiusM, common.pointer].join(' ')}
                    onClick={() => setPromoSelect(data)}>
                    <Box px={2} py={2}>
                      <Typography className={common.HeeboMedium}>
                        {data}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          ))}
        </Grid>
        <Grid item xs className={classes.scroll}>
          <Box px={1.5}>
            {renderOffers()}
          </Box>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        {paginationData?.current?.hasMore ? (
          <Typography
            className={[
              common.HeeboMedium,
              common.textCenter,
              common.pointer,
            ].join(' ')}
            onClick={() => getPromoResults()}
          >
            {'View more'}
          </Typography>
        ) : (
          ''
        )}
      </Grid>
    </Grid>
  )
}

const customStyles = makeStyles(CommanClasses)

const useStyles = makeStyles((theme) => ({
  mobileScreenHeadText: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },
  mobileScreenSubText: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  mobileScreenImage: {
    [theme.breakpoints.down('xs')]: {
      height: '120px !important',
      width: '120px !important',
    },
  },
  title: {
    flex: '1 1 100%',
  },
  oneLineText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  },
  endMoreIcon: {
    textAlign: 'right'
  },
  buttonBg: {
    borderRadius: 40,
    padding: '4px 15px',
  },
  header: {
    lineHeight: '1'
  },
  gridMedia: {
    '@media (min-width:200px)': {
      flexGrow: 0,
      width: '100%',
      maxWidth: '100%',
      flexBasis: '100%'
    },
    '@media (min-width:990px)': {
      flexGrow: 0,
      maxWidth: '50%',
      flexBasis: '50%'
    },
    '@media (min-width:1430px)': {
      flexGrow: 0,
      maxWidth: '33.333333%',
      flexBasis: '33.333333%'
    },
  },
  root: {
    width: '100%',
    backgroundColor: 'transparent',
  },
  container: {
    [theme.breakpoints.down('lg')]: {
      maxHeight: '440',
    },
    maxHeight: 'calc(100vh - 225px)',
  },
  tableStyle: {
    borderSpacing: '0 10px',
    borderCollapse: 'unset',
  },
  bottomStyle: {
    borderBottom: 'none',
  },
  tableCell: {
    padding: '10px 15px',
    height: 50,
    position: 'relative',
  },
  firstCell: {
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
    maxWidth: 50,
    paddingRight: 0,
    width: 50,
  },
  lastCell: {
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
  },
  rowStyle: {
    backgroundColor: '#fff',
    boxShadow: '0px 3px 6px #2731791A',
    borderRadius: 15,
  },
  disabledRow: {
    backgroundColor: '#fff',
    boxShadow: '0px 3px 6px #2731791A',
    opacity: '0.5',
    borderRadius: 15,
  },
  verticleCenter: {
    margin: '0 0 0 8px',
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
  },
  iconButton: {
    padding: 10,
  },
  progressBar: {
    margin: '0 15px 0 5px '
  },
  tableHTopeaders: {
    marginBottom: theme.spacing(0.4),
    marginTop: theme.spacing(2),
  },
  centeredBox: {
    textAlign: '-webkit-center',
  },
  statusBox: {
    whiteSpace: 'nowrap'
  }
}))

const CustomizedCell = (me) => {
  const classes = useStyles()
  const common = customStyles(CommanClasses)

  return (
    <TableCell
      key={me.id}
      className={[
        classes.bottomStyle,
        classes.tableCell,
        me.firstCell ? classes.firstCell : '',
        me.lastCell ? classes.lastCell : '',
        common.HeeboLight,
        common.textM,
        common.textDark,
      ].join(' ')}
      align='left'
    >
      {me.external ? (
        me.value
      ) :
        me.value
      }
    </TableCell>
  )
}

const useSortableData = (items, config = null) => {
  const [sortConfig, setSortConfig] = useState(config)

  const sortedItems = React.useMemo(() => {
    let sortableItems = [...items]

    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        if (a['breweryOfferCampaign'][sortConfig.key] < b['breweryOfferCampaign'][sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1
        }
        if (a['breweryOfferCampaign'][sortConfig.key] > b['breweryOfferCampaign'][sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1
        }
        return 0
      })
    }

    return sortableItems
  }, [items, sortConfig])

  const requestSort = (key) => {
    let direction = 'ascending'
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === 'ascending'
    ) {
      direction = 'descending'
    }
    setSortConfig({ key, direction })
  }

  return { items: sortedItems, requestSort, sortConfig }
}