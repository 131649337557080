import React, { useState, useEffect } from "react";
import LineChart from "../components/LineChart";
import { useLazyQuery } from "@apollo/client";
import moment from "moment";
import { GetPromoEngagementData } from "../graphql/promoEngagementGQL";
import { getOffersStatsCount } from "../graphql/offersStatsCountGQL";
import DoughnutLabel from "../components/DoughnutLabel";
import AGCard from "../components/AGCard";
import Color from "../themes/Colors";
import AGIcon from "../components/AGIcon";
import SimpleTextCard from "../components/SimpleTextCard";
import { Grid, Box } from "@material-ui/core";
import { map } from "lodash";
import updateChartData from "../utils";
import DropDown from "../components/Dropdown";
import { __thisYear, __years } from "../Services/local";

export default (props) => {
  const { breweryId } = props;

  const windowWidth = window.innerWidth;

  let defaultCounts = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

  const [result, setResult] = useState();
  const [offersCountResult, setOffersCountResult] = useState();
  const [sizeVal, setsizeVal] = useState(windowWidth < 600 ? 180 : 120);
  const today = moment().utc().endOf("year").format();
  const startOfYear = moment().utc().startOf("year").format();
  const [yearFilter, setYearFilter] = useState({
    start: startOfYear,
    end: today,
  });
  
  // const [getPromoEngagementData, { error: promoError, loading: promoLoading }] =
  //   useLazyQuery(GetPromoEngagementData, {
  //     onCompleted: (resp) => {
  //       if (!resp) return;
  //       setResult(resp);
  //     },
  //     onError: (error) => {
  //       console.log("error", error);
  //     },
  //     notifyOnNetworkStatusChange: true,
  //   });

    const [getOffersCountData, { error: offersCountError, loading: offersCountLoading }] =
    useLazyQuery(getOffersStatsCount, {
      onCompleted: (resp) => {
        if (!resp) return;
        setOffersCountResult(resp?.offersStatsCount)
      },
      onError: (error) => {
        console.log("error", error);
      },
      notifyOnNetworkStatusChange: true,
    });

  useEffect(() => {
    // getPromoEngagementData({
    //   variables: {
    //     breweryId,
    //     startDate: yearFilter.start,
    //     endDate: yearFilter.end,
    //   },
    // });

    getOffersCountData({
      variables: {
        hostBreweries: [breweryId],
        startDate: yearFilter.start,
        endDate: yearFilter.end,
      },
    });
  }, [breweryId, yearFilter]);

  const handleYearFilter = (year) => {
    setYearFilter({
      start: moment().subtract(moment().format('YYYY') - year, "years").utc().startOf("year").format(),
      end: moment().subtract(moment().format('YYYY') - year, "years").utc().endOf("year").format(),
    });
  };

  // const data = updateChartData(
  //   map(result?.brewery?.brewery?.offerStats, (data) => data.totalRedeemed) // incremental data
  // );

  // const data = map(result?.brewery?.brewery?.offerStats, (data) => data.totalRedeemed)
  const data = map(offersCountResult?.redemptionStats, (data) => data.totalRedeemed) || []
  // console.log('result promodata >',result?.brewery?.brewery?.offerStats)
  // console.log('data promodata >',data)
  // console.log('data1 promodata >',data1)
  // const data =
  //   {
  //     label: "Number of Redemptions",
  //     data:
  //       updateChartData(
  //         map(
  //           result?.brewery?.brewery?.offerStats,
  //           (data) => data.totalRedeemed
  //         ),
  //         true
  //       ) || defaultCounts,
  //   },
  // ];

  return (
    <AGCard
      cardHeader={"Promo Engagement"}
      cardContent={
        <Grid container spacing={5}>
          <Grid item xs={12} lg={6}>
            <Box mt={1}>
              <Grid container spacing={2} justify="center">
                <Grid item xs>
                  <SimpleTextCard
                    variant="column"
                    number={
                      Number(offersCountResult?.activeOffersCount) > 0 ? Number(offersCountResult?.activeOffersCount) : 0
                    }
                    text="Active"
                  />
                </Grid>
                <Grid item xs>
                  <SimpleTextCard
                    variant="column"
                    number={
                      Number(offersCountResult?.completedOffersCount) > 0 ? Number(offersCountResult?.completedOffersCount) : 0
                    }
                    text="Completed"
                  />
                </Grid>
                <Grid item xs>
                  <SimpleTextCard
                    variant="column"
                    number={
                      Number(offersCountResult?.totalRedeemed) > 0 ? Number(offersCountResult?.totalRedeemed) : 0
                    }
                    text="Redeemed"
                  />
                </Grid>
              </Grid>
            </Box>
            {/* <Typography style={{ marginTop: 30, textAlign: 'center', fontSize: 12 }}>
              {'* Active count includes scheduled promos'}
            </Typography> */}
          </Grid>
          <Grid item xs={12} lg={6}>
            <Box mt={-3} mb={1}>
              <DropDown
                size={"medium"}
                icon={<AGIcon name={"icon-Up"} size={6} />}
                color="primary"
                variant={"outlined"}
                placement="bottom"
                initialValue={__thisYear}
                list={__years}
                selectedValue={(e) => handleYearFilter(e)}
              />
            </Box>
            <LineChart
              colors={["#FB6A55"]}
              stepSize={20}
              height={sizeVal}
              yAxesLabel={"Number of Redemptions"}
              xAxesLabel={"Months"}
              labels={[
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "May",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
                "Oct",
                "Nov",
                "Dec",
              ]}
              datasets={[
                {
                  label: "Number of Redemptions",
                  data: data || defaultCounts,
                },
              ]}
            />
          </Grid>
        </Grid>
      }
    />
  );
};
