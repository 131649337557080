import { gql } from "@apollo/client";

export const ADD_BUSINESS_INFO = gql`
  mutation createBrewery(
    # $id: ID!
    $businessName: String
    $businessType: BreweryBusinessType
    $streetAddress: String!
    $city: String!
    $state: String!
    $zipCode: String!
    $email: String
    $merchantUserOwners: [ID!]!
    $phoneNumber: String
    $alternatePhoneNumber: String
		$website: String
    $description: String
    $hours: [HoursEntryInput!]
    $fullAddress: String
  ) {
    createBrewery(
      businessName: $businessName
      businessType: $businessType
      fullAddress: $fullAddress
      hierarchicalAddress: ["USA", $state, $city, $zipCode, $streetAddress]
      contactEmail: $email
      merchantUserOwners: $merchantUserOwners
      contactPhone: $phoneNumber
      alternatePhoneNumber: $alternatePhoneNumber
			website : $website
      description: $description
      hours: $hours
    ) {
      success
      brewery {
        name
        id
      }
    }
  }
`;
