import React from 'react'
import { Card, Grid, Typography, Box, Hidden } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CommanClasses from '../../themes/CommonClasses'
import Image from '../../components/Image'
import { map } from 'lodash'
import Color from '../../themes/Colors'
import BgImg from '../../assets/images/undraw_mobile_testing_reahh.svg'
import MyPic from '../../assets/images/my.png'
import AgButton from '../../components/AgButton'
import { useHistory } from 'react-router-dom'
import Drawer from '../../components/Drawer'
import AGText from '../../components/AgText'
import ManageOfferDrawer from '../../containers/ManageOfferDrawer'

const Promotions = (props) => {
    const common = customStyles(CommanClasses)
    const classes = useStyles()
    const incomingData = props.data
    const history = useHistory()
    const handleChangeHistory = (location) => {
        history.push(location)
    }
    const [drawerState, setDrawerState] = React.useState(false)
    
    const editUI = () => (
        <Box>
            <Hidden smDown>
                <Grid container>
                    <Grid item md={6}>
                        <Box mb={2.5}>
                            <Typography className={[common.HeeboRegular, common.textDark].join(' ')} >
                                Create promotions that drive sales and engagement.
                        </Typography>
                        </Box>
                        <Box mb={2.5}>
                            <Typography className={[common.HeeboLight, common.textM, common.textDark].join(' ')}>
                                Indulj Promotions is your vehicle to engage your customers and members with everything from special offers to education. Promotions can be targeted to members or any customer using the app. Take advantage of the ability to schedule your promotions to save time. You can also limit redemptions to drive interest in your promos.
                            </Typography>
                        </Box>
                        <Box display='flex'>
                            <Box><AgButton label='Add Promotions' disabled={!props?.breweryId} smblue='true' onClick={() => setDrawerState(!drawerState)} /></Box>
                            <Box ml={2.5}><AgButton label='View Promotions' disabled={!props?.breweryId} smblue='true' onClick={() => handleChangeHistory('/promotions')} /></Box>
                        </Box>
                    </Grid>
                    <Grid item md={6}>
                        <Grid container justify='flex-end'>
                            <img src={BgImg} alt='' />
                        </Grid>
                    </Grid>
                </Grid>
            </Hidden>
            <Hidden mdUp>
                <Grid container direction='column' justify='flex-start' alignItems='flex-start' className={classes.root}>
                    <Grid item >
                        <Typography className={[common.HeeboRegular, common.textDark, classes.mb20].join(' ')} >
                            Create promotions that drive sales and engagement.
                        </Typography>

                        {map(incomingData, (obj, id) => (
                            <Card key={id} elevation={0} className={[common.borderRadiusM, classes.rootCard2].join(' ')}>
                                <Grid container direction='column'>
                                    <Grid item>
                                        <Grid container direction='row'>
                                            <Grid item style={{ width: '40%' }}>
                                                <Image src={MyPic} mbmlx radiusLg className={classes.mobileScreenImage} />
                                            </Grid>
                                            <Grid item style={{ width: '60%' }}>
                                                <Box mt={0} p={1}>
                                                    <Box><Typography className={[common.textPrimary, common.HeeboMedium, common.textXl,].join(' ')}>{obj.promoName}</Typography></Box>
                                                    <Box>
                                                        <Typography display='block' noWrap className={[common.textLight, common.HeeboLight, common.textM].join(' ')}>
                                                            {'Amet minim mollit non deserunt ullamco est sit aliqu…'}
                                                        </Typography></Box>
                                                    <Box>
                                                        <Grid item container>
                                                            <Grid item><Typography className={[common.textLight, common.HeeboLight, common.textM,].join(' ')}>{'Customer Redeemer:'}</Typography></Grid>
                                                            <Grid item><Typography className={[common.textLight, common.HeeboMedium, common.textM,].join(' ')}>{'124'}</Typography></Grid>
                                                        </Grid>
                                                    </Box>
                                                    <Grid container>
                                                        <Box>
                                                            <Grid item container>
                                                                <Grid item><Typography className={[common.textLight, common.HeeboLight, common.textM,].join(' ')}>{'Schedule:'}</Typography></Grid>
                                                                <Grid item><Typography className={[common.textLight, common.HeeboMedium, common.textM,].join(' ')}>{obj.start}&nbsp;-&nbsp;{obj.end}</Typography></Grid>
                                                            </Grid>
                                                        </Box>

                                                    </Grid>
                                                </Box>
                                                <Box p={1} display='flex' justifyContent='flex-end'>
                                                    <AgButton label='Edit' smblue='true' onClick={() => handleChangeHistory('/promotions')} />
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                </Grid>
                            </Card>
                        ))}
                    </Grid>
                    <Grid item>
                        <Grid container direction='row' justify='space between'>
                            <Hidden smDown>
                                <Box mt={2.5} mr={2}><AgButton label='Add Promotions' disabled={!props?.breweryId} smblue='true' onClick={() => setDrawerState(!drawerState)} /></Box>
                                <Box mt={2.5}><AgButton label='View Promotions' disabled={!props?.breweryId} smblue='true' onClick={() => handleChangeHistory('/promotions')} /></Box>
                            </Hidden>
                            <Hidden mdUp>
                                <Box mt={2} mr={1}><AgButton label='Add Promotions' disabled={!props?.breweryId} smblue='true' onClick={() => setDrawerState(!drawerState)} /></Box>
                                <Box mt={2}><AgButton label='View Promotions' disabled={!props?.breweryId} smblue='true' onClick={() => handleChangeHistory('/promotions')} /></Box>
                            </Hidden>
                        </Grid>
                    </Grid>
                </Grid>
            </Hidden>
        </Box>
    )
    return (
        <Box my={props.margin}>
            {editUI()}
            <Drawer
                open={drawerState}
                heading={
                    <AGText
                        label={<Typography className={[common.HeeboMedium, common.textPrimary, common.textXl].join(' ')}>{'Create Promotion'}</Typography>
                        }
                    />
                }
                content={
                    <ManageOfferDrawer closeDrawer={() => {
                        props.refetchGetClubFields()
                        setDrawerState(!drawerState)
                    }} mode={'create'} />
                }
                closeDrawer={() => {
                    props.refetchGetClubFields()
                    setDrawerState(!drawerState)
                }}
                footer={
                    <AgButton
                        label={'Create Promotion'}
                        textColor={'#FFFFFF'}
                        color={'#FA735F'}
                        fullWidth
                        onClick={() => setDrawerState(!drawerState)}
                    />
                }
            />
        </Box>
    )
}

const customStyles = makeStyles(CommanClasses)
const useStyles = makeStyles((theme) => ({
    root: {
        // background: `#ffffff url(${,
        // background: `transparent url(${/90% 90% no-repeat`
    },
    card: {
        width: 435,
        marginLeft: 0,
        marginRight: 10,
        marginTop: 10,
        marginBottom: 10,
    },
    rootCard: {
        width: 868,
        backgroundColor: Color.main,
    },
    rootCard2: { backgroundColor: Color.main },
    mb60: { marginBottom: 60 },
    mb20: { marginBottom: 20 },
    mobileScreenImage: {
        [theme.breakpoints.down('xs')]: {
            height: '120px !important',
            width: '80px !important',
        },
    },

}))

export default Promotions
