import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import CommanClasses from '../themes/CommonClasses'
import Color from '../themes/Colors'
import { Typography, Box, Divider, Link, Hidden, Grid } from '@material-ui/core'
import ProgressCircle from './ProgressCircle'
import BusinessInfo from '../container/settings/BusinessInfo'
import Profile from '../container/settings/Profile'
import Clubs from '../container/settings/Clubs'
import Members from '../container/settings/MembersPage'
import Promotions from '../container/settings/Promotions'
import { useMutation, useQuery, useLazyQuery } from '@apollo/client'
import { GET_BREWERY_DETAILS } from '../graphql/Settings/businessInfoGQL'
import { GET_PROFILE_DETAILS } from '../graphql/Settings/profileDataGQL'
import { GetClubFields } from '../graphql/clubDetailsGQL'
import Joyride, { EVENTS } from 'react-joyride'
import Image from './Image'
import AgButton from './AgButton'
import Pic from '../assets/images/DeanAvatar.png'
import CloseSharpIcon from '@material-ui/icons/CloseSharp'
import { useHistory, useLocation } from 'react-router-dom'
import { UPDATE_IS_MINIMAL_SETUP_DONE } from '../graphql/Settings/updateIsMinimalSetupDoneGQL'
import { get } from 'lodash'

const Stepper = (props) => {
  const { breweryId, isNew } = props
  const location = useLocation()
  const [isNewBrewery, setIsNewBrewery] = useState(false)
  console.log('STEPPERR>>>>>>', breweryId)

  const history = useHistory()

  const [getBrewery, { data: breweryData, refetch: refetchBreweryData }] = useLazyQuery(GET_BREWERY_DETAILS, {
    variables: {
      breweryId,
    },
    fetchPolicy: 'no-cache'
  })

  useEffect(() => {
    getBrewery({
      variables: {
        breweryId,
      },
    });
  }, [breweryId]);

  useEffect(() => {
    setIsNewBrewery(location?.state?.isNew || false)
  }, [location]);

  const [updateIsMinimalSetupDone] = useMutation(UPDATE_IS_MINIMAL_SETUP_DONE, {
    onCompleted: (resp) => {
      refetchBreweryData()
    }
  })

  // settingPercentage in progress circle in Stepper(BusinessInfo)
  var bn = breweryData?.brewery?.brewery?.name !== '' ? 1 : 0
  var bt = breweryData?.brewery?.brewery?.businessType !== '' ? 1 : 0
  var sa = breweryData?.brewery?.brewery?.hierarchicalLocation[4] === '' || breweryData?.brewery?.brewery?.hierarchicalLocation[4] === undefined ? 0 : 1
  var city = breweryData?.brewery?.brewery?.hierarchicalLocation[2] === '' || breweryData?.brewery?.brewery?.hierarchicalLocation[2] === undefined ? 0 : 1
  var state = breweryData?.brewery?.brewery?.hierarchicalLocation[1] === '' || breweryData?.brewery?.brewery?.hierarchicalLocation[1] === undefined ? 0 : 1
  var zipCode = breweryData?.brewery?.brewery?.hierarchicalLocation[3] === '' || breweryData?.brewery?.brewery?.hierarchicalLocation[3] === undefined ? 0 : 1
  var email = breweryData?.brewery?.brewery?.contactEmail !== '' ? 1 : 0
  var pn = breweryData?.brewery?.brewery?.contactPhone === 0 || breweryData?.brewery?.brewery?.contactPhone === undefined ? 0 : 1
  var desc = breweryData?.brewery?.brewery?.description !== '' ? 1 : 0

  let totalBI = bn + bt + sa + city + state + zipCode + email + pn + desc
  let percentBI = (totalBI / 9) * 100
  // ---------

  const { data: profileData, refetch: refetchProfileData } = useQuery(GET_PROFILE_DETAILS, {
    variables: {
      breweryId,
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  })
  console.log('is New profile Data', profileData)


  // settingPercentage in progress circle in Stepper(Profile)
  var foto = profileData?.brewery?.brewery?.photosConnection?.edges.length !== 0 ? 1 : 0
  var btt = profileData?.brewery?.brewery?.consumerBreweryPreferencesOffering?.beerTypes?.length !== 0 ? 1 : 0
  var ft = profileData?.brewery?.brewery?.consumerBreweryPreferencesOffering?.foodTypes?.length !== 0 ? 1 : 0
  var vt = profileData?.brewery?.brewery?.consumerBreweryPreferencesOffering?.venueTypes?.length !== 0 ? 1 : 0
  var st = profileData?.brewery?.brewery?.consumerBreweryPreferencesOffering?.spiritsTypes?.length !== 0 ? 1 : 0
  var ct = profileData?.brewery?.brewery?.consumerBreweryPreferencesOffering?.coffeeTypes?.length !== 0 ? 1 : 0
  var mt = profileData?.brewery?.brewery?.consumerBreweryPreferencesOffering?.meadTypes?.length !== 0 ? 1 : 0
  var wt = profileData?.brewery?.brewery?.consumerBreweryPreferencesOffering?.wineTypes?.length !== 0 ? 1 : 0
  var cdt = profileData?.brewery?.brewery?.consumerBreweryPreferencesOffering?.ciderTypes?.length !== 0 ? 1 : 0
  var at = profileData?.brewery?.brewery?.consumerBreweryPreferencesOffering?.additionalTypes?.length !== 0 ? 1 : 0

  let totalPr = foto + btt + ft + vt + st + ct + mt + wt + cdt + at
  let percentPr = (totalPr / 10) * 100
  // ---------

  const {
    data: clubData,
    refetch: refetchGetClubFields
  } = useQuery(GetClubFields, {
    variables: {
      breweryId,
    },
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache'
  })

  const promotionsData = [
    { promoName: 'Test Promotion', start: 'Jul 14 2020', end: 'Sept 14 2020' },
  ]

  const common = customStyles(CommanClasses)
  const classes = useStyles()
  const biPercentZero = 0
  const [active, setActive] = useState('bi')
  const [mode, setMode] = useState('read')
  const [bIPercent, setBIPercent] = useState(0)
  const [profilePercent, setProfilePercent] = useState(0)
  const [runJoyride, setRunJoyride] = useState(false)

  // set ProgressCircle Percent(percent)
  useEffect(() => {
    setBIPercent(percentBI);
    setProfilePercent(percentPr);

  }, [percentBI, percentPr])

  useEffect(() => {
    clubData && handleSowMinimalSetupDone()
  }, [clubData])

  const handleSowMinimalSetupDone = () => {
    if (!breweryData?.brewery?.brewery?.isMinimalSetupDone) {
      if (bIPercent > 70
        && profilePercent > 50
        && getPromoProgress() > 50) {
        updateIsMinimalSetupDone({
          variables: {
            id: breweryId,
            isMinimalSetupDone: true
          }
        })
        // setRunJoyride(true)
      }
    }
  }

  const handleOnClickSection = (sectionId) => {
    if (sectionId === 'settings_bi') {
      // setMode('edit_businessinfo')
    }
    if (sectionId === 'settings_profile') {
      // setMode('edit_profile'); setActive('profile')
    }
  }

  window.addEventListener('scroll', () => {
    if (window.location.pathname === '/settings') {

      let nav = document.querySelector('.nav-container')
      if (nav) {
        if (window.scrollY >= 0) {
          nav.classList.add('scroll')
        } else {
          nav.classList.remove('scroll')
        }
      }
    }
  })

  window.addEventListener('scroll', () => {
    if (window.location.pathname === '/settings') {

      let navigationLinks = document.querySelectorAll('nav ul li a')
      let fromTop = window.scrollY + 120
      navigationLinks.forEach(link => {
        let section = document.querySelector(link.hash)
        if (
          section.offsetTop <= fromTop && section.offsetTop + section.offsetHeight > fromTop
        ) {
          link.classList.add('active')
        } else {
          link.classList.remove('active')
        }
      })

    }
  })

  const getPromoProgress = () => {
    const promosEdges = clubData?.brewery?.brewery?.activeOffersConnection?.edges || []
    if (promosEdges?.length > 1 || (promosEdges?.length === 1 && promosEdges?.[0]?.breweryOfferCampaign?.title !== 'Test For Everyone')) {
      return 100
    } else {
      return 0
    }
  }

  //To make the BusinessInfo nav highlighted on page load
  // window.scrollTo(0, 45)

  const Header = (props) => (
    <div>
      <Typography className={[common.HeeboMedium, common.textXxl, common.textPrimary, classes.bottomMarginHeader].join(' ')}>{props.heading}</Typography>
      <Divider style={{ marginBottom: 0 }} />
    </div>
  )

  const Tooltip = ({
    step,
    tooltipProps,
    skipProps,
  }) => {
    return <Grid containers className={[common.bgWhite, classes.customTooltip].join(' ')} {...tooltipProps}>
      <Grid item container justify='space-between'>
        <Grid item><Box>
          <Box display='flex' alignItems='center' flexGrow={1}>
            <Box><Image src={Pic} sm circle /></Box>&nbsp;&nbsp;&nbsp;
            <Box display='flex' alignItems='center'>
              <Typography className={[common.HeeboMedium, common.textM, common.textPrimary].join(' ')}>Dean Rogers &nbsp;</Typography><Typography className={[common.HeeboLight, common.textM, common.textLight].join(' ')}>from Indulj</Typography>
            </Box>
          </Box>
        </Box></Grid>
        <Grid item><CloseSharpIcon {...skipProps} onClick={() => setRunJoyride(false)} /></Grid>
      </Grid>
      <Grid item>
        <Box my={3.75}>{step.content}</Box>
        <Divider />
      </Grid>
      <Box mt={3.75}>
        <Grid item container>
          <Grid item sm={8} container justify='flex-start'>
            <Box display='flex'>
              <Box style={{ marginRight: 20 }}>
                <AgButton
                  outlined
                  label='Explore Dashboard'
                  onClick={(e) => {
                    history.push('/')
                  }}
                />
              </Box>
              <Box>
                <AgButton
                  outlined
                  label='Explore Promotions'
                  onClick={(e) => {
                    history.push('/promotions')
                  }}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item sm={4} container justify='flex-end'>
            <Box px={0}>
              <AgButton label={'Continue Setup'} smblue onClick={() => {
                setRunJoyride(false)
              }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  }

  return (
    <div className={classes.rootBody}>
      {!isNewBrewery ?
  <div className='nav-container'>
        <Hidden smDown>
          <nav className='nav-checkbox' style={{ position: 'fixed', width: '100%', height: '10%', backgroundColor: '#F7FAFF', zIndex: 1000 }}>&nbsp;
            <ul className='tab-content'>
              <li><Link key='1' href='#settings_bi' className={classes.link} onClick={() => { setMode('edit_businessinfo') /*setActive('settings_bi') */ }} underline='none'>
                <ProgressCircle num='1' percentage={bIPercent} tabLabel='Business Info' style={{ margin: '0px' }} />
                {/* <ProgressCircle num='1' percentage={biPercentZero} tabLabel='Business Info' style={{ margin: '0px' }} /> */}
              </Link></li>
              <li><Link key='2' href='#settings_profile' className={classes.link} onClick={() => { setMode('edit_profile'); setActive('settings_profile') }} underline='none'>
                <ProgressCircle num='2' percentage={profilePercent} tabLabel='Profile' />
                {/* <ProgressCircle num='2' percentage={biPercentZero} tabLabel='Profile' /> */}
              </Link></li>
              <li><Link key='3' href='#settings_promotions' className={classes.link} underline='none'>
                <ProgressCircle num='3' percentage={getPromoProgress()} tabLabel='Promotions' />
                {/* <ProgressCircle num='3' percentage={biPercentZero} tabLabel='Promotions' /> */}
              </Link></li>
              <li><Link key='4' href='#settings_clubs' className={classes.link} underline='none'>
                <ProgressCircle num='4' percentage={get(clubData, 'brewery.brewery.mugClubs[0].id') ? 100 : 0} tabLabel='Clubs' />
                {/* <ProgressCircle num='4' percentage={biPercentZero} tabLabel='Clubs' /> */}
              </Link></li>
              <li><Link key='5' href='#settings_members' className={classes.link} underline='none'>
                {/* <ProgressCircle num='5' percentage={biPercentZero} tabLabel='Members' /> */}
                <ProgressCircle num='5' percentage={get(clubData, 'brewery.brewery.mugClubs[0].activeMembers.totalActiveMembers') > 0 ? 100 : 0} tabLabel='Members' />
              </Link></li>
            </ul>
          </nav>
        </Hidden>
      </div> : '' }
      <section key='1' id='settings_bi' onClick={() => handleOnClickSection('settings_bi')} >
        <Box>
          <Hidden mdUp>
            <ProgressCircle num='1' percentage={biPercentZero} tabLabel='Business Info' style={{ margin: '0px' }} /><Divider style={{ marginTop: '15px' }} />
            {/* <ProgressCircle num='1' percentage={`${bIPercent}`} tabLabel='Business Info' style={{ margin: '0px' }} /><Divider style={{ marginTop: '15px' }} /> */}
          </Hidden>
          {breweryData && <BusinessInfo mode={mode} margin={1.25} bIData={breweryData} breweryIds={breweryId} refetchBreweryData={() => {
            refetchBreweryData()
            handleSowMinimalSetupDone()
          }} value={(v) => setBIPercent(v)} />}

        </Box>
      </section>
      <section key='2' id='settings_profile' className={classes.section} onClick={() => handleOnClickSection('settings_profile')}>
        <Box>
          {/* <Hidden smDown>
            {active === 'settings_profile' ? '' : <Header heading='Profile' />}
          </Hidden> */}
          <Hidden mdUp>
            {/* <ProgressCircle num='2' percentage={`${profilePercent}`} tabLabel='Profile' /> */}
            <ProgressCircle num='2' percentage={biPercentZero} tabLabel='Profile' />
            <Divider className={[classes.mt15].join(' ')} />
          </Hidden>
          {profileData && <Profile mode={mode} margin={1.25} pData={isNewBrewery ? {} : profileData} breweryId={isNewBrewery ? '' : breweryId} isNewBrewery={isNewBrewery} refetchProfileData={() => {
            handleSowMinimalSetupDone()
            refetchProfileData()
          }} value={(v) => setProfilePercent(v)} />}
        </Box>
      </section>
      <section key='3' id='settings_promotions' className={classes.section} >
        <Box>
          <Hidden smDown>{active === 'settings_promotions' ? '' : <Header heading='Promotions' />}</Hidden>
          <Hidden mdUp>
            <ProgressCircle num='3' percentage={'0'} tabLabel='Promotions' />
            <Divider className={[classes.mt15].join(' ')} />
          </Hidden>

          <Promotions
            mode={mode}
            breweryId={isNewBrewery ? '' : breweryId}
            margin={1.25}
            data={promotionsData}
            refetchGetClubFields={() => {
              handleSowMinimalSetupDone()
              refetchGetClubFields()
            }}
          />
        </Box>
      </section>
      <section key='4' id='settings_clubs' className={classes.section} >
        <Box>
          <Hidden smDown>{active === 'settings_clubs' ? '' : <Header heading='Clubs' />}</Hidden>
          <Hidden mdUp>
            <ProgressCircle num='4' percentage={'0'} tabLabel='Clubs' />
            <Divider className={[classes.mt15].join(' ')} />
          </Hidden>

          <Clubs
            margin={1.25}
            breweryId={isNewBrewery ? '' : breweryId}
            clubDetails={isNewBrewery ? {} : get(clubData, 'brewery.brewery.mugClubs[0]', {})}
            refetchGetClubFields={() => refetchGetClubFields()}
          />
        </Box>
      </section>
      <section key='5' id='settings_members' className={classes.section} >
        <Box>
          <Hidden smDown>
            {active === 'settings_members' ? '' : <Header heading='Members' />}
          </Hidden>
          <Hidden mdUp>
            <ProgressCircle num='5' percentage={'0'} tabLabel='Members' />
            <Divider className={[classes.mt15].join(' ')} />
          </Hidden>

          <Members margin={1.25} clubDetails={isNewBrewery ? {} : get(clubData, 'brewery.brewery.mugClubs[0]')} breweryId={isNewBrewery ? '' : breweryId} />
        </Box>
      </section>

      {/* ------Tour(Joyride)------------ */}
      <Joyride
        showSkipButton
        continuous={true}
        scrollToFirstStep
        run={runJoyride}
        steps={[
          {
            content: <Box justifyContent='center' alignItems='center' style={{ width: '100%' }} className={[classes.mb30].join(' ')}>
              <Box display='flex' justifyContent='center' alignItems='center'>
                <Typography className={[common.HeeboMedium, common.textL, common.textDark].join(' ')}>Hurray !</Typography>
              </Box>
              <Box className={[classes.bottomMarginHeader, classes.mt15].join(' ')}>
                <Typography className={[common.HeeboRegular, common.textM].join(' ')} style={{ color: '#8E8E8E' }}>{'Congrats! You’ve finished the basics of getting your business on Indulj.'}</Typography>
              </Box>
              <Box className={[classes.bottomMarginHeader].join(' ')}>
                <Typography className={[common.HeeboRegular, common.textM].join(' ')} style={{ color: '#8E8E8E' }}>{'You can now either go to the dashboard or continue with setting up a club and promos. Thanks!'}</Typography>
              </Box>
            </Box>,
            locale: {
              next: <AgButton label='Next' smblue />,
            },
            placement: 'center',
            target: 'body',
            contentHead: 'TestHead'
          },
        ]}
        tooltipComponent={Tooltip}
        disableOverlayClose
        styles={{
          options: {
            zIndex: 10000,
          }
        }}
      />

      {/* ------------Commented as we are not showing Payments part until next instruction------ */}
      {/* <section key='6' id='settings_payments' className={classes.section} 
      
      >
        <Box>
          <Hidden smDown>{active === 'settings_payments' ? '' : <Header heading='Payments' />}</Hidden>
          <Hidden mdUp>
            <ProgressCircle num='6' percentage={'0'} tabLabel='Payments' />
            <Divider className={[classes.mt15].join(' ')} />
          </Hidden>

          <Payments mode={mode} margin={1.25} />
        </Box>
      </section> */}
    </div>
  )
}

const customStyles = makeStyles(CommanClasses)

const useStyles = makeStyles((theme) => ({
  rootBody: {
    margin: 0,
    padding: 0,
    justifyItems: 'center',
    alignItems: 'center',
    overflowX: 'hidden',
    scrollPaddingTop: 65,
  },
  divider: {
    border: `1px ${Color.inputBorder}`,
    marginBottom: 10,
  },
  bottomMarginHeader: {
    marginTop: 0,//not needed as the section has a padding
    marginBottom: 19,
  },
  link: {
    padding: 20,
    display: 'inline-block',
    textAlign: 'center',
    borderRadius: 35,
  },
  section: {
    backgroundColor: Color.white,
  },
  heightZero: {
    height: 0,
  },
  mt15: { marginTop: 15 },
  customTooltip: {
    width: 737,
    borderRadius: 15,
    opacity: 1,
    padding: 30,
  },
}))

export default Stepper