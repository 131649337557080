import 'date-fns'
import React from 'react'
import { Grid, FormControl, FormHelperText } from '@material-ui/core'
import AGIcon from '../components/AGIcon'
import DateFnsUtils from '@date-io/date-fns'
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers'
import { makeStyles } from '@material-ui/core/styles'
import CommanClasses from '../themes/CommonClasses'
import Color from '../themes/Colors'
const customStyles = makeStyles(CommanClasses)
const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiInputBase-root': {
            color: Color.dark,
            background: '#FFFFFF 0% 0% no-repeat padding-box',
            '& .MuiInputAdornment-root': {
                marginLeft: 0,
            },
        },
        '& .MuiInput-underline': {
            textDecorationColor: '#F7FAFF'
        },
        textDecorationLine: 'none',
        border: '1px solid #DEE2F1',
        borderRadius: 10,
        padding:5,
        
    },
    
}))
function DatePicker(props) {
    const common = customStyles(CommanClasses)
    const classes = useStyles()
    const selectedDate = new Date()
   
    return (
        <FormControl>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container /*justify='space-around'*/>
                <KeyboardDatePicker
                    // disableToolbar
                    autoOk
                    views={["year", "month","date"]}
                    disabled = {props.disabled || false}
                    variant="inline"
                    format='MM/dd/yyyy'
                    margin='none'
                    id='date-picker-outlined'
                    value={props.selectedDate || selectedDate}
                    minDate={props.minDate}
                    onChange={props.onChange}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                    className={[classes.root,].join('')}
                    InputProps={{
                        disableUnderline: true,
                    }}
                    fullWidth={props.fullWidth? true : false}
                    keyboardIcon={<AGIcon
                        name={'icon-Date'}
                        onChange={props.onChange}

                        color={Color.light}
                        size='14'
                      />}
                />
            </Grid>
        </MuiPickersUtilsProvider>
        {props.error && <FormHelperText error>Invalid Range</FormHelperText>}
        </FormControl>
    )
}

export default DatePicker
