import React, { useEffect, useRef, useState } from 'react'
import CommanClasses from '../../themes/CommonClasses'
import { get, isEmpty } from 'lodash'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import {
  Button,
  Box
} from '@material-ui/core'
import { useSnackbar } from 'notistack'
import AddOutlinedIcon from '@material-ui/icons/AddOutlined'
import Color from '../../themes/Colors'


// const customStyles = makeStyles(CommanClasses)
const useStyles = makeStyles((theme) => ({
  button: {
    width: 423,
    background: `${Color.main} 0% 0% no-repeat padding-box`,
    border: `1px ${Color.inputBorder}`,
    borderRadius: 15,
    opacity: 1,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: Color.main,
      boxShadow: 'none',
    },
  },

  smallButton: {
    width: 172,
    background: `${Color.main} 0% 0% no-repeat padding-box`,
    border: `1px ${Color.inputBorder}`,
    borderRadius: 15,
    opacity: 1,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: Color.main,
      boxShadow: 'none',
    },
  },

  autosizeButton: {
    width: '100%',
    background: `${Color.main} 0% 0% no-repeat padding-box`,
    border: `1px ${Color.inputBorder}`,
    borderRadius: 15,
    opacity: 1,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: Color.main,
      boxShadow: 'none',
    },
  },
  centerdButton: {
    textAlign: 'center'
  }
}))

const ImageSelector = (props) => {
  const classes = useStyles()
  const { url, cropedUrl, cropedFile, event, aspect = 16 / 9, size, buttonText } = props
  let imageRef = useRef(null)
  const { enqueueSnackbar } = useSnackbar()
  const offerImagePickerRef = useRef(null)
  const [src, setSrc] = useState(null)
  const [croped, setCroped] = useState(null)
  const [crop, setCrop] = useState({
    unit: '%',
    width: 30,
    aspect: aspect
  })

  useEffect(() => {
    setSrc(null)
    setCroped(null)
  }, [url])

  const onSelectFile = (e) => {
    if ((get(e, 'target.files[0].size') / 1024 / 1024) > 25) {
      //we are not allowing user to upload more then 6MB
      enqueueSnackbar('Max allowed file size is 25MB')
      return;
    }
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        setSrc(reader.result)
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const onImageLoaded = (image) => {
    imageRef.current = image;
  };

  const onCropComplete = (crop) => {
    makeClientCrop(crop);
  };

  const onCropChange = (crop, percentCrop) => {
    setCrop(crop)
  };

  const makeClientCrop = async (crop) => {
    if (imageRef?.current && crop.width && crop.height) {
      const getCroppedImageUrl = await getCroppedImg(
        imageRef.current,
        crop,
        'newFile.png'
      );
      cropedUrl(getCroppedImageUrl)
      setCroped(getCroppedImageUrl)
    }
  }

  const getCroppedImg = (image, crop, fileName) => {
    const canvas = document.createElement('canvas');
    const pixelRatio = 1.2;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');

    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(
        (blob) => {
          if (!blob) {
            //reject(new Error('Canvas is empty'));
            console.error('Canvas is empty');
            return;
          }
          blob.name = fileName;
          let fileUrl = window.URL.createObjectURL(blob);
          cropedFile(blob)
          resolve(fileUrl);
        },
        'image/png',
        1
      );
    });
  }


  return (<div>
    <Box mb={1} className={classes.centerdButton}>
      <input type="file" accept="image/png, .jpeg, .jpg, image/gif" hidden ref={offerImagePickerRef} onChange={(e) => {
        onSelectFile(e)
      }} />
      {isEmpty(croped) ?
        <Button
          variant="contained"
          disableRipple={true}
          className={size === 'sm' ? classes.smallButton : size === 'auto' ? classes.autosizeButton : classes.button}
          onClick={() => {
            setSrc(null)
            offerImagePickerRef.current['value'] = ''
            return offerImagePickerRef.current?.click(onSelectFile)
          }
          }
        >
          {buttonText ? buttonText : <AddOutlinedIcon />}
        </Button> : <></>}
    </Box>
    {src && (
      <ReactCrop
        src={src}
        crop={crop}
        ruleOfThirds
        ref={imageRef}
        onImageLoaded={(e) => onImageLoaded(e)}
        onComplete={(e) => onCropComplete(e)}
        onChange={(e) => onCropChange(e)}
      />
    )}
    {/* {croppedImageUrl && (
      <img alt="Crop" style={{ maxWidth: '100%' }} src={croppedImageUrl} />
    )} */}
  </div>)
};


export default ImageSelector
