import React, { useState, useRef, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Box, Grid, Typography, Button, Divider, CircularProgress } from '@material-ui/core'
import CommanClasses from '../../themes/CommonClasses'
import { makeStyles } from '@material-ui/core/styles'
import { map, pull, startCase, includes, concat, filter, get, isEmpty } from 'lodash'
import Drawer from '../../components/Drawer'
import AGIcon from '../../components/AGIcon'
import Color from '../../themes/Colors'
import Chips from '../../components/AgChip'
import Image from '../../components/Image'
import AddOutlinedIcon from '@material-ui/icons/AddOutlined'
import AgButton from '../../components/AgButton'
import { BREWERY_DOMAIN_WHITE_BOARD } from '../../graphql/breweryDomainWhiteboard'
import { UPDATE_PROFILE_DATA } from '../../graphql/Settings/updateProfileDataGQL'
import { UPLOAD_BREWERY_LOGO } from '../../graphql/Settings/uploadBreweryLogoGQL'
import { UPLOAD_BREWERY_PHOTOS } from '../../graphql/Settings/uploadBreweryPhotosGQL'
import { DELETE_PHOTO } from '../../graphql/Settings/deletePhoto'
import DeleteIconM from '@material-ui/icons/Delete';
import { useApolloClient, useMutation, useQuery } from '@apollo/client'
// import DeleteIcon from '../../assets/icons/Icons_Delete.svg'
import DeleteIcon from '../../assets/icons/delete.png'
import editIcon from '../../assets/images/editIcon.png'
import { useSnackbar } from 'notistack'
import { imageKitUploadApi } from '../../Services/externalApi'
import { imageWidthParams } from '../../Services/local'
import ImageSelector from '../../components/ImageSelector/ImageSelector'

function Profile(props) {
	const location = useLocation();
	const { pData, isNewBrewery } = props || {}
	const { breweryId } = props
	const common = customStyles(CommanClasses)
	const classes = useStyles()
	const { enqueueSnackbar } = useSnackbar()
	const apolloClient = useApolloClient()

	const [cMode, setCMode] = React.useState('read')
	useEffect(() => {
		if (props.mode === 'edit_profile') {
			setCMode('edit_profile')
		} else {
			setCMode('read')
		}
		if (location.state?.editBusiness) {
			setCMode('edit_businessinfo')
			setBreweryLogo("")
			setBreweryPhoto("")
			// setProfileData("")
		}
	}, [props.mode, location, isNewBrewery])

	const getBreweryLogo = () => {
		const breweryLogo = pData?.brewery?.brewery?.logo?.url
		const breweryPhotos = pData?.brewery?.brewery?.photosConnection?.edges
		const thumbnail = breweryPhotos?.find(imageObj => imageObj?.image?.tags?.includes('thumbnail'))?.image?.url

		if (breweryLogo) {
			return breweryLogo
		} else if (thumbnail) {
			return thumbnail
		} else if (breweryPhotos?.[0]?.image?.url) {
			return breweryPhotos?.[0]?.image?.url
		} else {
			return null
		}
	}

	const [breweryLogo, setBreweryLogo] = useState(getBreweryLogo())
	const [breweryPhoto, setBreweryPhoto] = useState(pData?.brewery?.brewery?.photosConnection?.edges)
	const [hoverState, setHoverState] = useState('')
	const [logoHover, setLogoHover] = useState(false)
	const [uploadingLogo, setUploadingLogo] = useState(false)
	const [uploadingBanner, setUploadingBanner] = useState(false)
	const [uploadingVideo, setUploadingVideo] = useState(false)
	const [profileDrawerState, setProfileDrawerState] = useState(false)
	const [drawerMode, setDrawerMode] = useState('')
	const [cropedUrl, setCropedUrl] = useState({
		logo: '',
		photos: '',
		shortVideos: ''
	})

	const [cropedFile, setCropedFile] = useState({
		logo: {},
		photos: {},
		shortVideos: {}
	})
	const [shortVideoUrl, setshortVideoUrl] = useState({
		video: '',
		image: ''
	})

	const breweryLogoRef = useRef(null)
	const breweryPhotoRef = useRef(null)
	const breweryVideoRef = useRef(null)

	const {
		data: { breweryDomainWhiteboard = {} } = {}
	} = useQuery(BREWERY_DOMAIN_WHITE_BOARD)

	const allPreferences = JSON.parse(
		breweryDomainWhiteboard?.edges?.[0]?.value || '[]',
	)

	const handleCropedURL = (e, type) => {
		setCropedUrl({ ...cropedUrl, [type]: e })
	}

	const handleCropedFile = async (e, type) => {
		if (!e) {
			return
		}
		let today = new Date()
		e.lastModified = today.getTime()
		e.lastModifiedDate = today
		e.webkitRelativePath = ""
		const file = new File([e], "name");

		if (file) {
			setCropedFile({ ...cropedFile, [type]: file })
			// ImageKitUpload(file)
		}
	}

	const ImageKitUploadLogo = async (file) => {
		setUploadingLogo(true)

		const { url: imageUrl } = await imageKitUploadApi({
			apolloClient,
			fileName: `breweryLogo_${new Date().getTime()}.${file?.type?.split('/')?.[1]}`,
			file
		})

		setUploadingLogo(false)

		setBreweryLogo(imageUrl)
		uploadBreweryLogo({
			variables: {
				id: breweryId,
				logo: { imageUrl }
			}
		})
		setCropedUrl({ ...cropedUrl, logo: '' })
		setProfileDrawerState(false)
	}

	const ImageKitUploadBreweryPhoto = async (file) => {
		/* brewery photo picker */
		setUploadingBanner(true)

		const { url: imageUrl } = await imageKitUploadApi({
			apolloClient,
			fileName: `breweryImage_${new Date().getTime()}.${file?.type?.split('/')?.[1]}`,
			file
		})

		setUploadingBanner(false)

		const tmpId = `tempimgid_${new Date().getTime()}`	//help to delete proper file

		setBreweryPhoto([...breweryPhoto, { image: { url: imageUrl, id: tmpId } }])
		// calcPercentage(profileData, [...breweryPhoto, { image: { url: imageUrl, id: tmpId } }])
		uploadBreweryPhoto({
			variables: {
				id: breweryId,
				addPhotos: { imageUrl }
			}
		})
		setCropedUrl({ ...cropedUrl, photos: '' })
		setProfileDrawerState(false)
	}

	// const ImageKitUploadThumbnail = async (file) => {
	// 	const { url: imageUrl } = await imageKitUploadApi({
	// 		apolloClient,
	// 		fileName: `breweryImage_${new Date().getTime()}.${file?.type?.split('/')?.[1]}`,
	// 		file
	// 	})

	// 	setshortVideoUrl({ ...shortVideoUrl, image: imageUrl })
	// }

	const uploadVideoWithThumbnail = () => {
		console.log('shortVideoUrl ??', shortVideoUrl)
		setBreweryPhoto([...breweryPhoto, { image: { url: shortVideoUrl?.video, imageUrl: shortVideoUrl?.image, id: `tempimgid_${new Date().getTime()}`, videoUrl: shortVideoUrl?.video }, videoUrl: shortVideoUrl?.video }])
		uploadBreweryPhoto({
			variables: {
				id: breweryId,
				addPhotos: {
					videoUrl: shortVideoUrl?.video,
				}
			}
		})
		setshortVideoUrl({
			video: '',
			image: ''
		})
		setProfileDrawerState(false)
	}
	//this has to implement with functionality implementation
	const [profileData, setProfileData] = React.useState({
		id: breweryId || '',
		beerTypes: [...(pData?.brewery?.brewery?.consumerBreweryPreferencesOffering?.beerTypes || [])],
		foodTypes: [...(pData?.brewery?.brewery?.consumerBreweryPreferencesOffering?.foodTypes || [])],
		venueTypes: [...(pData?.brewery?.brewery?.consumerBreweryPreferencesOffering?.venueTypes || [])],
		spiritsTypes: [...(pData?.brewery?.brewery?.consumerBreweryPreferencesOffering?.spiritsTypes || [])],
		additionalTypes: [...(pData?.brewery?.brewery?.consumerBreweryPreferencesOffering?.additionalTypes || [])],
		coffeeTypes: [...(pData?.brewery?.brewery?.consumerBreweryPreferencesOffering?.coffeeTypes || [])],
		meadTypes: [...(pData?.brewery?.brewery?.consumerBreweryPreferencesOffering?.meadTypes || [])],
		wineTypes: [...(pData?.brewery?.brewery?.consumerBreweryPreferencesOffering?.wineTypes || [])],
		ciderTypes: [...(pData?.brewery?.brewery?.consumerBreweryPreferencesOffering?.ciderTypes || [])]
	})
	console.log('pData ??', pData)

	useEffect(() => {
		if (isNewBrewery) {
			setProfileData({
				id: '',
				beerTypes: [],
				foodTypes: [],
				venueTypes: [],
				spiritsTypes: [],
				additionalTypes: [],
				coffeeTypes: [],
				meadTypes: [],
				wineTypes: [],
				ciderTypes: []
			})
		} else if (pData) {
			setProfileData({
				id: breweryId || '',
				beerTypes: [...(pData?.brewery?.brewery?.consumerBreweryPreferencesOffering?.beerTypes || [])],
				foodTypes: [...(pData?.brewery?.brewery?.consumerBreweryPreferencesOffering?.foodTypes || [])],
				venueTypes: [...(pData?.brewery?.brewery?.consumerBreweryPreferencesOffering?.venueTypes || [])],
				spiritsTypes: [...(pData?.brewery?.brewery?.consumerBreweryPreferencesOffering?.spiritsTypes || [])],
				additionalTypes: [...(pData?.brewery?.brewery?.consumerBreweryPreferencesOffering?.additionalTypes || [])],
				coffeeTypes: [...(pData?.brewery?.brewery?.consumerBreweryPreferencesOffering?.coffeeTypes || [])],
				meadTypes: [...(pData?.brewery?.brewery?.consumerBreweryPreferencesOffering?.meadTypes || [])],
				wineTypes: [...(pData?.brewery?.brewery?.consumerBreweryPreferencesOffering?.wineTypes || [])],
				ciderTypes: [...(pData?.brewery?.brewery?.consumerBreweryPreferencesOffering?.ciderTypes || [])]
			})
		}
	}, [isNewBrewery, pData])

	const calcPercentage = (obj, fotoArry) => {
		let objData = obj
		let arrFoto = fotoArry
		// setting percentage in progress circle in parent(Stepper)
		var foto = arrFoto.length !== 0 ? 1 : 0
		var bt = objData?.beerTypes?.length !== 0 ? 1 : 0
		var ft = objData?.foodTypes?.length !== 0 ? 1 : 0
		var vt = objData?.venueTypes?.length !== 0 ? 1 : 0
		// var st = objData?.spiritsTypes?.length !== 0 ? 1 : 0
		// var at = objData?.additionalTypes?.length !== 0 ? 1 : 0

		let total = foto + bt + ft + vt
		let percent = (total / 4) * 100
		props.value(percent)
		// ---------
	}


	const [updateProfileData, profileDataResp] = useMutation(UPDATE_PROFILE_DATA, {
		onCompleted: (resp) => {
			if (resp) {
				// refresh(resp)
				calcPercentage({ ...profileData }, breweryPhoto)
			}
		}
	})

	const [uploadBreweryLogo, uploadBreweryLogoResp] = useMutation(UPLOAD_BREWERY_LOGO, {})

	const [uploadBreweryPhoto, { data: uploadedBreweryData, loading: uploadingBreweryPhoto }] = useMutation(UPLOAD_BREWERY_PHOTOS, {})

	const [deletePhoto, deletePhotoResp] = useMutation(DELETE_PHOTO, {
		onCompleted: (resp) => {
			if (resp) {
				// refresh(resp)
			}
		},
		onError: (err) => {
			console.log('err??', err)
		},
	})

	//this has to be used with the chip click fun edit
	const handleOnClick = () => {
		updateProfileData({ variables: profileData })
		props.refetchProfileData()
	}

	const handleSetSelectedPref = (all_pre_key, child_pre) => {
		let prefKey = profileData[all_pre_key]
		let cloneprofileData = {}
		if (prefKey.includes(child_pre.token)) {
			pull(prefKey, child_pre.token)
			cloneprofileData = { ...profileData, [all_pre_key]: prefKey }
			// setProfileData({ ...profileData, [all_pre_key]: prefKey })
			// calcPercentage({ ...profileData, [all_pre_key]: prefKey }, breweryPhoto)
		} else {
			cloneprofileData = { ...profileData, [all_pre_key]: [...prefKey, child_pre.token] }
			// setProfileData({ ...profileData, [all_pre_key]: [...prefKey, child_pre.token] })
			// calcPercentage({ ...profileData, [all_pre_key]: [...prefKey, child_pre.token] }, breweryPhoto)
		}
		console.log(prefKey)
		setProfileData(cloneprofileData)
	}

	const handleBreweryLogoPicker = async (e) => {
		const file = e.target.files[0]
		if (!file) {
			return
		}

		ImageKitUploadLogo(file)

	}

	const handleBreweryPhotoPicker = async (e) => {
		const file = e.target.files[0]
		if (!file) {
			return
		}
		ImageKitUploadBreweryPhoto(file)
	}

	const handleBreweryVideoPicker = async (e) => {
		console.log('e??', e)
		const file = e.target.files[0]
		console.log('file??', file)
		if (!file) {
			return
		}

		if (file?.size / 1024 / 1024 > 70) {
			//we are not allowing user to upload more then 10MB file
			enqueueSnackbar('Max allowed file size is 70MB')
			return
		}

		setUploadingVideo(true)

		const { url: videoUrl } = await imageKitUploadApi({
			apolloClient,
			fileName: `shortVideo_${new Date().getTime()}.${file?.type?.split('/')?.[1]}`,
			file
		})

		setUploadingVideo(false)
		setshortVideoUrl({ ...shortVideoUrl, video: videoUrl })
		// setBreweryPhoto([...breweryPhoto, { image: { url: videoUrl, id: `tempimgid_${new Date().getTime()}` }, videoUrl }])
		// calcPercentage(profileData, [...breweryPhoto, { image: { url: videoUrl }, videoUrl }])

		// uploadBreweryPhoto({
		// 	variables: {
		// 		id: breweryId,
		// 		addPhotos: { videoUrl }
		// 	}
		// })
	}

	const isSelected = (all_pre_key, child_pre) => {
		const beerTypes = includes(profileData.beerTypes, child_pre.token) && all_pre_key === 'beerTypes'
		const foodTypes = includes(profileData.foodTypes, child_pre.token) && all_pre_key === 'foodTypes'
		const venueTypes = includes(profileData.venueTypes, child_pre.token) && all_pre_key === 'venueTypes'
		const spiritsTypes = includes(profileData.spiritsTypes, child_pre.token) && all_pre_key === 'spiritsTypes'
		const additionalTypes = includes(profileData.additionalTypes, child_pre.token) && all_pre_key === 'additionalTypes'
		const coffeeTypes = includes(profileData.coffeeTypes, child_pre.token) && all_pre_key === 'coffeeTypes'
		const meadTypes = includes(profileData.meadTypes, child_pre.token) && all_pre_key === 'meadTypes'
		const wineTypes = includes(profileData.wineTypes, child_pre.token) && all_pre_key === 'wineTypes'
		const ciderTypes = includes(profileData.ciderTypes, child_pre.token) && all_pre_key === 'ciderTypes'

		if (beerTypes || foodTypes || venueTypes || spiritsTypes || additionalTypes || coffeeTypes || meadTypes || wineTypes || ciderTypes) {
			return true
		} else {
			return false
		}
	}

	let orborder = 'orborder'
	let pelborder = 'pelborder'
	let orngborder = 'orngborder'
	let drkborder = 'drkborder'

	const colorsCombination = {
		beerTypes: { orborder },
		foodTypes: { pelborder },
		spiritsTypes: { orngborder },
		venueTypes: { drkborder },
		additionalTypes: { orborder },
		coffeeTypes: { orborder },
		meadTypes: { orborder },
		wineTypes: { orborder },
		ciderTypes: { orborder },
	}

	const randomColor = () => {
		var keys = Object.keys(colorsCombination)
		return colorsCombination[keys[keys.length * Math.random() << 0]]
	}

	const handleColor = (all_pre_key, child_pre) => {
		if (!isSelected(all_pre_key, child_pre)) {
			return { noBorder: true }
		} else {
			// all_pre_key = lowerCase(all_pre_key)
			let foundColor = randomColor()

			map(colorsCombination, (cc, cc_key) => {
				if (all_pre_key.includes(cc_key)) {
					foundColor = cc
				}
			})

			return foundColor
		}
	}

	const handleDelete = (picId, url = '') => {
		console.log('picId??', picId)
		const index = breweryPhoto.findIndex(x => x.image.id === picId)

		//this delete the particular image from mongoDB
		if (picId.indexOf('tempimgid_') > -1) {
			//replace picId with real imageId
			let respPhotosEdges = get(uploadedBreweryData, 'updateBrewery.brewery.photosConnection.edges') || []
			picId = respPhotosEdges.find((rpe) => get(rpe, 'image.url') === url)?.image?.id
		}

		deletePhoto({ variables: { id: picId, } })

		// removes the pic from local state and displays the changed phot array
		let newPhotoArray = [...breweryPhoto.slice(0, index), ...breweryPhoto.slice(index + 1)]
		setBreweryPhoto(newPhotoArray)
		calcPercentage(profileData, newPhotoArray)
	}

	const Footer = (props) => (
		<Grid container justify='flex-end'>
			<AgButton label='Save' smblue='true' onClick={() => { handleOnClick(); setCMode('read') }} />
		</Grid>
	)

	const readData = () => (
		<Box /*onClick={() => setCMode('edit_profile')}*/>
			<Box mb={1.25}>
				<Image src={breweryLogo + imageWidthParams(170)} lx radiusLg />
			</Box>
			{map(allPreferences, (all_pre, all_pre_key) => {
				return (
					<Box mb={2.5} key={all_pre_key}>
						<Typography className={[common.HeeboRegular, common.textDark, common.textXl, classes.mb10].join(' ')} >{startCase(all_pre_key).split(' ')[0]}</Typography>
						<Grid container>
							{map(all_pre, (child_pre, child_pre_key) => (
								!handleColor(all_pre_key, child_pre)?.noBorder && <Box key={child_pre_key} mr={1.25} className={[classes.mb10].join(' ')}>
									<Chips label={child_pre?.label} {...handleColor(all_pre_key, child_pre)} />
								</Box>
							))}
						</Grid>
					</Box>
				)
			})}
		</Box>
	)

	const handleProfileDrawer = () => {
		setProfileDrawerState(false)
		setshortVideoUrl({
			video: '',
			image: ''
		})
	}
	//Formating the preferences list
	const formatPreferences = (preferences) => {
		let resultingArray = []
		let keyValue = -1
		map(preferences, (all_pre, all_pre_key) => {
			keyValue = keyValue + 1
			resultingArray = concat(resultingArray, [[]])
			map(all_pre, (child_pre, child_pre_key) => {
				if (!handleColor(all_pre_key, child_pre)?.noBorder) {
					resultingArray[keyValue / 2].push(child_pre)
				} else { }
			})
			keyValue = keyValue + 1
		})
		return resultingArray
	}

	let formattedPreferences = formatPreferences(allPreferences)

	const readDataa = () => {
		let headingArray = []
		map(allPreferences, (all_pre, all_pre_key) => {
			headingArray = concat(headingArray, all_pre_key)
		})
		return (
			<Box /*onClick={() => setCMode('edit_profile')}*/>
				<Box mb={1.25}>
					<Image src={breweryLogo + imageWidthParams(170)} lx radiusLg />
				</Box>
				{map(headingArray, (heading, i) => {
					return (
						formattedPreferences[i].length !== 0 ?
							<Grid key={i} container direction='column'>
								<Box><Typography className={[common.HeeboRegular, common.textDark, common.textXl, classes.mb10].join(' ')} >{startCase(heading).split(' ')}</Typography></Box>
								<Box mb={2.5}>
									<Grid container>
										{map(formattedPreferences[i], (child_pre, child_pre_key) => (
											<Box key={child_pre_key} mr={1.25} className={[classes.mb10].join(' ')}>
												<Chips label={child_pre.label} {...handleColor(heading, child_pre)} />
											</Box>
										))}
									</Grid>
								</Box>
							</Grid>
							: ''
					)
				})}
			</Box>
		)
	}

	return (
		<Box my={props.margin}>
			<Grid container justify='space-between' alignItems='center'>
				<Typography className={[common.HeeboMedium, common.textXxl, common.textPrimary, classes.bottomMarginHeader].join(' ')}>{'Profile'}</Typography>
				{cMode === 'edit_profile' ?
					<AgButton label='Save' disabled={!breweryId} smblue='true' onClick={() => { handleOnClick(); setCMode('read') }} />
					:
					<AgButton label='Edit' disabled={!breweryId} smblue='true' onClick={() => { setCMode('edit_profile') }} />
				}
			</Grid>
			<Divider style={{ marginTop: 10, marginBottom: 10 }} />
			<Box>
				{cMode === 'edit_profile' ?

					<Grid container>
						<Grid item md={5} sm={12}>
							<Box>
								<Grid container>
									<Grid item xs>
										<Typography className={[common.HeeboMedium, common.textDark, common.textXl, classes.mb10].join(' ')}>Logo/Profile</Typography>
									</Grid>
									<Grid item>
										<Box mr={2} mb={1} mt={0.5}>
											<Button
												variant="contained"
												disableRipple={true}
												className={classes.button}
												onClick={() => {
													setDrawerMode('logo')
													setProfileDrawerState(true)
												}
												}
											>
												{'Upload Logo'}
											</Button>
										</Box>
									</Grid>
								</Grid>
								<Box mr={2} mb={3} className={common.pointer}>
									{uploadingLogo ? <CircularProgress /> : <Image src={breweryLogo + imageWidthParams(170)} lx radiusLg onHover={() => setLogoHover(true)} onLeave={() => setLogoHover(false)} />}
									<br />
									<input
										ref={breweryLogoRef}
										type='file'
										name='breweryLogo'
										hidden
										onChange={handleBreweryLogoPicker}
										accept='image/png, .jpeg, .jpg, image/gif'
									/>
								</Box>

								<Grid container>
									<Grid item xs>
										<Typography className={[common.HeeboMedium, common.textDark, common.textXl, classes.mb10].join(' ')} >Photos</Typography>
									</Grid>
									<Grid item>
										<Box mr={2} mb={1} mt={0.5}>
											<Button
												variant="contained"
												disableRipple={true}
												className={classes.button}
												onClick={() => {
													setDrawerMode('photos')
													setProfileDrawerState(true)
												}
												}
											>
												{'Add Photo'}
											</Button>
										</Box>
									</Grid>
								</Grid>
								<Grid container>
									{map(filter(breweryPhoto, (bp) => !bp.videoUrl), (img, i) => (
										<Box key={img.image.id} mr={2} mb={2} className={common.pointer}>
											<Image src={hoverState === `hover${img.image.id}` && !uploadingBreweryPhoto ? DeleteIcon : img.image.url + imageWidthParams(170)} lx radiusLg onClick={() => handleDelete(img.image.id, img.image.url)} onHover={() => setHoverState(`hover${img.image.id}`)} onLeave={() => setHoverState('')} />
										</Box>
									))}
									<Box mr={2} mb={2}>
										<input
											ref={breweryPhotoRef}
											type='file'
											name='breweryPhoto'
											hidden
											onChange={handleBreweryPhotoPicker}
											accept='image/png, .jpeg, .jpg, image/gif'
										/>
									</Box>
								</Grid>

								<Grid container>
									<Grid item xs>
										<Typography className={[common.HeeboMedium, common.textDark, common.textXl, classes.mb10].join(' ')} >Short Videos</Typography>
									</Grid>
									<Grid item>
										<Box mr={2} mb={1} mt={0.5}>
											<Button
												variant="contained"
												disableRipple={true}
												className={classes.button}
												onClick={() => {
													setDrawerMode('shortVideos')
													setProfileDrawerState(true)
												}
												}
											>
												{'Add Video'}
											</Button>
										</Box>
									</Grid>
								</Grid>
								<Grid container className={classes.fullWidth}>
									{map(filter(breweryPhoto, (bp) => bp.videoUrl), (img, i) => (
										<Box key={img.image.id} mr={2} mb={2} className={[common.pointer, common.relativePosition, classes.videoContainer].join(" ")}>
											<Box className={[classes.deleteIconContainer].join(" ")} onClick={() => { img.image.id && handleDelete(img.image.id, img.image.url) }} >
												<DeleteIconM className={classes.deleteIcon} />
											</Box>
											<video width="100%" height="100%" autoplay controls muted className={[classes.videoPlayer, common.smZindex].join(" ")}>
												<source src={img.image?.videoUrl} type="video/webm" />
												<source src={img.image?.videoUrl} type="video/mp4" />
											</video>
										</Box>
									))}
									<Box mr={2} mb={2} className={classes.fullWidth}>

										<input
											ref={breweryVideoRef}
											type='file'
											name='breweryVideo'
											hidden
											onChange={handleBreweryVideoPicker}
											accept='video/*'
										/>
									</Box>
								</Grid>
							</Box>
						</Grid>
						<Grid item md={7} sm={12}>
							<Box>
								<Box>
									<Typography className={[common.HeeboMedium, common.textDark, common.textXl, classes.mb10].join(' ')}>Offerings</Typography>
									<Typography className={[common.HeeboRegular, common.textDark, classes.mb10].join(' ')} >Offerings are how you tell your customers what you offer - from beer characteristics to food to the atmosphere. Customers and members can see how their preferences align with your offerings in the app.</Typography>
								</Box>
								<Box>
									{map(allPreferences, (all_pre, all_pre_key) => {
										return (
											<Box mb={2.5} key={all_pre_key}>
												<Typography className={[common.HeeboRegular, common.textDark, common.textXl, classes.mb10].join(' ')} >{startCase(all_pre_key).split(' ')[0]}</Typography>
												<Grid container>
													{map(all_pre, (child_pre, child_pre_key) => (
														<Box key={child_pre_key} mr={1.25} className={[classes.mb10].join(' ')}>
															<Chips onClick={() => handleSetSelectedPref(all_pre_key, child_pre)} label={child_pre?.label} {...handleColor(all_pre_key, child_pre)} />
														</Box>
													))}
												</Grid>
											</Box>
										)
									})}
								</Box>
							</Box>
						</Grid>
						{/* <div className={classes.footer} >
                            <Divider />
                        </div>
                        {Footer()} */}
					</Grid>

					: readDataa()}
			</Box>
			<Drawer
				open={profileDrawerState}
				content={
					<Box m={2}>
						<Grid container>
							<Grid item xs>
								<Typography>
									{
										drawerMode === 'logo' ? 'Update Logo' : drawerMode === 'photos' ? 'Add Photo' : 'Add Video'
									}
								</Typography></Grid>
							<Grid item>
								<AGIcon
									name={'icon-Close'}
									onClick={() => handleProfileDrawer()}
									color={Color.light}
									size='14'
								/>
							</Grid>
						</Grid>

						{drawerMode === 'logo' ?
							<Box mt={1}>
								<ImageSelector size={'sm'} cropedUrl={e => handleCropedURL(e, 'logo')} cropedFile={(e) => handleCropedFile(e, 'logo')} />
								{!isEmpty(cropedUrl?.logo) ?
									<Box my={1}>
										<Button
											variant="contained"
											disableRipple={true}
											className={classes.button}
											onClick={() =>
												ImageKitUploadLogo(cropedFile?.logo)
											}
										>
											{uploadingLogo ? <CircularProgress /> : 'Confirm Upload'}
										</Button>
									</Box> :
									<></>}
							</Box> :
							drawerMode === 'photos' ? <Box mt={1}>
								<ImageSelector size={'sm'} cropedUrl={e => handleCropedURL(e, 'photos')} cropedFile={(e) => handleCropedFile(e, 'photos')} />
								{!isEmpty(cropedUrl?.photos) ?
									<Box mt={1}>
										<Button
											variant="contained"
											disableRipple={true}
											className={classes.button}
											onClick={() =>
												ImageKitUploadBreweryPhoto(cropedFile?.photos)
											}
										>
											{uploadingBanner ? <CircularProgress /> : 'Confirm Upload'}
										</Button>
									</Box> :
									<></>}
							</Box> :
								<Box mt={1} mr={2} mb={2} className={classes.fullWidth}>
									<Typography>Select Video</Typography>
									<br />
									{shortVideoUrl?.video ?
										<video width="320" height="240" autoplay muted>
											<source src={shortVideoUrl.video} type="video/webm" />
											<source src={shortVideoUrl.video} type="video/mp4" />
										</video> : ""
									}
									<Box mb={3} className={[classes.fullWidth].join(" ")}>
										<Box className={[classes.fullWidth, classes.centerdButton].join(" ")}>
											<Button variant='contained' disableRipple={true} disabled={uploadingVideo} className={classes.button} onClick={() => breweryVideoRef.current?.click(handleBreweryVideoPicker)}>{uploadingVideo ? <CircularProgress /> : <AddOutlinedIcon />}</Button>
										</Box>
									</Box>
									{/* <Typography>Select Thumbnail</Typography>
									<br />
									{shortVideoUrl?.image ?
										<Image src={shortVideoUrl?.image + imageWidthParams(170)} lx radiusLg />
										:
										''}
									<ImageSelector size={'sm'} cropedUrl={e => handleCropedURL(e, 'shortVideos')} cropedFile={(e) => handleCropedFile(e, 'shortVideos')} />
									{!isEmpty(cropedUrl?.shortVideos) ?
										<Box >
											<Button
												variant="contained"
												disableRipple={true}
												className={classes.button}
												onClick={() =>
													ImageKitUploadThumbnail(cropedFile?.shortVideos)
												}
											>
												{'Confirm Upload'}
											</Button>
										</Box> :
										<></>} */}
									<Box pt={1} className={classes.fullWidth}>
										{shortVideoUrl?.video ?
											<Button
												variant="contained"
												disableRipple={true}
												className={classes.fullbutton}
												onClick={() =>
													uploadVideoWithThumbnail(cropedFile?.shortVideos)
												}
											>
												Upload
											</Button> : ''}
									</Box>

								</Box>
						}

					</Box>
				}
				closeDrawer={() => {
					setProfileDrawerState(!profileDrawerState)
				}}
			/>
		</Box >)
}

const customStyles = makeStyles(CommanClasses)
const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: Color.white
	},
	button: {
		width: 170,
		// height: 135,
		background: `${Color.main} 0% 0% no-repeat padding-box`,
		border: `1px ${Color.inputBorder}`,
		borderRadius: 15,
		opacity: 1,
		boxShadow: 'none',
		'&:hover': {
			backgroundColor: Color.main,
			boxShadow: 'none',
		}
	},
	fullWidth: {
		width: '100%'
	},
	fullbutton: {
		width: '100%',
		background: `${Color.main} 0% 0% no-repeat padding-box`,
		border: `1px ${Color.inputBorder}`,
		borderRadius: 15,
		opacity: 1,
		boxShadow: 'none',
		'&:hover': {
			backgroundColor: Color.main,
			boxShadow: 'none',
		}
	},
	smButton: {
		width: 92,
		height: 92,
		background: `${Color.main} 0% 0% no-repeat padding-box`,
		border: `1px ${Color.inputBorder}`,
		borderRadius: 15,
		opacity: 1,
		boxShadow: 'none',
		'&:hover': {
			backgroundColor: Color.main,
			boxShadow: 'none',
		}
	},
	mb10: { marginBottom: 10 },
	footer: {
		width: '100%',
		marginBottom: 10,
	},
	hoverStyle: {
		'&:hover': {
			backgroundColor: 'grey'
		}
	},
	breakWord: {
		overflowWrap: 'break-word'
	},
	centerdButton: {
		textAlign: 'center'
	},
	videoPlayer: {
		position: 'absolute',
		right: 0,
		left: 0,
		top: 0,
		bottom: 0
	},
	videoContainer: {
		height: 130,
		width: 170
	},
	deleteIconContainer: {
		position: 'absolute',
		right: 4,
		top: 4,
		zIndex: 1400,
		width: 34,
		height: 34,
		textAlign: 'center',
		'&:hover': {
			background: '#000',
			color: '#fff',
			borderRadius: 17
		}
	},
	deleteIcon: {
		width: 18,
		marginTop: 4
	}
}))

export default Profile
