import React, { useEffect } from 'react'
import { Card, Grid, Typography, Box, colors } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { map } from 'lodash'

import CommanClasses from '../themes/CommonClasses'
import Color from '../themes/Colors'
import Image from './Image'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import MyPic from '../assets/images/my.png'
import AGIcon from '../components/AGIcon'
import AGCard from './AGCard'
import ThumbUpIcon from '@material-ui/icons/ThumbUp'
import ThumbDownIcon from '@material-ui/icons/ThumbDown'
import { imageWidthParams } from '../Services/local'
const customStyles = makeStyles(CommanClasses)
const useStyles = makeStyles(() => ({
  thumbSize: {
    fontSize: '1rem !important',
    color: '#EA952D',
  },
  alignEnd: {
    textAlign: 'end',
  },
  clubExpiringMembership: {
    height: '100%',
    maxHeight: '100%',
    overflowY: 'auto'
  }
}))

export default (props) => {
  const {
    secondary,
    fullHeight = false,
    cardTitle,
    actionText,
    users,
    dataKeys,
    handleAction,
    expiring,
    feedback,
    feedBackSection,
    ...otherProps
  } = props
  const common = customStyles(CommanClasses)
  const classes = useStyles()
  useEffect(() => {

  }, [])


  return (
    <AGCard
      fullHeight={fullHeight}
      feedBackSection={feedBackSection}
      secondary={secondary}
      handleAction={() => (handleAction ? handleAction() : null)}
      cardHeader={cardTitle}
      action={actionText}
      cardContent={
        <Grid container>
          <Grid item xs={12} container justify='space-between'>
            <Grid item xs={12} className={classes.clubExpiringMembership}>
              {map(users, (data, idx) => (
                // props.selectedFeedbackValue == "Up" && props.selectedFeedbackValue == data[dataKeys[3]] || props.selectedFeedbackValue == "Down" && props.selectedFeedbackValue == data[dataKeys[3]] ?
                  <Box py={0.5} key={idx}>
                    <Card
                      elevation={0}   
                      classes={{ root: common.borderRadiusM }}
                      className={secondary ? common.bgWhite : common.bgInfo}
                    >
                      <Box py={2.5}>
                        <Grid container direction='row'>
                          {/* <Grid item container direction='row' justify='flex-end'>
                          <Grid item>
                            <Box pr={2} mt={-1.5}>
                              <MoreHorizIcon className={common.pointer} />
                            </Box>
                          </Grid>
                        </Grid> */}
                          <Box className={common.w_100}>
                            <Grid item xs={12} container direction='row'>
                              <Grid item xs>
                                <Box>
                                  <Grid container direction='row'>
                                    <Grid item>
                                      <Box pl={2.5} pr={1}>
                                        <Image src={data.avatar + imageWidthParams(40)} sm circle />
                                      </Box>
                                    </Grid>
                                    <Grid item xs>
                                      <Typography>{data[dataKeys[0]]}</Typography>
                                      <Box mt={-0.5}>
                                        <Grid container alignItems='center'>
                                          <Grid item>
                                            <Typography
                                              className={[
                                                common.textLight,
                                                feedback
                                                  ? common.textS
                                                  : common.textM,
                                                common.HeeboLight,
                                              ].join(' ')}
                                            >
                                              {expiring ? 'Expiring on : ' : ''}
                                              {feedback
                                                ? 'Consumer Rating'
                                                : data[dataKeys[1]]}
                                            </Typography>
                                          </Grid>
                                          {feedback ? (
                                            <Grid item>
                                              <Box ml={0.5}>
                                                {data[dataKeys[3]] === 'Up' ? (
                                                  <AGIcon size={12} color={Color.yellow} name={'icon-Like'} />
                                                ) : (
                                                  <AGIcon size={12} color={Color.yellow} name={'icon-Dislike'} />
                                                )}
                                              </Box>
                                            </Grid>
                                          ) : null}
                                          {feedback ? (
                                            <Grid
                                              item
                                              xs
                                              className={classes.alignEnd}
                                            >
                                              <Box pr={2.5}>
                                                <Typography
                                                  className={[
                                                    common.textLight,
                                                    feedback
                                                      ? common.textS
                                                      : common.textM,
                                                    common.HeeboLight,
                                                  ].join(' ')}
                                                >
                                                  {data[dataKeys[1]]}
                                                </Typography>
                                              </Box>
                                            </Grid>
                                          ) : null}
                                        </Grid>
                                      </Box>
                                    </Grid>
                                  </Grid>
                                </Box>
                              </Grid>
                            </Grid>
                          </Box>
                        </Grid>
                        {dataKeys[2] ? (
                          <Grid container direction='row'>
                            <Grid item xs>
                              <Box px={2} >
                                <Typography
                                  className={[
                                    common.textLight,
                                    common.textM,
                                    common.HeeboLight,
                                  ].join(' ')}
                                >
                                  {data[dataKeys[2]]}
                                </Typography>
                              </Box>
                            </Grid>
                          </Grid>
                        ) : (
                          <></>
                        )}
                      </Box>
                    </Card>
                  </Box>
                  // : ""

              ))}
              {
                !users.length && <Typography className={common.textLight}>{'No Feedback found'}</Typography>
              }
            </Grid>
          </Grid>
        </Grid>
      }
    />
  )
}
