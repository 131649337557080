import gql from 'graphql-tag'

export const GetClubFields = gql`
  query getClubDetails(
    $breweryId: ID!
    $startDate: String
    $endDate: String
    $mugClubIds: [String]
  ) {
    brewery(id: $breweryId) {
      success
      brewery {
        id
        activeOffersConnection {
          pageInfo {
            hasMore
            totalCount
          }
          edges {
            cursor
            breweryOfferCampaign {
              id
              banner {
                url
                altText
                tags
              }
              title
            }
          }
        }
        mugClubs(mugClubIds:$mugClubIds) {
          id
          name
          benefits
          benefitsMultiple
          badgeIconName
          badgeIconColor
          clubRenewalCadence
          clubLimit
          memberCount
          autoRenewable
          annualCost
          description
          photosConnection {
            edges {
              image {
                url
                altText
              }
            }
          }
          amountCollected(
            startDate: $startDate
            endDate: $endDate
            mugClubIds: $mugClubIds
          ) {
            totalAmountCollected
            filteredAmountCollected
            amountCollectedStats {
              year
              month
              amountCollected
            }
          }
          activeMembers (
            startDate: $startDate
            endDate: $endDate
            mugClubIds: $mugClubIds
          ) {
            totalActiveMembers
            filteredActiveMembers
            activeMembersStats {
              year
              month
              newJoined
            }
          }
          memberStats {
            activeMemberCount
          }
          breweriesConnecton {
            id
            name
          }
        }
      }
    }
  }
`
