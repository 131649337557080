import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Grid
} from '@material-ui/core'
import moment from 'moment'
import Drawer from './Drawer'
import AGIcon from '../components/AGIcon'
import CommanClasses from '../themes/CommonClasses'
import Color from '../themes/Colors'
import AllMemberCodesDrawer from '../containers/AllMemberCodesDrawer'

const customStyles = makeStyles(CommanClasses)

const useSortableData = (items, config = null) => {
  const [sortConfig, setSortConfig] = React.useState(config)

  const sortedItems = React.useMemo(() => {
    let sortableItems = [...items]
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1
        }
        return 0
      })
    }
    return sortableItems
  }, [items, sortConfig])

  const requestSort = (key) => {
    let direction = 'ascending'
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === 'ascending'
    ) {
      direction = 'descending'
    }
    setSortConfig({ key, direction })
  }

  return { items: sortedItems, requestSort, sortConfig }
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginBottom: theme.spacing(2),
    backgroundColor: 'transparent',
  },
  container: {
    [theme.breakpoints.down('lg')]: {
      height: '440',
    },
    height: 'calc(100vh - 175px)',
  },
  tableStyle: {
    borderSpacing: '0 10px',
    borderCollapse: 'unset',
  },
  bottomStyle: {
    borderBottom: 'none',
  },
  tableCell: {
    padding: '10px 15px',
    height: 50,
    position: 'relative',
  },
  firstCell: {
    minWidth: 140,
    borderTopLeftRadius: 15,
    borderBottomLeftRadius: 15,
  },
  lastCell: {
    borderTopRightRadius: 15,
    borderBottomRightRadius: 15,
  },
  rowStyle: {
    backgroundColor: '#fff',
    boxShadow: '0px 3px 6px #2731791A',
    borderRadius: 15,
  },
  disabledRow: {
    backgroundColor: '#fff',
    boxShadow: '0px 3px 6px #2731791A',
    opacity: '0.5',
    borderRadius: 15,
  },
  verticleCenter: {
    margin: '0 0 0 8px',
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
  },
  buttonBg: {
    borderRadius: 40,
    padding: '4px 15px',
  },
  searchRoot: {
    display: 'flex',
    alignItems: 'center',
    width: 400,
    borderRadius: 40,
    height: 30,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  searchInput: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  tableHTopeaders: {
    marginBottom: theme.spacing(0.4),
    marginTop: theme.spacing(2),
  },
  centeredBox: {
    textAlign: '-webkit-center',
  },
}))

const CustomizedCell = (me) => {
  const classes = useStyles()
  const common = customStyles(CommanClasses)

  return (
    <TableCell
      key={me.id}
      className={[
        classes.bottomStyle,
        classes.tableCell,
        me.firstCell ? classes.firstCell : '',
        me.lastCell ? classes.lastCell : '',
      ].join(' ')}
      align='left'
    >
      {me.external ? (
        me.value
      ) : (
        <Typography
          className={[
            common.HeeboLight,
            common.textM,
            common.textDark,
            me.lastCell ? classes.centeredBox : '',
          ].join(' ')}
        >
          {me.value}
        </Typography>
      )}
    </TableCell>
  )
}

export default (props) => {
  const classes = useStyles()
  const common = customStyles(CommanClasses)

  const [dData, setDData] = useState()
  const [showManageMemberCodes, setShowManageMemberCodes] = useState(false)

  const { items, requestSort, sortConfig } = useSortableData(props.data)

  const handleClickRow = (event, name, object) => {
    setShowManageMemberCodes(!showManageMemberCodes)
    setDData(object)
  }

  return (
    <Grid>
      <Paper className={classes.root} elevation={0}>
        <Grid container spacing={2} className={classes.tableHTopeaders}>
          <Grid item>
            <Typography
              className={[
                classes.title,
                common.textXl,
                common.textDark,
                common.HeeboMedium,
              ].join(' ')}
              id='tableTitle'
              component='div'
            >
              All Coupon Codes
            </Typography>
          </Grid>
        </Grid>
        <TableContainer className={classes.container}>
          <Table
            stickyHeader
            aria-label='sticky table'
            id='mytable'
            classes={{ root: classes.tableStyle }}
          >
            <TableHead>
              <TableRow>
                {props.headCells.map((column, idx) => (
                  <TableCell
                    className={[
                      classes.bottomStyle,
                      idx === 0
                        ? classes.firstCell
                        : idx + 1 === props.headCells.length
                          ? classes.lastCell
                          : 'null',
                    ].join(' ')}
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                    id={column.id}
                    onClick={() => requestSort(column.id)}
                    style={{ backgroundColor: Color.infoDark }}
                  >
                    <Typography className={[common.textM].join(' ')}>
                      {column.label}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item, idx) => (
                <TableRow
                  role='checkbox'
                  tabIndex={-1}
                  key={idx}
                  className={
                    !item?.consumed ? classes.rowStyle : classes.disabledRow
                  }
                >
                  <CustomizedCell firstCell value={item.code} />
                  <CustomizedCell value={item.description} />
                  <CustomizedCell
                    value={item?.oneTimeUse ? 'Yes' : 'No'}
                  />
                  <CustomizedCell
                    value={
                      !item?.oneTimeUse
                        ? moment(item.membershipExpirationDate).format(
                          'MMM Do, YY'
                        )
                        : '-'
                    }
                  />
                  <CustomizedCell
                    lastCell
                    value={
                      <AGIcon
                        name={'icon-Edit'}
                        disabled={item?.consumed ? true : false}
                        color={!item?.consumed ? Color.primary : Color.light}
                        onClick={(event) => !item?.consumed ? handleClickRow(event, item.name, item) : ''}
                        size='14'
                      />
                    }
                  />
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      <Drawer
        open={showManageMemberCodes}
        content={
          <AllMemberCodesDrawer
            mode={'Edit'}
            clubId={props.clubId}
            allClubs={props.allClubs}
            data={dData}
            refresh={(e) => props.refresh(e)}
            closeDrawer={() => {
              setShowManageMemberCodes(!showManageMemberCodes)
            }}
          />
        }
        closeDrawer={() => {
          setShowManageMemberCodes(!showManageMemberCodes)
        }}
      />
    </Grid>
  )
}
