import React from 'react'
import './App.css'
import Customisidetheme from './themes/CustomisedThemes'
import { ThemeProvider } from '@material-ui/core/styles'
import { Auth0Provider } from '@auth0/auth0-react'
import { Routes } from './routes'
import { SnackbarProvider } from 'notistack'
import { ErrorBoundary } from 'react-error-boundary'
import ErrorFallback from './components/ErrorFallback'

function App() {
	if (!process?.env?.REACT_APP_AUTH0_CLIENT_ID) {
		throw new Error('No Auth0 config found!')
	}
	if (!process?.env?.REACT_APP_API_HOST) {
		throw new Error('No API host found!')
	}
	if (!process?.env?.REACT_APP_AUTH0_DOMAIN) {
		throw new Error('No API host found!')
	}

	return (
		<Auth0Provider
			onRedirectCallback={(...what) => {
				console.dir(what)
			}}
			audience={process.env.REACT_APP_API_HOST}
			clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
			domain={process.env.REACT_APP_AUTH0_DOMAIN}
			redirectUri={`${window.location.origin}`}
			scope='read:current_user update:current_user_metadata'
		>
			<ThemeProvider theme={Customisidetheme}>
				<SnackbarProvider
					preventDuplicate={true}
					variant='warning'
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'right',
					}}
					maxSnack={5}>
					<ErrorBoundary
						FallbackComponent={ErrorFallback}
						onReset={() => {
							window.location = '/'
							// reset the state of your app so the error doesn't happen again
						}}
					>
						<Routes />
					</ErrorBoundary>
				</SnackbarProvider>
			</ThemeProvider>
		</Auth0Provider>
	)
}

export default App