import 'date-fns'
import React from 'react'
import DateFnsUtils from '@date-io/date-fns'
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from '@material-ui/pickers'
import { makeStyles } from '@material-ui/core/styles'
import Color from '../themes/Colors'
import Icon from '../components/AGIcon'

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiInputBase-root': {
      color: Color.dark,
      background: '#FFFFFF 0% 0% no-repeat padding-box',
      '& .MuiInputAdornment-root': {
        marginLeft: 0,
      },
    },
    '& .MuiInput-underline': {
      textDecorationColor: '#F7FAFF'
    },
    textDecorationLine: 'none',
    border: '1px solid #DEE2F1',
    borderRadius: 10,
    padding: 5,
  },
}))

const TimePicker2 = (props) => {
  const { onChange, value } = props || {}

  const classes = useStyles()

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} >
      <KeyboardTimePicker
        variant='outlined'
        margin='none'
        id='time-picker2'
        KeyboardButtonProps={{
          'aria-label': 'change time',
        }}
        className={[classes.root].join('')}
        InputProps={{
          disableUnderline: true,
        }}
        value={value}
        onChange={onChange}
        keyboardIcon={<Icon
          name={'icon-Date'}
          onChange={props.onChange}

          color={Color.light}
          size='14'
        />}
      />
    </MuiPickersUtilsProvider>
  )
}

export default TimePicker2
