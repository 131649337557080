/*All the colors use in the applications are here....*/
export default {
	primary: '#273179',
	secondary: '#2290CF',
	success: '#41D691',
	muted: '#6c757d',
	info: '#F0F4FF',
	yellow: '#EA952D',
	lightred: '#F86A55',
	pink: '#F24178',
	warning: '#F9D089',
	danger: '#FF0000',
	white: '#ffffff',
	light: '#808080',
	dark: '#464646',
	infoDark:'#DEE2F1',
	exLight: '#F5F9FB',
	tLight: '#999999',
	shadow: '#2731791A',
	lightShadow: '#00000029',
	inactive: '#BCBCBC',
	main: '#F7FAFF',
	button: '#374785',
	aliceBlue: '#F2F6FE',
	tabColor: '#F9E4B2',
	inputBorder: '#DEE2F1',
	cardHeading: '#24315E',
	divider:'#171C32',
	lighten:'#EBF1FB',
	green:'#23F023',
}
