import React, { useState, useEffect } from 'react'
import {
  Grid,
  Box,
  Hidden,
  Icon,
  Tabs,
  Tab,
  Typography,
  useEventCallback,
} from '@material-ui/core'
import { useLazyQuery } from '@apollo/client'
import { makeStyles } from '@material-ui/core/styles'
import { map, uniqWith, isEqual, find, filter } from 'lodash'
import Head from '../Screens/TopHeader'
import ActivityCard from '../containers/Activities'
import AllUsers from '../containers/AllUsers'
import DropDown from '../components/Dropdown'
import GroupSelectBox from '../components/GroupSelectBox'
import AGIcon from '../components/AGIcon'
import MemberCodes from '../containers/MemberCodes'
import MemberCountCard from '../containers/MemberCountCard'
import AGButton from '../components/AgButton'
import Popup from '../components/Popup'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import Drawer from '../components/Drawer'
import AddMemberDrawer from '../containers/AddMemberDrawer'
import InviteMemberDrawer from '../containers/InviteMemberDrawer'
import AllMemberCodesDrawer from '../containers/AllMemberCodesDrawer'
import { getBreweryId, getBreweries } from '../Services/local'
import { storeBreweryId, storeBreweryName } from '../Services/local'
import { GetPromoMemberCount } from '../graphql/memberCountGQL'
import CommanClasses from '../themes/CommonClasses'
import { useQuery } from '@apollo/client'
import { GetClubFields } from '../graphql/clubDetailsGQL'
import { useSnackbar } from 'notistack'

export default () => {
  const classes = usestyles()
  const breweryId = getBreweryId()
  const breweries = getBreweries()
  const common = customStyles(CommanClasses)

  const { enqueueSnackbar } = useSnackbar()

  const [selected, setSelected] = useState()
  const [value, setValue] = useState(0)
  const [newData, setNewData] = useState({})
  const [showInviteMember, setShowInviteMember] = useState(false)
  const [showAddNewCode, setShowAddNewCode] = useState(false)
  const [freshCount, setFreshCount] = useState()
  const [allCountData, setAllCountData] = useState({})
  const [clubData, setClubData] = useState()
  const [showAddMember, setShowAddMember] = useState(false)
  const [selectedClubIds, setSelectedClubIds] = useState([])
  const [allClubs, setAllClubs] = useState([])

  useEffect(() => {
    if (breweries?.length) {
      getAllClubsFromBreweries()
    }
  }, [breweries])

  const getAllClubsFromBreweries = () => {
    let mugClubs = []

    breweries.map(({ brewery }) => {
      if (brewery?.mugClubs) {
        brewery.mugClubs.map((mugClub) => {
          mugClubs = [
            ...mugClubs,
            {
              ...(mugClub || {})
            }
          ]
        })
      }
    })
    let clubsA = uniqWith(mugClubs, isEqual) || []
    setAllClubs(filter(clubsA, c => c.id))
    if (clubsA?.length) {
      setSelectedClubIds([clubsA[0].id])
    }
  }

  const handleSelectedClub = (v) => {
    // if (v?.length >= 1) {
    //   setSelectedClubIds([...v.map(d => d.id)])
    // }
    setSelectedClubIds([v?.id || ''])
  }

  const [
    getCount,
    { error: getMemberActivitiesError, loading: getMemberActivitiesLoading },
  ] = useLazyQuery(GetPromoMemberCount, {
    onCompleted: (resp) => {
      if (resp) {
        console.log('GetPromoMemberCount  ?? >> ', resp)
        setAllCountData(resp)
      }
    },
    onError: (error) => {
      console.log('error', error)
    },
    notifyOnNetworkStatusChange: true,
  })

  const {
    data: cData
  } = useQuery(GetClubFields, {
    variables: {
      breweryId,
    },
    onCompleted: (resp) => { if (resp) { setClubData(resp) } },
    notifyOnNetworkStatusChange: true,
  })

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const validateInvite = () => {
    setShowInviteMember(true)
  }

  const EActions = [
    {
      name: 'Invite',
      outlined: true,
      onClick: () => validateInvite()
    },
    // {
    //   name: value === 0 ? 'Add Member' : '',
    //   outlined: true,
    //   onClick: () => setShowAddMember(true)
    // },
    {
      name: value === 0 ? '' : 'Add New Code',
      outlined: false,
      onClick: () => setShowAddNewCode(true)
    },
  ]

  const handleSavedData = (e) => {
    setNewData(e)
  }

  const handleBrewery = (action) => {
    storeBreweryId(action.id)
    storeBreweryName(action.name)
  }
  useEffect(() => {
    let selectedClubBrewery = find(allClubs, c => c.id === selectedClubIds[0])
    getCount({
      variables: {
        breweryId: selectedClubBrewery?.breweriesConnecton[0]?.id || breweryId,
        mugClubIds: selectedClubIds
      }
    })
  }, [breweryId, freshCount, selectedClubIds])

  const selectedClubs = [
    ...allClubs.filter(ac => selectedClubIds.includes(ac.id))
  ]

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        className={[
          common.bgTransparent,
          common.stickyPosition,
          common.topNavBar,
        ].join(' ')}
      >
        <Head
          // pageHeader={'Users'}
          pageHeader={
            <Grid container>
              <Grid item>
                <Box>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor='primary'
                    textColor='primary'
                    centered
                  >
                    <Tab
                      disableFocusRipple={true}
                      disableRipple={true}
                      classes={{ root: classes.tabStyle }}
                      label={
                        <Typography
                          variant='h5'
                          className={value === 0 ? common.HeeboMedium : ''}
                        >
                          Members
                        </Typography>
                      }
                    />
                    <Tab
                      disableFocusRipple={true}
                      disableRipple={true}
                      classes={{ root: classes.tabStyle }}
                      label={
                        <Typography
                          variant='h5'
                          className={value === 1 ? common.HeeboMedium : ''}
                        >
                          Membership Codes
                        </Typography>
                      }
                    />
                  </Tabs>
                </Box>
              </Grid>
              <Hidden xsDown>
                <Grid item xs={12} sm={true}>
                  <Box mt={0.7} ml={5}>
                    <GroupSelectBox
                      singleSelect={true}
                      options={allClubs}
                      // value={selectedClubs}
                      value={selectedClubs?.length ? selectedClubs[0] : ''}
                      onChange={(e, v) =>
                        handleSelectedClub(v)
                      } />
                  </Box>
                </Grid>
              </Hidden>
            </Grid>
          }
          endActions={
            <Box>
              <Grid container spacing={2} >
                <Hidden smDown>
                  {map(EActions, (data, idx) => (
                    data.name?.length ?
                      <Grid
                        item
                        key={idx}
                        className={['members_target0'].join(' ')}
                      >
                        {idx % 2 === 0 ?
                          <AGButton
                            // outlined={data.outlined}
                            outlined='true'
                            label={data.name}
                            onClick={data.onClick}
                          />
                          :
                          <AGButton
                            label={data.name}
                            onClick={data.onClick}
                          />}

                      </Grid> : ''
                  ))}
                </Hidden>
              </Grid>
                <Grid container className={classes.mobileScreenActions}>
                <Hidden smUp>
                  <Grid item xs>
                    <Box mr={2.2}>
                    <GroupSelectBox
                      singleSelect={true}
                      options={allClubs}
                      // value={selectedClubs}
                      value={selectedClubs?.length ? selectedClubs[0] : ''}
                      onChange={(e, v) =>
                        handleSelectedClub(v)
                      } />
                      </Box>
                  </Grid>
                  </Hidden>
                  <Hidden mdUp>
                  <Grid item><Popup
                    action={
                      <Icon>
                        <MoreVertIcon />
                      </Icon>
                    }
                    placement={'left-start'}
                    initialValue={''}
                    selectedValue={(e) => setSelected(e)} //EActions has onClick now
                    data={EActions}

                  /></Grid>
                  </Hidden>
                </Grid>
            </Box>
          }
        />
      </Grid>
      <Grid item xs={12}>
        <Box mx={2} py={1}>
          <Grid container spacing={2}>
            <Grid item md={9} xs={12}>
              {value === 0 ? <MemberCountCard AllCount={allCountData} /> : <></>}
              {value === 0 ? <br /> : false}
              <Grid
                container
                spacing={2}
                direction='column'
                alignItems='stretch'
              >
                <Grid item xs={12}>
                  {value === 0 ? <AllUsers allClubs={allClubs} mugClubIds={selectedClubIds || []} refreshCounts={(e) => { setFreshCount(!freshCount) }} /> : <MemberCodes allClubs={allClubs} mugClubIds={selectedClubIds} refreshCodes={newData} />}
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={3} xs={12}>
              <Grid container direction='column' spacing={2}>
                {/* <Grid item xs={12}><ExpiringMembership/></Grid> */}
                <Grid item xs={12} className={['members_target1'].join(' ')}>
                  <ActivityCard type='MEMBER' mugClubIds={selectedClubIds} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Drawer
        open={showInviteMember}
        content={
          <InviteMemberDrawer
            allClubs={allClubs || []}
            closeDrawer={() => setShowInviteMember(false)}
          />
        }
        closeDrawer={() => setShowInviteMember(false)}
      />
      <Drawer
        open={showAddMember}
        content={
          <AddMemberDrawer closeDrawer={() => setShowAddMember(!showAddMember)} />
        }
        closeDrawer={() => setShowAddMember(!showAddMember)}
      />
      <Drawer
        open={showAddNewCode}
        content={
          <AllMemberCodesDrawer
            allClubs={allClubs}
            mode={'New'}
            refresh={(e) => handleSavedData(e)}
            closeDrawer={() => {
              setShowAddNewCode(false)
            }}
          />
        }
        closeDrawer={() => setShowAddNewCode(false)}
      />

    </Grid>
  )
}

const customStyles = makeStyles(CommanClasses)

const usestyles = makeStyles((theme) => ({
  tabStyle: {
    textTransform: 'initial',
    minWidth: 0,
  },
  mobileScreenActions:{
    [theme.breakpoints.down("xs")]:{
      marginTop: theme.spacing(1.5)
    }
  }
}))

