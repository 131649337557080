import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Grid,
  Box,
  Tooltip,
  InputBase,
  Chip,
  Icon,
  Button,
  LinearProgress,
} from '@material-ui/core'
import moment from 'moment'
import Image from './Image'
import Drawer from './Drawer'
import AGText from './AgText'
import AGButton from './AgButton'
import CommanClasses from '../themes/CommonClasses'
import AGIcon from '../components/AGIcon'
import AllMembersManageDrawer from '../containers/AllMembersManageDrawer'
import Color from '../themes/Colors'
import Popup from '../components/Popup'
import { GetExportedData } from '../graphql/getExportedDataGQL'
import { useLazyQuery } from '@apollo/client'
import { get } from 'lodash'
import { imageWidthParams } from '../Services/local'

const customStyles = makeStyles(CommanClasses)

const useSortableData = (items, config = null) => {
  const [sortConfig, setSortConfig] = React.useState(config)
  const sortedItems = React.useMemo(() => {
    let sortableItems = [...items]
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1
        }
        return 0
      })
    }
    return sortableItems
  }, [items, sortConfig])
  const requestSort = (key) => {
    let direction = 'ascending'
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === 'ascending'
    ) {
      direction = 'descending'
    }
    setSortConfig({ key, direction })
  }
  return { items: sortedItems, requestSort, sortConfig }
}
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: 'transparent',
  },
  container: {
    [theme.breakpoints.down('lg')]: {
      maxHeight: '440',
    },
    maxHeight: 'calc(100vh - 334px)',
  },
  tableStyle: {
    borderSpacing: '0 10px',
    borderCollapse: 'unset',
  },
  bottomStyle: {
    borderBottom: 'none',
  },
  tableCell: {
    padding: '10px 15px',
    height: 50,
    position: 'relative',
  },
  firstCell: {
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
    maxWidth: 50,
    paddingRight: 0,
    width: 50,
  },
  lastCell: {
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    textAlign: 'center',
  },
  rowStyle: {
    backgroundColor: '#fff',
    boxShadow: '0px 3px 6px #2731791A',
    borderRadius: 15,
  },
  disabledRow: {
    backgroundColor: '#fff',
    boxShadow: '0px 3px 6px #2731791A',
    opacity: '0.5',
    borderRadius: 15,
  },
  verticleCenter: {
    margin: '0 0 0 8px',
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
  },
  buttonBg: {
    borderRadius: 40,
    padding: '4px 15px',
  },
  searchRoot: {
    display: 'flex',
    alignItems: 'center',
    width: 400,
    borderRadius: 40,
    height: 30,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  searchInput: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  progressBar: {
    margin: '0 15px 0 5px '
  },
  tableHTopeaders: {
    marginBottom: theme.spacing(0.4),
    marginTop: theme.spacing(2),
  },
  centeredBox: {
    textAlign: '-webkit-center',
  },
  statusBox: {
    whiteSpace: 'nowrap'
  }
}))
const CustomizedCell = (me) => {
  const classes = useStyles()
  const common = customStyles(CommanClasses)
  return (
    <TableCell
      key={me.id}
      className={[
        classes.bottomStyle,
        classes.tableCell,
        me.firstCell ? classes.firstCell : '',
        me.lastCell ? classes.lastCell : '',
        common.HeeboLight,
        common.textM,
        common.textDark,
      ].join(' ')}
      align='left'
    >
      {me.external ? (
        me.value
      ) :
        me.value
      }
    </TableCell>
  )
}
export default (props) => {
  const {
    searchTextChange,
    mugClubIds,
    filterType,
    loading,
    errorCheck,
    headCells,
    usersList,
    onClickViewMore,
    hasMore,
  } = props
  const classes = useStyles()
  const common = customStyles(CommanClasses)
  const [selected, setSelected] = React.useState('All')
  const [drawerState, setDrawerState] = React.useState(false)
  const [drawerData, setDrawerData] = React.useState({})
  const [drawerMode, setDrawerMode] = React.useState('read')
  const [searchText, setSearchText] = React.useState('')
  const { items, requestSort, sortConfig } = useSortableData(usersList)

  const handleClickRow = (event, name, object) => {
    const selectedIndex = selected.indexOf(name)
    setDrawerMode('read')
    setDrawerState(!drawerState)
    setDrawerData(object)
  }

  const handleFilterSelected = (res) => {
    setSelected(res)
    filterType(res)
  }

  const handleChange = (e) => {
    searchTextChange(e?.target?.value)
    if (!e?.length) filterType('All')
    setSearchText(e?.target?.value)
  }

  const handleClearSearchText = () => {
    searchTextChange('')
    filterType('All')
    setSearchText('')
    setSelected('All')
  }
  const [exportedData, { loading :exportingUsers , data: expData}] = useLazyQuery(GetExportedData, {
    onCompleted: (resp) => {
      if (resp) {
        console.log('resp.getExportedData.fileUrl',resp.getExportedData.fileUrl)
        if(get(resp, 'getExportedData.fileUrl')){
          window.open(resp.getExportedData.fileUrl)
        }
      }
    },
    fetchPolicy: 'network-only'
  })
  console.log('expData',expData)

  const handleMemberExport = () => {
    let variables = {
      typeOfExport: 'MEMBER_EXPORT'
    }
    if (mugClubIds && mugClubIds.length) {
      variables = { ...variables, mugClubIds: mugClubIds }
    }
    exportedData({ variables })
  }

  return (
    <Paper className={classes.root} elevation={0}>
      <Grid container spacing={2} className={classes.tableHTopeaders}>
        <Grid item xs className={classes.smallFullWidth}>
          <Grid container className={classes.tablesubHTopeaders} spacing={2}>
            <Grid item>
              <Typography
                className={[
                  classes.title,
                  common.textXl,
                  common.textDark,
                  common.HeeboMedium,
                ].join(' ')}
                id='tableTitle'
                component='div'
              >
                All Members
              </Typography>
            </Grid>
            <Grid item>
              <Box>
                <Paper
                  className={classes.searchRoot}
                  elevation={0}
                >
                  <Box pl={1} pt={0.25}>
                    <AGIcon
                      name={'icon-Search'}
                      color={Color.light}
                      size='14'
                    />
                  </Box>
                  <InputBase
                    margin='dense'
                    value={searchText}
                    onChange={(event) => handleChange(event)}
                    className={classes.searchInput}
                    placeholder='Search'
                    inputProps={{ 'aria-label': 'search' }}
                    endAdornment={
                      <Box pr={1}>
                        {searchText.length ? (
                          <AGIcon
                            name={'icon-Close'}
                            onClick={() => handleClearSearchText()}
                            color={Color.light}
                            size='10'
                          />
                        ) : (
                          ''
                        )}
                      </Box>
                    }
                  />
                </Paper>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item className={classes.smallFullWidth}>
          {!searchText.length ? (
            <Grid container className={classes.tablesubHTopeaders} spacing={2}>
              <Grid item>
                {selected !== 'All' ? (
                  <Chip
                    className={classes.chipStyle}
                    variant='outlined'
                    label={`${selected} Users`}
                    onDelete={() => handleFilterSelected('All')}
                    deleteIcon={
                      <Box pt={0.5}>
                        <AGIcon name={'icon-Close'} size='10' />
                      </Box>
                    }
                  />
                ) : (
                  ''
                )}
              </Grid>
              <Grid item>
                <AGButton
                  outlined='true'
                  label={"Export" }
                  loading = {exportingUsers}
                  color='#FA735F'
                  onClick={() => handleMemberExport()}
                />
              </Grid>
              <Grid item>
                <Popup
                  action={
                    <Tooltip title='Filter list'>
                      <Icon
                        className={[
                          classes.buttonBg,
                          common.bgWhite,
                          common.pointer,
                        ].join(' ')}
                      >
                        <AGIcon
                          name={'icon-Sort'}
                          color={Color.primary}
                          size='14'
                        />
                      </Icon>
                    </Tooltip>
                  }
                  placement={'left-start'}
                  initialValue={selected}
                  list={['All', 'Recent', 'Expiring', 'Expired']}
                  selectedValue={(e) => handleFilterSelected(e)}
                />
              </Grid>
            </Grid>
          ) : (
            ''
          )}
        </Grid>
      </Grid>
      {loading && !drawerState ? (
        <div>
          <LinearProgress className={classes.progressBar} />
        </div>
      ) : (
        ''
      )}
      <TableContainer className={classes.container}>
        <Table
          stickyHeader
          aria-label='sticky table'
          id='mytable'
          classes={{ root: classes.tableStyle }}
          className={common.relativePosition}
        >
          <TableHead>
            <TableRow>
              {headCells.map((column, idx) => (
                <TableCell
                  className={[
                    classes.bottomStyle,
                    idx === 0
                      ? classes.firstCell
                      : idx + 1 === headCells.length
                        ? classes.lastCell
                        : 'null',
                  ].join(' ')}
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                  id={column.id}
                  onClick={() => console.log("Members.......", requestSort(column.id))}
                  style={{ backgroundColor: Color.infoDark }}
                >
                  <Typography
                    className={[common.textM, common.pointer].join(' ')}
                  >
                    {column.label}
                  </Typography>
                </TableCell>
              ))}

            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item, idx) => (
              <TableRow
                onClick={(event) => handleClickRow(event, item.name, item)}
                role='checkbox'
                tabIndex={-1}
                key={idx}
                className={[item.status ? classes.rowStyle : classes.disabledRow, common.pointer].join(' ')}
              >
                <CustomizedCell
                  firstCell
                  value={
                    <Image
                      src={item.avatar+ imageWidthParams(50)}
                      alt={item.name}
                      md
                      circle
                    />

                  }
                />
                <CustomizedCell value={item.name} />
                <CustomizedCell value={item.contact} />
                <CustomizedCell value={get(item, 'dateOfBirth') || ''} />
                <CustomizedCell
                  value={moment(item.joiningDate).utc().format('MMM Do, YYYY')}
                />
                <CustomizedCell
                  value={moment(item.expiringDate).utc().format('MMM Do, YYYY')}
                />
                <CustomizedCell
                  lastCell
                  value={
                    <button
                      style={{
                        border: 0,
                        backgroundColor: item?.sharedInfo?.length
                          ? Color.success
                          : Color.danger,
                        height: 12,
                        width: 12,
                        borderRadius: 6,
                      }}
                    ></button>
                  }
                />
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {!items?.length && !errorCheck && !loading ? (
        <Typography className='transition'>{'No members found'}</Typography>
      ) : (
        ''
      )}
      {errorCheck ? (
        <Typography
          variant='h6'
          className={[
            common.HeeboMedium,
            common.textCenter,
            common.pointer,
            common.textDanger,
          ].join(' ')}
        >
          {'Oops Error!'}
        </Typography>
      ) : (
        ''
      )}
      {hasMore?.hasMore && (
        <Typography
          className={[
            common.HeeboMedium,
            common.textCenter,
            common.pointer,
          ].join(' ')}
          onClick={onClickViewMore}
        >
          {'View more'}
        </Typography>
      )}
      <Drawer
        open={drawerState}
        heading={
          <AGText
            label={
              <Box display='flex' alignItems='center'>
                <Typography
                  className={[
                    common.HeeboMedium,
                    common.textPrimary,
                    common.textXl,
                    classes.mr20,
                  ].join(' ')}
                >
                  {drawerMode === 'read' ? drawerData?.name : 'Edit Member'}
                </Typography>
                <Box component={'span'} ml={0.5}>
                  <Button
                    className={[
                      drawerData.status === 'Active'
                        ? common.bgSuccess
                        : common.bgPink,
                      common.textWhite,
                      common.textM,
                      classes.statusBox,
                    ].join(' ')}
                  >
                    {drawerData.status}
                  </Button>
                </Box>
              </Box>
            }
          />
        }
        content={
          <AllMembersManageDrawer
            refreshuserList={props.refreshuserList}
            mugClubIds={mugClubIds}
            data={drawerData}
            modeChange={(e) => setDrawerMode(e)}
            mode={drawerMode}
            closeDrawer={() => setDrawerState(!drawerState)}
          />
        }
        closeDrawer={() => setDrawerState(!drawerState)}
        footer={
          <AGButton
            label={drawerMode === 'read' ? 'Edit' : 'Update'}
            textColor={'#FFFFFF'}
            color={'#FA735F'}
            fullWidth
            onClick={() => {
              drawerMode === 'read'
                ? setDrawerMode('edit')
                : setDrawerMode('read')
              // setDrawerState(!drawerState)
            }}
          />
        }
      />
    </Paper>
  )
}
