import gql from 'graphql-tag'
import { BreweryFragmentDoc } from './Brewery/breweryFragment'

export const OwnedBreweriesConnectionEdgeFragmentDoc = gql`
  fragment OwnedBreweriesConnectionEdge on MerchantUserOwnedBreweriesEdge {
    cursor
    brewery {
      ...BreweryFragment
    }
  }
  ${BreweryFragmentDoc}
`
export const OwnedBreweriesConnectionFragmentDoc = gql`
  fragment OwnedBreweriesConnection on MerchantUserOwnedBreweriesConnection {
    pageInfo {
      totalCount
      hasMore
    }
    edges {
      ...OwnedBreweriesConnectionEdge
    }
  }
  ${OwnedBreweriesConnectionEdgeFragmentDoc}
`
export const MerchantUserFragmentDoc = gql`
  fragment MerchantUser on MerchantUser {
    id
    email
    phoneNumber
    ownedBreweriesConnection {
      ...OwnedBreweriesConnection
    }
  }
  ${OwnedBreweriesConnectionFragmentDoc}
`
export const MeFragmentDoc = gql`
  fragment Me on MeQueryResponse {
    merchantUser {
      ...MerchantUser
    }
  }
  ${MerchantUserFragmentDoc}
`
export const MeDocument = gql`
  query Me {
    # myBreweryId @client
    me {
      ...Me
    }
  }
  ${MeFragmentDoc}
`