const activityTypes = {
    "OFFER_UPDATE" : {
        "template": `Promo <span class='HeeboMedium' style='color:#273179'>{{meta.offerName}} </span> updated on {{activityDate}}
        {{#up}}
        <div>Fields Updated  
            {{#title}}<div><b>Title : </b>{{title}}</div>{{/title}}  
            {{#ctaLink}}<div><b>External Link : </b>{{ctaLink}}</div>{{/ctaLink}}  
            {{#markdown}}<div><b>Description : </b>{{markdown}}</div>{{/markdown}}  
            {{#possibleRedemptions}}<div><b>Possible Redemptions : </b>{{possibleRedemptions}}</div>{{/possibleRedemptions}}  
            {{#expiringDate}}<div><b>Expiring Date : </b>{{expiringDate}}</div>{{/expiringDate}}  
            {{#scheduledDate}}<div><b>Scheduled Date : </b>{{scheduledDate}}</div>{{/scheduledDate}}
            {{#targetClasses}}
            <div><b>Promotion Target : </b>
                {{^membershipProgramMember}}Every One{{/membershipProgramMember}}
            </div>
            {{/targetClasses}}
            {{^targetClasses}}
                {{^targetPreferences}}
                    <div><b>Promotion Target : </b> Every One</div>
                {{/targetPreferences}}
            {{/targetClasses}}
            {{#targetPreferences}}
            {{#beerTypes.length}} <div><b>Promotion Target : </b> Beer Preferences </div> {{/beerTypes.length}}
            {{/targetPreferences}}
        <div>
        {{/up}} 
        `,
        "by" : "BUSINESS",
        "type" : ["MERCHANT_ACTIVITY"]
    },

    "CLUB_UPDATE": {
        "template": `Club Data updated on {{activityDate}}
        {{#up}}
        <div>Fields Updated 
        {{#name}}<div><b>Club Name : </b>{{name}}</div>{{/name}} 
        {{#description}}<div><b>Description : </b>{{description}}</div>{{/description}} 
        {{#clubLimit}}<div><b>Club Limit : </b>{{clubLimit}}</div>{{/clubLimit}} 
        {{#annualCost}}<div><b>Annual Cost : </b>{{annualCost}}</div>{{/annualCost}} 
            {{#benefitsMultiple.length}}
            <div><b>Benefits : </b></div>
            <ul style="padding:0 0 0 12px; margin:0;">
                {{#benefitsMultiple}}
                    <li>{{.}}</li>
                {{/benefitsMultiple}}
            </ul>
            {{/benefitsMultiple.length}}
            </div>
        {{/up}}
        `,
        "by" : "BUSINESS",
        "type" : ["MERCHANT_ACTIVITY"]
    },

    "OFFER_INSERT": {
        "template": `Promo <span class='HeeboMedium' style='color:#273179'>{{meta.offerName}} </span> added on {{activityDate}}
        {{#af}}
        <div>Fields Added  
            {{#title}}<div><b>Title : </b>{{title}}</div>{{/title}}  
            {{#ctaLink}}<div><b>External Link : </b>{{ctaLink}}</div>{{/ctaLink}}  
            {{#markdown}}<div><b>Description : </b>{{markdown}}</div>{{/markdown}}  
            {{#possibleRedemptions}}<div><b>Possible Redemptions : </b>{{possibleRedemptions}}</div>{{/possibleRedemptions}}  
            {{#expiringDate}}<div><b>Expiring Date : </b>{{expiringDate}}</div>{{/expiringDate}}  
            {{#scheduledDate}}<div><b>Scheduled Date : </b>{{scheduledDate}}</div>{{/scheduledDate}}
            {{#targetClasses}}
            <div><b>Promotion Target : </b>
                {{^membershipProgramMember}}Every One{{/membershipProgramMember}}
            </div>
            {{/targetClasses}}
            {{^targetClasses}}
                {{^targetPreferences}}
                    <div><b>Promotion Target : </b> Every One</div>
                {{/targetPreferences}}
            {{/targetClasses}}
            {{#targetPreferences}}
            {{#beerTypes.length}} <div><b>Promotion Target : </b> Beer Preferences </div> {{/beerTypes.length}}
            {{/targetPreferences}}
        <div>
        {{/af}}
        `,
        "by" : "BUSINESS",
        "type" : ["MERCHANT_ACTIVITY"]
    },
    

    "CLUB_OFFER_INSERT": {
        "template": `Promo <span class='HeeboMedium' style='color:#273179'>{{meta.offerName}}</span> added on {{activityDate}}
        {{#af}}
            <div>Fields Added  
            {{#title}}<div><b>Title : </b>{{title}}</div>{{/title}}  
            {{#ctaLink}}<div><b>External Link : </b>{{ctaLink}}</div>{{/ctaLink}}  
            {{#markdown}}<div><b>Description : </b>{{markdown}}</div>{{/markdown}}  
            {{#possibleRedemptions}}<div><b>Possible Redemptions : </b>{{possibleRedemptions}}</div>{{/possibleRedemptions}}  
            {{#expiringDate}}<div><b>Expiring Date : </b>{{expiringDate}}</div>{{/expiringDate}}  
            {{#scheduledDate}}<div><b>Scheduled Date : </b>{{scheduledDate}}</div>{{/scheduledDate}}
            {{#targetClasses}}
            <div><b>Promotion Target : </b>
                {{#membershipExpiration}}Upcoming Expiries {{/membershipExpiration}}
                {{#membershipProgramMember}}Members {{/membershipProgramMember}}
            </div>
            {{/targetClasses}}
            {{#targetPreferences}}
            {{#beerTypes.length}} <div><b>Promotion Target : </b> Beer Preferences </div> {{/beerTypes.length}}
            {{/targetPreferences}}
             <div>
        {{/af}}
        `,
        "by" : "BUSINESS",
        "type" : ["MERCHANT_ACTIVITY"]
    },
    

    "CLUB_OFFER_REDEEMED": {
        "template": "Promo <span class='HeeboMedium' style='color:#273179'>{{meta.offerName}}</span> redeemed by <b>{{meta.memberName}}</b> on {{activityDate}}",
        "by" : "MEMBER",
        "type" : ["MEMBER_ACTIVITY"]
    },
    

    "CLUB_OFFER_UPDATE": {
        "template": `Promo <span class='HeeboMedium' style='color:#273179'>{{meta.offerName}}</span>  updated on {{activityDate}} 
        <div>Fields Updated  {{#up}}
            {{#title}}<div><b>Title : </b>{{title}}</div>{{/title}}  
            {{#ctaLink}}<div><b>External Link : </b>{{ctaLink}}</div>{{/ctaLink}}  
            {{#markdown}}<div><b>Description : </b>{{markdown}}</div>{{/markdown}}  
            {{#possibleRedemptions}}<div><b>Possible Redemptions : </b>{{possibleRedemptions}}</div>{{/possibleRedemptions}}  
            {{#expiringDate}}<div><b>Expiring Date : </b>{{expiringDate}}</div>{{/expiringDate}}  
            {{#scheduledDate}}<div><b>Scheduled Date : </b>{{scheduledDate}}</div>{{/scheduledDate}}
            {{#targetClasses}}
            <div><b>Promotion Target : </b>
                {{#membershipExpiration}}Upcoming Expiries {{/membershipExpiration}}
                {{#membershipProgramMember}}Members {{/membershipProgramMember}}
            </div>
            {{/targetClasses}}
            {{#targetPreferences}}
            {{#beerTypes.length}} <div><b>Promotion Target : </b> Beer Preferences </div> {{/beerTypes.length}}
            {{/targetPreferences}}
        {{/up}} <div>`,
        "by" : "BUSINESS",
        "type" : ["MERCHANT_ACTIVITY"]
    },
    

    "CLUB_COMEMBER_ADD": {
        "template": "<span class='HeeboMedium' style='color:#273179'>{{meta.coMemberName}}</span> is associated as a comember with primary member {{meta.primaryMemberName}} on {{activityDate}}",
        "by" : "MEMBER",
        "type" : ["MERCHANT_ACTIVITY"]
    },

    // "CLUB_COMEMBER_UPDATE" : {
    //     "template": "<span class='HeeboMedium' style='color:#273179'>{{meta.coMemberName}}</span> is associated as a comember with primary member {{meta.primaryMemberName}} on {{activityDate}}",
    //     "by" : "MEMBER",
    //     "type" : ["MERCHANT_ACTIVITY"]
    // },
    
    "CLUB_COMEMBER_DELETE": {
        "template": "<span class='HeeboMedium' style='color:#273179'>{{meta.coMemberName}}</span> is disassociated as a comember on {{activityDate}}",
        "by" : "MEMBER",
        "type" : ["MERCHANT_ACTIVITY"]
    },
    
    "CLUB_MEMBER_UPDATE": {
        "template": `Member <span class='HeeboMedium' style='color:#273179'>{{meta.memberName}}</span> updated on {{activityDate}}
        {{#up}}
            <div>Fields Updated  
                {{#expiry}}<div><b>Expiring Date : </b>{{expiry}}</div>{{/expiry}}  
                {{#memberNumber}}<div><b>Member Number : </b>{{memberNumber}}</div>{{/memberNumber}}  
                {{#notes}}<div><b>Note : </b>{{notes}}</div>{{/notes}}  
            <div>
        {{/up}}
        `,
        "by" : "MEMBER",
        "type" : ["MERCHANT_ACTIVITY","MEMBER_ACTIVITY"]
    },

    "BUSINESS_UPDATE": {
        "template": "<b><%fieldsUPdated.title%></b> updated on {{activityDate}}.",
        "by" : "BUSINESS",
        "type" : ["MERCHANT_ACTIVITY"]
    },

    "BUSINESS_PROFILE_UPDATE": {
        "template": "club <b><%fieldsUPdated.title%></b> updated on{{activityDate}}.",
        "by" : "BUSINESS",
        "type" : ["MERCHANT_ACTIVITY"]
    },

    "OFFER_REDEEMED": {
        "template": "Promo <span class='HeeboMedium' style='color:#273179'>{{meta.offerName}}</span> redeemed by <b>{{meta.memberName}}</b> on {{activityDate}}",
        "by" : "BUSINESS",
        "type" : ["MERCHANT_ACTIVITY","MEMBER_ACTIVITY"]
    },

    "CLUB_MEMBER_DATA_SHARED": {
        "template": "Data Shared by <span class='HeeboMedium' style='color:#273179'>{{meta.memberName}}</span> on {{activityDate}} ",
        "by" : "MEMBER",
        "type" : ["MEMBER_ACTIVITY"]
    },

    "USER_DATA_SHARED": {
        "template": "Data Shared by <span class='HeeboMedium' style='color:#273179'>{{meta.memberName}}</span> on {{activityDate}} ",
        "by" : "MEMBER",
        "type" : ["MEMBER_ACTIVITY"]
    },

    "CLUB_MEMBER_FEEDBACK_ADDED": {
        "template": "New feedback received from <span class='HeeboMedium' style='color:#273179'>{{meta.memberName}}</span> on {{activityDate}}",
        "by" : "MEMBER",
        "type" : ["MEMBER_ACTIVITY"]
    },
    "CLUB_MEMBER_JOINED": {
        "template": "<span class='HeeboMedium' style='color:#273179'>{{meta.memberName}}</span> joined the club on {{activityDate}}.",
        "by" : "MEMBER",
        "type" : ["MEMBER_ACTIVITY"]
    },
    "CLUB_REPLACE": {
        "template": "Club Data updated on {{activityDate}}.",
        "by" : "BUSINESS",
        "type" : ["MERCHANT_ACTIVITY"]
    },
    "MEMBER_CODE_UPDATED": {
        "template": `<span class='HeeboMedium' style='color:#273179'>{{meta.memberCode}}</span> Member code  is updated on {{activityDate}}`,
        "by" : "BUSINESS",
        "type" : ["MERCHANT_ACTIVITY"]
    },
    "MEMBER_CODE_DELETED": {
        "template": "<span class='HeeboMedium' style='color:#273179'>{{meta.memberCode}}</span> Member code  deleted on {{activityDate}}",
        "by" : "BUSINESS",
        "type" : ["MERCHANT_ACTIVITY"]
    },
    "MEMBER_CODE_ADDED": {
        "template": "<span class='HeeboMedium' style='color:#273179'>{{meta.memberCode}}</span> Member code  added on {{activityDate}}",
        "by" : "BUSINESS",
        "type" : ["MERCHANT_ACTIVITY"]
    },
    "MEMBER_CODE_CONSUMED": {
        "template": "<span class='HeeboMedium' style='color:#273179'>{{meta.memberCode}}</span> Member code   consumed on {{activityDate}}.",
        "by" : "BUSINESS",
        "type" : ["MEMBER_ACTIVITY"]
    },
    "CLUB_MEMBER_AUTORENEWED": {
        "template": "<span class='HeeboMedium' style='color:#273179'>{{meta.userName}}</span> is autorenewed on {{activityDate}}.",
        "by" : "BUSINESS",
        "type" : ["MEMBER_ACTIVITY"]
    },
    "CLUB_MEMBER_UNSUBSCRIBED": {
        "template": "<span class='HeeboMedium' style='color:#273179'>{{meta.userName}}</span> has unsubscribed on {{activityDate}}.",
        "by" : "BUSINESS",
        "type" : ["MEMBER_ACTIVITY"]
    }
}
export default activityTypes


