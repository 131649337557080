import gql from 'graphql-tag'

export const GetFeedBackdata = gql`
  query feedBackData($breweryId: ID!) {
    brewery(id: $breweryId) {
      success
      brewery {
        monthlyThumbsUpCount (page: { maxResultCount: 50 }) {
          value
          windowStartDateTime
          windowEndDateTime
        }
        monthlyThumbsDownCount (page: { maxResultCount: 50 }) {
          value
          windowStartDateTime
          windowEndDateTime
        }
      }
    }
  }
`
