import React, { useState, useEffect } from 'react'
import { Grid, Box, Icon, Hidden } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { map, filter, find, get } from 'lodash'
import { useLazyQuery } from '@apollo/client'
import { GET_OFFER_STATS } from '../graphql/offerStatsGQL'
import { getBreweryId, getBreweries, storeAndGetClubs } from '../Services/local'
import Head from './TopHeader'
import AllOffers from '../containers/Alloffers'
import AGButton from '../components/AgButton'
import Offers from '../containers/Offers'
import PromotionsOverviewChart from '../containers/PromotionsOverviewChart'
import Popup from '../components/Popup'
import { GetExportedData } from "../graphql/getExportedDataGQL"
import MoreVertIcon from '@material-ui/icons/MoreVert'
import CommanClasses from '../themes/CommonClasses'
import Drawer from '../components/Drawer'
import ManageOfferDrawer from '../containers/ManageOfferDrawer'
import GroupSelectBox from '../components/GroupSelectBox'

const customStyles = makeStyles(CommanClasses)
const types = [{ name: 'Business' }, { name: 'Clubs' }]
const size = 3

export default (props) => {
  const common = customStyles()
  const breweryId = getBreweryId()
  const breweries = getBreweries()
  const clubsList = storeAndGetClubs()
  const [drawerState, setDrawerState] = useState(false)
  const [ofersCreated, setOffersCreated] = useState(false)
  const [selected, setSelected] = useState('Yearly')
  const [selectedClubIds, setSelectedClubIds] = useState([])
  const [selectedBrwery, setSelectedBrwery] = useState({})
  const [allClubs, setAllClubs] = useState([])
  const [selectedType, setselectedType] = useState(types[0])
  const [offers, setOffers] = useState([])
  const [breweriesList, setBreweriesList] = useState([])
  const [updateOffersCount, setUpdateOffersCount] = useState(false)

  const EActions = [
    {
      name: 'Export',
      outlined: true,
    },
    {
      name: 'Create New',
      outlined: true,
    },
  ]
  const [fetchPopular, { data: offerStatsDataRaw }] = useLazyQuery(
    GET_OFFER_STATS,

    {
      onCompleted: (resp) => {
        if (resp) {
          const tOffers = resp.brewery?.brewery?.activeOffersConnection?.edges || []
          const pOffers = map(tOffers, (offerChild) => {
            return {
              src: offerChild?.breweryOfferCampaign?.banner?.url || '',
              offerName: offerChild?.breweryOfferCampaign?.title,
              reedemNo: offerChild?.breweryOfferCampaign?.totalRedeemed,
            }
          })
          setOffers(pOffers)
        }
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only', //query is not getting called again if we remove it
    }
  )

  useEffect(() => {
    let v = {
      breweryId: selectedBrwery?.id || '',
      popular: true,
      active: true
    }
    if (selectedType?.name === 'Clubs') {
      v = { ...v, membershipProgramIds: selectedClubIds || [] }
    }
    fetchPopular({
      variables: { ...v },
    })
  }, [selectedClubIds, selectedBrwery, selectedType])

  useEffect(() => {
    if (clubsList?.length) {
      setAllClubs(clubsList)
      setSelectedClubIds([clubsList[0].id])
    }
  }, [clubsList])

  useEffect(() => {
    if (breweries && breweries.length) {
      const bl = map(breweries, (data) => {
        return { name: data?.brewery?.name, id: data?.brewery?.id };
      });
      setBreweriesList(bl || [])
    }
  }, [breweries])

  useEffect(() => {
    if (breweriesList && breweriesList.length) {
      setSelectedBrwery(find(breweriesList, b => b.id === breweryId) || {})
    }
  }, [breweriesList])


  const handleSelectedClub = (v) => {
    // if (v?.length >= 1) {
    //   setSelectedClubIds([...v.map(d => d.id)])
    // }
    setSelectedClubIds([v?.id || ''])
  }

  const selectedClubs = [
    ...filter(allClubs, ac => selectedClubIds.includes(ac.id))
  ]

  const handleActions = (e) => {
    if (e === 'Create New') {
      setOffersCreated(false)
      setDrawerState(!drawerState)
    } else {
      handlePromoExport()
    }
  }

  const [exportedData, { loading :exportingPromo }] = useLazyQuery(GetExportedData, {
    onCompleted: (resp) => {
      if (resp) {
        if(get(resp, 'getExportedData.fileUrl')){
          window.open(resp.getExportedData.fileUrl)
        }
      }
    },
    fetchPolicy: 'network-only'
  })

  const handlePromoExport = () => {
    let variables = {
      typeOfExport: 'PROMO_EXPORT'
    }

    if (selectedType?.name === 'Clubs') {
      variables = { ...variables, mugClubIds: selectedClubIds || [] }
    } else {
      variables = { ...variables, breweryId: selectedBrwery.id }
    }
    exportedData({ variables })
  }
  console.log('>>>', allClubs)
  return (
    <Grid container>
      <Grid
        item
        xs={12}
        className={[
          common.bgTransparent,
          common.stickyPosition,
          common.topNavBar,
        ].join(' ')}
      >
        <Head
          pageHeader={'Promotions'}
          actions={
            <Grid container spacing={2}>
              <Grid item>
                <GroupSelectBox
                  singleSelect={true}
                  options={types}
                  value={selectedType}
                  onChange={(e, v) => setselectedType(v)} />
              </Grid>
              {selectedType?.name === 'Clubs' ?
                <Grid item>
                  <GroupSelectBox
                    singleSelect={true}
                    options={allClubs}
                    // value={selectedClubs}
                    value={selectedClubs?.length ? selectedClubs[0] : ''}
                    onChange={(e, v) =>
                      handleSelectedClub(v)
                    } />
                </Grid> :
                <Grid item>
                  <GroupSelectBox
                    singleSelect={true}
                    options={breweriesList}
                    value={selectedBrwery}
                    // value={selectedClubs?.length ? selectedClubs[0] : ''}
                    onChange={(e, v) => setSelectedBrwery(v)}
                  />
                </Grid>
              }
            </Grid>
          }
          endActions={
            <Grid container spacing={2}>
              <Hidden smDown>
                {map(EActions, (data, idx) => (
                  <Grid
                    item
                    key={idx}
                    className={['promotions_target0'].join(' ')}
                  >
                    {idx % 2 === 0 ?
                      <AGButton
                        outlined='true'
                        label={data.name}
                        loading={exportingPromo}
                        color='#FA735F'
                        onClick={() => {
                          handleActions(data.name)

                        }}
                      />
                      :
                      <AGButton
                        label={data.name}
                        onClick={() => {
                          setOffersCreated(false)
                          setDrawerState(!drawerState)
                        }}
                      />
                    }
                  </Grid>
                ))}
              </Hidden>
              <Hidden mdUp>
                <Popup
                  action={
                    <Icon>
                      <MoreVertIcon />
                    </Icon>
                  }
                  placement={'left-start'}
                  initialValue={''}
                  list={map(EActions, (data) => data.name)}
                  selectedValue={(e) => handleActions(e)}

                />
              </Hidden>
            </Grid>
          }
        />
      </Grid>
      <Grid item xs={12}>
        <Box mx={2} py={1}>
          <Grid container spacing={2}>
            <Grid item lg={9} xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <PromotionsOverviewChart refreshData={ofersCreated || updateOffersCount}  selectedType={selectedType} selectedBrwery={selectedBrwery} membershipProgramIds={selectedClubIds} timePeriod={selected} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={3} xs={12} >
              <Offers popularOffers={offers.slice(0, size)} />
            </Grid>
            <Grid item xs={12}>
              <Grid container direction='column' spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <AllOffers type={selectedType}
                    selectedClubIds={selectedClubIds}
                    breweryId={selectedBrwery?.id || ''}
                    refreshData={ofersCreated}
                    updateOffersCount = {(e) => setUpdateOffersCount(!updateOffersCount)}
                    />
                </Grid>
                {/* <Grid item xs={12} sm={12} md={12} lg={6} style={{ border: "5px solid green" }}>
                  <AllOffers refreshData={ofersCreated} />
                </Grid> */}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Drawer
        open={drawerState}
        content={
          <ManageOfferDrawer
            closeDrawer={() => {
              setDrawerState(!drawerState)
              setOffersCreated(true)
            }}
          />
        }
        closeDrawer={() => {
          setDrawerState(!drawerState)
          setOffersCreated(true)
        }}
      />
    </Grid>
  )
}
