import {
    storeBreweries,
    storeAndGetClubs,
    storeBreweryId,
    storeBreweryName
} from '../Services/local'
import { isEmpty, isEqual, uniqWith } from 'lodash'

export const getAllClubsFromBreweries = (breweries) => {
    let mugClubs = []

    breweries.map(({ brewery }) => {
      if (brewery?.mugClubs) {
        brewery.mugClubs.map((mugClub) => {
          !isEmpty(mugClub) && mugClubs.push(mugClub)
        })
      }
    })
    let result = uniqWith(mugClubs, isEqual)
    storeAndGetClubs(result)
}


export const storeAllDetails = (resp) => {
    let breweries =
        resp?.putMerchantUser?.merchantUser?.ownedBreweriesConnection?.edges

    if (breweries) {
        getAllClubsFromBreweries(breweries)
        storeBreweries(breweries)
    }
}

export const storeCurrentBreweryDetails = (resp) => {
    let breweryId =
        resp?.putMerchantUser?.merchantUser?.ownedBreweriesConnection
            ?.edges?.[0]?.brewery?.id
    let breweryName =
        resp?.putMerchantUser?.merchantUser?.ownedBreweriesConnection
            ?.edges?.[0]?.brewery?.name

    if (breweryId) {
        storeBreweryId(breweryId)
        storeBreweryName(breweryName)
    }
}