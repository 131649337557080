import React, { useState } from 'react'
import {
  Box,
  Button,
  Typography,
  Popper,
  Fade,
  Paper,
  List,
  ListItem,
  Divider,
  Hidden,
} from '@material-ui/core'
import AGIcon from '../components/AGIcon'
import { getBreweryId, imageWidthParams } from '../Services/local'
import Image from '../components/Image'
import { makeStyles } from '@material-ui/core/styles'
import CommanClasses from '../themes/CommonClasses'
import { useAuth0 } from '@auth0/auth0-react'
import { useHistory } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { GET_PROFILE_DETAILS } from '../graphql/Settings/profileDataGQL'

const customStyles = makeStyles(CommanClasses)

const useStyles = makeStyles((theme) => ({
  root: {
    // maxWidth: 314,
    minHeight: 80,
    [theme.breakpoints.up('md')]: {
      // minWidth: 314,
      // width: 314,
      // maxWidth: 314,
    },
  },
  mt: {
    marginTop: 10,
  }
}))

function ProfileBox(props) {

  const common = customStyles(CommanClasses)
  const classes = useStyles()
  const history = useHistory()
  const [anchorPopper, setAnchorPopper] = useState(null)
  const [openPopper, setOpenPopper] = useState(false)
  const { isLoading, user, logout } = useAuth0({
    audience: process?.env?.REACT_APP_API_HOST,
    scope: 'openid profile email',
  })

  const breweryId = getBreweryId()

  const { data: breweryData, error: profileDataError, loading: profileDataLoading, refetch: refetchProfileData } = useQuery(GET_PROFILE_DETAILS, {
    variables: {
      breweryId,
    }
  })

  if (profileDataLoading) {
    return <div />
  }

  const breweryName = breweryData?.brewery?.brewery?.name

  const getBreweryLogo = () => {
    const breweryLogo = breweryData?.brewery?.brewery?.logo?.url
    const breweryPhotos = breweryData?.brewery?.brewery?.photosConnection?.edges
    const thumbnail = breweryPhotos?.find(imageObj => imageObj?.image?.tags?.includes('thumbnail'))?.image?.url

    if (breweryLogo) {
      return breweryLogo
    } else if (thumbnail) {
      return thumbnail
    } else if (breweryPhotos?.[0]?.image?.url) {
      return breweryPhotos?.[0]?.image?.url
    } else {
      return null
    }
  }

  const popperActionsArray = ['Profile', 'Sign Out']

  const handlePopperAction = (action, data) => {
    if (action === 'Sign Out') {
      logout({
        returnTo: `${window?.location?.origin}`,
      })
    }
    if (action === 'Profile') {
      history.push('/settings')
    }

    setOpenPopper(false)
  }

  const handleClickPopper = () => (event) => {
    setAnchorPopper(event.currentTarget)
    setOpenPopper(!openPopper)
  }

  return (
    <Box
      display='flex'
      flexDirection='row'
      flexWrap='nowrap'
      justifyContent='flex-end'
      alignItems='center'
      className={[classes.root, common.bgMain].join(' ')}
      mr={2} ml={1.2}
    >
      {/* <Hidden smDown>
        <Box px={0.5}>
          <Typography className={[common.textDark, common.pointer].join(' ')} onClick={handleClickPopper()}>
            {breweryName}
          </Typography>
        </Box>
      </Hidden> */}
      <Box px={0.5}>
        {/* <Hidden smDown>
          {' '}
          <AGIcon name={'icon-Up'} size={6} onClick={handleClickPopper()} />
        </Hidden> */}
        <Popper
          open={openPopper}
          anchorEl={anchorPopper}
          placement={'bottom-end'}
          transition
          className={[common.mdZindex, classes.mt].join(' ')}
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper elevation={1} className={common.borderRadiusM}>
                <List>
                  {popperActionsArray.map((action, idx) => (
                    <Box key={idx}>
                      <ListItem
                        dense
                        button
                        onClick={(e) => handlePopperAction(action)}
                      >
                        <Typography className={common.textM}>
                          {action}
                        </Typography>
                      </ListItem>
                      {popperActionsArray.length !== idx + 1 ? (
                        <Divider variant='middle' />
                      ) : null}
                    </Box>
                  ))}
                </List>
              </Paper>
            </Fade>
          )}
        </Popper>
      </Box>
      <Box px={0.5} className={common.pointer} onClick={handleClickPopper()}>
        <Image src={getBreweryLogo() + imageWidthParams(40)} sm circle />
      </Box>
    </Box>
  )
}

export default ProfileBox
