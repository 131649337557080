import gql from 'graphql-tag'

export const GetFeedbackFields = gql`
  query FeedbackFields(
    $breweryId: ID!
  ) {
    brewery(id: $breweryId) {
      success
      brewery {
        feedbackConnection {
          edges {
            feedback {
              id
              text
              rating
              postedAt
              sourceCommunityMember {
                id
                fullName
                phoneNumber
                nickname
                photosConnection {
                  edges {
                    image {
                      url
                      tags
                      altText
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export const GetMembershipRevenueFields = gql`
  query MembershipRevenueFields(
    $breweryId: ID!
    $revenueStartDate: String!
    $revenueEndDate: String!
  ) {
    brewery(id: $breweryId) {
      success
      brewery {
        platformRevenue(
          startDate: $revenueStartDate,
          endDate: $revenueEndDate
        )
      }
    }
  }
`

export const GetTrustRatingFields = gql`
  query TrustRatingFields(
    $breweryId: ID!
  ) {
    brewery(id: $breweryId) {
      success
      brewery {
        trustRatingX100
      }
    }
  }
`