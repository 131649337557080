import { createMuiTheme } from '@material-ui/core/styles'
import Color from './Colors'
import Size from './FontSizes'

const theme = createMuiTheme({

  typography: {
    useNextVariants: true,
    fontFamily: 'Heebo-Regular',
    body1: {
      fontSize: Size.l,
      color: Color.primary,
    },
    h4: {
      fontSize: 40,
      color: Color.primary,
    },
    h3: {
      color: Color.primary,
      fontSize: 42,
    },
    subtitle1: {
      fontSize: Size.m,
      color: Color.light,
      fontFamily: 'Heebo-Regular',
    },
    subtitle2: {
      fontSize: Size.s,
      color: Color.light,
      fontFamily: 'HeeboLight',
    },
  },
  palette: {
    primary: {
      main: Color.primary,
    },
    secondary: {
      main: Color.green,
    },
    default: {
      main: Color.inputBorder
    },
  },
})
export default theme
