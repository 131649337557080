import { forEach, isArray, slice } from 'lodash'

// updateChartData funtion will add the current month data with the previous month data 
const updateChartData = (data, checkMonth) => {
  const thisMonth = new Date().getMonth()
  // Here updatedData is an array if the index is plus 1, it gets add to the previous index value else it gives the same index value data
  if (isArray(data)) {
    let updatedData = []
    forEach(data, (d, k) => {
      if (k) updatedData[k] = updatedData[k - 1] + d;
      else updatedData[k] = d;
    })
    // Here it checks the boolean value and if it is true it slice the data else it gives the original data
    if (checkMonth) {
      return slice(updatedData, 0, thisMonth + 1)
    }
    return updatedData;
  }
  // Here updatedData is an object and adds the current month with the previous month
  else {
    let updatedData = {};
    forEach(data, (v, k) => {
      if (!updatedData[k]) {
        updatedData[k] = [];
      }
      forEach(v, (d, idx) => {
        if (idx) updatedData[k][idx] = updatedData[k][idx - 1] + d;
        else updatedData[k][idx] = d;
      });
    });
    // Here it checks for the getFullYear function and if it current year it slices the data else gives the original data 
    let result = {}
    forEach(updatedData, (data, key) => {
      if (key == new Date().getFullYear()) {
        result[key] = slice(updatedData[key], 0, thisMonth + 1)
      } else {
        result[key] = data
      }
    })

    return result;
  }
}
export default updateChartData