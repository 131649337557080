import { gql } from '@apollo/client'

export const UPDATE_CLUB = gql`
	mutation updateClub(
			$id: ID!
			$name: String
			$annualCost: String
			$autoRenewable: Boolean
			$clubLimit: String
			$description: String
			$benefits: String
			$benefitsMultiple: [String]
			$photos: [PhotoUpload!]
			$breweriesAssociatedIds: [String]
			$badgeIconName: String
			$badgeIconColor: String
			$clubRenewalCadence: ClubRenewalCadenceType
	) {
		updateBreweryMembershipProgram(
			id: $id
			name: $name
			annualCost: $annualCost
			autoRenewable: $autoRenewable
			clubLimit: $clubLimit
			description: $description
			benefits: $benefits
			benefitsMultiple: $benefitsMultiple
			photos: $photos
			breweriesAssociatedIds: $breweriesAssociatedIds
			badgeIconName: $badgeIconName
			badgeIconColor: $badgeIconColor
			clubRenewalCadence: $clubRenewalCadence
		)
			{
				success
				breweryMembershipProgram {
					id
					name
				}
			}
	}`