import React, { useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { map, find } from 'lodash'
import { GetMembershipCodesData } from '../graphql/membershipCodesGQL'
import { getBreweryId } from '../Services/local'
import AllMemberCodes from '../components/AllMemberCodes'

export default (props) => {
  const {refreshCodes, mugClubIds, allClubs } = props
  const breweryId = getBreweryId()

  const [mcodes, setMCodes] = useState([])
  let selectedClubBrewery = find(allClubs, c => c.id === mugClubIds[0])

  const { loading: clubLoading, refetch: refetchMembershipCodes } = useQuery(
    GetMembershipCodesData,
    {
      variables: {
        breweryId:selectedClubBrewery?.breweriesConnecton[0]?.id || breweryId,
        mugClubIds
      },
      onCompleted: (res) => {
        if (!res)
          return
        let allMCodes =  []
        map(res?.brewery?.brewery?.mugClubs, club => {
          map(club.existingMemberCodes, code =>{
            allMCodes = [...allMCodes, {...code, id: club.id}]
          })
        } )
        console.log('allMCodes',allMCodes)
        setMCodes(allMCodes)
      },
      notifyOnNetworkStatusChange: true,
    },
  )

  useEffect(() => {
    refetchMembershipCodes()
  }, [refreshCodes, mugClubIds])

  return (
    <AllMemberCodes
      refresh={refetchMembershipCodes}
      headCells={[
        {
          id: 'code',
          label: 'Coupon Code',
        },
        {
          id: 'description',
          label: 'Description',
        },

        {
          id: 'oneTimeUse',
          label: 'One Time Use',
        },
        {
          id: 'membershipExpirationDate',
          label: 'Expiring/Expired Date',
        },
        {
          id: 'actions',
        },
      ]}
      data={mcodes || []}
      allClubs={allClubs}
    />
  )
}
