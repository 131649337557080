import gql from "graphql-tag";

export const GetSearchedMembers = gql`
  query searchMember($breweryId: ID!, $search: String!, $mugClubIds: [String] ) {
    brewery(id: $breweryId) {
      success
      brewery {
        membersConnectionSearch(search: $search mugClubIds: $mugClubIds) {
          edges {
            joinDate
            membershipExpirationDate
            memberId
            active
            communityMember {
              id
              fullName
              email
              phoneNumber
              joinedAt
              photosConnection {
                edges {
                  image {
                    url
                    tags
                    altText
                  }
                }
              }
              brewerySharesConnection {
                edges {
                  brewery {
                    id
                  }
                  sharedInfo
                }
              }
            }
          }
        }
        mugClub {
          id
          name
        }
      }
    }
  }
`;
