import gql from 'graphql-tag'

export const BREWERY_DOMAIN_WHITE_BOARD = gql`
  query getBreweryDomainWhiteboard($keyType:String) {
    breweryDomainWhiteboard(key: $keyType) {
      edges {
        key
        value
      }
    }
  }
`