import gql from 'graphql-tag'

export const GET_OFFER_STATS = gql`
  query getOfferStats(
    $breweryId: ID!
    $startDate: String
    $endDate: String
    $membershipProgramId: String
    $membershipProgramIds: [String]
		$hostBreweries: [String]
    $popular: Boolean
    $active: Boolean
    ) {
    brewery(id: $breweryId) {
      success
      brewery {
        offerStats(
          startDate: $startDate
          endDate: $endDate
          membershipProgramId: $membershipProgramId
          membershipProgramIds: $membershipProgramIds
          hostBreweries:$hostBreweries
        ) {
          activeOffersCount
          inActiveOffersCount
          completedOffersCount
          totalOffersCount
          totalRedeemed
          totalRedeemed
        }
        activeOffersConnection(
          popular: $popular
          active: $active
          startDate: $startDate
          endDate: $endDate
          membershipProgramId: $membershipProgramId
          membershipProgramIds: $membershipProgramIds
          hostBreweries:$hostBreweries
        ) {
          edges {
            breweryOfferCampaign {
              id
              title
              banner {
                id
                url
              }
              totalRedeemed
            }
          }
        }
      }
    }
  }
`
