import React, { useRef, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { storeAllDetails } from '../Services/commonFunctions'
import { putMerchantUserGql } from '../graphql/putMerchantUserGql'
import { Box, Typography, TextField, Grid, Checkbox, FormHelperText, LinearProgress, IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import AgChips from '../components/AgChip'
import Color from '../themes/Colors'
import { isEmpty, map } from 'lodash'
import { storeAndGetClubs } from '../Services/local'
import CommanClasses from '../themes/CommonClasses'
import Text from '../components/AgText'
import AGIcon from '../components/AGIcon'
import Button from '../components/AgButton'
import { useMutation } from '@apollo/client'
import { INVITE_COMMUNITY_MEMBERS } from '../graphql/inviteCommunitymembersGQL'
import { getBreweryId } from '../Services/local'
import AddOutlinedIcon from '@material-ui/icons/AddOutlined'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { useSnackbar } from 'notistack'

const InviteMemberDrawer = (props) => {
  const { closeDrawer, allClubs } = props || {}
  const clubList = storeAndGetClubs() || allClubs

  const common = customStyles(CommanClasses)
  const classes = useStyles()

  const { enqueueSnackbar } = useSnackbar()

  const breweryId = getBreweryId()
  const [selectedCode, setSelectedCode] = React.useState({})
  const [clubDetails, setClubDetails] = useState({})
  const [sendNewMembershipCode, setSendNewMembershipCode] = useState(false)
  const [emails, setEmails] = useState([])
  const [emailError, setEmailError] = useState('')
  const [clubError, setClubError] = useState('')
  const [validateForm, setValidateForm] = useState({
    emailError: '',
    clubError: ''
  })
  let emailRef = useRef(null)

  const [sendEmail, { loading: emailSending }] = useMutation(
    INVITE_COMMUNITY_MEMBERS,
    {
      onCompleted: (resp) => {
        if (resp) {
          updateData()
        }
      },
      onError: (err) => {
        console.log('err??', err)
      },
    }
  )
  const { user, isLoading, error, getAccessTokenSilently } = useAuth0({
    audience: process?.env?.REACT_APP_API_HOST,
    scope: 'openid profile email',
  })

  const [putMerchantUser, { data: meData, error: meDataError, loading: meDataLoading }] = useMutation(putMerchantUserGql, {
    onCompleted: (resp) => {
      if (resp) {
        storeAllDetails(resp)
        closeDrawer()
      }
    }
  })

  const updateData = () => {
    putMerchantUser({
      variables: {
        auth0Sub: user?.sub,
        email: user?.email,
      }
    })
  }

  const keyPress = (e) => {
    validateForm.emailError && setValidateForm({ ...validateForm, emailError: '' })
    if (e.keyCode === 13) {
      const emailString = e.target.value
      console.log('value', e.target.value)
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(emailString)) {
        setEmails([
          ...emails,
          emailString
        ])

        emailRef.current.value = ''

        if (e.sendNow && handleClubLimitValidation()) {
          handleInviteEmail([
            ...emails,
            emailString
          ])
        }
      } else {
        // set emailError
        setValidateForm({ ...validateForm, emailError: 'Please enter valid email' })
      }
    }
  }

  const handleInviteEmail = (emailsRef) => {
    sendEmail({
      variables: {
        sendNewMembershipCode,
        to: emailsRef,
        // breweryId,
        membershipProgramId: clubDetails?.id,
      }
    })
  }

  const handleClubLimitValidation = () => {
    let limitCheckCount = 0
    if (clubDetails?.memberStats?.activeMemberCount) {
      limitCheckCount += clubDetails?.memberStats?.activeMemberCount
    }
    if (clubDetails?.inviteCount) {
      limitCheckCount += clubDetails?.inviteCount
    }

    if (clubDetails?.clubLimit) {
      if (clubDetails?.memberStats?.activeMemberCount >= Number(clubDetails?.clubLimit)) {
        enqueueSnackbar('Club limit already exceeded')
        return false
      } else if (limitCheckCount >= Number(clubDetails?.clubLimit)) {
        enqueueSnackbar('Invite limit already exceeded')
        return false
      } else if (emails.length > (Number(clubDetails?.clubLimit && clubDetails?.clubLimit) - limitCheckCount)) {
        enqueueSnackbar(`Invite limit remaining is ${(Number(clubDetails?.clubLimit && clubDetails?.clubLimit) - limitCheckCount)}`)
        return false
      } else {
        return true
      }
    } else {
      return true
    }
  }

  const handleInviteMember = () => {
    if (isEmpty(selectedCode)) {
      setValidateForm({ ...validateForm, clubError: 'Please select club' })
      return
    }

    if (isEmpty(emails)) {
      if (emailRef.current.value) {
        keyPress({ keyCode: 13, target: { value: emailRef.current.value }, sendNow: true })
      } else {
        setValidateForm({ ...validateForm, emailError: 'Please enter atleast 1 email' })
        // setEmailError('Please enter atleast 1 email')
      }
    } else {
      if (handleClubLimitValidation()) {
        handleInviteEmail(emails)
      }
    }
  }


const handleCloseIconClick = (indx) => {
  setEmails([
    ...emails.slice(0, indx),
    ...emails.slice(indx + 1, emails.length),
  ])
}

return (
  <Box p={2}>
    <Grid container>
      <Grid item container xs={12} justify='space-between'>
        <Grid item>
          <Text
            label={
              <Box display='flex' alignItems='center'>
                <Typography className={[common.HeeboMedium, common.textPrimary, common.textXl, classes.mr20].join(' ')}>
                  {'Invite Member'}
                </Typography>
              </Box>
            }
          />
        </Grid>
        <Grid item>
          <AGIcon
            name={'icon-Close'}
            onClick={props.closeDrawer}
            color={Color.light}
            size='14'
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {emailSending && <LinearProgress />}
        <Box my={2}>
          <Box mb={2.5}>
            <Typography className={[common.HeeboLight, common.textS, common.textDark, classes.mb5].join(' ')}>Club</Typography>
            <Autocomplete
              id='tags-outlined'
              options={clubList}
              size='small'
              getOptionLabel={(option) => option.name}
              classes={{ root: classes.locationBox }}
              value={selectedCode}
              renderInput={(params) => (
                <TextField
                  variant='outlined'
                  size='small'
                  {...params}
                  placeholder='Select Club'
                />
              )}
              renderOption={(option) => option.name}
              onChange={(e, v) => {
                setValidateForm({ ...validateForm, clubError: '' })
                setClubDetails(v)
                setSelectedCode(v)
              }
              }
            />
            {validateForm.clubError && <FormHelperText error>{validateForm.clubError}</FormHelperText>}
          </Box>
          <Box>
            <Box>
              <Typography className={[common.HeeboLight, common.textS, common.textDark, classes.mb5].join(' ')}>Email</Typography>
              <Grid container>
                {map(emails, (email, indx) => (
                  <Box mr={.5} mb={1.25}>
                    <AgChips
                      noBorder
                      label={email}
                      onDelete={() => handleCloseIconClick(indx)}
                    />
                  </Box>
                ))}
              </Grid>
            </Box>
            <Box mb={2.5}>
              <Box mb={0.625} display='flex' alignItems='center'>
                <Box flexGrow={1} pr={1}>
                  <TextField
                    className={[classes.textField].join(' ')}
                    variant='outlined'
                    size='small'
                    fullWidth
                    inputRef={emailRef}
                    autoFocus
                    onKeyDown={keyPress}
                  />
                </Box>
                <Box>
                  <IconButton className={classes.button}><AddOutlinedIcon color='primary' onClick={() => keyPress({ keyCode: 13, target: { value: emailRef.current.value } })} /></IconButton>
                </Box>
              </Box>
              {validateForm.emailError && <FormHelperText error>{validateForm.emailError}</FormHelperText>}
            </Box>
            <Box mb={2.5} display='flex' alignItems='center'>
              <Checkbox className={[classes.check].join(' ')} color='primary' onChange={() => setSendNewMembershipCode(!sendNewMembershipCode)} value='sendNewMembershipCode' checked={sendNewMembershipCode} />
              <Typography className={[common.HeeboLight, common.textS, common.textDark].join(' ')}>{'Send Membership Code'}</Typography>
            </Box>
          </Box>

        </Box>
      </Grid>
    </Grid>
    <Button
      label={'Invite Member'}
      color={'#FA735F'}
      textColor={'#FFFFFF'}
      fullWidth
      onClick={handleInviteMember} />
  </Box>
)
}

const customStyles = makeStyles(CommanClasses)

const useStyles = makeStyles((theme) => ({
  mb10: { marginBottom: 10 },
  mb5: { marginBottom: 5 },
  textField: {
    background: `${Color.white} 0% 0% no-repeat padding-box`,
    // border: `1px ${Color.inputBorder}`,
    borderColor: Color.inputBorder,
    borderRadius: 5,
    opacity: 1,
    '& .MuiOutlinedInput-root': {
      border: `1px ${Color.inputBorder}`,
      borderRadius: 5,
    }
  },
  check: {
    padding: 0,
    marginRight: 5,
  },
}))

export default InviteMemberDrawer
