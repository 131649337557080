import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import CommanClasses from "../../themes/CommonClasses";
import Stepper from "../../components/Stepper";
import AgButton from "../../components/AgButton";
import { Tabs, Tab, Box, Typography, Hidden, Grid, LinearProgress } from "@material-ui/core";
import {
  getBreweries,
  getBreweryName,
  getBreweryId,
} from "../../Services/local";
import { storeBreweryId, storeBreweryName } from "../../Services/local";
import { map, filter } from "lodash";
import BusinessInfo from "./BusinessInfo";
import Profile from "./Profile";
import Clubs from "./Clubs";
import Members from "./MembersPage";
import Promotions from "./Promotions";
import Payments from "./Payments";
import Head from "../../Screens/TopHeader";
import Color from "../../themes/Colors";
import DropDown from "../../components/Dropdown";
import AGIcon from "../../components/AGIcon";
import GroupSelectBox from '../../components/GroupSelectBox'

const Settings = (props) => {
  const common = customStyles(CommanClasses);
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const breweryId = getBreweryId();
  const breweries = getBreweries();
  const breweryName = getBreweryName();
  const initialFilterValue = breweryName || "";
  const [value, setValue] = useState(0);
  const [currentBrewInfo, setCurrentBrewInfo] = useState({
    breweryId,
    breweryName,
  });
  const [bList, setBList] = useState([])

  useEffect(() => {
    setBList(map(breweries, (data) => {
      return { name: data?.brewery?.name, id: data?.brewery?.id };
    }) || [])
  }, [breweries])

  const tabArray = [
    { tab: "Businesss Info", tabPanel: <BusinessInfo /> },
    { tab: "Profile", tabPanel: <Profile /> },
    { tab: "Clubs", tabPanel: <Clubs /> },
    { tab: "Members", tabPanel: <Members /> },
    { tab: "Promotions", tabPanel: <Promotions /> },
    { tab: "Payments", tabPanel: <Payments /> },
  ];

  console.log("SETTINGS>>>>>>>", breweryId);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    if (breweryName && breweryId) {
      setCurrentBrewInfo({ breweryId: breweryId, breweryName: breweryName });
    }
  }, [breweryId, breweryName])
  
  const handleBrewery = (action) => {
    storeBreweryId(action.id);
    storeBreweryName(action.name);
    setCurrentBrewInfo({ breweryId: action.id, breweryName: action.name });
    console.log("DROPDOWN ID AND BREWERY NAME:::::", action.id, action.name);
  };
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && <Box>{children}</Box>}
      </div>
    );
  }
  const selectedBrewery = filter(bList, ac => breweryId === ac.id) || []

  return (
    <div>
      <Hidden smDown>
        <Box className={classes.root}>
          <Head
            pageHeader={
              <Box>
                <Typography
                  className={[
                    value === 0
                      ? [common.HeeboMedium, common.textPrimary].join(" ")
                      : "",
                    common.textXxl,
                    classes.tab,
                    "settings_target0",
                  ].join(" ")}
                >
                  Business Set-up
                </Typography>
              </Box>
            }
            endActions={
              <AgButton
                textColor={"#FFFFFF"}
                color={"#273179"}
                label={"Add Location"}
                onClick={(e) => {
                  history.push({
                    pathname: "/settings",
                    state: { editBusiness: true, isNew: true },
                  });
                }}
              />
            }
            actions={
              <Grid container spacing={2}>
                <Grid item>
                  <GroupSelectBox
                    singleSelect={true}
                    options={bList}
                    // value={selectedClubs}
                    value={selectedBrewery?.length ? selectedBrewery[0] : ''}
                    onChange={(e, v) =>
                      handleBrewery(v)
                    } />
                </Grid>
              </Grid>
            }
            showGoLiveButton
          />
          <Box pl={2} pr={2}>
            <TabPanel value={value} index={0} dir={theme.direction}>
              <Stepper tabArray={tabArray} breweryId={currentBrewInfo.breweryId} />
            </TabPanel>
            <TabPanel value={value} index={1} dir={theme.direction}>
              <Box>
                <div class="nav-container">PaymentsBox</div>
              </Box>
            </TabPanel>
          </Box>
        </Box>
      </Hidden>
      <Hidden mdUp>
        <Box className={classes.rootSmall}>
          <Head
            pageHeader={
              <Box>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor="primary"
                  TabIndicatorProps={{
                    style: { border: `1px solid ${Color.primary}` },
                  }}
                  textColor="primary"
                  aria-label="full width tabs"
                  className={[classes.tabs].join("")}
                >
                  <Tab
                    label="Brewery Set-up&nbsp;"
                    className={[
                      common.HeeboMedium,
                      common.textPrimary,
                      common.textXxl,
                      classes.tab,
                    ].join(" ")}
                  />
                </Tabs>
              </Box>
            }
          />
          <Box pl={2}>
            <TabPanel value={value} index={0} dir={theme.direction}>
              <Stepper tabArray={tabArray} breweryId={breweryId} />
            </TabPanel>
            <TabPanel value={value} index={1} dir={theme.direction}>
              <Box>
                <div class="nav-container">PaymentsBox</div>
              </Box>
            </TabPanel>
          </Box>
        </Box>
      </Hidden>
    </div>
  );
};

const customStyles = makeStyles(CommanClasses);

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
  },
  rootSmall: {
    position: "relative",
    paddingRight: 16,
  },
  tabs: {
    height: 35,
    backgroundColor: Color.main,
  },
  tab: {
    padding: 0,
    textTransform: "none",
    minWidth: 100,
    marginRight: 10,
    fontSize: 24,
  },
  indicator: {
    border: `2px solid ${Color.primary}`,
  },
}));

export default Settings;
