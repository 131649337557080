import { gql } from '@apollo/client'

export const UPDATE_BUSINESS_INFO = gql`
	mutation updateBusinessInfo(
		$id:ID!
		$businessName: String
		$businessType: BreweryBusinessType
		$streetAddress: String!
		$city: String!
		$state: String!
		$zipCode: String!
		$email: String
		$phoneNumber: String
		$alternatePhoneNumber: String
		$website: String
		$description: String
		$hours: [HoursEntryInput!]
		$fullAddress: String
	) {
		updateBrewery(
			id: $id
			businessName: $businessName	
			businessType: $businessType
			fullAddress: $fullAddress
			hierarchicalAddress: ["USA", $state, $city, $zipCode, $streetAddress]
			contactEmail: $email
			contactPhone: $phoneNumber
			alternatePhoneNumber: $alternatePhoneNumber
			website : $website
			description: $description
			hours: $hours
		)
			{
				success
				brewery{
					name
				  }
			}
	}`

	// export const UPDATE_BUSINESS_INFO = gql`
	// mutation updateBusinessInfo(
	// 	$id:ID!
	// 	$businessName: String
	// 	$businessType: BreweryBusinessType
	// 	$streetAddress: String
	// 	$city: String
	// 	$state: String
	// 	$zipCode: String
	// 	$email: String
	// 	$phoneNumber: String	
	// 	$description: String
	// 	$hours: [HoursEntryInput]		
	// ) {
	// 	updateBrewery(
	// 		id: $id
	// 		businessName: $businessName	
	// 		businessType: $businessType	
	// 		fullAddress: $streetAddress	$city $state $zipCode "USA"
	// 		hierarchicalAddress: ["USA", $state, $city, $zipCode, $streetAddress]
	// 		contactEmail: $email
	// 		contactPhone: $phoneNumber
	// 		description: $description
	// 		hours: $hours
	// 	)
	// 		{
	// 			success
	// 			brewery{
	// 				name
	// 			  }
	// 		}
	// }`