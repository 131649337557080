import gql from 'graphql-tag'

export const GET_PROFILE_DETAILS = gql`
query getProfileDetails($breweryId: ID!) {
    brewery(id: $breweryId) {
      success
      brewery {
          id
          name
          consumerBreweryPreferencesOffering {
            beerTypes
            foodTypes
            venueTypes
            spiritsTypes
            additionalTypes
            coffeeTypes
            meadTypes
            wineTypes
            ciderTypes
          }          
          logo { 
            id
            url
          }
          photosConnection {
            edges {
              image {
                 id
                 url
                 tags
                 imageUrl
                 videoUrl
              }
              videoUrl
            }
          }
      }
    }
  }
`
