import React, { useState, useEffect } from "react";
import { Grid, Box, LinearProgress } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Head from "../Screens/TopHeader";
import DropDown from "../components/Dropdown";
import AGIcon from "../components/AGIcon";
import AgButton from "../components/AgButton";
import { map, filter, find } from "lodash";
import CommanClasses from "../themes/CommonClasses";
import MembershipRevenue from "../containers/MembershipRevenue";
import Feedback from "../containers/Feedback";
import PromoEngagement from "../containers/PromoEngagement";
import { getBreweryId, getBreweries, getBreweryName } from "../Services/local";
import { useLazyQuery, useQuery } from "@apollo/client";
import PreferencesBarchart from "../containers/PreferencesBarchart";
// import TrustRating from "../containers/TrustRating";
// import ProfileEngagement from "../containers/ProfileEngagement";
import MembershipOverview from "../containers/MembershipOverview";
import CustomerFeedbackLinechart from "../containers/CustomerFeedbackLinechart";
import { GetClubDetails } from "../graphql/clubInfoGQL";
import { storeBreweryId, storeBreweryName } from "../Services/local";
import GroupSelectBox from '../components/GroupSelectBox'
const widthSet = 314;

const customStyles = makeStyles(CommanClasses);

const useStyles = makeStyles((theme) => ({
  main: {
    position: "relative",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  subright: {
    height: `calc(100% - ${80}px)`,
    position: "fixed",
    right: 0,
    [theme.breakpoints.up("md")]: {
      width: widthSet,
      minWidth: widthSet,
    },
    [theme.breakpoints.down("sm")]: {
      position: "relative",
      height: "auto",
    },
  },
  leftPanel: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${widthSet}px)`,
    },
  },
  mobileScreenButton:{
    [theme.breakpoints.down('xs')]:{
      marginTop: theme.spacing(1)
    }
  },
  feedbackContainer: {
    // height: "calc(100% - 100px)",
    // maxHeight: "calc(100% - 100px)",
    height: "calc(100% - 20px)",
    maxHeight: "calc(100% - 20px)",
  },
}));


export default (props) => {
  const defaultRefetchCycle = 20;
  const history = useHistory();
  const common = customStyles();
  const breweryId = getBreweryId();
  const breweries = getBreweries();
  const breweryName = getBreweryName();
  const classes = useStyles();
  // const [selectedFilter, setSelectedFilter] = useState(initialFilterValue)
  const [clubData, setClubData] = useState();
  const [selectedBreweryClubIds, setselectedBreweryClubIds] = useState([]);
  const [selectedBrewInfo, setSelectedBrewInfo] = useState({
    breweryId,
    breweryName,
  });
  const initialFilterValue = breweryName || "";
  const locationData = ["India", "Australia"];
  const breweriesList = map(breweries, (data) => {
    return { name: data?.brewery?.name, id: data?.brewery?.id };
  });

  const [getClubs, { loading: allClubsLoading }] = useLazyQuery(
    GetClubDetails,
    {
      variables: {
        breweryId,
      },
      onCompleted: (resp) => {
        if (resp) {
          if (JSON.stringify(resp) != JSON.stringify(clubData)) {
            setClubData(resp);
          }
        }
      },
      onError: (error) => {
        console.log("error", error);
      },
      notifyOnNetworkStatusChange: true,
    }
  );

  useEffect(() => {
    getClubs({
      variables: {
        breweryId,
      },
    });
  }, [breweryId]);

  useEffect(() => {
    let brew = find(breweries, b => b?.brewery?.id === breweryId)
    let cIds = map(filter(brew?.brewery?.mugClubs, c => c?.id),c => c.id) || []    
    setselectedBreweryClubIds([...cIds])
  }, [breweryId, breweries]);

  const handleBrewery = (action) => {
    storeBreweryId(action.id);
    storeBreweryName(action.name);
    setSelectedBrewInfo({ breweryId: action.id, breweryName: action.name });
  };

  const selectedBrewery = filter(breweriesList, ac => breweryId === ac.id) || []
  return (
    <Grid container>
      <Grid
        item
        xs={12}
        className={[
          common.bgTransparent,
          common.stickyPosition,
          common.topNavBar,
        ].join(" ")}
      >
        <Head
          pageHeader={
            allClubsLoading ? (
              <>
                Dashboard
                <LinearProgress />
              </>
            ) : (
              "Dashboard"
            )
          }
          actions={
            <Grid container spacing={2}>
              <Grid item>
                {/* <DropDown
                  size={"small"}
                  icon={<AGIcon name={"icon-Up"} size={6} />}
                  color="primary"
                  variant={"outlined"}
                  placement="bottom"
                  initialValue={initialFilterValue}
                  list={breweriesList}
                  selectedValue={(e) => {
                    console.log("eeee?", e);
                    handleBrewery(e);

                    // setSelectedFilter(e)
                  }}
                /> */}

                <GroupSelectBox
                  singleSelect={true}
                  options={breweriesList}
                  // value={selectedClubs}
                  value={selectedBrewery?.length ? selectedBrewery[0] : ''}
                  onChange={(e, v) =>
                    handleBrewery(v)
                  } />
              </Grid>
            </Grid>
          }
          endActions={
            <Box className={classes.mobileScreenButton}>
              <AgButton
                textColor={"#FFFFFF"}
                color={"#273179"}
                label={"Add Location"}
                onClick={(e) => {
                  history.push({
                    pathname: "/settings",
                    state: { editBusiness: true, isNew: true },
                  });
                }}
              />
            </Box>
          }
          showGoLiveButton
        />
      </Grid>
      <Grid item xs={12}>
        <Grid className={[classes.main, common.h_100].join(" ")}>
          <Grid className={[classes.leftPanel].join(" ")}>
            <Box px={2}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <PromoEngagement breweryId={selectedBrewInfo.breweryId} />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box className="dashboard_target1">
                    <MembershipRevenue breweryId={selectedBrewInfo.breweryId} selectedBreweryClubIds={selectedBreweryClubIds} />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <MembershipOverview breweryId={selectedBrewInfo.breweryId} />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <PreferencesBarchart breweryId={selectedBrewInfo.breweryId} />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <CustomerFeedbackLinechart breweryId={selectedBrewInfo.breweryId} />
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid className={[common.bgInfo, classes.subright].join(" ")}>
            <Box
              mt={-1.5}
              className={["dashboard_target2", classes.feedbackContainer].join(
                " "
              )}
            >
              <Feedback cardTitle="Feedback" breweryId={selectedBrewInfo.breweryId} />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
