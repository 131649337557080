import React from 'react'
import {
  Box,
  Typography,
  Grid,
  Checkbox,
  TextField
} from '@material-ui/core'
import AGIcon from '../components/AGIcon'
import { makeStyles } from '@material-ui/core/styles'
import { useMutation } from '@apollo/client'
import InputLabel from '../components/InputLabel'
import Color from '../themes/Colors'
import DatePicker from '../components/DatePicker'
import Autocomplete from '@material-ui/lab/Autocomplete'
import moment from 'moment'
import { UPDATE_MEMBERSHIP_CODE } from '../graphql/addUpdateMembershipCodesGQL'
import { DELETE_MEMBERSHIP_CODE } from '../graphql/deleteMembershipCodeGQL'
import CommanClasses from '../themes/CommonClasses'
import Text from '../components/AgText'
import Button from '../components/AgButton'
import { find, isEmpty } from 'lodash'

const customStyles = makeStyles(CommanClasses)

const useStyles = makeStyles((theme) => ({
  mb10: { marginBottom: 10 },
  mb5: { marginBottom: 5 },
  mt10: { marginTop: 10 },
  textField: {
    background: `${Color.white} 0% 0% no-repeat padding-box`,
    // border: `1px ${Color.inputBorder}`,
    borderColor: Color.inputBorder,
    borderRadius: 5,
    opacity: 1,
    '& .MuiOutlinedInput-root': {
      border: `1px ${Color.inputBorder}`,
      borderRadius: 5,
    },
  },
  checkbox: {
    borderRadius: '5px',
    // border: `1px solid ${Color.inputBorder}`,
    marginRight: 7,
    padding: 0,
  },
  avatar: {
    width: 220,
    height: 220,
  },
}))

const AddMemberCodesDrawer = (props) => {
  const { data, closeDrawer, mode, clubId, refresh, allClubs } = props || {}

  const common = customStyles(CommanClasses)
  const classes = useStyles()

  const [checked, setChecked] = React.useState(
    mode === 'New' ? true : data?.oneTimeUse
  )

  const randomNuberGenarator = () => {
    return Math.floor(1000 + Math.random() * 90000).toString()
  }
  const [selectedCode, setSelectedCode] = React.useState({})
  const [formData, setFormData] = React.useState({
    programId: data?.id || '',
    code: mode === 'New' ? randomNuberGenarator() : data?.code || '',
    description: data?.description || '',
    oneTimeUse: mode === 'New' ? true : data?.oneTimeUse,
    membershipExpirationDate:
      mode === 'New'
        ? moment().utc().add(1, 'Y').format()
        : moment(data?.membershipExpirationDate).utc().format() || '',
    consumed: mode === 'New' ? false : data?.consumed,
  })

  React.useEffect(() => {
    if (!isEmpty(data) && !isEmpty(allClubs)) {
      setSelectedCode(find(allClubs, d => d.id === data.id) || {})
    }
  }, [data, allClubs])

  const [updateMembershipCode, membershipCodeDataResp] = useMutation(
    UPDATE_MEMBERSHIP_CODE,
    {
      onCompleted: (resp) => {
        if (resp) {
          refresh(resp)
          closeDrawer()
        }
      },
      onError: (err) => {
        console.log('err??', err)
      },
    }
  )

  const [deleteMembershipCode, membershipCodeDeleteResp] = useMutation(DELETE_MEMBERSHIP_CODE, {
    onCompleted: (resp) => {
      if (resp) {
        refresh(resp)
        closeDrawer()
      }
    },
    onError: (err) => {
      console.log('err??', err)
    },
  })

  const handleCheckbox = (event, value, lable) => {
    let form = { ...formData, [lable]: value }

    setFormData(form)
    setChecked(!checked)

    if (!value) {
      setFormData({
        ...form,
        membershipExpirationDate: moment().utc().add(1, 'Y').format(),
      })
    }
  }

  const handleFormAction = () => {
    let dataToPost = { ...formData }
    // if(dataToPost.oneTimeUse){
    //   dataToPost = {...dataToPost, membershipExpirationDate:""}
    // }
    if (mode === 'New') {
      updateMembershipCode({ variables: dataToPost })
    }
    else {
      updateMembershipCode({ variables: dataToPost })
    }
  }

  const handleDelete = () => {
    deleteMembershipCode({ variables: { programId: formData.programId, code: formData.code } })
  }

  return (
    <Box p={2}>
      <Grid item container xs={12} justify='space-between'>
        <Grid item>
          <Text
            label={
              <Box display='flex' alignItems='center'>
                <Typography
                  className={[
                    common.HeeboMedium,
                    common.textPrimary,
                    common.textXl,
                    classes.mr20,
                  ].join(' ')}
                >
                  {`${mode === 'New' ? 'Add New' : 'Edit'
                    } Membership Code`}
                </Typography>
              </Box>
            }
          />
        </Grid>
        <Grid item>
          <AGIcon
            name={'icon-Close'}
            onClick={props.closeDrawer}
            color={Color.light}
            size='14'
          />
        </Grid>
      </Grid>
      <Box my={2.5}>
        <Autocomplete
          id='tags-outlined'
          options={allClubs}
          size='small'
          getOptionLabel={(option) => option.name}
          classes={{ root: classes.locationBox }}
          value={selectedCode}
          renderInput={(params) => (
            <TextField
              variant='outlined'
              size='small'
              {...params}
              placeholder='Select Club'
            />
          )}
          renderOption={(option) => option.name}
          onChange={(e, v) => {
            setSelectedCode(v)
            setFormData({ ...formData, programId: v?.id })
          }
          }
        />
      </Box>
      <Box my={2.5}>
        <InputLabel
          autoFocus
          multiline={3}
          label='Description'
          name='description'
          value={formData?.description}
          onChangeValue={(n, value) => setFormData({ ...formData, [n]: value })}
        />
      </Box>
      <Box mb={2.5} display='flex' alignItems='center'>
        <Checkbox
          className={[classes.checkbox].join(' ')}
          color='primary'
          checked={checked}
          onChange={(e, value) => handleCheckbox(e, value, 'oneTimeUse')}
        />
        <Typography
          className={[common.HeeboLight, common.textS, common.textDark].join(
            ' '
          )}
        >
          {'One Time Usage'}
        </Typography>
      </Box>
      {!checked ? (
        <Box mb={2.5}>
          <Typography
            className={[common.HeeboLight, common.textS, common.textDark].join(
              ' '
            )}
          >
            {'Expiration Date'}
          </Typography>
          <DatePicker
            fullWidth
            minDate={new Date()}
            selectedDate={formData?.membershipExpirationDate}
            onChange={(date) =>
              setFormData({
                ...formData,
                membershipExpirationDate: moment(date).utc().format(),
              })
            }
          />
        </Box>
      ) : (
        ''
      )}

      <Button
        label={`${mode === 'New' ? 'Add' : 'Update'}`}
        color={'#FA735F'}
        textColor={'#FFFFFF'}
        fullWidth
        loading={membershipCodeDataResp.loading || membershipCodeDeleteResp.loading}
        onClick={() => handleFormAction()}
      />
      {mode !== 'New' ?
        <Box my={1}>
          <Button
            label={'Delete'}
            outlined
            color={'#FA735F'}
            fullWidth
            loading={membershipCodeDeleteResp.loading || membershipCodeDataResp.loading}
            onClick={() => handleDelete()}
          />
        </Box>
        :
        ''
      }
    </Box>
  )
}

export default AddMemberCodesDrawer
