import React from 'react'
import Color from '../themes/Colors'
import { makeStyles } from '@material-ui/core/styles'
import CommanClasses from '../themes/CommonClasses'
import { Typography, Box, Divider } from '@material-ui/core'

const customStyles = makeStyles(CommanClasses)
const useStyle = makeStyles((theme) => ({
  // border:{
  //     borderRight:`1px solid ${Color.lighten}`
  // },
  textSize: {
    fontSize: 38,
  },
  verticalLine: {
    height: '62%',
    position: 'absolute',
    right: 0,
    top: 10,
    bottom: 0,
  },
}))
export default ({ data, label, noBorder }) => {
  const common = customStyles()
  const classes = useStyle()
  return (
    <Box>
      <div
        className={[
          common.textPrimary,
          common.HeeboMedium,
          common.textCenter,
          classes.textSize,
          common.relativePosition,
          noBorder ? '' : classes.border,
        ].join(' ')}
      >
        {data}
        {noBorder ? (
          ''
        ) : (
            <Divider
              classes={{ vertical: classes.verticalLine }}
              variant='inset'
              orientation='vertical'
            />
          )}
      </div>
      <div
        className={[
          common.textDark,
          common.HeeboLight,
          common.textM,
          common.textCenter,
        ].join(' ')}
      >
        {label}
      </div>
    </Box>
  )
}
