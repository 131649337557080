import gql from "graphql-tag";

export const GetPromoEngagementData = gql`
  query promoEngagementData(
    $breweryId: ID!
    $startDate: String
    $membershipProgramIds: [String]
		$hostBreweries: [String]
    $endDate: String
  ) {
    brewery(id: $breweryId) {
      success
      brewery {
        offerStatsLifetime(membershipProgramIds: $membershipProgramIds hostBreweries:$hostBreweries) {
          totalOffersCount
          activeOffersCount
          inActiveOffersCount
          completedOffersCount
          totalRedeemed
        }
        offerStats(startDate: $startDate endDate: $endDate membershipProgramIds: $membershipProgramIds hostBreweries:$hostBreweries) {
          totalOffersCount
          activeOffersCount
          inActiveOffersCount
          completedOffersCount
          totalRedeemed
        }
      }
    }
  }
`;
