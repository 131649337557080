import * as React from 'react'
import { TextField, Checkbox } from '@material-ui/core/'
import Autocomplete from '@material-ui/lab/Autocomplete'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import { find } from 'lodash'
import Color from '../themes/Colors'

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />
const checkedIcon = <CheckBoxIcon fontSize='small' />

const renderInputValues = (params) => {
  return (
    <TextField
      {...params}
      variant='standard'
      style={{ border: Color.primary }}
    />
  )
}

export default function CheckboxesTags(props) {
  const { onChange, value = [], options = [], singleSelect, placeholder,name } = props || {}

  return (
    <Autocomplete
      sx={{
        display: 'inline-block',
        borderColor: Color.primary,
      }}
      multiple={singleSelect ? false : true}
      size='medium'
      id='checkboxes-tags-demo'
      name={name}
      options={options}
      placeholder={placeholder}
      closeIcon = {false}
      disableCloseOnSelect
      value={value}
      onChange={onChange}
      getOptionLabel={(option) => option?.name ? option?.name : option}
      renderOption={(option) => {
        if (singleSelect) {
          return <React.Fragment>
            {option?.name ? option?.name : option}
          </React.Fragment>
        } else {
          return <React.Fragment>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={find(value, v => v.id === option.id) ? true : false}
              color='primary'
            />
            {option?.name ? option?.name : option}
          </React.Fragment>
        }

      }}
      style={{ minWidth: 300, display: 'inline-block' }}
      renderInput={renderInputValues}
    />
  )
}