export const consumerPreferenceValues = {
  'Beer': [
    {
      'token': 'crispy',
      'label': 'Crisp'
    },
    {
      'token': 'hoppy',
      'label': 'Hoppy'
    },
    {
      'token': 'smoky',
      'label': 'Smoky'
    },
    {
      'token': 'fruit+spice',
      'label': 'Fruit + Spice'
    },
    {
      'token': 'tart+funky',
      'label': 'Tart + Funky'
    },
    {
      'token': 'malty',
      'label': 'Malty'
    },
    {
      'token': 'roasted',
      'label': 'Roasted'
    },
    {
      'token': 'hazy+tropical',
      'label': 'Hazy + Tropical'
    },
    {
      'token': 'light',
      'label': 'Light'
    },
    {
      'token': 'classic beer styles',
      'label': 'Classic Beer Styles'
    },
    {
      'token': 'barrel-aged',
      'label': 'Barrel-Aged'
    },
    {
      'token': 'dessert',
      'label': 'Dessert'
    }
  ],
  'Food': [
    {
      'token': 'vegetarian+vegan',
      'label': 'Vegetarian + Vegan'
    },
    {
      'token': 'tapas+light snacks',
      'label': 'Tapas + Light Snacks'
    },
    {
      'token': 'seafood',
      'label': 'Seafood'
    },
    {
      'token': 'meat+cheese',
      'label': 'Meat + Cheese'
    },
    {
      'token': 'bar food',
      'label': 'Bar Food'
    },
    {
      'token': 'pasta+pizza',
      'label': 'Pasta + Pizza'
    },
    {
      'token': 'fried+crispy',
      'label': 'Fried + Crispy'
    },
    {
      'token': 'shared plates',
      'label': 'Shared Plates'
    }
  ],
  'Venue': [
    {
      'token': 'dog friendly',
      'label': 'Dog Friendly'
    },
    {
      'token': 'family-friendly',
      'label': 'Family-Friendly'
    },
    {
      'token': 'lgbtq+friendly',
      'label': 'LGBTQ + Friendly'
    },
    {
      'token': 'minority-owned',
      'label': 'Minority-Owned'
    },
    {
      'token': 'good for singles',
      'label': 'Good for Singles'
    },
    {
      'token': 'lots of seating',
      'label': 'Lots of seating'
    },
    {
      'token': 'heated outdoors',
      'label': 'Heated Outdoors'
    },
    {
      'token': 'outdoor seating',
      'label': 'Outdoor Seating'
    }
  ],
  'Spirits': [
    {
      'token': 'single malt',
      'label': 'Single Malt'
    },
    {
      'token': 'aged 5+ years',
      'label': 'Aged 5+ Years'
    },
    {
      'token': 'corn mash',
      'label': 'Corn Mash'
    },
    {
      'token': 'juniper forward',
      'label': 'Juniper Forward'
    },
    {
      'token': 'herb+floral forward',
      'label': 'Herb/Floral Forward'
    },
    {
      'token': 'citrus forward',
      'label': 'Citrus Forward'
    },
    {
      'token': 'spiced spirit',
      'label': 'Spiced Spirit'
    },
    {
      'token': 'flavored spirit',
      'label': 'Flavored Spirit'
    },
    {
      'token': 'warming',
      'label': 'Warming'
    },
    {
      'token': 'baking spice',
      'label': 'Baking Spice'
    },
    {
      'token': 'rye',
      'label': 'Rye'
    },
    {
      'token': 'caramel',
      'label': 'Caramel'
    },
    {
      'token': 'fruity',
      'label': 'Fruity'
    },
    {
      'token': 'nutty',
      'label': 'Nutty'
    },
    {
      'token': 'smooth',
      'label': 'Smooth'
    }
  ],
  'Coffee': [
    {
      'token': 'bright',
      'label': 'Bright'
    },
    {
      'token': 'lively',
      'label': 'Lively'
    },
    {
      'token': 'delicate',
      'label': 'Delicate'
    },
    {
      'token': 'bold',
      'label': 'Bold'
    },
    {
      'token': 'earthy',
      'label': 'Earthy'
    },
    {
      'token': 'floral',
      'label': 'Floral'
    },
    {
      'token': 'rich',
      'label': 'Rich'
    },
    {
      'token': 'citrus',
      'label': 'Citrus'
    },
    {
      'token': 'berry',
      'label': 'Berry'
    },
    {
      'token': 'nutty',
      'label': 'Nutty'
    },
    {
      'token': 'cocoa',
      'label': 'Cocoa'
    },
    {
      'token': 'spice',
      'label': 'Spice'
    },
    {
      'token': 'tobacco',
      'label': 'Tobacco'
    },
    {
      'token': 'bourbon',
      'label': 'Bourbon'
    }
  ],
  'Mead': [
    {
      'token': 'sweet',
      'label': 'Sweet'
    },
    {
      'token': 'dry',
      'label': 'Dry'
    },
    {
      'token': 'fruit',
      'label': 'Fruit'
    },
    {
      'token': 'spiced',
      'label': 'Spiced'
    },
    {
      'token': 'carbonated',
      'label': 'Carbonated'
    },
    {
      'token': 'cider',
      'label': 'Cider'
    },
    {
      'token': 'spicy',
      'label': 'Spicy'
    },
    {
      'token': 'oaked+barrel aged',
      'label': 'Oaked/Barrel Aged'
    },
    {
      'token': 'coffee+caffeine',
      'label': 'Coffee/Caffeine'
    },
    {
      'token': 'gluten-free',
      'label': 'Gluten-Free'
    }
  ],
  'Wine': [
    {
      'token': 'red',
      'label': 'Red'
    },
    {
      'token': 'white',
      'label': 'White'
    },
    {
      'token': 'orange',
      'label': 'Orange'
    },
    {
      'token': 'rose',
      'label': 'Rose'
    },
    {
      'token': 'sparkling',
      'label': 'Sparkling'
    },
    {
      'token': 'dessert',
      'label': 'Dessert'
    },
    {
      'token': 'dry',
      'label': 'Dry'
    },
    {
      'token': 'sweet',
      'label': 'Sweet'
    },
    {
      'token': 'organic',
      'label': 'Organic'
    },
    {
      'token': 'old world',
      'label': 'Old World'
    },
    {
      'token': 'new World',
      'label': 'New World'
    }
  ],
  'Cider': [
    {
      'token': 'acidic',
      'label': 'Acidic'
    },
    {
      'token': 'fragrant',
      'label': 'Fragrant'
    },
    {
      'token': 'fruity',
      'label': 'Fruity'
    },
    {
      'token': 'floral',
      'label': 'Floral'
    },
    {
      'token': 'spicy',
      'label': 'Spicy'
    },
    {
      'token': 'nutty',
      'label': 'Nutty'
    },
    {
      'token': 'grassy',
      'label': 'Grassy'
    },
    {
      'token': 'caramelized',
      'label': 'Caramelized'
    },
    {
      'token': 'sweet',
      'label': 'Sweet'
    },
    {
      'token': 'toasted',
      'label': 'Toasted'
    }
  ],
  'Additional': [
    {
      'token': 'live music',
      'label': 'Live Music'
    },
    {
      'token': 'food trucks',
      'label': 'Food Trucks'
    },
    {
      'token': 'sports',
      'label': 'Sports'
    },
    {
      'token': 'games+trivia',
      'label': 'Games + Trivia'
    },
    {
      'token': 'beer education',
      'label': 'Beer Education'
    },
    {
      'token': 'business networking',
      'label': 'Business Networking'
    },
    {
      'token': 'fundraisers',
      'label': 'Fundraisers'
    },
    {
      'token': 'craft workshops',
      'label': 'Craft Workshops'
    },
    {
      'token': 'curb-side',
      'label': 'Curb-side'
    },
    {
      'token': 'pick-up',
      'label': 'Pick-Up'
    },
    {
      'token': 'mug+beer clubs',
      'label': 'Mug/Beer Clubs'
    }
  ]
}

export const preferencesTypes = [
  {
    token: 'beerTypes',
    label: 'Beer',
  },
  {
    token: 'foodTypes',
    label: 'Food',
  },
  {
    token: 'venueTypes',
    label: 'Venue',
  },
  {
    token: 'spiritsTypes',
    label: 'Spirits',
  },
  {
    token: 'additionalTypes',
    label: 'Additional',
  },
  {
    token: 'coffeeTypes',
    label: 'Coffee',
  },
  {
    token: 'meadTypes',
    label: 'Mead',
  },
  {
    token: 'wineTypes',
    label: 'Wine',
  },
  {
    token: 'ciderTypes',
    label: 'Cider',
  },
]