import React from 'react'
import { Box, Typography, TextField, Grid, Checkbox, Badge, Avatar, Card } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import InputLabel from '../components/InputLabel'
import Color from '../themes/Colors'
import Image from '../components/Image'
import Pic from '../assets/images/my.png'
import CameraEnhanceOutlinedIcon from '@material-ui/icons/CameraEnhanceOutlined'
import CommanClasses from '../themes/CommonClasses'
import Text from '../components/AgText'
import AGIcon from '../components/AGIcon'
import Button from '../components/AgButton'

const customStyles = makeStyles(CommanClasses)

const useStyles = makeStyles((theme) => ({
  mb10: { marginBottom: 10 },
  mb5: { marginBottom: 5 },
  mt10: { marginTop: 10 },
  textField: {
    background: `${Color.white} 0% 0% no-repeat padding-box`,
    // border: `1px ${Color.inputBorder}`,
    borderColor: Color.inputBorder,
    borderRadius: 5,
    opacity: 1,
    '& .MuiOutlinedInput-root': {
      border: `1px ${Color.inputBorder}`,
      borderRadius: 5,
    }
  },
  checkbox: {
    borderRadius: '5px',
    // border: `1px solid ${Color.inputBorder}`,
    marginRight: 7,
    padding: 0,
  },
  avatar: {
    width: 220,
    height: 220,
  },
}))

const AddMemberDrawer = (props) => {
  const { closeDrawer } = props || {}

  const common = customStyles(CommanClasses)
  const classes = useStyles()
  const [checked, setChecked] = React.useState(false)

  const handleAddMember = () => {
    //do the logic
  }

  const theCard = () => (
    <Card
      elevation={0}
      classes={{ root: common.borderRadiusM }}
      className={[common.bgInfo, classes.mt10].join(' ')}
    >
      <Box p={1}>
        <Grid container>
          <Grid item xs>
            <Grid container alignItems='center'>
              <Grid item>
                <Image src={Pic} sm circle />
              </Grid>
              <Grid item xs>
                <Grid
                  container
                  justify='space-between'
                  alignItems='center'
                  className={common.h_100}
                >
                  <Grid item>
                    <Box px={2}>
                      <Typography
                        component='p'
                        className={[
                          common.HeeboMedium,
                          common.textL,
                        ].join(' ')}
                      >
                        {'Jane Cooper'}
                      </Typography>
                      <Typography
                        component='span'
                        className={[
                          common.textLight,
                          common.textM,
                          common.HeeboLight,
                        ].join(' ')}
                      >
                        {'Added on: '}
                      </Typography>
                      <Typography
                        component='span'
                        className={[
                          common.textLight,
                          common.HeeboMedium,
                          common.textM,
                        ].join(' ')}
                      >
                        {'Dec 31, 2020'}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Card>
  )

  return (
    <Box p={2}>
      <Grid container>
        <Grid item container xs={12} justify='space-between'>
          <Grid item>
            <Text
              label={
                <Box display='flex' alignItems='center'>
                  <Typography className={[common.HeeboMedium, common.textPrimary, common.textXl, classes.mr20].join(' ')}>
                    {'Add Member'}
                  </Typography>
                </Box>
              }
            />
          </Grid>
          <Grid item>
            <AGIcon
              name={'icon-Close'}
              onClick={props.closeDrawer}
              color={Color.light}
              size='14'
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Box my={2}>
            <Box>
              <Box mb={2.5} >
                <Grid container justify='center'>
                  <Badge
                    // onClick={avatarHandle}
                    overlap='circle'
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    badgeContent={
                      // <span class='icon-Image'></span>
                      <CameraEnhanceOutlinedIcon color='primary' fontSize='small' />
                    }>
                    <Avatar alt='Remy Sharp' src={''} className={classes.avatar} />
                  </Badge>
                </Grid>
              </Box>
              <Box mb={2.5}>
                <InputLabel label={'Name'} autoFocus error={false} />
              </Box>
              <Box mb={2.5}>
                <InputLabel label={'Mobile'} error={false} />
              </Box>
              <Box mb={2.5}>
                <InputLabel label={'Email ID'} />
              </Box>
              <Box mb={2.5} display='flex' alignItems='center'>
                <Checkbox className={[classes.checkbox].join(' ')} color='primary' checked={checked} onChange={() => setChecked(!checked)} />
                <Typography className={[common.HeeboLight, common.textS, common.textDark].join(' ')}>{'Associate Primary Member'}</Typography>
              </Box>
              {checked ?
                <Box mb={2.5}>
                  <Box>
                    <Typography className={[common.HeeboLight, common.textS, common.textDark].join(' ')}>{'Search Primary Member'}</Typography>
                    <TextField
                      className={[classes.textField].join(' ')}
                      variant='outlined'
                      size='small'
                      fullWidth
                      name='searchMember'
                    />
                  </Box>
                  <Box mt={1.75}>
                    {theCard}
                  </Box>
                </Box>
                :
                <Box mb={2.5} display='flex' alignItems='center'>
                  <Box pr={1}>
                    <InputLabel label={'Start Date'} error={false} />
                  </Box>
                  <Box pl={1}>
                    <InputLabel label={'Expiration Date'} error={false} />
                  </Box>
                </Box>
              }
              <Box mb={2.5}>
                <InputLabel label={'Notes'} multiline={3} error={false} />
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Button
        label={'Add Member'}
        color={'#FA735F'}
        textColor={'#FFFFFF'}
        fullWidth
        onClick={handleAddMember} />
    </Box>
  )
}

export default AddMemberDrawer
