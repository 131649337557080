import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import CommanClasses from '../themes/CommonClasses'
import { Box, Grid, Typography, Hidden } from '@material-ui/core'
import AGIcon from '../components/AGIcon'
import { useLazyQuery, useQuery } from '@apollo/client'
import { useSnackbar } from 'notistack'
import { GetBreweryStatus } from '../graphql/clubInfoGQL'
import { getBreweryId } from '../Services/local'
import AgButton from './AgButton'
import { useAuth0 } from '@auth0/auth0-react'

const customStyles = makeStyles(CommanClasses)

// const DATA_CB_PLAN_ID = 'indulj-stairs', DATA_CB_SITE = 'gobeep'  //  for prod
const DATA_CB_PLAN_ID = 'cbdemo_scale', DATA_CB_SITE = 'gobeep-test'  //  for test

export default (props) => {
  const common = customStyles(CommanClasses)
  const { enqueueSnackbar } = useSnackbar()
  const breweryId = getBreweryId()

  const { user } = useAuth0({
    audience: process?.env?.REACT_APP_API_HOST,
    scope: 'openid profile email',
  })

  const defaultRefetchCycle = 20

  const [clubData, setClubData] = useState()
  const [maxBreweryStatusFetchTimes, setMaxBreweryStatusFetchTimes] = useState(defaultRefetchCycle)
  const [failedToFetchBreweryStatus, setFailedToFetchBreweryStatus] = useState(false)

  useQuery(GetBreweryStatus, {
    variables: {
      breweryId
    },
    onCompleted: (resp) => { if (resp) setClubData(resp) },
    skip: !props.showGoLiveButton
  })

  const [refreshBreweryStatus, { loading }] = useLazyQuery(GetBreweryStatus, {
    onCompleted: (resp) => {
      if (resp) {
        console.log('refreshBreweryStatus resp??', resp)
        if (resp?.brewery?.brewery.status === 'ACTIVE') {
          //show brewery is live
          setMaxBreweryStatusFetchTimes(defaultRefetchCycle)
          setClubData(resp)
          enqueueSnackbar('Brewery is Live now.', { variant: 'success' })
        } else {
          //keep trying after 1 sec delay
          if (maxBreweryStatusFetchTimes > 0) {
            // wait for 1 sec & retry to refetch
            console.log('maxBreweryStatusFetchTimes??', maxBreweryStatusFetchTimes)
            maxBreweryStatusFetchTimes % 5 === 0 && enqueueSnackbar('Please wait, getting the brewery status...')
            setMaxBreweryStatusFetchTimes(maxBreweryStatusFetchTimes - 1)

            setTimeout(() => {
              refreshBreweryStatus({ variables: { breweryId } })
            }, 3000) //after 3 second refreshBreweryStatus

          } else {
            // show failed to fetch brewery status will be updated soon
            setFailedToFetchBreweryStatus(true)
            enqueueSnackbar('Failed to fetch the brewery status, please refresh & check!')
          }
        }
      }
    },
    fetchPolicy: 'network-only'
  })

  const handleChargebeeCb = (cart) => {
    console.log('cart??', cart)
    // you can define a custom callbacks based on cart object
    return {
      loaded: function () {
        console.log('checkout opened')
      },
      close: function () {
        console.log('checkout closed')
      },
      success: function (hostedPageId) {
        console.log('hostedPageId??', hostedPageId)
      },
      step: function (value) {
        // value -> which step in checkout
        console.log(value)
        if (value === 'thankyou_screen') {
          // refresh the brewery data
          refreshBreweryStatus({ variables: { breweryId } })
        }
      }
    }
  }

  useEffect(() => {
    const mounted = () => {
      const el = document.createElement('script')

      el.onload = () => {
        window.Chargebee.registerAgain()
        const cbInstance = window.Chargebee.getInstance()

        var cart = cbInstance.getCart()
        var customer = { cf_hidden_email: clubData?.brewery?.brewery?.contactEmail || '', first_name: user?.nickname || '', last_name: '', billing_address: { first_name: user?.nickname || '', last_name: '', company: '', phone: '', line1: '', line2: '' } }
        cart.setCustomer(customer)

        cbInstance.setCheckoutCallbacks(handleChargebeeCb)
      }

      el.setAttribute('data-cb-site', DATA_CB_SITE)
      el.setAttribute('src', 'https://js.chargebee.com/v2/chargebee.js')
      document.body.appendChild(el)
    }
    clubData && mounted()
  }, [clubData])

  const goLiveButton = () => {
    return clubData?.brewery?.brewery?.name && clubData?.brewery?.brewery?.status !== 'ACTIVE' ? <Grid container spacing={2}>
      <Hidden smDown>
        <Grid item className={['clubs_target1'].join(' ')}>
          <AgButton
            // outlined={true}
            disabled={failedToFetchBreweryStatus || maxBreweryStatusFetchTimes < defaultRefetchCycle}
            textColor={'#FFFFFF'}
            color={'#FA735F'}
            label={'Go Live'}
            data-cb-type='checkout'
            data-cb-plan-id={DATA_CB_PLAN_ID}
          />
        </Grid>
      </Hidden>
    </Grid>
      :
      null
  }

  return (
    <Box px={2} py={1}>
      <Box>
        <Grid
          container
          direction='row'
          justify='space-between'
          alignItems='center'
        >
          <Grid item>
            <Grid
              container
              direction='row'
              justify='flex-start'
              alignItems='center'
            >
              {props.headerLogo ? (
                <Grid item>
                  <Box mr={1}>
                    <img
                      src={props.headerLogo}
                      alt={props.headerLogoAlt}
                      width='40px'
                      height='40px'
                      className={common.borderRadiusL}
                    />
                  </Box>
                </Grid>
              ) : null}
              <Grid item>
                <Box mr={0.5}>
                  <Typography
                    variant='h5'
                    component='span'
                    className={[common.HeeboMedium, common.textPrimary].join(
                      ' '
                    )}
                  >
                    {props.pageHeader}
                  </Typography>
                </Box>
              </Grid>
              <Grid item>
                {props.info && (
                  <Box mt={0.6}>
                    <AGIcon name={'icon-Info'} size={18} onClick={props.onClickInfo} />
                  </Box>
                )}
              </Grid>
              <Grid item>
                <Hidden xsDown> <Box px={2}>{props.actions}</Box></Hidden>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Hidden xsDown>
              <Grid
                container
                direction='row'
                justify='space-between'
              >
                {/* <Grid item className={common.sm}>
                  {props.showGoLiveButton && goLiveButton()}
                </Grid> */}
                <Grid item>
                  {props.endActions}
                </Grid>
              </Grid>
            </Hidden>
          </Grid>
        </Grid>
      </Box>

      <Hidden smUp>
        <Box py={1}>
          <Grid container justify='space-between' alignItems='center'>
            <Grid item xs>{props.actions}</Grid>
            {/* <Grid
              container
              direction='row'
              justify='space-between'
            > */}
            {/* <Grid item className={common.sm}>
                {props.showGoLiveButton && goLiveButton()}
              </Grid> */}
            <Grid item>
              {props.endActions}
            </Grid>
            {/* </Grid> */}
          </Grid>
        </Box>
      </Hidden>

    </Box>
  )
}
