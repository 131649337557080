import React, { useState } from 'react'
import {
  Grid,
  Box,
  Typography,
  Checkbox,
  Card,
  Avatar,
  Divider
} from '@material-ui/core'
import AGIcon from '../components/AGIcon'
import AGCard from '../components/AGCard'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import moment from 'moment'
import { GetSearchedMembers } from '../graphql/searchMemberGQL'
import { MeDocument } from '../graphql/getMe'
import Image from '../components/Image'
import Chips from '../components/AgChip'
import DatePicker from '../components/DatePicker'
import { makeStyles } from '@material-ui/core/styles'
import InputLabel from '../components/InputLabel'
import Text from '../components/AgText'
import { map, pick, uniqBy, startCase, capitalize } from 'lodash'
import Color from '../themes/Colors'
import { getBreweryId, imageWidthParams } from '../Services/local'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import CommanClasses from '../themes/CommonClasses'
import Button from '../components/AgButton'
import { UPDATE_USER_DETAILS } from '../graphql/updateUserGQL'

const customStyles = makeStyles(CommanClasses)
const useStyles = makeStyles((theme) => ({
  mb10: { marginBottom: 10 },
  mb5: { marginBottom: 5 },
  boxStyle: {
    border: `1px solid ${Color.inputBorder}`,
    borderRadius: 15,
  },
  checkbox: {
    borderRadius: '5px',
    // border: `1px solid ${Color.inputBorder}`,
    marginRight: 7,
    padding: 0,
  },
  avatar: {
    width: 220,
    height: 220,
  },
  iconButton: {
    width: 40,
    height: 40,
    borderRadius: 20,
    background: `${Color.main} 0% 0% no-repeat padding-box`,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: Color.main,
      boxShadow: 'none',
    },
  },
  wordBreak: {
    overflowWrap: 'break-word',
  },
  drawerBody: {
    position: 'sticky',
    top: 0,
    zIndex: 2,
  },
  flexDisplay: {
    display: 'flex'
  }
}))

function AllMembersManageDrawer(props) {
  const breweryId = getBreweryId()
  const common = customStyles(CommanClasses)
  const classes = useStyles()
  const [searchValue, setSearchValue] = React.useState('')
  const [searchResult, setSearchResult] = React.useState()
  const [errorCheck, setErrorCheck] = React.useState(false)
  const alphaNumericNospace = /^[a-zA-Z0-9]+$/g
  const requiredData = {
    breweryId: props.data?.breweryId,
    communityMemberId: props.data?.communityMemberId,
    membershipProgramId: props.mugClubIds[0] || '', // props.data?.membershipProgramId, // props.mugClubIds[0]
    membershipProgramIds: props?.mugClubIds,
  }

  const [
    getCoMemberSearchResult,
    { error: CoMemberSearchResultError, loading: CoMemberSearchResultLoading },
  ] = useLazyQuery(GetSearchedMembers, {
    onCompleted: (resp) => {
      if (!resp) return

      const edges =
        resp?.brewery?.brewery?.membersConnectionSearch?.edges.filter(
          (a) => a?.communityMember?.id != props.data?.communityMemberId
        ) || []
      setSearchResult(uniqBy(edges, 'communityMember.id'))
    },
    onError: (error) => {
      console.log('error', error)
    },
    notifyOnNetworkStatusChange: true,
  })

  const {
    data: meUserDetails,
    error: meUserDetailsError,
    loading: meUserDetailsLoading,
  } = useQuery(MeDocument, {
    notifyOnNetworkStatusChange: true,
  })

  const [updateUserDetails, membershipCodeDataResp] = useMutation(
    UPDATE_USER_DETAILS,
    {
      onCompleted: (resp) => {
        if (resp) {
          props.modeChange('read')
          props.refreshuserList()
          var notesData = memberData?.allNotes

          if (memberData.notes)
            notesData.unshift({
              notes: memberData.notes,
              addedBy: { email: meUserDetails?.me?.merchantUser?.email },
              dateAdded: moment().format(),
            })

          setMemberData({ ...memberData, allNotes: notesData, notes: '' })
        }
      },
      onError: (err) => {
        console.log('err??', err)
      },
    }
  )

  const [diassociatePrimaryMember, diassociatePrimaryMemberResp] = useMutation(
    UPDATE_USER_DETAILS,
    {
      onCompleted: (resp) => {
        if (resp) {
          setAssociated(false)
          props.refreshuserList()
        }
      },
      onError: (err) => {
        console.log('err??', err)
      },
    }
  )

  const [associatePM, associatePMResp] = useMutation(UPDATE_USER_DETAILS, {
    onCompleted: (resp) => {
      if (resp) props.refreshuserList()
    },
    onError: (err) => {
      setMemberData({ ...memberData, pMId: undefined })
      setMemberData({ ...memberData, primaryMemberData: {} })
      setAssociated(false)
      console.log('err??', err)
    },
  })

  const handlesearchValue = (lable, value) => {
    setSearchValue(value)
    if (value.length > 2) {
      getCoMemberSearchResult({
        variables: {
          breweryId,
          mugClubIds: props?.mugClubIds,
          search: value,
        },
      })
    }
  }

  const ObjectDiff = (obj1, obj2) => {
    const diff = pick(
      obj2,
      Object.keys(obj1).filter((k) => obj1[k] !== obj2[k])
    )
    Object.keys(diff).forEach(
      (key) => diff[key] === undefined && delete diff[key]
    )
    Object.keys(diff).forEach((key) => diff[key] === '' && delete diff[key])
    return diff
  }
  const [memberData, setMemberData] = useState({
    name: props?.data?.name || '',
    avatar: props.data?.avatar || '',
    memberId: props.data?.memberId,
    membershipProgramId: props.data?.membershipProgramId || '',
    communityMemberId: props.data?.communityMemberId || '',
    breweryId: props.data?.breweryId || '',
    mobile: props.data?.phone || '',
    dateOfBirth: props.data?.dateOfBirth || '',
    email: props.data?.contact || '',
    joiningDate: moment(props?.data?.joiningDate).utc().format('YYYY-MM-DD') || '',
    expiringDate: moment(props?.data?.expiringDate).utc().format('YYYY-MM-DD') || '',
    notes: '',
    clubName: props?.data?.clubName,
    associatedMember: {},
    // beer: props?.data?.breweryPreferences?.beerType,
    // food: props?.data?.breweryPreferences?.foodType,
    // events: props?.data?.breweryPreferences?.eventTypes,
    // venues: props?.data?.breweryPreferences?.atmosphere,
    sharedData: props?.data?.sharedInfo,
    pMId: props?.data?.primaryMemberId,
    primaryMemberData:
      {
        id: props?.data?.primaryMember?.id,
        name: props?.data?.primaryMember?.fullName,
        joinedAt: props?.data?.primaryMember?.joinedAt,
        avatar:
          props?.data?.primaryMember?.photosConnection?.edges[0]?.image?.url,
      } || {},
    allNotes: props?.data?.notes?.slice().reverse(),
  })

  const handleButton = () => {
    if (props.mode === 'read') {
      props.modeChange('Update')
    } else {
      let updatedData = ObjectDiff(props.data, memberData)
      if (!Object.keys(updatedData).length) {
        props.modeChange('read')
        return
      }
      setErrorCheck(true)
      if (
        !memberData.memberId.match(alphaNumericNospace) ||
          moment() > moment(memberData?.expiringDate)
          ? true
          : false
      ) {
        return
      } else {
        if (updatedData?.notes?.length) {
          updatedData = { ...updatedData, addedOn: moment().format() }
        }
        updateUserDetails({ variables: { ...requiredData, ...updatedData } })
      }
    }
  }

  const handleSelectAssociatedUser = (data) => {
    associatePM({
      variables: { ...requiredData, primaryMemberId: data.id },
    })
    setSearchResult([])
    setMemberData({ ...memberData, pMId: data.id, primaryMemberData: data })
  }

  const [associated, setAssociated] = useState(
    memberData?.primaryMemberData?.name?.length ? true : false
  )

  const handleSelectedAssociatedMember = () => {
    diassociatePrimaryMember({
      variables: { ...requiredData, disAssociatePrimaryMember: true },
    })
    setMemberData({ ...memberData, pMId: undefined })
    setMemberData({ ...memberData, primaryMemberData: {} })
  }

  const handleFormValues = (label, value) => {
    setMemberData({ ...memberData, [label]: value })
  }

  const mapAllUsers = map(searchResult, (res) => ({
    id: res?.communityMember?.id,
    avatar: res?.communityMember?.photosConnection?.edges[0]?.image?.url,
    name: res?.communityMember?.fullName,
    addedOn: res?.active && res?.joinDate ? res?.joinDate : res?.communityMember?.joinedAt,
  }))

  const LableFontMedium = (data) => (
    <Typography
      component='span'
      className={[common.textLight, common.HeeboMedium, common.textM].join(' ')}
    >
      {data.label}
    </Typography>
  )

  const LableFontLight = (data) => (
    <Typography
      component='span'
      className={[common.textLight, common.textM, common.HeeboLight].join(' ')}
    >
      {data.label}
    </Typography>
  )

  const FormLabel = ({ label, noMargin }) => (
    <Typography
      gutterBottom={noMargin ? false : true}
      className={[common.HeeboLight, common.textS, common.textDark].join(' ')}
    >
      {label}
    </Typography>
  )
  const FormLData = ({ label }) => (
    <Typography
      className={[
        common.HeeboRegular,
        common.textM,
        common.textDark,
        classes.wordBreak,
      ].join(' ')}
    >
      {label}
    </Typography>
  )

  const TheCard = (card) => (
    <Card
      elevation={0}
      classes={{ root: common.borderRadiusM }}
      className={common.bgInfo}
    >
      <Box p={1}>
        <Grid container>
          <Grid item xs>
            <Grid container alignItems='center'>
              <Grid item xs>
                <Grid container alignItems='center'>
                  <Grid item>
                    <Image src={card?.avatar + imageWidthParams(40)} sm circle />
                  </Grid>
                  <Grid item xs>
                    <Grid
                      container
                      justify='space-between'
                      alignItems='center'
                      className={common.h_100}
                    >
                      <Grid item>
                        <Box px={2}>
                          <Typography
                            component='p'
                            className={[common.HeeboMedium, common.textL].join(
                              ' '
                            )}
                          >
                            {card?.name}
                          </Typography>
                          <LableFontLight label='Added on: ' />
                          <LableFontMedium
                            label={moment(card?.addedOn).format('MMM Do, YYYY')}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                {card?.deleteButton ? (
                  <DeleteOutlineIcon
                    onClick={() => handleSelectedAssociatedMember(card.id)}
                  />
                ) : (
                  ''
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Card>
  )

  const getPrefChips = (prefType, borderColor) => {
    if (!prefType?.length) {
      return "-"
    }

    return map(prefType, type => <Grid item> <Chips
      label={type}
      {...borderColor}
    /></Grid>)
  }

  return (
    <Box px={2} pb={2} className={common.relativePosition}>
      <Box py={2} className={[common.bgWhite, classes.drawerBody].join(' ')}>
        <Grid item container xs={12} justify='space-between'>
          <Grid item xs>
            <Text
              label={
                <Box display='flex' alignItems='center'>
                  <Typography
                    component='span'
                    className={[
                      common.HeeboMedium,
                      common.textPrimary,
                      common.textXl,
                      classes.mr20,
                    ].join(' ')}
                  >
                    {`${props.mode === 'read' ? memberData.name : 'Edit Member'
                      }`}
                    {props.mode === 'read' ? (
                      <Box
                        ml={2}
                        component='span'
                        py={0.5}
                        px={2}
                        className={[
                          common.borderRadiusXs,
                          common.textWhite,
                          common.textM,
                          props.data.status ? common.bgSuccess : common.bgPink,
                        ].join(' ')}
                      >
                        {props.data.status ? 'Active' : 'Expired'}
                      </Box>
                    ) : (
                      ''
                    )}
                  </Typography>
                </Box>
              }
            />
          </Grid>
          <Grid item>
            <Box pt={0.5}>
              <AGIcon
                name={'icon-Close'}
                onClick={props.closeDrawer}
                color={Color.light}
                size='14'
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      {props.mode === 'read' ? (
        <Box>
          <Box mb={2.5} justifyContent='center'>
            <Grid container justify='center'>
              {/* <Image src={props.data.avatar} circle xl /> */}
              <Avatar
                alt='Remy Sharp'
                src={memberData.avatar}
                className={classes.avatar}
              />
            </Grid>
          </Box>

          <Box mb={2.5}>
            <Grid container>
              <Grid item xs={4}>
                <FormLabel label={'Membership ID'} />
                <FormLData
                  label={memberData.memberId ? memberData.memberId : '-'}
                />
              </Grid>
              {/* <Grid item xs={4}>
                <FormLabel label={'Phone No'} />
                <FormLData
                  label={memberData.mobile ? '+1 ' + memberData.mobile : '-'}
                />
              </Grid>
              <Grid item xs={4}>
                <FormLabel label={'Email ID'} />
                <FormLData label={memberData.email ? memberData.email : '-'} />
              </Grid> */}
            </Grid>
          </Box>
          <Box mb={2.5}>
            <FormLabel label={'Membership Details'} />
            <Box p={1} className={classes.boxStyle}>
              <Box>
                <Typography
                  className={[
                    common.HeeboRegular,
                    common.textM,
                    common.textPrimary,
                    classes.mb10,
                  ].join(' ')}
                >
                  {memberData.clubName}
                </Typography>
              </Box>
              <Box display='flex' alignItems='center'>
                <Box mr={10}>
                  <FormLabel label={'Joining Date'} />
                  <FormLData
                    label={moment(memberData?.joiningDate).format('MMM Do, YYYY')}
                  />
                </Box>
                <Box>
                  <FormLabel label={'Expiring Date'} />
                  <FormLData
                    label={moment(memberData?.expiringDate).format(
                      'MMM Do, YYYY'
                    )}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
          <Box mb={2.5}>
            {memberData?.sharedData?.length ? (
              <FormLabel label={'Shared Data'} />
            ) : (
              ''
            )}
            {map(memberData.sharedData, (a, b) => (
              <Typography
                key={b}
                component='span'
                className={[
                  common.HeeboRegular,
                  common.textM,
                  common.textPrimary,
                  classes.mb10,
                ].join(' ')}
              >
                {/* {a === "NAME" ? <Typography>
                  { memberData.name}
                </Typography> : "" } */}
                {a === "PHONE_NUMBER" ?
                  <Box mt={0.6} className={classes.flexDisplay}> <Typography>
                    {memberData.mobile}
                  </Typography> </Box> : ""}
                {a === "EMAIL" ? <Box mt={0.6} className={classes.flexDisplay}><Typography>
                  {memberData.email}
                </Typography> </Box> : ""}
                {a === "DATE_OF_BIRTH" ? <Box mt={0.6} className={classes.flexDisplay}><Typography>
                  {memberData.dateOfBirth}
                </Typography> </Box> : ""}

                {a === "BEER_PREFERENCES" ? <Box mb={1.5} mt={1}>
                  <FormLabel label={'Beer Types'} />
                  <Grid container spacing={1}>
                    {getPrefChips(props?.data?.breweryPreferences?.beerTypes, { orborder: "orborder" })}
                  </Grid>
                </Box> : ""}
                {a === "FOOD_PREFERENCES" ? <Box mb={1.5}>
                  <FormLabel label={'Food Types'} />
                  <Grid container spacing={1}>
                    {getPrefChips(props?.data?.breweryPreferences?.foodTypes, { pelborder: "pelborder" })}
                  </Grid>
                </Box> : ""}
                {a === "VENUE_PREFERENCES" ? <Box mb={1.5}>
                  <FormLabel label={'Venue Types'} />
                  <Grid container spacing={1}>
                    {getPrefChips(props?.data?.breweryPreferences?.venueTypes, { drkborder: "drkborder" })}
                  </Grid>
                </Box> : ""}
                {a === "SPIRITS_PREFERENCES" ? <Box mb={1.5}>
                  <FormLabel label={'Spirits Types'} />
                  <Grid container spacing={1}>
                    {getPrefChips(props?.data?.breweryPreferences?.spiritsTypes, { orngborder: "orngborder" })}
                  </Grid>
                </Box> : ""}
                {a === "ADDITIONAL_PREFERENCES" ? <Box mb={1.5}>
                  <FormLabel label={'Additional Types'} />
                  <Grid container spacing={1}>
                    {getPrefChips(props?.data?.breweryPreferences?.additionalTypes, { pelborder: "pelborder" })}
                  </Grid>
                </Box> : ""}
                {a === "COFFEE_PREFERENCES" ? <Box mb={1.5}>
                  <FormLabel label={'Mead Types'} />
                  <Grid container spacing={1}>
                    {getPrefChips(props?.data?.breweryPreferences?.coffeeTypes, { orborder: "orborder" })}
                  </Grid>
                </Box> : ""}
                {a === "MEAD_PREFERENCES" ? <Box mb={1.5}>
                  <FormLabel label={'Mead Types'} />
                  <Grid container spacing={1}>
                    {getPrefChips(props?.data?.breweryPreferences?.meadTypes, { orborder: "orngborder" })}
                  </Grid>
                </Box> : ""}
                {a === "WINE_PREFERENCES" ? <Box mb={1.5}>
                  <FormLabel label={'Mead Types'} />
                  <Grid container spacing={1}>
                    {getPrefChips(props?.data?.breweryPreferences?.wineTypes, { orborder: "drkborder" })}
                  </Grid>
                </Box> : ""}
                {a === "CIDER_PREFERENCES" ? <Box mb={1.5}>
                  <FormLabel label={'Mead Types'} />
                  <Grid container spacing={1}>
                    {getPrefChips(props?.data?.breweryPreferences?.ciderTypes, { orborder: "pelborder" })}
                  </Grid>
                </Box> : ""}
                {/* {capitalize(startCase(a))}{memberData.sharedData?.length === b + 1 ? '' : ', '} */}
              </Typography>
            ))}
          </Box>
          <Box mb={2.5}>
            {memberData?.allNotes?.length ? <FormLabel label={'Notes'} /> : ''}
            {memberData?.allNotes?.length
              ? map(memberData.allNotes, (data, id) => (
                <Box my={1}>
                  <AGCard
                    secondary
                    cardContent={
                      <Box>
                        <Typography
                          className={[common.textLight, common.textM].join(
                            ' '
                          )}
                        >
                          {data?.notes}
                        </Typography>
                        <Typography
                          className={[
                            common.textLight,
                            common.textRight,
                            common.textM,
                          ].join(' ')}
                        >
                          {moment(data?.dateAdded).format('MMM Do, YYYY h:mm A')}
                        </Typography>
                      </Box>
                    }
                  />
                </Box>
              ))
              : ''}
          </Box>
          {memberData.primaryMemberData?.name?.length && (
            <Box mb={2.5}>
              <Typography
                className={[
                  common.HeeboLight,
                  common.textS,
                  common.textDark,
                  classes.mb10,
                ].join(' ')}
              >
                {'Associated Primary Member'}
              </Typography>
              <Box>
                <TheCard
                  avatar={memberData?.primaryMemberData?.avatar}
                  name={memberData?.primaryMemberData?.name}
                  addedOn={memberData?.primaryMemberData?.joinedAt}
                />
              </Box>
            </Box>
          )}
        </Box>
      ) : (
        <Box>
          <Box mb={2.5} justifyContent='center'>
            <Grid container justify='center'>
              <Avatar
                alt='Remy Sharp'
                src={memberData?.avatar}
                className={classes.avatar}
              />
            </Grid>
          </Box>
          <Box mb={2.5}>
            <FormLabel label={'Name'} />
            <FormLData label={memberData?.name} />
          </Box>
          <Box mb={2.5}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <FormLabel label={'Mobile'} />
                <FormLData
                  label={memberData?.mobile ? memberData?.mobile : '-'}
                />
              </Grid>
              <Grid item xs={6}>
                <FormLabel label={'Email ID'} />
                <FormLData
                  label={memberData?.email ? memberData?.email : '-'}
                />
              </Grid>
            </Grid>
          </Box>
          <Box mb={2.5}>
            <InputLabel
              error={
                errorCheck && !alphaNumericNospace.test(memberData.memberId)
              }
              label='Member ID'
              name='memberId'
              value={memberData?.memberId}
              onChangeValue={(lable, value) => handleFormValues(lable, value)}
            />
          </Box>
          <Box mb={1} display='flex' alignItems='center'>
            <Checkbox
              className={[classes.checkbox].join(' ')}
              color='primary'
              disabled={
                memberData?.primaryMemberData?.name?.length ? true : false
              }
              checked={associated}
              onChange={() => setAssociated(!associated)}
            />
            <FormLabel noMargin label={'Associate Primary Member'} />
          </Box>
          {associated && !memberData?.primaryMemberData?.name?.length && (
            <Box mb={0.1}>
              <InputLabel
                label='Search Member'
                name='search'
                onChangeValue={(lable, value) =>
                  handlesearchValue(lable, value)
                }
                value={searchValue}
              />
            </Box>
          )}

          {memberData?.primaryMemberData?.name?.length ? (
            <Box>
              <TheCard
                deleteButton={true}
                id={memberData?.primaryMemberData?.id}
                avatar={memberData?.primaryMemberData?.avatar}
                name={memberData?.primaryMemberData?.name}
                addedOn={memberData?.primaryMemberData?.joinedAt}
              />
            </Box>
          ) : (
            <Box
              style={{ maxHeight: 200, overflowY: 'auto' }}
              className={common.borderRadiusXs}
            >
              {associated &&
                map(mapAllUsers, (data, idx) =>
                  data.name ? (
                    <Box
                      key={idx}
                      className={common.bgInfo}
                      onClick={() => handleSelectAssociatedUser(data)}
                    >
                      <Box pl={0.7}>
                        <TheCard
                          deleteButton={false}
                          avatar={data?.avatar}
                          name={data?.name}
                          addedOn={data?.addedOn}
                        />
                      </Box>
                      {mapAllUsers.length !== idx + 1 ? (
                        <Divider variant='middle' />
                      ) : (
                        ''
                      )}
                    </Box>
                  ) : (
                    ''
                  )
                )}
            </Box>
          )}
          <Box mt={2.5}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormLabel label={'Start Date'} />
                <DatePicker
                  selectedDate={memberData?.joiningDate}
                  onChange={(date) => handleFormValues('joiningDate', moment(date).utc().format())}
                />{' '}
              </Grid>
              <Grid item xs={6}>
                <FormLabel label={'Expiration Date'} />
                <DatePicker
                  error={
                    errorCheck &&
                    (moment() > moment(memberData?.expiringDate) ? true : false)
                  }
                  selectedDate={memberData?.expiringDate}
                  onChange={(date) =>
                    handleFormValues('expiringDate', moment(date).utc().format())
                  }
                />{' '}
              </Grid>
            </Grid>
          </Box>
          <Box my={2.5}>
            <InputLabel
              label='Notes'
              name='notes'
              value={memberData?.notes}
              onChangeValue={(lable, value) => handleFormValues(lable, value)}
              multiline={3}
            />
          </Box>
        </Box>
      )}
      <Box>
        <Button
          label={`${props.mode === 'read' ? 'Edit' : 'Update'}`}
          color={'#FA735F'}
          textColor={'#FFFFFF'}
          fullWidth
          onClick={() => {
            handleButton()
          }}
        />
      </Box>
    </Box>
  )
}

export default AllMembersManageDrawer
