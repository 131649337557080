import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import CommanClasses from '../themes/CommonClasses'
import { Grid, Divider, Box, Typography } from '@material-ui/core'
import { TopIcon } from '../assets/icons'
import Fab from '@material-ui/core/Fab'
import { findIndex, map } from 'lodash'
import Color from '../themes/Colors'
import Image from '../components/Image'
import BgPic from '../assets/images/undraw_celebration_0jvk.svg'
import Pic from '../assets/images/DeanAvatar.png'
import Joyride, { EVENTS, STATUS, ACTIONS } from 'react-joyride'
import AGIcon from '../components/AGIcon'
import AgButton from '../components/AgButton'
import CloseSharpIcon from '@material-ui/icons/CloseSharp'
import { useParams } from 'react-router-dom'
import GLogo from '../assets/images/induljLogo.png'
import { gql, useQuery } from '@apollo/client'
import { imageWidthParams, menu } from '../Services/local'

function LeftSideBar(props) {

  const goBeepMenu = menu()
  const common = customStyles(CommanClasses)
  const classes = useStyles()
  const [objId, setObjId] = useState(null)

  const { data: menuData } = useQuery(gql`
  query getMenu {
    menu @client 
  }`)

  const theTitle = () => (
    <Box display='flex' alignItems='center' flexGrow={1}>
      <Box><Image src={Pic} sm circle /></Box>&nbsp;&nbsp;&nbsp;
      <Box display='flex' alignItems='center'>
        <Typography className={[common.HeeboMedium, common.textM, common.textPrimary].join(' ')}>Dean Rogers &nbsp;</Typography><Typography className={[common.HeeboLight, common.textM, common.textLight].join(' ')}>from GoBeep</Typography>
      </Box>
    </Box>
  )

  const mainSteps = [
    {
      content: <Box display='flex' justifyContent='center' alignItems='center' style={{ width: '100%' }} className={[classes.mb30].join(' ')}>
        <Box>
          <Typography className={[common.HeeboMedium, common.textL, common.textDark].join(' ')}>Welcome to GoBeep !</Typography>
          <Image src={BgPic} xl />
        </Box>
      </Box>,
      locale: {
        next: <AgButton label='Next' smblue />,
      },
      placement: 'center',
      target: 'body',
      contentHead: 'TestHead'
    },
    {
      title: 'Dashboard',
      target: '.target0',
      content: <Box style={{ textAlign: 'left' }}>
        <Typography className={[common.HeeboMedium, common.textL, common.textDark].join(' ')}>Dashboard</Typography>
        <Typography className={[common.HeeboLight, common.textL, common.textDark].join(' ')}>Amet minim mollit non deserunt ullamco est sit aliqua Dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua Dolor do amet sint.</Typography>
      </Box>,
      placement: 'right-start',
      offset: 20,
      footer: 'what is this?',
      explore: true,
    },
    {
      title: 'Clubs',
      target: '.target1',
      content: <Box style={{ textAlign: 'left' }}>
        <Typography className={[common.HeeboMedium, common.textL, common.textDark].join(' ')}>Clubs</Typography>
        <Typography className={[common.HeeboLight, common.textL, common.textDark].join(' ')}>Amet minim mollit non deserunt ullamco est sit aliqua Dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua Dolor do amet sint.</Typography>
      </Box>,
      placement: 'right-start',
      offset: 20,
      explore: true,
    },
    {
      title: 'Members',
      target: '.target2',
      content: <Box style={{ textAlign: 'left' }}>
        <Typography className={[common.HeeboMedium, common.textL, common.textDark].join(' ')}>Members</Typography>
        <Typography className={[common.HeeboLight, common.textL, common.textDark].join(' ')}>Amet minim mollit non deserunt ullamco est sit aliqua Dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua Dolor do amet sint.</Typography>
      </Box>,
      placement: 'right-start',
      offset: 20,
      explore: true,
    },
    {
      title: 'Promotions',
      target: '.target3',
      content: <Box style={{ textAlign: 'left' }}>
        <Typography className={[common.HeeboMedium, common.textL, common.textDark].join(' ')}>Promotions</Typography>
        <Typography className={[common.HeeboLight, common.textL, common.textDark].join(' ')}>Amet minim mollit non deserunt ullamco est sit aliqua Dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua Dolor do amet sint.</Typography>
      </Box>,
      placement: 'right-start',
      offset: 20,
      explore: true,
    },
    {
      title: 'Settings',
      target: '.target4',
      content: <Box style={{ textAlign: 'left' }}>
        <Typography className={[common.HeeboMedium, common.textL, common.textDark].join(' ')}>Settings</Typography>
        <Typography className={[common.HeeboLight, common.textL, common.textDark].join(' ')}>Amet minim mollit non deserunt ullamco est sit aliqua Dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua Dolor do amet sint.</Typography>
      </Box>,
      placement: 'right-start',
      offset: 20,
      explore: true,
    },
  ]
  const mainStepsIntro = [
    {
      content: <Box display='flex' justifyContent='center' alignItems='center' style={{ width: '100%' }} className={[classes.mb30].join(' ')}>
        <Box>
          <Typography className={[common.HeeboMedium, common.textL, common.textDark].join(' ')}>Welcome to GoBeep !</Typography>
          <Image src={BgPic} xl />
        </Box>
      </Box>,
      locale: {
        next: <AgButton label='Next' smblue />,
      },
      placement: 'center',
      target: 'body',
      contentHead: 'TestHead'
    },
    {
      title: 'Dashboard',
      target: '.target0',
      content: <Box style={{ textAlign: 'left' }}>
        <Typography className={[common.HeeboMedium, common.textL, common.textDark].join(' ')}>Dashboard</Typography>
        <Typography className={[common.HeeboLight, common.textL, common.textDark].join(' ')}>Amet minim mollit non deserunt ullamco est sit aliqua Dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua Dolor do amet sint.</Typography>
      </Box>,
      placement: 'right-start',
      offset: 20,
      footer: 'what is this?',
      explore: true,
    },
    {
      title: 'Promotions',
      target: '.target1',
      content: <Box style={{ textAlign: 'left' }}>
        <Typography className={[common.HeeboMedium, common.textL, common.textDark].join(' ')}>Promotions</Typography>
        <Typography className={[common.HeeboLight, common.textL, common.textDark].join(' ')}>Amet minim mollit non deserunt ullamco est sit aliqua Dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua Dolor do amet sint.</Typography>
      </Box>,
      placement: 'right-start',
      offset: 20,
      explore: true,
    },
    {
      title: 'Settings',
      target: '.target2',
      content: <Box style={{ textAlign: 'left' }}>
        <Typography className={[common.HeeboMedium, common.textL, common.textDark].join(' ')}>Settings</Typography>
        <Typography className={[common.HeeboLight, common.textL, common.textDark].join(' ')}>Amet minim mollit non deserunt ullamco est sit aliqua Dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua Dolor do amet sint.</Typography>
      </Box>,
      placement: 'right-start',
      offset: 20,
      explore: true,
    },
  ]

  let dashboardStepIndex = goBeepMenu?.length > 3 ? mainSteps.length : mainStepsIntro.length

  const dashboardSteps = [
    {
      content: (
        <Box style={{ textAlign: 'left' }}>
          <Typography className={[common.HeeboLight, common.textL, common.textDark].join(' ')}>Amet minim mollit non deserunt ullamco est sit aliqua Dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua Dolor do amet sint.</Typography>
        </Box>
      ),
      placement: 'right-start',
      target: '.dashboard_target0',
      offset: 20,
    },
    {
      content: (
        <Box style={{ textAlign: 'left' }}>
          <Typography className={[common.HeeboLight, common.textL, common.textDark].join(' ')}>Amet minim mollit non deserunt ullamco est sit aliqua Dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua Dolor do amet sint.</Typography>
        </Box>
      ),
      placement: 'left-start',
      target: '.dashboard_target1',
      offset: 20,
    },
    {
      content: (
        <Box style={{ textAlign: 'left' }}>
          <Typography className={[common.HeeboLight, common.textL, common.textDark].join(' ')}>Amet minim mollit non deserunt ullamco est sit aliqua Dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua Dolor do amet sint.</Typography>
        </Box>
      ),
      placement: 'left-start',
      target: '.dashboard_target2',
      offset: 20,
    },
  ]

  let clubsStepIndex = goBeepMenu?.length > 3 ? mainSteps.length + dashboardSteps.length : mainStepsIntro.length + dashboardSteps.length

  const clubsSteps =
    // []
    [
      {
        content: (
          <Box style={{ textAlign: 'left' }}>
            <Typography className={[common.HeeboLight, common.textL, common.textDark].join(' ')}>Amet minim mollit non deserunt ullamco est sit aliqua Dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua Dolor do amet sint.</Typography>
          </Box>
        ),
        placement: 'right-start',
        target: '.clubs_target0'
      },
      {
        content: (
          <Box style={{ textAlign: 'left' }}>
            <Typography className={[common.HeeboLight, common.textL, common.textDark].join(' ')}>Amet minim mollit non deserunt ullamco est sit aliqua Dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua Dolor do amet sint.</Typography>
          </Box>
        ),
        placement: 'left-start',
        target: '.clubs_target1'
      },
      {
        content: (
          <Box style={{ textAlign: 'left' }}>
            <Typography className={[common.HeeboLight, common.textL, common.textDark].join(' ')}>Amet minim mollit non deserunt ullamco est sit aliqua Dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua Dolor do amet sint.</Typography>
          </Box>
        ),
        placement: 'left-start',
        target: '.clubs_target2'
      }
    ]

  let membersStepIndex = goBeepMenu?.length > 3 ? mainSteps.length + dashboardSteps.length + clubsSteps.length : mainStepsIntro.length + dashboardSteps.length + clubsSteps.length

  const membersSteps = [
    {
      content: (
        <Box style={{ textAlign: 'left' }}>
          <Typography className={[common.HeeboLight, common.textL, common.textDark].join(' ')}>Amet minim mollit non deserunt ullamco est sit aliqua Dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua Dolor do amet sint.</Typography>
        </Box>
      ),
      placement: 'left-start',
      target: '.members_target1'
    }
  ]

  let promotionsStepIndex = goBeepMenu?.length > 3 ? mainSteps.length + dashboardSteps.length + clubsSteps.length + membersSteps.length : mainStepsIntro.length + dashboardSteps.length

  const promotionsSteps = [
    {
      content: (
        <Box style={{ textAlign: 'left' }}>
          <Typography className={[common.HeeboLight, common.textL, common.textDark].join(' ')}>Amet minim mollit non deserunt ullamco est sit aliqua Dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua Dolor do amet sint.</Typography>
        </Box>
      ),
      placement: 'left-start',
      target: '.promotions_target0'
    }
  ]

  let settingsStepIndex = goBeepMenu?.length > 3 ? mainSteps.length + dashboardSteps.length + clubsSteps.length + membersSteps.length + promotionsSteps.length : mainStepsIntro.length + dashboardSteps.length + promotionsSteps.length

  const settingsSteps = [
    {
      content: (
        <Box style={{ textAlign: 'left' }}>
          <Typography className={[common.HeeboLight, common.textL, common.textDark].join(' ')}>Amet minim mollit non deserunt ullamco est sit aliqua Dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua Dolor do amet sint.</Typography>
        </Box>
      ),
      placement: 'right-start',
      target: '.settings_target0'
    },
  ]

  // --------------------Joyride Starts-------------XXXXXXX
  const { tour } = useParams()

  const [run, setRun] = useState(false)
  const [continuous, setContinuous] = useState(true)
  const [stepIndex, setStepIndex] = useState(0)
  const [targetIndex, setTargetIndex] = useState(tour || -1)
  const steps = [...mainSteps, ...dashboardSteps, ...clubsSteps, ...membersSteps, ...promotionsSteps, ...settingsSteps]
  const stepsIntro = [...mainStepsIntro, ...dashboardSteps, ...promotionsSteps, ...settingsSteps]
  const [stepState, setStepState] = useState(stepsIntro)//only for page#
  const [stepState2, setStepState2] = useState(0)//only for page#


  useEffect(() => {
    if (tour) {
      setRun(true)
    }
  }, [])

  useEffect(() => {
    let length = goBeepMenu?.length > 3 ? mainSteps.length : mainStepsIntro.length
    if (stepIndex < length) {
      handleClick(0, { ...goBeepMenu[stepIndex - 1] })
    }

  }, [stepIndex])

  const handleTour = (e) => {
    // e.preventDefault()
    setStepIndex(0)
    setRun(!run)
    // setStepState(mainSteps)//only for page#
    setStepState2(0)
  }

  const handleJoyrideCallback = (data) => {
    const { action, index, status, type, controlled } = data

    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      if (targetIndex !== -1) {
        setStepIndex(targetIndex)
        setTargetIndex(-1)
      } else {
        //Update state to advance the tour 
        setStepIndex(index + (action === ACTIONS.PREV ? -1 : 1))

        //...below commented lines are related to react joyride(maintained for the reference later)
        // //-------------for main steps--------------
        // if (index + 1 === 1) {
        //   handleClick(0, { icon: 'DashboardOutlinedIcon', href: '/' })
        // }
        // if (index + 1 === 2) {
        //   handleClick(1, { icon: 'SecurityOutlinedIcon', href: '/clubs' })
        // }
        // if (index + 1 === 3) {
        //   handleClick(2, { icon: 'PeopleAltOutlinedIcon', href: '/user' })
        // }
        // if (index + 1 === 4) {
        //   handleClick(3, { icon: 'CardGiftcardOutlinedIcon', href: '/promotions' })
        // }
        // if (index + 1 === 5) {
        //   handleClick(4, { icon: 'SettingsOutlinedIcon', href: '/settings' })
        // }
        // // -----------end--
        // --------for explore------------------        
        if (index + 1 === dashboardStepIndex) {
          // console.log('at dashboard step now')
          handleClick(0, { icon: 'DashboardOutlinedIcon', href: `/?tour=${dashboardStepIndex}` })
          setStepState(dashboardSteps)//only for page#
          setStepState2(dashboardStepIndex)//only for page#
        }

        if (index + 1 === clubsStepIndex) {
          // console.log('at clubsStep now')
          handleClick(1, { icon: 'SecurityOutlinedIcon', href: `/clubs?tour=${clubsStepIndex}` })
          setStepState(clubsSteps)//only for page#
          setStepState2(clubsStepIndex)//only for page#
        }

        if (index + 1 === membersStepIndex) {
          // console.log('at members step now')
          handleClick(2, { icon: 'PeopleAltOutlinedIcon', href: `/user?tour=${membersStepIndex}` })
          setStepState(membersSteps)//only for page#
          setStepState2(membersStepIndex)//only for page#
        }

        if (index + 1 === promotionsStepIndex) {
          // console.log('at promotion step now')
          handleClick(3, { icon: 'CardGiftcardOutlinedIcon', href: `/promotions?tour=${promotionsStepIndex}` })
          setStepState(promotionsSteps)//only for page#
          setStepState2(promotionsStepIndex)//only for page#
        }

        if (index + 1 === settingsStepIndex) {
          // console.log('at settings step now')
          handleClick(4, { icon: 'SettingsOutlinedIcon', href: `/settings?tour=${settingsStepIndex}` })
          setStepState(settingsSteps)//only for page#
          setStepState2(settingsStepIndex)//only for page#
        }
        // -----------------------------
      }

    }
    else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      setRun(false)

    }

    console.groupCollapsed(type)
    console.log(data) //eslint-disable-line no-console
    console.groupEnd()
  }

  const handleClickAddSteps = (title, data, e) => {
    // console.log(data, 'event=', e)
    if (title === 'Dashboard') {
      //this is to redirect the route to the corresponding page
      handleClick(0, { icon: 'DashboardOutlinedIcon', href: `/?tour=${dashboardStepIndex}` })

      //redirecting joyride to the corresponding step 
      setTargetIndex(dashboardStepIndex)
      data.onClick(e)
      setStepState(dashboardSteps)//only for page#
      setStepState2(dashboardStepIndex)//only for page#
    }

    if (title === 'Clubs') {
      //this is to redirect the route to the corresponding page
      handleClick(1, { icon: 'SecurityOutlinedIcon', href: `/clubs?tour=${clubsStepIndex}` })

      //redirecting joyride to the corresponding step 
      setTargetIndex(clubsStepIndex)
      data.onClick(e)
      setStepState(clubsSteps)//only for page#
      setStepState2(clubsStepIndex)//only for page#
    }

    if (title === 'Members') {
      //this is to redirect the route to the corresponding page
      handleClick(2, { icon: 'PeopleAltOutlinedIcon', href: `/user?tour=${membersStepIndex}` })

      //redirecting joyride to the corresponding step 
      setTargetIndex(membersStepIndex)
      data.onClick(e)
      setStepState(membersSteps)//only for page#
      setStepState2(membersStepIndex)//only for page#
    }

    if (title === 'Promotions') {
      //this is to redirect the route to the corresponding page
      handleClick(3, { icon: 'CardGiftcardOutlinedIcon', href: `/promotions?tour=${promotionsStepIndex}` })

      //redirecting joyride to the corresponding step 
      setTargetIndex(promotionsStepIndex)
      data.onClick(e)
      setStepState(promotionsSteps)//only for page#
      setStepState2(promotionsStepIndex)//only for page#
    }

    if (title === 'Settings') {
      //this is to redirect the route to the corresponding page
      handleClick(4, { icon: 'SettingsOutlinedIcon', href: `/settings?tour=${settingsStepIndex}` })

      //redirecting joyride to the corresponding step 
      setTargetIndex(settingsStepIndex)
      data.onClick(e)
      setStepState(settingsSteps)//only for page#
      setStepState2(settingsStepIndex)//only for page#
    }
  }
  const Tooltip = ({
    continuous,
    index,
    step,
    backProps,
    closeProps,
    primaryProps,
    tooltipProps,
    size,
    skipProps,
    isLastStep,
  }) => {
    // console.log('primaryProps=', primaryProps)
    return <Grid containers className={[common.bgWhite, classes.customTooltip].join(' ')} {...tooltipProps}>
      <Grid item container justify='space-between'>
        <Grid item><Box>{theTitle}</Box></Grid>
        <Grid item><CloseSharpIcon {...skipProps} /></Grid>
      </Grid>
      <Grid item>
        <Box my={3.75}>{step.content}</Box>
        <Divider />
      </Grid>
      <Box mt={3.75}>
        <Grid item container>
          <Grid item sm={4} container justify='flex-start'>
            {step.explore && <AgButton outlined label={`Explore ${step.title}`} onClick={(e) => { handleClickAddSteps(step.title, primaryProps, e) }} />}
          </Grid>
          <Grid item sm={4} container justify='center'>
            {/* <Box pt={1}><Typography className={[common.HeeboRegular, common.textM].join(' ')} style={{ color: '#8E8E8E' }}>{index + 1}/{size}</Typography></Box> */}
            <Box pt={1}><Typography className={[common.HeeboRegular, common.textM].join(' ')} style={{ color: '#8E8E8E' }}>{(index + 1) - (stepState2)}/{stepState.length}</Typography></Box>
          </Grid>
          <Grid item sm={4} container justify='flex-end'>
            {index > 0 && (<Box px={2}><AgButton label='Previous' smblue {...backProps} /></Box>)}
            {continuous && (<Box px={0}><AgButton label={index + 1 === size ? 'Done' : 'Next'} smblue {...primaryProps} /></Box>)}
          </Grid>
        </Grid>
      </Box>
    </Grid>
  }
  // ----------------Joyride Ends-----------------XXXXXXXX
  const handleClick = (id, obj) => {
    // setObjId(id)
    props.history.push(obj.href)
  }

  useEffect(() => {
    let location = props.history?.location?.pathname
    let getHrefIndex = findIndex(goBeepMenu, nbis => nbis?.href === location)
    setObjId(getHrefIndex)
  }, [props.history?.location?.pathname, goBeepMenu])

  return (
    <div className={[common.bgPrimary, 'leftSidebar'].join(' ')}>
      <Grid
        container
        direction='column'
        justify='flex-start'
        // justify='space-between'
        alignItems='center'
        spacing={2}
      >
        <br />
        {map(TopIcon, (o, idx) => (
          <Grid item xs={12} key={idx}>
            <img src={GLogo} width={40} height={40} onClick={() => props.drawerActon()} alt='' />
          </Grid>
        ))}
        <Grid item xs={12}>
          <Box mb={2}><Divider className={[classes.dividerRoot].join(' ')} /></Box>
        </Grid>
        {map(goBeepMenu, (obj, id) => (
          <Fab
            key={id}
            size='small'
            color='primary'
            disableRipple
            onClick={() => {
              handleClick(id, obj)
              props.drawerActon()
            }}
            className={objId === id ? classes.selectedFab : classes.fab}
          >
            {/* <obj.icon
              className={[common.textWhite, common.pointer, `target${id}`]}
              onClick={() => handleClick(id, obj)}
            /> */}
            <AGIcon
              name={[obj.name, `target${id}`].join(' ')}
              color={objId === id ? Color.primary : Color.white}
              // onClick={() => handleClick(id, obj)}
              size={obj.size}
            />
          </Fab>
        ))}
        <Grid item xs={12}>
          <Box mt={2}><Divider className={[classes.dividerRoot, 'step2'].join(' ')} /></Box>
        </Grid>
      </Grid>
    </div>
  )
}

const customStyles = makeStyles(CommanClasses)

const useStyles = makeStyles((theme) => ({
  dividerRoot: {
    background: Color.divider,
    width: '24px',
    height: '0px',
    border: '1px solid #171C32',
    opacity: 1,
  },
  fab: {
    border: 'none',
    marginBottom: 10,
    '&:hover': {
      background: 'inherit',
    },
    boxShadow: 'none'
  },
  selectedFab: {
    border: 'none',
    marginBottom: 10,
    backgroundColor: Color.white,
    '&:hover': {
      background: Color.main,
    },
    boxShadow: 'none',
    '& .MuiSvgIcon-root': {
      color: Color.primary,
    },
  },
  selctedIcon: {
    background: Color.white,
    borderRadius: 50,
    '& .MuiSvgIcon-root': {
      color: Color.primary,
    },
  },
  customTooltip: {
    width: 737,
    borderRadius: 15,
    opacity: 1,
    padding: 30,
  },
  tourGrid: {
    bottom: 5, position: 'fixed',
  },
  tourIcon: {
    cursor: 'pointer',
  },
}))

export default LeftSideBar