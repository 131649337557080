import React, { useEffect } from 'react'
// import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
// import { LoginPage } from '../pages/login'
import { useAuth0 } from '@auth0/auth0-react'
import { LinearProgress } from '@material-ui/core'

export default () => {
  const { loginWithRedirect } = useAuth0()

  useEffect(() => {
    loginWithRedirect()
  }, [])

  return (
    <div>
      <LinearProgress />
    </div>
  )
}
