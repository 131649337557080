import React, { useRef, useState, useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { storeAllDetails } from '../Services/commonFunctions'
import { putMerchantUserGql } from '../graphql/putMerchantUserGql'
import { makeStyles } from '@material-ui/core/styles'
import CommanClasses from '../themes/CommonClasses'
import {
  Box,
  Typography,
  Grid,
  Checkbox,
  TextField,
  IconButton,
  FormHelperText,
  Button,
  Chip,
  CircularProgress
} from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Image from '../components/Image'
import { differenceBy, isEmpty, map, filter, isEqual, pick, find, get, startCase, toLower } from 'lodash'
import InputLabel from '../components/InputLabel'
import Color from '../themes/Colors'
import AddOutlinedIcon from '@material-ui/icons/AddOutlined'
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined'
import { useApolloClient, useMutation, useQuery } from '@apollo/client'
import { UPDATE_CLUB } from '../graphql/updateBreweryMembershipProgramGQL'
import { getCommonMdiIconsSet } from '../graphql/commonMdiIconsSetGQL'
import AgButton from '../components/AgButton'
import MdiIcon from '../components/MdiIcon'
import ManageOfferDrawer from './ManageOfferDrawer'
import AGIcon from '../components/AGIcon'
import Text from '../components/AgText'
import { ADD_CLUB } from '../graphql/addBreweryMembershipProgramGQL '
import { fullMenuArr, getBreweryId, menu, getBreweries, imageWidthParams } from '../Services/local'
import { useSnackbar } from 'notistack'
import { imageKitUploadApi } from '../Services/externalApi'
import ImageSelector from '../components/ImageSelector/ImageSelector'

// import Icon from '@material-ui/core/Icon'

const clubNameMaxLenth = 60
const ClubsManageDrawer = (props) => {
  const { clubDetails = {}, closeDrawer, newClubId, mode = 'read', basepath } = props || {}
  console.log('clubDetails??', clubDetails)

  const breweryId = getBreweryId()
  let initialData = {
    name: clubDetails?.name,
    clubLimit: clubDetails?.clubLimit,
    // autoRenewable: clubDetails.autoRenewable || false,
    benefitsMultiple: Array.isArray(clubDetails?.benefitsMultiple) ? clubDetails?.benefitsMultiple : clubDetails?.benefitsMultiple?.split(),
    description: clubDetails?.description,
    annualCost: clubDetails?.annualCost,
    badgeIconName: clubDetails?.badgeIconName,
    badgeIconColor: clubDetails?.badgeIconColor,
    clubRenewalCadence: clubDetails?.clubRenewalCadence || 'YEARLY'
  }

  let defaultSelectedTags = []

  if (clubDetails?.id) {
    initialData.id = clubDetails?.id

    //for locations/breweries
    if (!isEmpty(clubDetails?.breweriesConnecton)) {
      defaultSelectedTags = clubDetails?.breweriesConnecton || []
      initialData.breweriesAssociatedIds = defaultSelectedTags.map((bc) => bc.id)
    }
  }

  const [defaultClubDetailsData, setDefaultClubDetailsData] = useState(initialData)
  const common = customStyles(CommanClasses)
  const classes = useStyles()
  const breweries = getBreweries()
  const { enqueueSnackbar } = useSnackbar()
  const apolloClient = useApolloClient()
  const history = useHistory()

  const clubImagePickerRef = useRef(null)
  let benefitRef = useRef(null)

  const [benefitsMultiple, setBenefitsMultiple] = useState(Array.isArray(clubDetails?.benefitsMultiple) ? clubDetails?.benefitsMultiple : clubDetails?.benefitsMultiple?.split())
  const [clubImage, setClubImage] = useState(clubDetails?.photosConnection?.edges?.[0]?.image?.url)
  const [changedImage, setChangedImage] = useState({})
  const [newBenefits, setNewBenefits] = useState([])
  const [clubDetailsData, setClubDetailsData] = useState(initialData)
  const [drawerMode, setDrawerMode] = useState(mode)
  const [isToCreatePromoReq, setIsToCreatePromoReq] = useState(false)
  const [isToCreatePromoReqApproved, setIsToCreatePromoReqApproved] = useState(false)
  const [errorCollections, setErrorCollections] = useState({})
  const [selectedTags, setSelectedTags] = useState(defaultSelectedTags)
  const [iconsList, setIconsList] = useState([])
  const [uploadingFile, setUploadingFile] = useState(false)
  const [uploadComplte, setUploadComplte] = useState('');
  const [cropedUrl, setCropedUrl] = useState()
  const [cropedFile, setCropedFile] = useState({})

  const handleCropedURL = (e) => {
    setCropedUrl(e)
  }

  const ImageKitUpload = async (file) => {
    setUploadingFile(true)

    const { url: imageUrl } = await imageKitUploadApi({
      apolloClient,
      fileName: `clubImage_${new Date().getTime()}.${file?.type?.split('/')?.[1]}`,
      file
    })

    setUploadingFile(false)
    setClubImage(imageUrl)
    setUploadComplte(imageUrl)
    setChangedImage({ photos: [{ imageUrl }] })
    setErrorCollections({ ...errorCollections, clubImage: '' })
    setCropedUrl('')
  }

  const handleCropedFile = async (e) => {
    if (!e) {
      return
    }
    let today = new Date()
    e.lastModified = today.getTime()
    e.lastModifiedDate = today
    e.webkitRelativePath = ""
    var file = new File([e], "name");

    if (file) {
      setCropedFile(file)
      // ImageKitUpload(file)
    }
  }
  const breweriesList = map(breweries, data => {
    return { name: data?.brewery?.name, id: data?.brewery?.id }
  })

  const { user, isLoading, error, getAccessTokenSilently } = useAuth0({
    audience: process?.env?.REACT_APP_API_HOST,
    scope: 'openid profile email',
  })

  useEffect(() => {
    const getAccessToken = async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          audience: process.env.REACT_APP_API_HOST,
          scope: 'read:current_user',
        })
        console.log('accessToken??', accessToken)
      } catch (err) {
        throw err
      }
    }
    getAccessToken()
  }, [])


  const [putMerchantUser, { data: meData, error: meDataError, loading: meDataLoading }] = useMutation(putMerchantUserGql, {
    onCompleted: (resp) => {
      if (resp) {
        storeAllDetails(resp)
      }
    }
  })

  const updateData = () => {
    putMerchantUser({
      variables: {
        auth0Sub: user?.sub,
        email: user?.email,
      }
    })
  }

  const { data: badgeIconsData } = useQuery(getCommonMdiIconsSet, {
    onCompleted: (res) => {
      if (!res)
        return
      let iList = get(res, 'getCommonMdiIconsSet.edges') || []
      console.log(iList)
      setIconsList(iList)
    },
    fetchPolicy: 'no-cache'
  })

  const handlebadgeIcon = (v) => {
    setClubDetailsData({ ...clubDetailsData, badgeIconName: v?.slug || '  ' })
  }

  console.log('clubDetailsData', '>>>>>', clubDetailsData)
  const [updateClub, { loading: updatingClub }] = useMutation(UPDATE_CLUB, {
    onCompleted: (resp) => {
      console.log('resp updateClub??', resp)
      //reset image state
      setChangedImage({})
      if (resp) {
        setDefaultClubDetailsData(clubDetailsData)
        if (isToCreatePromoReq) {
          if (isEmpty(newBenefits)) {
            //Show snackbar that there is no new benefit added

            console.log('Show snackbar that there is no new benefit added')
            setBenefitsMultiple(clubDetailsData?.benefitsMultiple || [])
            setDrawerMode('read')
          } else {
            setIsToCreatePromoReqApproved(true)
          }
        } else {
          setBenefitsMultiple(clubDetailsData?.benefitsMultiple || [])
          setDrawerMode('read')
        }
      }
      updateData()
    },
    onError: (err) => {
      console.log('err??', err)
    }
  })

  const [addClub, { loading: addingClub }] = useMutation(ADD_CLUB, {
    onCompleted: (resp) => {
      console.log('resp addClub??', resp)
      //reset image state
      setChangedImage({})
      if (resp) {
        if (get(resp, 'addBreweryMembershipProgram.program.id')) {
          setClubDetailsData({ ...clubDetailsData, id: resp.addBreweryMembershipProgram.program.id })
        }

        if (basepath === 'settings') {
          setDrawerMode('read')
        }
        const idd = resp?.addBreweryMembershipProgram?.program?.id
        newClubId(idd)
        if (isToCreatePromoReq) {
          if (isEmpty(newBenefits)) {
            //Show snackbar that there is no new benefit added
            console.log('Show snackbar that there is no new benefit added')
            setBenefitsMultiple(clubDetailsData?.benefitsMultiple || [])
            setDrawerMode('read')
          } else {
            setIsToCreatePromoReqApproved(true)
          }
        } else {
          setBenefitsMultiple(clubDetailsData?.benefitsMultiple || [])
          setDrawerMode('read')
        }
        //setting menu items
        menu(fullMenuArr)
        updateData()

      }
    },
    onError: (err) => {
      console.log('err??', err)
    }
  })

  const handleCreatePromoCheckbox = (e) => {
    if (e.target.checked) {
      setIsToCreatePromoReq(true)
    } else {
      setIsToCreatePromoReq(false)
    }
  }

  const isValidatedClubDetailsData = () => {
    let errorCollectionsTmp = {}

    let errored = false

    // clubDetailsData
    if (!clubDetailsData?.name) {
      errored = true
      errorCollectionsTmp = {
        ...errorCollectionsTmp,
        name: 'Club name is empty.'
      }
    }

    if (isEmpty(clubImage)) {
      errored = true
      errorCollectionsTmp = {
        ...errorCollectionsTmp,
        clubImage: 'Please Select Image.'
      }
    }

    if (clubDetailsData?.clubLimit && clubDetailsData?.clubLimit > 9999) {
      errored = true
      errorCollectionsTmp = {
        ...errorCollectionsTmp,
        clubLimit: 'Club limit should be less than 10000.'
      }
    }

    if (isEmpty(clubDetailsData?.description)) {
      errored = true
      errorCollectionsTmp = {
        ...errorCollectionsTmp,
        description: 'Please enter club description.'
      }
    }

    setErrorCollections(errorCollectionsTmp)

    return !errored
  }

  const ObjectDiff = (obj1, obj2) => {
    const diff = pick(
      obj2,
      Object.keys(obj1).filter((k) => obj1[k] !== obj2[k])
    )
    return diff
  }

  const handleButtonClick = () => {
    if (isEqual(defaultClubDetailsData, clubDetailsData) && isEmpty(changedImage)) {
      setDrawerMode('read')
      return
    }

    if (drawerMode !== 'read') {
      if (isValidatedClubDetailsData()) {
        if (updatingClub || addingClub) {
          return
        }
        setNewBenefits(differenceBy(clubDetailsData?.benefitsMultiple || [], benefitsMultiple))
        if (clubDetails?.id || clubDetailsData?.id) {
          let requiredData = ObjectDiff(defaultClubDetailsData, clubDetailsData)
          updateClub({ variables: { id: clubDetails?.id || clubDetailsData?.id, ...requiredData, ...changedImage } })
        } else {
          addClub({ variables: { ...clubDetailsData, breweryId, ...changedImage } })
        }
      }
    }
  }

  const handleAddBenefits = () => {
    let benefit = benefitRef.current.value
    if (benefit) {
      setClubDetailsData({ ...clubDetailsData, benefitsMultiple: [...(clubDetailsData?.benefitsMultiple || []), benefit] })
      benefitRef.current.value = ''
    }
    benefitRef.current.focus()
  }

  const handleRemoveBenefits = (indx) => {
    console.log('benefitsMultiple??', benefitsMultiple)
    setClubDetailsData({ ...clubDetailsData, benefitsMultiple: [...(clubDetailsData?.benefitsMultiple || []).slice(0, indx), ...(clubDetailsData?.benefitsMultiple || []).slice(indx + 1)] })
  }


  const handleClubImagePicker = async (e) => {
    const file = e.target.files[0]
    if (!file) {
      return
    }
    ImageKitUpload(file)
  }

  const handleBenefitKeyPress = (e) => {
    if (e.keyCode === 13) {
      handleAddBenefits()
    }
  }

  const handleLocations = (v) => {
    console.log('v??', v)
    if (v.length && find(selectedTags, data => data.id === v[v.length - 1].id)) {
      let newT = filter(selectedTags, dt => dt.id !== v[v.length - 1].id)
      setSelectedTags(newT)
      setClubDetailsData({
        ...clubDetailsData,
        breweriesAssociatedIds: newT.map((d) => d.id)
      })
    } else {
      setSelectedTags(v)
      setClubDetailsData({
        ...clubDetailsData,
        breweriesAssociatedIds: v.map((d) => d.id)
      })
    }
  }

  const cadenceOpt = {
    'Monthly': 'MONTHLY',
    'Quarterly': 'QUARTERLY',
    'Half Yearly': 'HALF_YEARLY',
    'Yearly': 'YEARLY'
  }

  return (
    <Box px={2} pb={2}>
      <Grid container className={common.relativePosition}>
        <Grid item container xs={12} justify='space-between' className={[common.bgWhite, classes.drawerBody].join(' ')}>
          <Grid item xs>
            <Text
              label={
                <Box display='flex' alignItems='center'>
                  <Box mr={1} mt={0.5}>
                    <MdiIcon
                      name={find(iconsList, d => d.slug === clubDetailsData.badgeIconName)?.name}
                      color={clubDetailsData.badgeIconColor}
                    />
                  </Box>
                  <Typography className={[common.HeeboMedium, common.textPrimary, common.textXl, classes.mr20].join(' ')}>
                    {isToCreatePromoReqApproved ? 'Create promotion with updated benefits' : clubDetailsData?.name}
                  </Typography>
                </Box>
              }
            />
          </Grid>
          <Grid item>
            <AGIcon
              name={'icon-Close'}
              onClick={props.closeDrawer}
              color={Color.light}
              size='14'
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Box my={2}>
            <div>
              {isToCreatePromoReqApproved ?
                <ManageOfferDrawer benefitsMultiple={newBenefits || []} closeDrawer={closeDrawer} />
                :
                drawerMode === 'read' ?
                  <Box>
                    <Box mb={2.5}>
                      <Image src={clubImage + imageWidthParams(418)} xxl radiusMd />
                    </Box>
                    <Box mb={1.25}>
                      <Typography
                        className={[common.HeeboLight,
                        common.textM,
                        common.textDark].join(' ')}
                      >
                        {'Benefits'}
                      </Typography>
                      {map(Array.isArray(clubDetailsData?.benefitsMultiple) ? clubDetailsData?.benefitsMultiple : clubDetailsData?.benefitsMultiple?.split(), (text, idx) => (
                        <Box display='flex' alignItems='center' key={idx}>
                          <Box><span className={[classes.dot2,].join(' ')} /></Box>
                          <Box><Typography
                            key={idx}
                            className={[common.HeeboRegular,
                            common.textM,
                            common.textDark].join(' ')}
                          >
                            {text?.trim()}
                          </Typography></Box>
                        </Box>
                      ))}
                    </Box>
                    <Box mb={2.5}>
                      <Typography
                        className={[common.HeeboLight,
                        common.textM,
                        common.textDark].join(' ')}
                      >
                        {'Club Description'}
                      </Typography>
                      {map(Array.isArray(clubDetailsData?.description) ? clubDetailsData?.description : clubDetailsData?.description?.split(), (text, i) => (
                        <Box display='flex' alignItems='center' key={i}>
                          <Box><span className={[classes.dot2,].join(' ')} /></Box>
                          <Box><Typography
                            key={i}
                            className={[common.HeeboRegular,
                            common.textM,
                            common.textDark].join(' ')}
                          >
                            {text?.trim()}
                          </Typography></Box>
                        </Box>
                      ))}
                    </Box>
                    <Box mb={2.5}>
                      <Grid container justify='space-between'>
                        <Box>
                          <Typography
                            className={[common.HeeboLight,
                            common.textS,
                            common.textDark].join(' ')}
                          >
                            {'Total Memberships'}
                          </Typography>
                          <Typography
                            className={[common.HeeboRegular,
                            common.textM,
                            common.textDark].join(' ')}
                          >
                            {clubDetailsData?.clubLimit || '-'}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography className={[common.HeeboLight, common.textS, common.textDark].join(' ')}>{'Active Memberships'}</Typography>
                          <Typography className={[common.HeeboRegular, common.textM, common.textDark].join(' ')}>{clubDetails?.memberStats?.activeMemberCount || 0}</Typography>
                        </Box>
                        <Box>
                          <Typography className={[common.HeeboLight, common.textS, common.textDark].join(' ')}>{'Remaining Memberships'}</Typography>
                          <Typography className={[common.HeeboRegular, common.textM, common.textDark].join(' ')}>{clubDetailsData?.clubLimit ? (clubDetailsData?.clubLimit - (clubDetails?.memberStats?.activeMemberCount || 0)) : '-'}</Typography>
                        </Box>
                      </Grid>
                    </Box>
                    <Box mb={2.5}>
                      <Typography className={[common.HeeboLight, common.textM, common.textDark].join(' ')}>{'Locations'}</Typography>
                      <Box>
                        {
                          map(clubDetailsData?.breweriesAssociatedIds, (location, idx) =>
                            <Box display='flex' alignItems='center' key={idx}>
                              <Box><span className={[classes.dot2,].join(' ')} /></Box>
                              <Box><Typography
                                key={idx}
                                className={[common.HeeboRegular,
                                common.textM,
                                common.textDark].join(' ')}
                              >
                                {find(breweriesList, b => b?.id === location)?.name || ''}
                              </Typography></Box>
                            </Box>
                          )
                        }
                      </Box>
                    </Box>
                    {/* {clubDetailsData?.autoRenewable && <Box display='flex' mb={2.5} alignItems='center'>
                      <span className={[classes.dot].join(' ')} />
                      <Typography className={[common.HeeboRegular, common.textM, common.textDark].join(' ')}>{'Auto-renewable'}</Typography>
                    </Box>} */}
                    <Box mb={2.5}>
                      <Typography className={[common.HeeboLight, common.textM, common.textDark].join(' ')}>{'Payment Plans'}</Typography>
                      <Box display='flex' className={[common.bgMain, classes.smallBox].join(' ')}>
                        <Typography className={[common.HeeboMedium, common.textM, common.textPrimary].join(' ')}>{clubDetailsData?.annualCost?.split('.')?.[0]?.replace('USD ', '$') || '$0'}</Typography>
                        <Typography className={[common.HeeboLight, common.textM, common.textPrimary].join(' ')}>{`/${startCase(toLower(clubDetailsData?.clubRenewalCadence || 'YEARLY'))}`}</Typography>
                      </Box>
                    </Box>

                    {/* badgeIconName, badgeIconColor */}
                    <AgButton
                      label={'Edit'}
                      color={'#FA735F'}
                      textColor={'#FFFFFF'}
                      fullWidth
                      onClick={() => setDrawerMode('write')} />
                  </Box>
                  :
                  <Box>

                    <Box mb={2.5}>
                      {uploadingFile ? <div></div> : clubImage && isEmpty(cropedUrl) ? (
                        <Image
                          src={clubImage + imageWidthParams(418)}
                          xxl
                          radiusMd
                        />
                      ) : (
                        <div></div>
                      )}
                      <input
                        ref={clubImagePickerRef}
                        type='file'
                        name='clubImage'
                        hidden
                        onChange={handleClubImagePicker}
                        accept='image/png, .jpeg, .jpg, image/gif'
                      />
                    </Box>
                    <Box mb={2.5}>
                      <ImageSelector
                        cropedUrl={e => handleCropedURL(e)}
                        cropedFile={(e) => handleCropedFile(e)}
                        url={uploadComplte}
                        buttonText={clubImage?.length ? 'Update Club Image' : 'Add Club Image'}
                      />
                    </Box>

                    <Box mb={2.5}>
                      {!isEmpty(cropedUrl) ?
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6}>
                            <Button
                              variant="contained"
                              disableRipple={true}
                              className={classes.confirmButton}
                              onClick={() => {
                                setCropedUrl()
                                setUploadComplte(new Date().getTime())
                              }}
                            >
                              {'Cancel'}
                            </Button>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Button
                              variant="contained"
                              disableRipple={true}
                              className={classes.confirmButton}
                              onClick={() =>
                                ImageKitUpload(cropedFile)
                              }
                            >
                              {uploadingFile ? <CircularProgress /> : 'Confirm Upload'}
                            </Button>
                          </Grid>
                        </Grid> :
                        <></>}
                      {errorCollections?.clubImage && <FormHelperText error>{errorCollections?.clubImage}</FormHelperText>}
                    </Box>
                    <Box mb={2.5}>
                      <InputLabel label={'Club Name'} maxLength={clubNameMaxLenth} name='clubName' value={clubDetailsData?.name} autoFocus onChangeValue={(name, value) => {
                        setErrorCollections({ ...errorCollections, name: '' })
                        setClubDetailsData({ ...clubDetailsData, name: value })
                      }} />
                      {errorCollections?.name && <FormHelperText error>{errorCollections?.name}</FormHelperText>}
                    </Box>
                    <Box mb={2.5}>
                      <InputLabel label={'Club Limit'} name='clubLimit' value={clubDetailsData?.clubLimit} onChangeValue={(name, value) => {
                        setErrorCollections({ ...errorCollections, clubLimit: '' })
                        setClubDetailsData({ ...clubDetailsData, clubLimit: value })
                      }} />
                      {errorCollections?.clubLimit && <FormHelperText error>{errorCollections?.clubLimit}</FormHelperText>}
                    </Box>
                    {/* <Box mb={2.5} display='flex' alignItems='center'>
                      <Checkbox className={[classes.checkbox].join(' ')} color='primary' checked={clubDetailsData?.autoRenewable} onChange={handleAutoRenewableCheckbox} />
                      <Typography className={[common.HeeboLight, common.textS, common.textDark].join(' ')}>{'Auto-renewable'}</Typography>
                    </Box> */}
                    <Box mb={2.5}>
                      <Typography className={[common.HeeboLight, common.textS, common.textDark, classes.mb5].join(' ')}>{'Benefits'}</Typography>

                      {map(clubDetailsData.benefitsMultiple, (list, i) => (
                        <Box key={i} display='flex' alignItems='center' mb={.5}>
                          <Box flexGrow={1}>
                            <li className={[common.HeeboRegular, common.textM, common.textDark, classes.mb10, classes.mr5].join(' ')}>{list}</li>
                          </Box>
                          <Box mt={-1}>
                            <IconButton className={classes.button}><DeleteOutlineOutlinedIcon color='primary' fontSize='small' onClick={() => handleRemoveBenefits(i)} /></IconButton>
                          </Box>
                        </Box>
                      ))}

                      <Box mb={0.625} display='flex' alignItems='center'>
                        <Box flexGrow={1} pr={1}>
                          <TextField
                            className={[classes.mr5].join(' ')}
                            variant='outlined'
                            size='small'
                            fullWidth
                            inputRef={benefitRef}
                            onKeyDown={handleBenefitKeyPress}
                          />
                        </Box>
                        <Box>
                          <IconButton className={classes.button}><AddOutlinedIcon color='primary' onClick={handleAddBenefits} /></IconButton>
                        </Box>
                      </Box>
                      <Box display='flex' alignItems='center' pt={2}>
                        <Checkbox className={[classes.checkbox].join(' ')} color='primary' checked={isToCreatePromoReq} onChange={handleCreatePromoCheckbox} />
                        <Typography className={[common.HeeboLight, common.textS, common.textDark].join(' ')}>{'Create promotion with updated benefits'}</Typography>
                      </Box>
                    </Box>
                    <Box mb={2.5}>
                      <Typography className={[common.HeeboLight, common.textS, common.textDark, classes.mb5].join(' ')}>{'Club Description'}</Typography>
                      <TextField variant='outlined' size='small' fullWidth multiline rows={3} defaultValue={clubDetailsData?.description} onChange={(e) => {
                        setErrorCollections({ ...errorCollections, description: '' })
                        setClubDetailsData({ ...clubDetailsData, description: e?.target?.value || '' })
                      }} />
                      {errorCollections?.description && <FormHelperText error>{errorCollections?.description}</FormHelperText>}
                    </Box>
                    <Box mb={2.5}>
                      <Typography className={[common.HeeboLight, common.textS, common.textDark, classes.mb5].join(' ')}>{'Locations'}</Typography>
                      {/* <TextField variant='outlined' size='small' fullWidth multiline rows={3} defaultValue={clubDetailsData?.description} onChange={(e) => {
                        setErrorCollections({ ...errorCollections, description: '' })
                        setClubDetailsData({ ...clubDetailsData, description: e?.target?.value || '' })
                      }} /> */}
                      <Box>
                        {map(selectedTags, (d, i) =>
                          <Chip
                            key={i}
                            label={d.name}
                            onClick={() => console.log()}
                            onDelete={() => handleLocations([...selectedTags, d])}
                            className={classes.chip}
                          />
                        )}
                      </Box>
                      <Autocomplete
                        multiple
                        id='tags-outlined'
                        options={breweriesList}
                        size='small'
                        getOptionLabel={(option) => option.name}
                        classes={{ root: classes.locationBox }}
                        value={selectedTags}
                        renderInput={(params) => (
                          <TextField
                            variant='outlined'
                            size='small'
                            {...params}
                            placeholder='Select Locations'
                          />
                        )}
                        renderOption={(option) => option.name}
                        onChange={(e, v) => handleLocations(v)}
                        renderTags={() => ''}
                      />
                      {errorCollections?.locations && <FormHelperText error>{errorCollections?.locations}</FormHelperText>}
                    </Box>
                    <Box mb={2.5}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Typography className={[common.HeeboLight, common.textS, common.textDark, classes.mb5].join(' ')}>{'Badge Icons'}</Typography>
                          <Autocomplete
                            id='tags-outlined'
                            size='small'
                            options={iconsList}
                            getOptionLabel={(option) => option.slug}
                            classes={{ root: classes.locationBox }}
                            // value={clubDetailsData.badgeIconName}
                            renderInput={(params) => {
                              console.log('>>> params', params)
                              params.inputProps.value = ''
                              return (<TextField
                                variant='outlined'
                                size='small'
                                {...params}
                                placeholder=''
                                InputProps={{
                                  ...params.InputProps,
                                  startAdornment: (
                                    <MdiIcon
                                      name={find(iconsList, d => d.slug === clubDetailsData.badgeIconName)?.name}
                                      color={clubDetailsData.badgeIconColor}
                                    />
                                  ),
                                }}
                              />
                              )
                            }}
                            renderOption={(option) => <MdiIcon
                              name={option.name}
                              color={clubDetailsData.badgeIconColor}
                            />}
                            onChange={(e, v) => handlebadgeIcon(v)}
                            renderTags={() => ''}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Typography className={[common.HeeboLight, common.textS, common.textDark, classes.mb5].join(' ')}>{'Badge Color (Ex: "#00FFFF")'}</Typography>
                          <TextField variant='outlined' size='small' fullWidth defaultValue={clubDetailsData?.badgeIconColor} onChange={(e) => {
                            setClubDetailsData({ ...clubDetailsData, badgeIconColor: e?.target?.value || '' })
                          }} />
                        </Grid>
                      </Grid>
                    </Box>
                    <Box mb={2.5}>
                      <Typography className={[common.HeeboLight, common.textS, common.textDark, classes.mb5].join(' ')}>{'Payment Plans'}</Typography>
                      <Box display='flex' alignItems='center'>
                        <Box mb={2.5} marginRight={5}>
                          <InputLabel
                            select
                            disabled={(clubDetails?.id && Number(clubDetails?.totalMemberCount) > 0) ? true : false}
                            label='Select Cadence'
                            name='clubRenewalCadence'
                            value={startCase(toLower(clubDetailsData?.clubRenewalCadence || 'YEARLY'))}
                            options={Object.keys(cadenceOpt)}
                            onChangeValue={(name, value) => setClubDetailsData({ ...clubDetailsData, clubRenewalCadence: cadenceOpt[value] })}
                          />
                        </Box>
                        <TextField type='number' variant='outlined' size='small' defaultValue={clubDetailsData?.annualCost?.replace('USD ', '')} className={[classes.textField].join(' ')} onChange={(e) => {
                          const annualCost = 'USD ' + e?.target?.value?.replace('USD ', '')
                          setClubDetailsData({ ...clubDetailsData, annualCost })
                        }} />
                        <Box ml={1.25}><Typography className={[common.HeeboRegular, common.textM, common.textPrimary, classes.mr20].join(' ')}>USD</Typography></Box>
                      </Box>
                    </Box>
                    {/* <Icon>shield-star</Icon>
                      <Icon style={{ color: 'green' }}>glass-mug</Icon>
                      <Icon>diamond-stone</Icon> */}
                    <AgButton
                      label={clubDetails?.id || clubDetailsData?.id ? 'Update' : 'Add'}
                      color={'#FA735F'}
                      textColor={'#FFFFFF'}
                      fullWidth
                      loading={updatingClub || addingClub || uploadingFile}
                      onClick={handleButtonClick} />
                  </Box>
              }
            </div>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

const customStyles = makeStyles(CommanClasses)

const useStyles = makeStyles((theme) => ({
  confirmButton: {
    width: '100%',
    background: `${Color.main} 0% 0% no-repeat padding-box`,
    border: `1px ${Color.inputBorder}`,
    borderRadius: 15,
    opacity: 1,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: Color.main,
      boxShadow: 'none',
    },
  },
  smallBox: {
    maxHeight: 40,
    maxWidth: 80,
    padding: 10,
    marginTop: 10,
  },
  checkbox: {
    borderRadius: '5px',
    marginRight: 2,
    padding: 0,
  },
  buttonUpload: {
    width: 423,
    height: 233,
    background: `${Color.main} 0% 0% no-repeat padding-box`,
    border: `1px ${Color.inputBorder}`,
    borderRadius: 15,
    opacity: 1,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: Color.main,
      boxShadow: 'none',
    },
  },
  button: {
    background: `${Color.main} 0% 0% no-repeat padding-box`,
    border: `1px ${Color.inputBorder}`,
    width: 30,
    height: 30,
    borderRadius: 15,
    opacity: 1,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: Color.main,
      boxShadow: 'none',
    }
  },
  mr20: {
    marginRight: 20
  },
  mb5: {
    marginBottom: 5,
  },
  mb10: {
    marginBottom: 10,
  },
  mr5: {
    marginRight: 5,
  },
  textField: {
    width: 133,
    background: `${Color.white} 0% 0% no-repeat padding-box`,
    border: `1px solid ${Color.inputBorder}`,
    borderRadius: 5,
    opacity: 1,
  },
  rootTextField: {
    background: `${Color.white} 0% 0% no-repeat padding-box`,
    border: `1px solid ${Color.inputBorder}`,
    borderRadius: 5,
    opacity: 1,
  },
  dot: {
    height: 15,
    width: 15,
    backgroundColor: Color.primary,
    borderRadius: '50%',
    marginRight: 5,
  },
  dot2: {
    height: '5px',
    width: '5px',
    backgroundColor: 'black',
    borderRadius: '50%',
    display: 'inline-block',
    marginRight: 10,
  },
  drawerBody: {
    position: 'sticky',
    top: 0,
    zIndex: 2,
    padding: '16px 0'
  },
  locationBox: {
    padding: '0px !important'
  },
  chip: {
    margin: theme.spacing(1)
  }
}))

export default ClubsManageDrawer
