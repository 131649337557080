import gql from 'graphql-tag'

export const GetUserFields = gql`
  query getUserDetails(
    $breweryId: ID!
    $page: PaginationQuery
    $order: MembershipQueryOrder
    $expirationTime: TimeQuery
    $isActive: Boolean
    $mugClubIds: [String]
    ) {
    brewery(id: $breweryId) {
      success
      brewery {
        membersConnection(
            mugClubIds:$mugClubIds
            page: $page
            order: $order
            expirationTime: $expirationTime
            isActive: $isActive
        ) {
          pageInfo {
            hasMore
            totalCount
          }
          edges {
            cursor
            joinDate
            membershipExpirationDate
            active
            memberId
            primaryMemberId
            primaryMember
              {
                id
                fullName
                joinedAt
                photosConnection {
                  edges {
                    image {
                    id
                    url
                    }
                    }
                }
              } 
            notes {
              notes
              addedBy {
                email
              }
              dateAdded
            }
            communityMember {
              id
              fullName
              email
              phoneNumber
              joinedAt
              dateOfBirth
              consumerBreweryPreferences{
                beerTypes
                foodTypes
                venueTypes
                spiritsTypes
                additionalTypes
                coffeeTypes
                meadTypes
                wineTypes
                beerType
                foodType
                atmosphere
                eventTypes
              }
              photosConnection {
                edges {
                  image {
                    url
                    tags
                    altText
                  }
                }
              }
              brewerySharesConnection {
                edges {
                  brewery {
                    id
                  }
                  sharedInfo
                }
              }
            }
          }
        }
        mugClubs {
          id
          name
        }
      }
    }
  }
`
