import React from 'react'
import { CircularProgress, Box, Typography, Grid } from '@material-ui/core'
import Color from '../themes/Colors'
import { makeStyles } from '@material-ui/core/styles'
import CommanClasses from '../themes/CommonClasses'
const customStyles = makeStyles(CommanClasses)
const useStyles = makeStyles((theme) => ({
    progressBox: {
        borderRadius: 35,
        backgroundColor: Color.aliceBlue,
    }
}))
function ProgressCircle(props) {
    const common = customStyles(CommanClasses)
    const classes = useStyles()
    return (
        // <Grid container alignItems='center'>
        //     <Grid item>
        //         <Box position='relative' display='inline-flex' >
        //             <CircularProgress variant='static' value={props.percentage} {...props} color='primary'/>
        //             <Box
        //                 top={0}
        //                 left={0}
        //                 bottom={0}
        //                 right={0}
        //                 position='absolute'
        //                 display='flex'
        //                 alignItems='center'
        //                 justifyContent='center'
        //             >
        //                 <Typography variant='caption' component='div' color='textSecondary' className={[common.HeeboMedium].join(' ')}>{props.num}</Typography>
        //             </Box>
        //         </Box>
        //     </Grid>
        //     <Grid item>
        //         <Box pb={0.5} ml={1}>
        //             <Typography className={[common.HeeboMedium].join(' ')}>
        //                 {`${props.tabLabel.charAt(0).toUpperCase()}${props.tabLabel.slice(1).toLowerCase()}`}
        //             </Typography>
        //         </Box>
        //     </Grid>
        // </Grid>
        // -------------------------
        <Grid container alignItems='center'>
            <Grid item>
                <Box position='relative' display='inline-flex' className={[classes.progressBox].join(' ')}>
                    <CircularProgress variant='static' value={props.percentage} color={props.percentage === 100 ? 'secondary' : 'primary'} size={30} />
                    <Box
                        top={0}
                        left={0}
                        bottom={0}
                        right={0}
                        position='absolute'
                        display='flex'
                        alignItems='center'
                        justifyContent='center'
                    >
                        <Typography variant='caption' component='div' color='textSecondary' className={[common.HeeboMedium, common.textXl].join(' ')}>{props.num}</Typography>
                    </Box>
                </Box>
            </Grid>
            <Grid item>
                <Box pb={0.5} ml={1.87}>
                    <Typography className={[common.HeeboMedium, common.textXxl].join(' ')}>
                        {`${props.tabLabel.charAt(0).toUpperCase()}${props.tabLabel.slice(1).toLowerCase()}`}
                    </Typography>
                </Box>
            </Grid>
        </Grid>
    )
}

export default ProgressCircle