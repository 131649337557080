import React from 'react'
import { Card, Grid, Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CommanClasses from '../themes/CommonClasses'

const customStyles = makeStyles(CommanClasses)
const useStyles = makeStyles((theme) => ({
  oneLineText: {
    whiteSpace: 'nowrap'
  },
  contentContainer: {
    height: 'calc(100% - 40px)',
    overflowY: 'auto'
  },
  boxContainer: {
    height: 'calc(100% - 40px)',
  }
}))

export default (props) => {
  const {
    id,
    cardHeader,
    action,
    cardContent,
    noSpacing = false,
    secondary,
    onClick,
    fullHeight,
    feedBackSection,
    handleAction = () => null,
  } = props

  const common = customStyles(CommanClasses)
  const classes = useStyles()

  return (
    <Card
      id={id}
      elevation={0}
      classes={{ root: common.borderRadiusM }}
      className={[secondary ? common.bgInfo : common.bgWhite, fullHeight ? common.h_100 : ''].join(' ')}
      onClick={onClick}
    >
      <Box p={!noSpacing ? 2.5 : 0} className={feedBackSection && fullHeight ? classes.boxContainer : ''}>
        <Grid container className={feedBackSection && fullHeight ? common.h_100 : ''}>
          <Grid item xs={12}>
            {(cardHeader || action) && (
              <Box pb={0.5}>
                <Grid container>
                  {cardHeader && (
                    <Grid item xs>
                      <div
                        className={[
                          common.textDark,
                          common.HeeboMedium,
                          common.textXl,
                          classes.oneLineText
                        ].join(' ')}
                      >
                        {cardHeader}
                      </div>
                    </Grid>
                  )}
                  {action && (
                    <Grid item>
                      <div
                        className={[common.textLightRed, common.pointer].join(
                          ' '
                        )}
                        onClick={() => handleAction()}
                      >
                        {action}
                      </div>
                    </Grid>
                  )}
                </Grid>
              </Box>
            )}
          </Grid>
          <Grid item xs={12} className={feedBackSection ? classes.contentContainer : ''}>
            {cardContent}
          </Grid>
        </Grid>
      </Box>
    </Card>
  )
}
