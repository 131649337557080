import React, { useEffect, useState } from "react";
import { ApolloProvider, useMutation } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import { createApolloClient } from "../apollo/apollo-client";
import { AuthenticatedContainer } from "./AuthenticatedContainer";
import {
  storeApollo,
  storeBreweryId,
  storeBreweryName,
  storeMerchantUser,
} from "../Services/local";
import { storeAllDetails } from '../Services/commonFunctions'
import { putMerchantUserGql } from "../graphql/putMerchantUserGql";
import { LinearProgress } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { isEqual, uniqWith } from "lodash";

const AuthenticatedApp = () => {
  const { enqueueSnackbar } = useSnackbar();

  const [apolloClient, setApolloClient] = useState();
  const { user, isLoading, error, getAccessTokenSilently } = useAuth0({
    audience: process?.env?.REACT_APP_API_HOST,
    scope: "openid profile email",
  });

  console.log("user??", user);

  useEffect(() => {
    const getAccessToken = async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          audience: process.env.REACT_APP_API_HOST,
          scope: "read:current_user",
        });
        console.log("accessToken??", accessToken);
        const client = createApolloClient(accessToken, enqueueSnackbar);
        setApolloClient(client);
        storeApollo(client);
      } catch (err) {
        throw err;
      }
    };
    getAccessToken();
  }, []);

  if (!apolloClient) {
    return (
      <div>
        <LinearProgress />
      </div>
    );
  }

  if (isLoading) {
    return (
      <div>
        <LinearProgress />
      </div>
    );
  }

  if (error) {
    console.error(error);
    return "Error occured.";
  }

  const InitialAuthContainer = () => {
    const [foundBreweryId, setFoundBreweryId] = useState("");

    async function fetchData() {
      // You can await here as it is a good practice to do async call
      await putMerchantUser({
        variables: {
          auth0Sub: user?.sub,
          email: user?.email,
        },
      });
    }

    const [
      putMerchantUser,
      { data: meData, error: meDataError, loading: meDataLoading },
    ] = useMutation(putMerchantUserGql, {
      onCompleted: (resp) => {
        // console.log("putMerchantUser resp??", resp);
        let breweryId =
          resp?.putMerchantUser?.merchantUser?.ownedBreweriesConnection
            ?.edges?.[0]?.brewery?.id;
        let breweryName =
          resp?.putMerchantUser?.merchantUser?.ownedBreweriesConnection
            ?.edges?.[0]?.brewery?.name;
        let merchantUserId = resp?.putMerchantUser?.merchantUser?.id;
        let merchantUserEmail = resp?.putMerchantUser?.merchantUser?.email;
        // let breweries =
        //   resp?.putMerchantUser?.merchantUser?.ownedBreweriesConnection?.edges;
        // getAllClubsFromBreweries(breweries);

        if (breweryId) {
          storeBreweryId(breweryId);
          storeBreweryName(breweryName);
          storeMerchantUser({ id: merchantUserId, email: merchantUserEmail });
          // storeBreweries(breweries);
          setFoundBreweryId(breweryId);
          storeAllDetails(resp)
        } else {
          fetchData();
        }
      },
    });

    useEffect(() => {
      if (!foundBreweryId) {
        fetchData();
      }
    }, []);

    if (meDataLoading || meDataError || !meData || !foundBreweryId) {
      return (
        <div>
          <LinearProgress />
        </div>
      );
    }

    return <AuthenticatedContainer user={user} />;
  };

  return (
    <ApolloProvider client={apolloClient}>
      <InitialAuthContainer />
    </ApolloProvider>
  );
};

export default AuthenticatedApp;
