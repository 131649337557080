import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import CommanClasses from '../themes/CommonClasses'
import { TextField, Grid, Typography, FormControl, Select, FormHelperText, Link } from '@material-ui/core'
import Color from '../themes/Colors'
import { map, find } from 'lodash'
import MenuItem from '@material-ui/core/MenuItem';

//props required: label(to give label),read(in case its a readonly),select(incase its a select box),options(in case its a select box),onChangeValue(to give )

const InputLabel = (props) => {
  const {
    onChangeValue = () => null,
    label,
    mandatory,
    select,
    error,
    name,
    value,
    options,
    read,
    url,
    type,
    readValue,
    multiline,
    autoFocus,
    maxLength,
    multiple,
    wrap,
    ...otherProps
  } = props || {}

  const classes = useStyles()
  const common = customStyles(CommanClasses)
  const getReqiredLink = (link) => {
    if (link.includes('https://', 0) || link.includes('http://', 0)) {
      return link
    } else {
      return 'https://' + link
    }
  }

  return (
    <Grid >
      <Label label={label} mandatory={mandatory} />
      {select && !multiple ?
        <FormControl classes={{
          root: classes.selectRoot
        }} size='small' fullWidth variant='outlined' className='select_box' error={error}>
          <Select
            native
            onChange={(e) => onChangeValue(e.target.name, e.target.value)}
            name={name}
            value={value}
            {...otherProps}
          >
            {
              options.map((option, idx) =>
                <option value={option} key={idx}>{option?.name || option}</option>
              )
            }
          </Select>
          {error && <FormHelperText>Required *</FormHelperText>}
        </FormControl>
        :
        select && multiple ?
          <FormControl classes={{
            root: classes.selectRoot
          }} size='small' fullWidth variant='outlined' className='select_box' error={error}>
            <Select
              labelId="demo-mutiple-checkbox-label"
              id="demo-mutiple-checkbox"
              multiple
              value={value}
              name={name}
              onChange={(e, v) => onChangeValue(e.target.name, e.target.value)}
              renderValue={() => map(value, obj => obj.name).join(", ")}
            >
              {options.map((data, idx) => (
                <MenuItem key={idx} value={data} className={find(value, v => v?.id === data?.id) ? classes.selectedOption : ''}>
                  {data.name}
                </MenuItem>
              ))}
            </Select>
            {error && <FormHelperText>Required *</FormHelperText>}
          </FormControl>
          :
          read && url ?
            <Link href={getReqiredLink(readValue)} target='_blank' className={[common.HeeboRegular, classes.wordBreak, common.textM].join(' ')} >
              {readValue}
            </Link>
            :
            read ?
              <Typography
                style={{ whiteSpace: 'pre-line' }}
                className={[common.HeeboRegular, url ? common.textPrimary : common.textDark, common.textM, wrap ? common.overflowWrap : ''].join(' ')}
              >
                {readValue}
              </Typography>
              :
              multiline ?
                <TextField
                  className={[classes.textField].join(' ')}
                  variant='outlined'
                  size='small'
                  fullWidth
                  onChange={(e) =>
                    onChangeValue(e.target.name, e.target.value)
                  }
                  name={name}
                  value={value}
                  multiline
                  rows={multiline}
                  autoFocus={autoFocus ? true : false}
                  error={error}
                  helperText={error && 'Required *'}
                  inputProps={{ maxLength: maxLength }}
                  {...otherProps}
                />
                :
                <TextField
                  className={[classes.textField].join(' ')}
                  variant='outlined'
                  size='small'
                  fullWidth
                  onChange={(e) =>
                    onChangeValue(e.target.name, e.target.value)
                  }
                  name={name}
                  value={value}
                  type={type}
                  autoFocus={autoFocus ? true : false}
                  error={error}
                  helperText={error && 'Required *'}
                  inputProps={{ maxLength: maxLength }}
                  {...otherProps}
                />
      }
    </Grid>
  )
}

const customStyles = makeStyles(CommanClasses)

const Label = (props) => {
  const { label, mandatory } = props || {}
  const common = customStyles(CommanClasses)
  return (
    <Grid container>
      <Grid item>
        <Typography
          className={['HeeboLight', common.textDark, common.textS].join(' ')}
          gutterBottom>
          {label}
        </Typography>
      </Grid>
      {mandatory ? <Grid item className={common.textDanger}>&nbsp*</Grid> : ''}
    </Grid>
  )
}

const useStyles = makeStyles((theme) => ({
  selectRoot: {
    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${Color.inputBorder}`,
      borderRadius: 5
    },
  },
  textField: {
    background: `${Color.white} 0% 0% no-repeat padding-box`,
    // border: `1px ${Color.inputBorder}`,
    borderColor: Color.inputBorder,
    borderRadius: 5,
    opacity: 1,
    '& .MuiOutlinedInput-root': {
      border: `1px ${Color.inputBorder}`,
      borderRadius: 5,
    }
  },
  wordBreak: {
    wordBreak: 'break-all'
  },
  selectedOption:{
    backgroundColor:'#eee'
  }
}))

export default InputLabel
