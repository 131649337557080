import React, { useState, useEffect } from 'react'
import { Grid, Box } from '@material-ui/core'
import { map, find, isEmpty } from 'lodash'
import { useQuery } from '@apollo/client'
import { BREWERY_PREFERENCES } from '../graphql/breweryPreferences'
import { BREWERY_DOMAIN_WHITE_BOARD } from '../graphql/breweryDomainWhiteboard'
import BarChart from '../components/BarChart'
import AGCard from '../components/AGCard'
import AGIcon from '../components/AGIcon'
import { preferencesTypes } from '../common'
import DoughnutLabel from "../components/DoughnutLabel";
import Color from "../themes/Colors";
// import DoughnutLabel from '../components/DoughnutLabel'
import DropDown from '../components/Dropdown'
const keyType = 'consumerPreferenceValues'

export default (props) => {
  const { breweryId } = props
  const windowWidth = window.innerWidth
  const [prefrenceType, setPrefrenceType] = useState('Beer')
  const [breweryPref, setBreweryPref] = useState()
  const [allPref, setAllPref] = useState()
  const [sizeVal, setsizeVal] = useState(windowWidth < 600 ? 200 : 140)
  const [dataLabels, setDataLabels] = useState([])
  const [prefDataSet, setPrefDataSet] = useState([])

  const {
    data: prefrenceData,
    error: prefrenceError,
    loading: prefrenceLoading,
  } = useQuery(BREWERY_PREFERENCES, {
    variables: {
      breweryId,
    },
    onCompleted: (resp) => {
      if (resp)
        setBreweryPref(
          resp.brewery?.brewery?.aggregateFollowingConsumerBreweryPreferences
        )
    },
    notifyOnNetworkStatusChange: true,
  })

  const {
    data: allPrefrenceData,
    error: allPrefrenceError,
    loading: allPrefrenceLoading,
  } = useQuery(BREWERY_DOMAIN_WHITE_BOARD, {
    variables: {
      keyType: keyType,
    },
    onCompleted: (resp) => {
      if (resp) {
        setAllPref(
          JSON.parse(
            find(
              resp.breweryDomainWhiteboard?.edges,
              (data) => data.key === keyType
            ).value
          )
        )
      }
    },
    notifyOnNetworkStatusChange: true,
  })

  const getResults = (e) => {
    let pp = []
    switch (e) {
      case 'Beer':
        pp = map(allPref?.beerTypes, (data) => data)
        setDataLabels(pp)
        dataTobeSent(breweryPref, pp, 'beerTypes')
        break
      case 'Food':
        pp = map(allPref?.foodTypes, (data) => data)
        setDataLabels(pp)
        dataTobeSent(breweryPref, pp, 'foodTypes')
        break
      case 'Venue':
        pp = map(allPref?.venueTypes, (data) => data)
        setDataLabels(pp)
        dataTobeSent(breweryPref, pp, 'venueTypes')
        break
      case 'Spirits':
        pp = map(allPref?.spiritsTypes, (data) => data)
        setDataLabels(pp)
        dataTobeSent(breweryPref, pp, 'spiritsTypes')
        break
      case 'Additional':
        pp = map(allPref?.additionalTypes, (data) => data)
        setDataLabels(pp)
        dataTobeSent(breweryPref, pp, 'additionalTypes')
        break
      case 'Coffee':
        pp = map(allPref?.coffeeTypes, (data) => data)
        setDataLabels(pp)
        dataTobeSent(breweryPref, pp, 'coffeeTypes')
        break
      case 'Mead':
        pp = map(allPref?.meadTypes, (data) => data)
        setDataLabels(pp)
        dataTobeSent(breweryPref, pp, 'meadTypes')
        break
      case 'Wine':
        pp = map(allPref?.wineTypes, (data) => data)
        setDataLabels(pp)
        dataTobeSent(breweryPref, pp, 'wineTypes')
        break
      case 'Cider':
        pp = map(allPref?.ciderTypes, (data) => data)
        setDataLabels(pp)
        dataTobeSent(breweryPref, pp, 'ciderTypes')
        break
    }
  }

  const dataTobeSent = (newBrewPref, labels, typ) => {
    let rData = {},
      pCount = []
    map(newBrewPref, (data) => {
      let typeP = data.key.substr(0, data.key.indexOf('.'))
      let category = data.key.substring(data.key.indexOf('.') + 1)
      rData[typeP] = { ...rData[typeP], [category]: data.count }
    })
    map(labels, (data) => {
      pCount = [...pCount, rData?.[typ]?.[data?.token] || 0]
    })
    setPrefDataSet(pCount)
  }

  useEffect(() => {
    if (!isEmpty(allPref) && !isEmpty(prefrenceType) && !isEmpty(breweryPref))
      getResults(prefrenceType)
  }, [allPref, prefrenceType, breweryPref])

  return (
    <AGCard
      // action={<DoughnutLabel label='Member Selection' color='#F9D089' />}
      fullHeight
      action={
        <Grid container spacing={2} justify="center">
          <Grid item>
            <DoughnutLabel
              label={"Member Selection"}
              number={<AGIcon color={Color.yellow} size={12} />}
              color="#EA952D"
            />
          </Grid>
          <Grid item>
            {/* <DoughnutLabel
              label={"Non-Member Selection"}
              number={<AGIcon color={Color.yellow} size={12} />}
              color="#273179"
            /> */}
          </Grid>
        </Grid>}
      cardHeader={
        <Grid container spacing={2} alignItems='center'>
          <Grid item>
            <Box mr={1}>Preferences</Box>
          </Grid>
          <Grid item>
            <Box>
              <DropDown
                // noText
                size={'small'}
                icon={<AGIcon name={'icon-Up'} size={6} />}
                color='primary'
                variant={'outlined'}
                placement='bottom'
                initialValue={'Beer'}
                list={map(preferencesTypes, (data) => data.label)}
                selectedValue={(e) => setPrefrenceType(e)}
              />
            </Box>
          </Grid>
        </Grid>
      }
      cardContent={
        <BarChart
          xAxesLabel='Category'
          yAxesLabel='Number of Selections'
          colors={['#EA952D']}
          stepSize={20}
          height={sizeVal}
          labels={map(dataLabels, (data) => data.label)}
          datasets={[
            {
              label: 'Number of selections',
              data: prefDataSet,
            }
          ]}
        />
      }
    />
  )
}
