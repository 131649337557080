import { colors } from "@material-ui/core";
import { Height } from "@material-ui/icons";
import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";

export default (props) => {
  const [chartData, setchartData] = useState();

  const getChartData = (data) => {
    const a = data;
    if (a) {
      let colors = props.colors;
      data.forEach((element, i) => {
        element.backgroundColor = colors[i];
        element.barThickness = 10;
        // element.borderWidth = 2;
        element.pointBorderColor = "none";
        element.lineTension = 0;
      });
    }
    return a;
  };

  const options = {
    responsive: true,
    legend: {
      display: false,
    },
    title: {
      display: props.title ? true : false,
      text: props.title,
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            color: "rgba(0, 0, 0, 0)",
          },
          scaleLabel: {
            display: props.xAxesLabel ? true : false,
            labelString: props.xAxesLabel,
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            color: "rgba(0, 0, 0, 0)",
          },
          ticks: {
            stepSize: props.stepSize ? props.stepSize : 20,
            beginAtZero: true,
						// max:1000,
            min: 0,
          },
          scaleLabel: {
            display: props.yAxesLabel ? true : false,
            labelString: props.yAxesLabel,
          },
        },
      ],
    },
  };
  const chart = () => {
    setchartData({
      labels: props.labels,
      datasets: getChartData(props.datasets),
    });
  };
  useEffect(() => {
    chart();
  }, [props.labels]);

  return (
    <div>
      <Bar data={chartData || {}} height={props.height} options={options} />
    </div>
  );
};
