import React from 'react'
import { Grid, Hidden } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CommanClasses from '../themes/CommonClasses'
import ProfileBox from '../containers/ProfileBox'
import Pageheader from '../components/Pageheader'

export default (props) => {

  const common = customStyles()
  const classes = usestyles()

  return (
    <Grid
      container
      alignItems='center'
      className={[classes.header, common.bgMain].join(' ')}
    >
      <Grid item xs={12} sm>
        <Grid container alignItems='center'>
          <Grid item xs >
            <Pageheader
              headerLogo={props.headerLogo}
              headerLogoAlt={props.headerLogoAlt}
              info={props.info}
              pageHeader={props.pageHeader}
              actions={props.actions}
              endActions={props.endActions}
              showGoLiveButton={props.showGoLiveButton}
              onClickInfo={props.onClickInfo}
            />
          </Grid>
        </Grid>
      </Grid>
      <Hidden smDown>
        <Grid item>
          <ProfileBox />
        </Grid>
      </Hidden>
    </Grid>
  )
}

const customStyles = makeStyles(CommanClasses)

const usestyles = makeStyles((theme) => ({
  header: {
    position: 'sticky',
    top: 0,
    height: 80,
    maxHeight: 80,
    zIndex: 1,
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
      maxHeight: 'fit-content',
    },
  },
  buttonIcon: {
    height: 30,
    width: 30,
  },
  alignIcon: {
    margin: '3px 0 0 3px',
  },
}))

