import React, { useState, useEffect } from "react";
import { Box, Grid } from "@material-ui/core";
import { map, get } from 'lodash'
import { useLazyQuery } from "@apollo/client";
import { GetClubFields } from "../graphql/clubDetailsGQL";
import LineChart from "../components/LineChart";
import AGCard from "../components/AGCard";
import AGIcon from "../components/AGIcon";
import moment from "moment";
import DropDown from "../components/Dropdown";
import { __years } from "../Services/local";

export default (props) => {
  const { breweryId } = props;
  const windowWidth = window.innerWidth;

  const sizeVal = windowWidth < 600 ? 200 : 140;

  ///Revenue Graph///
  const [allGraphData, setAllGraphdata] = useState([]);
  const [filterGraphStr, setFilterGraphStr] = useState(moment().year());

  let defaultCounts = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

  const [getClubFields, { data: clubDatas }] = useLazyQuery(GetClubFields, {
    variables: {
      breweryId,
    },
    onCompleted: (resp) => {
      if (!resp) {
        return;
      }

      let output = [];

      map(get(resp,'brewery.brewery.mugClubs'),(dt) => {
        map(get(dt, "activeMembers.activeMembersStats"), (d) => {

          if (!output[d.year]) {
            output[d.year] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
          }

          output[d.year][getM(d.month)] =
            (output[d.year][getM(d.month)] || 0) + parseInt(d.newJoined);
        });
      });

      setAllGraphdata(output);
    },

    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    getClubFields({
      variables: {
        breweryId,
      },
    });
  }, [breweryId]);

  const setSelectedYear = (year) => {
    setFilterGraphStr(year);
  };

  const getM = (m) => {
    const monthIdx = [
      "jan",
      "feb",
      "mar",
      "apr",
      "may",
      "jun",
      "jul",
      "aug",
      "sep",
      "oct",
      "nov",
      "dec",
    ];

    const idx = monthIdx.findIndex((m1) => m1 == m?.toLowerCase());

    console.log("Month is", idx);

    return idx;
  };

  return (
    <AGCard
      fullHeight
      cardHeader={
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <Box mr={1}>Membership Overview</Box>
          </Grid>
          <Grid item>
            <Box>
              <DropDown
                size={"small"}
                icon={<AGIcon name={"icon-Up"} size={6} />}
                color="primary"
                variant={"outlined"}
                placement="bottom"
                initialValue={filterGraphStr}
                list={__years}
                selectedValue={(e) => setSelectedYear(e)}
              />
            </Box>
          </Grid>
        </Grid>
      }
      cardContent={
        <Grid container>
          <Grid item lg={12}>
            <Box mt={1}>
              <LineChart
                colors={["#273179", "#FF826E"]}
                stepSize={100}
                height={sizeVal}
                yAxesLabel={"Number of Members"}
                xAxesLabel={"Months"}
                labels={[
                  "Jan",
                  "Feb",
                  "Mar",
                  "Apr",
                  "May",
                  "Jun",
                  "Jul",
                  "Aug",
                  "Sep",
                  "Oct",
                  "Nov",
                  "Dec",
                ]}
                datasets={[
                  {
                    label: "Total Members",
                    data: allGraphData[filterGraphStr] || defaultCounts,
                  },
                ]}
              />
            </Box>
          </Grid>
        </Grid>
      }
    />
  );
};
