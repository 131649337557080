import gql from "graphql-tag";

export const UPDATE_USER_DETAILS = gql`
  mutation updateUserDetails(
    $breweryId: String!
    $communityMemberId: String!
    $membershipProgramId: String!
    $membershipProgramIds:[String]
    $expiringDate: String
    $joiningDate: String
    $notes: String
    $memberId: String
    $primaryMemberId: String
    $disAssociatePrimaryMember: Boolean
    $addedOn: String
  ) {
    updateMembership(
      breweryId: $breweryId
      communityMemberId: $communityMemberId
      membershipProgramId: $membershipProgramId
      membershipProgramIds: $membershipProgramIds
      membershipExpirationDate: $expiringDate
      membershipJoiningDate: $joiningDate
      note: $notes
      memberId: $memberId
      primaryMemberId: $primaryMemberId
      disAssociatePrimaryMember: $disAssociatePrimaryMember
      dateAdded: $addedOn
    ) {
      memberId
    }
  }
`;
