import React from 'react'
import { Card, Grid, Typography, Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CommanClasses from '../themes/CommonClasses'
import SimpleTextCard from './SimpleTextCard'
import Image from './Image'
import AGCard from './AGCard'
import { isUndefined } from 'lodash'
import { imageWidthParams } from '../Services/local'

const customStyles = makeStyles(CommanClasses)
const useStyles = makeStyles((theme) => ({
  longAndTruncated: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    width: '100%',
    height: '1.4em',
    whiteSpace: 'nowrap'
  },
}))

export default (props) => {
  const {
    header,
    active,
    redumptions,
    header2,
    sideAction,
    sideAction2,
    offers,
    handleAction,
    handleAction2,
    itemAction,
    isClub,
    handleItemAction,
  } = props

  const common = customStyles(CommanClasses)
  const classes = useStyles(CommanClasses)

  return (
    <AGCard
      fullHeight
      handleAction={() => handleAction()}
      cardHeader={header}
      action={sideAction}
      cardContent={
        <Grid container>
          <Grid item xs={12}>
            {header ?
              <Grid container spacing={2}>
                {!isUndefined(active) && (
                  <Grid item xs className={common.textCenter}>
                    <SimpleTextCard
                      variant='column'
                      number={active || 0}
                      text='Active'
                    />
                  </Grid>
                )}
                {!isUndefined(redumptions) && (
                  <Grid item xs className={common.textCenter}>
                    <SimpleTextCard
                      variant='column'
                      number={redumptions || 0}
                      text='Redemptions'
                    />
                  </Grid>
                )}
              </Grid>
              : ''}
          </Grid>
          <Box
            pb={0.5}
            pt={isClub ? 2 : 0}
            className={common.w_100}
          >
            <Grid item xs={12} container justify='space-between'>
              <Grid item>
                <Typography
                  className={[
                    common.textDark,
                    common.HeeboMedium,
                    common.textXl,
                  ].join(' ')}
                >
                  {header2}
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  className={[common.textLightRed, common.pointer].join(' ')}
                  onClick={() => handleAction2()}
                >
                  {sideAction2}
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Grid item xs={12} container justify='space-between'>
            <Grid item xs={12}>
              {offers.map((data, idx) => (
                <Box py={0.5} key={idx}>
                  <Card
                    elevation={0}
                    classes={{ root: common.borderRadiusM }}
                    className={common.bgInfo}
                  >
                    <Grid container>
                      <Grid item xs>
                        <Grid container wrap="nowrap">
                          <Grid item>
                            <Image src={data.src + imageWidthParams(80)} lg radiusLg />
                          </Grid>
                          <Grid item xs zeroMinWidth>
                            <Box p={2}>
                              <Grid
                                container
                                justify='space-between'
                                alignItems='center'
                                className={common.h_100}
                              >
                                <Grid item xs={12}>
                                  <Typography
                                    component='p'
                                    className={[
                                      common.HeeboMedium,
                                      common.textXl,
                                      classes.longAndTruncated,
                                    ].join(' ')}
                                  >
                                    {data.offerName}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                  <Typography
                                    component='span'
                                    className={[
                                      common.textLight,
                                      common.textM,
                                      common.HeeboLight,
                                    ].join(' ')}
                                  >
                                    {'Redeemed: '}
                                  </Typography>
                                  <Typography
                                    component='span'
                                    className={[
                                      common.textLight,
                                      common.HeeboMedium,
                                      common.textM,
                                    ].join(' ')}
                                  >
                                    {data.reedemNo || 0}
                                  </Typography>
                                </Grid>

                              </Grid>
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>
                      {itemAction && (
                        <Grid item>
                          <Box pt={1.2} pr={2} onClick={() => handleItemAction ? handleItemAction(data.offerName) : null} >
                            {itemAction}
                          </Box>
                        </Grid>
                      )}
                    </Grid>
                  </Card>
                </Box>
              ))}
              {
                !offers?.length ? 'No promotion found' : ''
              }
            </Grid>
          </Grid>
        </Grid>
      }
    />
  )
}
