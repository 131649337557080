import React from 'react'
import { Box, Chip } from '@material-ui/core'
import { intersection, keys, get, map } from 'lodash'
import Color from '../themes/Colors'

const AgChip = (props) => {
	const {
		label,
		sublabel,
		component,
		onClick,
		...otherProps
	} = props

	const styles = {
		noBorder: {
			fontSize: '14px',
			color: Color.light,
			textTransform: 'none',
			// width: '5%',
			// border: `1px solid #EA952D`,
			alignItems: 'center',
			backgroundColor: '#F7FAFF',
		},
		orborder: {
			fontSize: '14px',
			color: Color.light,
			textTransform: 'none',
			// width: '5%',
			border: '1px solid #EA952D',
			alignItems: 'center',
			backgroundColor: '#FFFFFF',
		},
		orborder1: {
			fontSize: '14px',
			color: Color.light,
			textTransform: 'none',
			// width: '5%',
			border: '1px solid #EA952D',
			alignItems: 'center',
			backgroundColor: '#F7FAFF',
			// borderRadius: 0,
		},
		pelborder: {
			fontSize: '14px',
			color: Color.light,
			textTransform: 'none',
			// width: '5%',
			border: '1px solid #2290CF',
			alignItems: 'center',
			backgroundColor: '#F7FAFF',
			// borderRadius: 0,
		},
		drkborder: {
			fontSize: '14px',
			color: Color.light,
			textTransform: 'none',
			// width: '5%',
			border: '1px solid #374785',
			alignItems: 'center',
			backgroundColor: '#F7FAFF',
			// borderRadius: 0,
		},
		orngborder: {
			fontSize: '14px',
			color: Color.light,
			textTransform: 'none',
			// width: '5%',
			border: '1px solid #FB6A55',
			alignItems: 'center',
			backgroundColor: '#F7FAFF',
			// borderRadius: 0,
		},
		button: {
			backgroundColor: Color.white,
			border: `1px solid ${Color.primary}`,
			textTransform: 'none',
			alignItems: 'center',
		},
	}

	const matchingStyles = intersection(keys(otherProps), keys(styles)).map((s) =>
		get(styles, s)
	)

	let finalStyles = {}

	map(matchingStyles, (ms) => {
		finalStyles = { ...finalStyles, ...ms }
	})

	return (
		<Box>
			<Chip
				label={label}
				style={finalStyles}
				component={component || ''}
				onClick={onClick}
				{...otherProps}
			/>
		</Box>
	)
}

export default AgChip
