import React from 'react'
import LineChart from '../components/LineChart'
import AGCard from '../components/AGCard'
import InfoBox from '../components/ClubDataInfoBox'
import { Grid } from '@material-ui/core'
import { map } from 'lodash'
import DoughnutLabel from '../components/DoughnutLabel'
import Colors from '../themes/Colors'
import AGIcon from '../components/AGIcon'
export default (props) => {
  const {
    cardHeader = 'Card Header',
    graphData = {},
    topData = []
  } = props || {}
  // const colorD = ['#FF281B', '#00C717', '#EC952D', '#6200EE', '#03DAC6', '#FFFF00', '#B00020', '#FF0266']
  const colorD = map(graphData?.datasets, data => data.color) || []
  return (
    <AGCard
      fullHeight
      cardHeader={cardHeader}
      cardContent={
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Grid container>
              {
                map(topData, ({ subTitle, title }, idx) => (
                  <Grid key={idx} item xs={topData?.length > 2 ? 4 : 6}>
                    <InfoBox label={subTitle} data={title} noBorder={topData.length - 1 === idx ? true : false} />
                  </Grid>
                ))
              }
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <LineChart
              gradient
              colors={colorD}
              stepSize={20}
              // grad
              // height={100}
              // width={100}
              {...graphData}

            />
          </Grid>
          <Grid container spacing={2} justify='center'>
            {map(props.selectedClubs, (sc, idx) => <Grid item key={idx}>
              <DoughnutLabel  label={sc.name} color={sc.badgeIconColor || '#000000'} />
            </Grid>
            )}
            {/* <Grid item>
              <DoughnutLabel label={'2nd Twenty First Club'} color='#FF281B' />
            </Grid>
            <Grid item>
              <DoughnutLabel label={'Twenty First Club'} color='#00C717' />
            </Grid> */}
          </Grid>
        </Grid>

      }
    />
  )
}
