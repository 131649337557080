import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Button, Typography } from '@material-ui/core'
import { intersection, keys, get, map } from 'lodash'
import CommanClasses from '../themes/CommonClasses'
import Color from '../themes/Colors'

const customStyles = makeStyles(CommanClasses)

const AgText = (props) => {
	const { label, component, onClick, ...otherProps } = props

	const styles = {
		xxxl: { fontSize: '60px', color: Color.primary },
		xxl: { fontSize: '48px', color: Color.primary, marginRight: 10 },
		xl: { fontSize: '30px', color: Color.light },
		md: { fontSize: '18px', color: Color.brewery },
		sm: { fontSize: '16px', color: Color.light },
		xsm: { fontSize: '14px', color: Color.light },
	}

	const matchingStyles = intersection(keys(otherProps), keys(styles)).map((s) =>
		get(styles, s)
	)
	let finalStyles = {}
	map(matchingStyles, (ms) => {
		finalStyles = { ...finalStyles, ...ms }
	})

	return (
		<Box>
			<Typography
				style={finalStyles}
				component={component || ''}
				onClick={onClick}>
				{label}
			</Typography>
		</Box>
	)
}
export default AgText
