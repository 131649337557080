import React, { useState } from 'react'
import { Box, Grid } from '@material-ui/core'
import { useQuery } from '@apollo/client'
import { groupBy, map, uniqBy } from 'lodash'
import { GetFeedBackdata } from '../graphql/feedbackChartDataGQL'
import DoughnutLabel from '../components/DoughnutLabel'
import LineChart from '../components/LineChart'
import AGCard from '../components/AGCard'
import Color from '../themes/Colors'
import AGIcon from '../components/AGIcon'
import moment from 'moment'
import DropDown from '../components/Dropdown'
import updateChartData from '../utils'
export default (props) => {
  const { breweryId } = props
  const windowWidth = window.innerWidth

  const sizeVal = windowWidth < 600 ? 200 : 120

  const [allGraphData, setAllGraphdata] = useState({
    mtucs: {},
    mtdcs: {}
  })
  const [filterGraphStr, setFilterGraphStr] = useState(moment().year())

  let defaultCounts = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]

  const { data: feedBackdata } = useQuery(GetFeedBackdata, {
    variables: {
      breweryId
    },
    onCompleted: (resp) => {
      if (!resp) {
        return
      }

      let mtucs = {}
      let mtdcs = {}

      let groupedByYearMtuc = groupBy(resp?.brewery?.brewery?.monthlyThumbsUpCount, function (item) {
        return item?.windowStartDateTime?.substring(0, 4)
      })

			

      let groupedByYearMtdc = groupBy(resp?.brewery?.brewery?.monthlyThumbsDownCount, function (item) {
        return item?.windowStartDateTime?.substring(0, 4)
      })

      map(groupedByYearMtuc, (yearData, yearAsKey) => {
        //assigning default counts here for this year
        let thisYearCount = [...defaultCounts]

        //assiging value
        map(yearData, item => {
          thisYearCount[moment(item?.windowStartDateTime).month()] = item?.value
					// console.log(moment(item?.windowStartDateTime).month(),'=======',item.value)
					// console.log('++++++++++',moment(item?.windowStartDateTime).month())
        })

        //final count for this year
        mtucs = { ...mtucs, [yearAsKey]: [...thisYearCount] }
      })

      map(groupedByYearMtdc, (yearData, yearAsKey) => {
        //assigning default counts here for this year
        let thisYearCount = [...defaultCounts]

        //assiging value
        map(yearData, item => {
          thisYearCount[moment(item?.windowStartDateTime).month()] = item?.value
					// console.log('<<<<<<>>>>>>>>',item.value)
        })

        //final count for this year
        mtdcs = { ...mtdcs, [yearAsKey]: [...thisYearCount] }
      })

      setAllGraphdata({
        mtucs: updateChartData(mtucs),
        mtdcs: updateChartData(mtdcs)
      })
    },
    notifyOnNetworkStatusChange: true,
  })

	
  return (
    <AGCard
      fullHeight
      action={<Grid container spacing={2} justify='center'>
        <Grid item>
          <DoughnutLabel label={'Positive Ratings'} number={<AGIcon color={Color.yellow} name={'icon-Like'} size={12} />} color='#273179' />
        </Grid>
        <Grid item>
          <DoughnutLabel label={'Negative Ratings'} number={<AGIcon color={Color.yellow} name={'icon-Dislike'} size={12} />} color='#FF826E' />
        </Grid>
      </Grid>}
      cardHeader={
        <Grid container spacing={2} alignItems='center'>
          <Grid item>
            <Box mr={1}>Customer Feedback</Box>
          </Grid>
          <Grid item>
            <Box>
              <DropDown
                size={'small'}
                icon={<AGIcon name={'icon-Up'} size={6} />}
                color='primary'
                variant={'outlined'}
                placement='bottom'
                initialValue={filterGraphStr}
                list={uniqBy([...(Object.keys(allGraphData?.mtucs) || []), ...(Object.keys(allGraphData?.mtdcs) || [])])}
                selectedValue={(e) => setFilterGraphStr(e)}
              />
            </Box>
          </Grid>
        </Grid>
      }
      cardContent={
        <LineChart
          colors={['#273179', '#FF826E']}
          stepSize={5}
          height={sizeVal}
          yAxesLabel={'Number of Ratings'}
          xAxesLabel={'Months'}
          labels={[
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec',
          ]}
          datasets={[
            {
              label: 'Positive Ratings',
              data: [...(allGraphData?.mtucs?.[filterGraphStr] || defaultCounts)],
            },
            {
              label: 'Negative Ratings',
              data: [...(allGraphData?.mtdcs?.[filterGraphStr] || defaultCounts)],
            },
          ]}
        />
      }
    />
  )
}
