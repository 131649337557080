import gql from 'graphql-tag'

export const getCommonMdiIconsSet = gql`
  query getCommonMdiIconsSet {
	getCommonMdiIconsSet {
    edges {
      	slug
      	name
      	desc
    }
  }
}
`
