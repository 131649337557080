import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button, CircularProgress, withStyles } from '@material-ui/core'
import CommanClasses from '../themes/CommonClasses'
import Color from '../themes/Colors'
import { intersection, keys, get, map } from 'lodash'

const customStyles = makeStyles(CommanClasses)

const useStyles = makeStyles((theme) => ({
	AgButton: {
		height: 35,
		padding: '0 20px',
		borderRadius: 20,
		textTransform: 'capitalize',
		whiteSpace: 'nowrap'
	},
}))

const styleButton = (color, textColor, outlined, fullWidth, loading, disabled) => {
	return {
		backgroundColor: loading || disabled ? 'lightgray' : (outlined ? Color.white : color ? color : Color.primary),
		color: loading || disabled ? 'gray' : (outlined
			? color
				? color
				: Color.primary
			: textColor
				? textColor
				: Color.white),
		border: loading || disabled ? 'gray' : (outlined
			? color
				? `1px solid ${color}`
				: `1px solid ${Color.primary}`
			: 'unset'),
		width: fullWidth ? '100%' : ''
	}
}

const AgButton = (props) => {
	const common = customStyles(CommanClasses)
	const classes = useStyles()

	const { label, component, onClick, className, clases, color, textColor, outlined, fullWidth, loading, disabled, ...otherProps } = props

	const styles = {
		smWhite: {
			fontSize: '16px',
			color: Color.button,
			borderRadius: 30,
			backgroundColor: 'white',
			textTransform: 'none',
			border: `1px solid ${Color.button}`,
			alignItems: 'center',
		},
		smOrange: {
			fontSize: '16px',
			color: Color.white,
			borderRadius: 30,
			backgroundColor: '#F86A55',
			textTransform: 'none',
			width: '10%',
			alignItems: 'center',
		},
		smblue: {
			fontSize: '16px',
			color: Color.white,
			borderRadius: 30,
			backgroundColor: Color.button,
			textTransform: 'none',
			alignItems: 'center',
		},
		smOrangeBorder: {
			fontSize: '16px',
			color: '#F86A55',
			borderRadius: 30,
			backgroundColor: Color.white,
			textTransform: 'none',
			width: '10%',
			border: '1px solid #F86A55',
			alignItems: 'center',
		},
	}

	const matchingStyles = intersection(keys(otherProps), keys(styles)).map((s) =>
		get(styles, s)
	)

	let finalStyles = {}

	map(matchingStyles, (ms) => {
		finalStyles = { ...finalStyles, ...ms }
	})

	const CircularProgressStyles = {
		root: {
			marginRight: 5
		},
		spinner: {
			marginRight: 5
		}
	}

	const SpinnerAdornment = withStyles(CircularProgressStyles)(props => (
		<CircularProgress
			className={props.classes.spinner}
			size={15}
		/>
	))

	return (
		<Button
			disabled={loading || disabled}
			disableElevation
			className={[className, common.HeeboMedium, common.textL].join(' ')}
			classes={{ root: classes.AgButton }}
			style={styleButton(color, textColor, outlined, fullWidth, loading, disabled)}
			onClick={onClick}
			{...props}
		>
			{loading && <SpinnerAdornment />}
			{label}
		</Button>
	)
}

export default AgButton
