import React from 'react'
import { Grid } from '@material-ui/core'
import MembersipCards from '../components/MembersipCards'
import { get } from 'lodash'

export default (props) => {
const club = get(props, 'AllCount.brewery.brewery.mugClubs[0]') || {}
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={3}>
        <MembersipCards
          header={'Active Members'}
          number={club?.memberStats?.activeMemberCount || 0}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <MembersipCards
          header={'Expired Memberships'}
          number={Math.max((club?.totalMemberCount || 0) - (club?.memberStats?.activeMemberCount || 0), 0)}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <MembersipCards
          header={'New Memberships'}
          number={club?.memberStats?.newMemberCount || 0}
          timeState={'In Last 30 Days'}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <MembersipCards
          header={'Expiring Memberships'}
          number={club?.memberStats?.expiringMemberCount || 0}
          timeState={'In Next 30 Days'}
        />
      </Grid>
    </Grid>
  )
}
