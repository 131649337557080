import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ClickAwayListener from "@material-ui/core/ClickAwayListener"

import {
  Box,
  Button,
  Typography,
  Popper,
  Paper,
  Fade,
  List,
  ListItem,
  Divider,
} from '@material-ui/core'
import CommanClasses from '../themes/CommonClasses'
import Color from '../themes/Colors'

const customStyles = makeStyles(CommanClasses)
const useStyles = makeStyles({
  buttonRoot: {
    minWidth: 100,
    textTransform: 'capitalize',
    height: 35,
    lineHeight: 'normal',
    whiteSpace: 'nowrap'
  },
  elevation1: {
    boxShadow: `0px 3px 6px ${Color.lightShadow}`,
  },
  noHeightButton: {
    minWidth: 150,
    textTransform: 'capitalize',
    lineHeight: 'normal',
    whiteSpace: 'nowrap'
  },
})
export default (props) => {
  props = {
    size: 'small',
    icon: <ExpandMoreIcon />,
    color: 'primary',
    variant: 'outlined',
    placement: 'bottom',
    ...props
  }
  const common = customStyles(CommanClasses)
  const classes = useStyles()

  const [anchorPopper, setAnchorPopper] = useState(null)
  const [openPopper, setOpenPopper] = useState(false)
  const [buttonText, setButtonText] = useState(props.initialValue)

  const handlePopperAction = (action, data) => {
    setButtonText(action?.name || action )
    props.selectedValue(action)
    setOpenPopper(false)
  }
  const handleClickPopper = () => (event) => {
    setAnchorPopper(event.currentTarget)
    setOpenPopper(!openPopper)
  }
  return (
    <Box>
      <Button
        classes={{ root: props.noHeight ? classes.noHeightButton : classes.buttonRoot }}
        className={[common.borderRadiusL, common.textL, common.HeeboMedium, props.variant === 'outlined' ? common.bgWhite : props.variant === 'text' ? common.textDark : ''].join(
          ' '
        )}
        onClick={handleClickPopper()}
        variant={props.variant}
        color={props.color}
        size={props.size}
        endIcon={!props.noText ? props.icon : null}
      >
        {!props.noText ? buttonText : props.icon}
      </Button>
      <Popper
        open={openPopper}
        anchorEl={anchorPopper}
        placement={props.placement}
        className={[common.mdZindex, common.popper].join(" ")}
        transition
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={() => setOpenPopper(false)}>
            <Fade {...TransitionProps} timeout={450}>
              <Paper
                elevation={1}
                className={[common.borderRadiusM, common.popper].join(" ")}
                classes={{ elevation1: classes.elevation1 }}
              >
                <List>
                  {props.list.map((action, idx) => (
                    <Box key={idx} style={{minWidth:100}}>
                      <ListItem
                        dense
                        button
                        onClick={(e) => handlePopperAction(action)}
                      >
                        <Typography className={common.textM}>{action?.name || action }</Typography>
                      </ListItem>
                      {props.list.length !== idx + 1 ? (
                        <Divider variant='middle' />
                      ) : null}
                    </Box>
                  ))}
                </List>
              </Paper>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
    </Box>
  )
}
