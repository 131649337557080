import React, { useEffect, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { Grid, Box, Hidden, Icon, Typography, Card, LinearProgress } from '@material-ui/core'
import { GetClubFields } from '../graphql/clubDetailsGQL'
import { getOffersStatsCount } from "../graphql/offersStatsCountGQL";
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { storeAllDetails } from '../Services/commonFunctions'
import { getBreweryId, getBreweries, imageWidthParams, storeAndGetClubs } from '../Services/local'
import { groupBy, map, find, isEqual, uniqWith, get, isEmpty, startCase, toLower } from 'lodash'
import Head from '../Screens/TopHeader'
import DropDown from '../components/Dropdown'
import AGButton from '../components/AgButton'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import AGIcon from '../components/AGIcon'
import Popup from '../components/Popup'
import Offers from '../containers/Offers'
import Drawer from '../components/Drawer'
import ClubsManageDrawer from '../containers/ClubsManageDrawer'
import InviteMemberDrawer from '../containers/InviteMemberDrawer'
import AddMemberDrawer from '../containers/AddMemberDrawer'
import { makeStyles } from '@material-ui/core/styles'
import CommanClasses from '../themes/CommonClasses'
import moment from 'moment'
import LineChartAndStats from '../containers/LineChartAndStats'
import { GET_OFFER_STATS } from '../graphql/offerStatsGQL'
import { DELETE_CLUB } from '../graphql/deleteClubGQL'
import { putMerchantUserGql } from '../graphql/putMerchantUserGql'
import MyPic from '../assets/images/my.png'
import updateChartData from '../utils'
import GroupSelectBox from '../components/GroupSelectBox'
import Image from '../components/Image'
import Colors from '../themes/Colors'
import { MeDocument } from '../graphql/getMe'
import { getAllClubsFromBreweries } from '../Services/commonFunctions'

const customStyles = makeStyles(CommanClasses)
const useStyles = makeStyles((theme) => ({
  dot: {
    height: '5px',
    width: '5px',
    backgroundColor: 'black',
    borderRadius: '50%',
    display: 'inline-block'
  },
  mb: {
    marginBottom: '5px',
  },
  mr: {
    marginRight: '8px',
  },
}))

export default (props) => {
  const common = customStyles()
  const classes = useStyles()

  // const breweryId = getBreweryId()
  const breweriesLcl = getBreweries()

  const initialFilterValue = 'This Year'
  const initialAmountCollectProps = {
    graphData: {
      yAxesLabel: 'Amount (In USD)',
      xAxesLabel: 'Months',
      labels: [],
      datasets: [
        {
          label: 'Amount collected',
          data: [],
        }
      ]
    },
    topData: []
  }

  const initialActiveMembersProps = {
    graphData: {
      yAxesLabel: 'Members',
      xAxesLabel: 'Months',
      labels: [],
      datasets: [
        {
          label: 'Members joined',
          data: [],
        }
      ]
    },
    topData: []
  }

  const allClubs = storeAndGetClubs()

  const [selectedFilter, setSelectedFilter] = useState(initialFilterValue)
  const [showManageClub, setShowManageClub] = useState(false)
  const [showInviteMember, setShowInviteMember] = useState(false)
  const [showAddMember, setShowAddMember] = useState(false)
  const [amountCollectedGraphData, setAmountCollectedGraphData] = useState(initialAmountCollectProps)
  const [activeMembersGraphData, setActiveMembersGraphData] = useState(initialActiveMembersProps)
  const [selectedClubIds, setSelectedClubIds] = useState([])
  const [selectedGraphClubIds, setSelectedGraphClubIds] = useState([])
  // const [allClubs, setAllClubs] = useState([])
  const [clubDetails, setClubDetails] = useState({})
  const [offerStatsData, setOfferStatsData] = useState({})
  const [offersCountResult, setOffersCountResult] = useState();
  const [breweries, setBreweries] = useState(breweriesLcl || [])
  const [multipleGraphData, setMultipleGraphData] = useState([])
  const [newClubId, setNewClubId] = useState()

  const handlenewClubID = (id) => {
    setNewClubId(id)
  }
  const rightTopButtons = [
    {
      name: 'Invite',
      outlined: true
    },
    {
      name: 'Add Club',
      outlined: false
    },
  ]

  const mugClubIds = selectedClubIds

  const getClubLimit = () => {
    if (selectedClubs?.length === 1) {
      return selectedClubs[0].clubLimit
    }
    return
  }

  let selectedClubBrewery = find(allClubs, c => c.id === selectedClubIds[0])
  const breweryId = selectedClubBrewery?.breweriesConnecton[0]?.id || getBreweryId()

  const { user, isLoading, error, getAccessTokenSilently } = useAuth0({
    audience: process?.env?.REACT_APP_API_HOST,
    scope: 'openid profile email',
  })

  const graphLabelsDefault = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ]

  const getTimeStamp = (data) => moment(data).utc().format()

  const durationWiseDate = {
    'This Month': { // current month
      startDate: getTimeStamp(moment().clone().startOf('month').format('YYYY-MM-DD')),
      endDate: getTimeStamp(moment().clone().endOf('month').format('YYYY-MM-DD'))
    },
    'Last Month': { // last month
      startDate: getTimeStamp(moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD')),
      endDate: getTimeStamp(moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'))
    },
    'Last 3 Months': {  // 3 months back from now
      startDate: getTimeStamp(moment().subtract(3, 'months').format('YYYY-MM-DD')),
      endDate: getTimeStamp(moment().format('YYYY-MM-DD'))
    },
    'Last 6 Months': {  // 6 months back from now
      startDate: getTimeStamp(moment().subtract(6, 'months').format('YYYY-MM-DD')),
      endDate: getTimeStamp(moment().format('YYYY-MM-DD'))
    },
    'This Year': {  // current year
      startDate: getTimeStamp(moment().startOf('year').format('YYYY-MM-DD')),
      endDate: getTimeStamp(moment().endOf('year').format('YYYY-MM-DD'))
    },
    'Last Year': {  // last year 
      startDate: getTimeStamp(moment().subtract(1, 'years').startOf('year').format('YYYY-MM-DD')),
      endDate: getTimeStamp(moment().subtract(1, 'years').endOf('year').format('YYYY-MM-DD'))
    },
  }

  const [getOffersCountData, { error: offersCountError, loading: offersCountLoading }] =
    useLazyQuery(getOffersStatsCount, {
      onCompleted: (resp) => {
        if (!resp) return;
        setOffersCountResult(resp?.offersStatsCount)
      },
      onError: (error) => {
        console.log("error", error);
      },
      notifyOnNetworkStatusChange: true,
    });

  useEffect(() => {
    refetchGetClubFields({
      breweryId,
      mugClubIds: selectedClubIds,
      ...durationWiseDate[selectedFilter],
    })
    if (selectedClubIds?.length) {
      getOffersCountData({
        variables: {
          membershipProgramIds: [...selectedClubIds],
          ...durationWiseDate[selectedFilter]
        },
      })
    }
  }, [breweryId, selectedFilter, selectedClubIds])

  const convertUSD = (data) => {
    if(!data) return 0
    return parseFloat(data?.split('USD ')?.[1])
  }

  const getPeriodWiseTitles = () => {
    if (selectedFilter === 'This Month') {
      return 'In This Month'
    } else if (selectedFilter === 'Last Month') {
      return 'In Last Month'
    } else if (selectedFilter === 'Last 3 Months') {
      return 'In Last 3 Months'
    } else if (selectedFilter === 'Last 6 Months') {
      return 'In Last 6 Months'
    } else if (selectedFilter === 'This Year') {
      return 'In This Year'
    } else if (selectedFilter === 'Last Year') {
      return 'In Last Year'
    }
  }

  const sliceLabels = (start, mid, end) => {
    if (mid) {
      return [...(graphLabelsDefault.slice(start, mid)), ...(graphLabelsDefault.slice(0, end))]
    } else {
      return [...(graphLabelsDefault.slice(start, end))]
    }
  }

  const getGraphDataLabelArray = () => {
    let finalLabels = []
    if (selectedFilter === 'This Month') {
      finalLabels = [moment().format('MMM')]
    } else if (selectedFilter === 'Last Month') {
      finalLabels = [moment().subtract(1, 'months').format('MMM')]
    } else if (selectedFilter === 'Last 3 Months') {
      let startMonth = parseInt(moment().subtract(3, 'months').format('MM'))
      let endMonth = parseInt(moment().format('MM'))
      if (startMonth - 1 > endMonth) {
        finalLabels = sliceLabels(startMonth - 1, 12, endMonth)
      } else {
        finalLabels = sliceLabels(startMonth - 1, null, endMonth)
      }
    } else if (selectedFilter === 'Last 6 Months') {
      let startMonth = parseInt(moment().subtract(6, 'months').format('MM'))
      let endMonth = parseInt(moment().format('MM'))
      if (startMonth - 1 > endMonth) {
        finalLabels = sliceLabels(startMonth - 1, 12, endMonth)
      } else {
        finalLabels = sliceLabels(startMonth - 1, null, endMonth)
      }
    } else if (selectedFilter === 'This Year' || selectedFilter === 'Last Year') {
      finalLabels = graphLabelsDefault
    }
    return finalLabels
  }

  const getAmountCollectedDataSet = (amountCollectedStats) => {
    const monthWise = groupBy(amountCollectedStats, 'month')
    let countMonthWise = {}
    map(monthWise, (mw, mw_key) => {
      map(mw, (mwc, mwc_ket) => {
        countMonthWise = { ...countMonthWise, [mw_key]: (countMonthWise[mw_key] || 0) + parseFloat(mwc['amountCollected']?.split('USD ')?.[1]) }
      })
    })

    let finalResult = []
    map(getGraphDataLabelArray(), month => {
      finalResult.push(countMonthWise[month] || 0)
    })

    return finalResult
  }

  const getActiveMembersDataSet = (activeMembersStats) => {
    const monthWise = groupBy(activeMembersStats, 'month')
    let countMonthWise = {}
    map(monthWise, (mw, mw_key) => {
      map(mw, (mwc, mwc_ket) => {
        countMonthWise = { ...countMonthWise, [mw_key]: (countMonthWise[mw_key] || 0) + parseInt(mwc['newJoined']) }
      })
    })

    let finalResult = []
    map(getGraphDataLabelArray(), month => {
      finalResult.push(countMonthWise[month] || 0)
    })

    return finalResult
  }

  const { refetch: refetchMeQuery, data: MeDocumentData } = useQuery(MeDocument, {
    // onCompleted: (resp) => {
    //   if (!resp) {
    //     return
    //   }
    //   let breweriesData = resp?.me?.merchantUser?.ownedBreweriesConnection?.edges || []

    //   setBreweries(breweriesData)
    //   getInitialClub(breweriesData)
    // },
    fetchPolicy: 'network-only'
  })

  useEffect(() => {
    if (MeDocumentData) {
      let breweriesData = MeDocumentData?.me?.merchantUser?.ownedBreweriesConnection?.edges || []

      setBreweries(breweriesData)
      // getInitialClub(breweriesData)
    }
  }, [MeDocumentData])

  useEffect(() => {
    getAllClubsFromBreweries(breweries)
  }, [breweries])

  useEffect(() => {
    if (allClubs && allClubs.length) {
      // getInitialClub()
      isEmpty(selectedClubIds) && setSelectedClubIds([allClubs[0].id])
      setSelectedGraphClubIds([allClubs[0]?.amountCollected])
    }

    if (newClubId?.length && find(allClubs, cl => cl.id === (newClubId[0]))) {
      setSelectedClubIds([...newClubId])
      setNewClubId()
    }

  }, [allClubs])

  const { loading: refetchingClub, refetch: refetchGetClubFields } = useQuery(GetClubFields, {
    variables: {
      breweryId,
      mugClubIds: selectedClubIds,
      ...durationWiseDate[selectedFilter],
    },
    onCompleted: (resp) => {
      if (!resp) {
        return
      }
      // ActiveMembers
      let outputs = {}
      const m22 = resp?.brewery?.brewery?.mugClubs
      map(m22, (dt) => {
        if (dt && dt?.name) {
          outputs[dt.name] = {}

          dt.activeMembers.activeMembersStats.map((d) => {
            if (!outputs[dt.name][d.year]) {
              outputs[dt.name][d.year] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
            }
            outputs[dt.name][d.year][getM(d.month)] =
              (get(outputs, "[dt.name][d.year][getM(d.month)]") || 0) +
              d.newJoined;
          })
        }
      })

      let activeMembersChartDataset = []

      map(outputs, (d, n) => {
        activeMembersChartDataset.push({
          label: n,
          data: outputs[n][moment(durationWiseDate[selectedFilter].startDate).format('YYYY')],
          color: find(allClubs, c => c.name === n)?.badgeIconColor || '#000000'
        })
      })

      let output = {}
      const m2 = resp?.brewery?.brewery?.mugClubs
      map(m2, (dt) => {
        if (dt && dt?.name) {
          output = { ...output, [dt.name]: {} }

          dt.amountCollected.amountCollectedStats.map((d) => {
            if (!output[dt.name][d.year]) {
              output[dt.name][d.year] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
            }
            output[dt.name][d.year][getM(d.month)] =
              (output[dt.name][d.year][getM(d.month)] || 0) +
              parseFloat(d?.amountCollected?.replace(/USD\s+/, ""));
          })
        }
      })

      let amountCollectedChartDataset = []
      // if (selectedFilter == "This year") {
      //   year = parseInt(moment().format('YYYY'));
      // }
      map(output, (d, n) => {
        amountCollectedChartDataset.push({
          label: n,
          data: output[n][moment(durationWiseDate[selectedFilter].startDate).format('YYYY')],
          color: find(allClubs, c => c.name === n)?.badgeIconColor || '#000000'
        })
      })

      if (resp?.brewery?.brewery?.mugClubs) {
        let filteredActiveMembers = 0
        let filteredAmountCollected = 0
        let totalAmountCollected = 0
        const allSelectedClubs = resp?.brewery?.brewery?.mugClubs
        for (let k = 0; k < allSelectedClubs.length; k++) {
          const club = allSelectedClubs[k]
          filteredActiveMembers = filteredActiveMembers + Number(club?.activeMembers?.filteredActiveMembers || 0 )
          filteredAmountCollected = filteredAmountCollected + Number(convertUSD(club?.amountCollected?.filteredAmountCollected) || 0)
          totalAmountCollected = totalAmountCollected + Number(convertUSD(club?.amountCollected?.totalAmountCollected) || 0)
        }

        //setting up amount collected stats data
        setAmountCollectedGraphData({
          ...amountCollectedGraphData,
          graphData: {
            ...(amountCollectedGraphData?.graphData || {}),
            labels: getGraphDataLabelArray(),
            datasets: amountCollectedChartDataset || []
          },
          topData: [
            {
              title: '$' + filteredAmountCollected,
              subTitle: getPeriodWiseTitles()
            },
            {
              title: '$' + totalAmountCollected,
              subTitle: 'Since the Beginning'
            }
          ]
        })

        //setting up active members stats data
        setActiveMembersGraphData({
          ...activeMembersGraphData,
          graphData: {
            ...(activeMembersGraphData?.graphData || {}),
            labels: getGraphDataLabelArray(),
            datasets: activeMembersChartDataset || []
          },
          topData: [
            {
              title: filteredActiveMembers,
              subTitle: 'New'
            },
            {
              title: getClubLimit() || '',
              subTitle: getClubLimit() ? 'Club Limit' : ''
            }
          ]
        })
      }

    }
  })

  const getTotalCounts = (arr) => {
    let activeOffersCount = 0
    let totalRedeemed = 0
    map(arr, d => {
      activeOffersCount += d.activeOffersCount
      totalRedeemed += d.totalRedeemed
    })
    return { activeOffersCount, totalRedeemed }
  }

  const [refetchGetOfferStats] = useLazyQuery(GET_OFFER_STATS, {
    onCompleted: (resp) => {
      if (!resp) {
        return
      }

      const breweryRaw = resp?.brewery?.brewery || {}
      const popularOffersRaw = breweryRaw?.activeOffersConnection?.edges || []
      let countD = getTotalCounts(breweryRaw?.offerStats)
      const popularOffers = map(popularOffersRaw, (offerChild) => {
        return {
          src: offerChild?.breweryOfferCampaign?.banner?.url || MyPic,
          offerName: offerChild?.breweryOfferCampaign?.title,
          reedemNo: offerChild?.breweryOfferCampaign?.totalRedeemed,
        }
      })
      setOfferStatsData({
        activeOffersCount: countD.activeOffersCount,
        totalRedeemed: countD.totalRedeemed,
        popularOffers: [...popularOffers]
      })
    }
  })

  const [deleteClub] = useMutation(DELETE_CLUB, {
    onCompleted: (resp) => {
      console.log('resp updateClub??', resp)
      //reset image state
      if (resp) {
        console.log('Club deleted')
        updateData()
      }
    },
    onError: (err) => {
      console.log('err??', err)
    }
  })

  const [putMerchantUser, { data: meData, error: meDataError, loading: meDataLoading }] = useMutation(putMerchantUserGql, {
    onCompleted: (resp) => {
      if (resp) {
        storeAllDetails(resp)
      }
    }
  })

  const updateData = () => {
    putMerchantUser({
      variables: {
        auth0Sub: user?.sub,
        email: user?.email,
      }
    })
  }

  const handleDrawer = (forWhat, mugClub) => {
    if (forWhat === 'Invite') {
      setShowInviteMember(true)
    } else if (forWhat === 'Add Club') {
      setClubDetails({})
      setShowManageClub(true)
    } else if (forWhat === 'View More') {
      setClubDetails({ ...mugClub })
      setShowManageClub(true)
    }
  }

  const handleDeleteClub = (id, c) => {
    console.log('>', id, 'cc >', c)
    deleteClub({
      variables: {
        id: id,
        status: 'DELETED'
      }
    })
  }

  const handleCloseClubsManageDrawer = () => {
    setShowManageClub(!showManageClub)
    refetchMeQuery({})
    refetchGetClubFields({
      breweryId,
      mugClubIds: selectedClubIds,
      ...durationWiseDate[selectedFilter],
    })
  }
  const getM = (m) => {
    const monthIdx = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec']
    const idx = monthIdx.findIndex((m1) => m1 == m?.toLowerCase())
    return idx
  }
  const handleButtunTask = (d) => {
    handleDrawer(d)
    setShowAddMember(false)
  }


  const handleSelectedClub = (v, year) => {
    if (v.length >= 1) {
      setSelectedClubIds([...v.map(d => d.id)])
      // setMultipleGraphData(getGraphData(year))
      // setSelectedGraphClubIds([...v.map(d => d.amountCollected)])
      // let mulGraphData = []
      // let graphData = clubData?.brewery?.brewery?.mugClubs?.[0]?.activeMembers
      // console.log("hdsjsdhjds", graphData)
      // console.log("cludata............", clubData)
      // v.map(d => mulGraphData.push({
      //   label: "Active Members",
      //   data: updateChartData(getActiveMembersDataSet(graphData),
      //     selectedFilter == "This Year") || [],

      // }))

      // setMultipleGraphData(mulGraphData)

    }

  }

  const selectedClubs = [
    ...allClubs.filter(ac => selectedClubIds.includes(ac.id))
  ]

  const getOffersData = () => {
    refetchGetOfferStats({
      variables: {
        breweryId,
        membershipProgramIds: selectedClubIds || [],
        popular: true,
        active: true,
        ...durationWiseDate[selectedFilter]
      }
    })
  }

  useEffect(() => {
    getOffersData()
  }, [selectedClubIds, selectedFilter])

  return (
    <Grid container>
      <Grid item xs={12} className={[common.bgTransparent, common.stickyPosition, common.topNavBar].join(' ')}>
        <Head
          // headerLogoAlt={clubDetails?.name}
          // headerLogo={clubDetails?.photosConnection?.edges[0]?.image?.url}
          // info={'Club Details'}
          // onClickInfo={() => {
          //   setShowManageClub(!showManageClub)
          // }}
          pageHeader={
            refetchingClub ? (
              <>
                Clubs
                <LinearProgress />
              </>
            ) : (
              'Clubs'
            )
          }
          actions={
            <Grid container spacing={2}>

              <Grid item>
                <GroupSelectBox
                  options={allClubs}
                  value={selectedClubs}
                  onChange={(e, v) =>
                    handleSelectedClub(v)
                  } />
              </Grid>
              {/* <GroupSelect
                  native
                  // onChange={(e) => onChangeValue(e.target.name, e.target.value)}
                  // name={name}
                  // value={value}
                  // {...otherProps}
                  items={Breweries}
                  items1={Clubs}
                /> */}


            </Grid>
          }
          endActions={
            <Grid container spacing={2}>
              <Hidden smDown>
                <Grid item>
                  <Box mr={10}>
                    <DropDown
                      size={'small'}
                      icon={<AGIcon name={'icon-Up'} size={6} />}
                      color='primary'
                      variant={'outlined'}
                      placement='bottom'
                      initialValue={initialFilterValue}
                      list={Object.keys(durationWiseDate)}
                      selectedValue={(e) => {
                        setSelectedFilter(e)
                      }}
                    /></Box>
                </Grid>

                {/* <Box mt={1.3} px={1}>
                  <AGIcon name={'icon-Info'} size={32} onClick={() => {
                    setShowManageClub(!showManageClub)
                  }} />
                </Box> */}
                {map(rightTopButtons, (data, idx) =>
                  <Grid item key={idx} className={['clubs_target1'].join(' ')}>
                    <AGButton outlined={data.outlined} label={data.name}
                      onClick={() => {
                        handleDrawer(data.name)
                        setShowAddMember(false)
                      }} />
                    {/* <AGButton outlined='true' label={data.name} onClick={() => handleDrawer(data.name)} /> */}
                  </Grid>
                )}
              </Hidden>
              <Hidden mdUp>
                <Popup
                  action={
                    <Icon>
                      <MoreVertIcon />
                    </Icon>
                  }
                  placement={'left-start'}
                  initialValue={''}
                  list={map(rightTopButtons, data => data.name)}
                  selectedValue={(e) => { handleButtunTask(e) }}
                />
              </Hidden>


            </Grid>
          }
        />
      </Grid>

      <Grid item xs={12}>
        <Box mx={2} py={1}>
          <Grid container spacing={2}>
            <Grid container spacing={2} item lg={9}>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <LineChartAndStats cardHeader='Active Members' {...activeMembersGraphData} selectedClubs={selectedClubs} />

              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <LineChartAndStats cardHeader='Amount Collected' {...amountCollectedGraphData} selectedClubs={selectedClubs} />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Grid container>
                  {map(selectedClubs, (sc, idx) =>
                    <Grid item lg={6} md={12} sm={12} xs={12} key={idx}>
                      <Box p={2}>
                        <Card elevation={0} classes={{ root: common.borderRadiusM }}>
                          <Grid container>
                            <Grid item>
                              <Box p={0.5}>
                                <Image src={get(sc, 'photosConnection.edges[0].image.url') + imageWidthParams(140)} xlg radiusSm />
                              </Box>
                            </Grid>
                            <Grid container item xs>
                              <Grid item lg={8}>
                                <Box p={1}>
                                  <Typography className={[
                                    // common.textDark,
                                    common.HeeboMedium,
                                    common.textXxl,
                                  ].join(' ')}>{sc.name}</Typography>
                                </Box>
                              </Grid>
                              <Grid item lg={4}>
                                <Box p={0.8} m={1}
                                  className={[
                                    common.textCenter,
                                    common.overflowX,
                                    common.borderRadiusXs,
                                    common.bgShadow,
                                  ].join(' ')}
                                  style={{ border: `1px solid ${Colors.primary}`, }}
                                >
                                  <Typography
                                    className={[
                                      common.textM,
                                      common.HeeboMedium,
                                    ].join(' ')}
                                    component='span'

                                  >
                                    {sc?.annualCost?.replace("USD ", "$")}
                                    {/* USD 9995.00 */}
                                  </Typography>
                                  <Typography
                                    className={[
                                      common.textM,
                                      common.HeeboLight,
                                    ].join(' ')}
                                    component='span'
                                  >
                                    {`/${startCase(toLower(sc?.clubRenewalCadence || 'YEARLY'))}`}
                                  </Typography>
                                </Box>
                              </Grid>
                              <Grid container item lg={12}>
                                {(sc?.breweriesConnecton || []).map((brewery, idx) => (
                                  <Grid item key={idx}>
                                    <Box p={1}>
                                      <Typography>{brewery.name} {sc?.breweriesConnecton?.length === idx + 1 ? '' : ', '}</Typography>
                                    </Box>
                                  </Grid>
                                ))}
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <Box px={2}>
                                <Typography
                                  gutterBottom
                                  className={[
                                    common.textDark,
                                    common.HeeboMedium,
                                    common.textM,
                                  ].join(' ')}
                                >
                                  {'Benefits'}
                                </Typography>
                                {map((sc?.benefitsMultiple || []), (data, idx) => (
                                  <Box display='flex' alignItems='center' key={idx}>
                                    <Box mb={1}>
                                      <span
                                        className={[classes.dot,
                                        sc.benefitsMultiple.length === idx + 1 ? classes.mb : '', classes.mr
                                          // classes.mb, classes.mr
                                        ].join(' ')}
                                      /></Box>
                                    <Box><Typography
                                      key={idx}
                                      gutterBottom
                                      className={[common.textDark, common.textM].join(' ')}
                                    >
                                      {data}
                                    </Typography></Box>
                                  </Box>
                                ))}
                                <Typography
                                  gutterBottom
                                  className={[
                                    common.textDark,
                                    common.HeeboMedium,
                                    common.textM,
                                  ].join(' ')}
                                >
                                  {'Description'}
                                </Typography>
                                <Typography
                                  key={idx}
                                  gutterBottom
                                  className={[common.textDark, common.textM].join(' ')}
                                >
                                  {sc.description}
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid item xs={12}>
                              <Box display='flex' justifyContent='flex-end' p={2}>
                                <Grid container spacing={1}>
                                  <Grid item>
                                    <AGButton
                                      label='Delete'
                                      onClick={() => handleDeleteClub(sc.id, sc)}
                                    />
                                  </Grid>
                                  <Grid item>
                                    <AGButton
                                      label='View More'
                                      onClick={() => handleDrawer('View More', sc)}
                                    />
                                  </Grid>
                                </Grid>
                              </Box>
                            </Grid>
                          </Grid>
                        </Card>
                      </Box>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} lg={3}>
              <Offers
                activeOffersCount={offersCountResult?.activeOffersCount}
                totalRedeemed={offersCountResult?.totalRedeemed}
                popularOffers={offerStatsData?.popularOffers?.slice(0, 3) || []}
              />
            </Grid>
          </Grid>
          {/* </Grid> */}
          {/* <Grid item lg={3} md={4} xs={12}>
              <ClubActivities
                type='CLUB'
                clubId={clubDetails?.id}
                clubPhoto={clubDetails?.photosConnection?.edges[0]?.image?.url} />
            </Grid> */}
          {/* </Grid> */}
        </Box>
      </Grid>
      <Drawer
        open={showInviteMember}
        content={
          <InviteMemberDrawer
            allClubs={allClubs || []}
            closeDrawer={() => setShowInviteMember(!showInviteMember)}
            membershipProgramId={clubDetails?.id}
          />
        }
        closeDrawer={() => setShowInviteMember(!showInviteMember)}
      />
      <Drawer
        open={showAddMember}
        content={
          <AddMemberDrawer closeDrawer={() => setShowAddMember(!showAddMember)} />
        }
        closeDrawer={() => setShowAddMember(!showAddMember)}
      />
      <Drawer
        open={showManageClub}
        content={
          <ClubsManageDrawer newClubId={e => handlenewClubID([e])} clubDetails={clubDetails} closeDrawer={handleCloseClubsManageDrawer} mode={clubDetails?.id ? 'read' : 'write'} />
        }
        closeDrawer={handleCloseClubsManageDrawer}
      />
    </Grid>
  )
}
