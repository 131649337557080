import { gql } from '@apollo/client'

export const ADD_CLUB = gql`
	mutation addClub(
			$breweryId: ID!
			$name: String
			$annualCost: String
			$autoRenewable: Boolean
			$clubLimit: String
			$description: String
			$benefits: String
			$benefitsMultiple: [String]
			$photos: [PhotoUpload!]
			$badgeIconName: String
			$badgeIconColor: String
			$breweriesAssociatedIds: [String]
			$clubRenewalCadence: ClubRenewalCadenceType
	) {
		addBreweryMembershipProgram(
			breweryId: $breweryId
			name: $name
			annualCost: $annualCost
			autoRenewable: $autoRenewable
			clubLimit: $clubLimit
			description: $description
			benefits: $benefits
			benefitsMultiple: $benefitsMultiple
			badgeIconName: $badgeIconName
			badgeIconColor: $badgeIconColor
			photos: $photos
			breweriesAssociatedIds: $breweriesAssociatedIds
			clubRenewalCadence: $clubRenewalCadence
		)
			{
				success
				program {
					id
					name
				}
			}
	}`