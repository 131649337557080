import React from 'react'
import { Card, Grid, Typography, Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CommanClasses from '../themes/CommonClasses'

const customStyles = makeStyles(CommanClasses)

const myColor = (color) => {
  return {
    color: color,
  }
}

export default (props) => {
  const common = customStyles(CommanClasses)

  return (
    <Card
      elevation={0}
      classes={{ root: common.borderRadiusM }}
      className={props.secondary ? common.bgWhite : common.bgInfo}
    >
      <Box mt={4} py={2}>
        <Grid
          container
          direction={props.variant || 'row'}
          justify='center'
          alignItems={'center'}
        >
          <Grid item>
            <Typography
              className={[
                common.HeeboMedium,
                props?.numberPrimaryColor ? common.textPrimary : !props.richText ? common.textDark : '',
              ].join(' ')}
              variant={'h7'}
            >
              {props.number}
            </Typography>
          </Grid>
          {props.richText || props.text ? (
            <Grid item>
              <Box px={0.5} component='span'>
                <Typography
                  component='span'
                  variant='subtitle1'
                  style={myColor(props.richTextColor)}
                >
                  <Box pr={0.5} component='span'>
                    {props.richText}
                  </Box>
                </Typography>

                <Typography
                  component='span'
                  variant='subtitle1'
                  className={[common.HeeboLight, common.textS].join(' ')}
                >
                  {props.text}
                </Typography>
              </Box>
            </Grid>
          ) : (
            ''
          )}
        </Grid>
      </Box>
    </Card>
  )
}
