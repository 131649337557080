import { gql } from '@apollo/client'

export const UPDATE_PROFILE_DATA = gql`
	mutation updateProfileData(
        $id:ID!
        $beerTypes: [String!]!
        $foodTypes: [String!]!
        $venueTypes: [String!]!
        $spiritsTypes: [String!]!
        $additionalTypes: [String!]!
        $coffeeTypes: [String!]!
        $meadTypes: [String!]!
        $wineTypes: [String!]!
        $ciderTypes: [String!]!
			
	) {
		updateBrewery(
            id: $id
            consumerBreweryPreferencesOffering:{
                beerTypes: $beerTypes
                foodTypes: $foodTypes 
                venueTypes: $venueTypes
                spiritsTypes: $spiritsTypes
                additionalTypes: $additionalTypes
                coffeeTypes: $coffeeTypes
                meadTypes: $meadTypes
                wineTypes: $wineTypes
                ciderTypes: $ciderTypes
	        })
			{
				success
				
			}
	}`