import React, { useState } from 'react'
import { Grid, Typography, Box, Hidden } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CommanClasses from '../../themes/CommonClasses'
import Image from '../../components/Image'
import Color from '../../themes/Colors'
import BgPic from '../../assets/images/undraw_group_selfie_ijc6.svg'
import AgButton from '../../components/AgButton'
import InviteMemberDrawer from '../../containers/InviteMemberDrawer'
import Drawer from '../../components/Drawer'
import { useHistory } from 'react-router-dom'
import { useSnackbar } from 'notistack'

const MembersPage = (props) => {
  // console.log('propssss??', props)
  const {
    clubDetails = {},
    breweryId,
  } = props || {}

  const common = customStyles(CommanClasses)
  const classes = useStyles()

  const { enqueueSnackbar } = useSnackbar()

  const [showInviteMember, setShowInviteMember] = useState(false)

  const history = useHistory()

  const onClickRedirection = () => {
    history.push('/user')
  }

  return (
    <div>
      <Grid container className={[classes.mt20].join(' ')}>
        <Hidden smDown>
          <Grid item md={5}>
            <Grid container>
              <Image src={BgPic} xxxl />
            </Grid>
          </Grid>
          <Grid item md={7}>
            <Box>
              <Grid container>
                <Box>
                  <Box mb={2.5}>
                    <Typography className={[common.HeeboRegular, common.textDark, classes.mb30].join(' ')} >
                      {clubDetails?.id ? 'Get Your Club Rolling With Invitations.' : 'Please add a club to your brewery'}
                    </Typography>
                    <Typography className={[common.HeeboLight, common.textLight, common.textM].join(' ')}>
                      In the Members area, you can view existing customers in your club or send invitations. Got an influencer or local celeb in town? Invite them to spread the word.
                    </Typography>
                  </Box>
                  <Box display='flex'>
                    <Box>
                      <AgButton
                        disabled={clubDetails?.id ? false : true}
                        label='Invite Member'
                        smblue='true' onClick={() => {
                          // console.log('clubDetails??', clubDetails)
                          if (clubDetails?.clubLimit && clubDetails?.clubLimit <= clubDetails?.memberStats?.activeMemberCount) {
                            //show snackbar message saying that Club limit already exceeded
                            enqueueSnackbar('Club limit already exceeded')
                            return
                          } else {
                            setShowInviteMember(true)
                          }
                        }}
                      />
                    </Box>
                    <Box ml={3}>
                      <AgButton
                        disabled={clubDetails?.id ? false : true}
                        label='View Members'
                        smblue='true' onClick={() => onClickRedirection()}
                      />
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Box>
          </Grid>
        </Hidden>
        <Hidden mdUp>
          <Grid item>
            <Grid container>
              <Image src={BgPic} xlx />
              <Box className={[classes.mt20].join('')}>
                <Typography className={[common.HeeboRegular, common.textDark, classes.mb30].join(' ')} >
                  We have setup some test club members for you. You can Add/Invite people to the club.
                </Typography>
                <Typography className={[common.HeeboLight, common.textLight, common.textM].join(' ')}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore <br /> magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea <br /> commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla <br /> pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est <br /> laborum
                </Typography>
              </Box>
            </Grid>

          </Grid>
          <Grid item>
            <Box className={[classes.mt20].join('')}>
              <Grid container>
                <Box><AgButton label='Invite Member' disabled={!breweryId} outlined onClick={() => setShowInviteMember(!showInviteMember)} /></Box>
                <Box ml={3}><AgButton label='View Members' disabled={!breweryId} smblue='true' onClick={() => onClickRedirection()} /></Box>
              </Grid>
            </Box>
          </Grid>
        </Hidden>
      </Grid>
      <Drawer
        open={showInviteMember}
        content={
          <InviteMemberDrawer
            closeDrawer={() => setShowInviteMember(!showInviteMember)}
            membershipProgramId={clubDetails?.id}
          />
        }
        closeDrawer={() => setShowInviteMember(!showInviteMember)}
      />
    </div >
  )
}

const customStyles = makeStyles(CommanClasses)
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: Color.white
  },
  card: {
    width: 435,
    // margin: 10,
    marginLeft: 0,
    marginRight: 10,
    marginTop: 10,
    marginBottom: 10,
  },
  tableHead: {
    backgroundColor: Color.aliceBlue,
  },
  mt20: { marginTop: 20 },
  mb30: { marginBottom: 30 },
}))


export default MembersPage
