import React from "react";
import { Line } from "react-chartjs-2";

export default (props) => {
  const setGradientColor = (canvas, color) => {
    const ctx = canvas.getContext("2d");
    const gradient = ctx.createLinearGradient(10, 100, 0, 0);
    // gradient.addColorStop(0.09, color)
    // gradient.addColorStop(0.5, '#F7FAFF')
    // gradient.addColorStop(0, '#d5d8f1');
    gradient.addColorStop(0.2, "#e4e6f6");
    gradient.addColorStop(0.5, "#F7FAFF");
    return gradient;
  };
  const getChartData = (data, ctx) => {
    const a = data;
    if (a) {
      let colors = props.colors;
      data.forEach((element, i) => {
        element.borderColor = colors[i];
        element.backgroundColor = props.gradient
          ? setGradientColor(ctx, colors[i])
          : "transparent";
        element.borderWidth = 2;
        element.pointBackgroundColor = colors[i];
        element.pointBorderColor = "#fff";
        element.lineTension = 0;
      });
    }
    return a;
  };

  const options = {
    responsive: true,
    elements: {
      // point: {
      //   radius: 0,
      // },
    },
    legend: {
      display: false,
    },
    title: {
      display: props.title ? true : false,
      text: props.title,
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            color: "rgba(0, 0, 0, 0)",
          },
          scaleLabel: {
            display: props.xAxesLabel ? true : false,
            labelString: props.xAxesLabel,
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            color: "rgba(0, 0, 0, 0)",
          },
          ticks: {
            // min: props.minTicks ? props.minTicks : 0,
            // max: props.maxTicks ? props.maxTicks : 100,
            stepSize: props.stepSize ? props.stepSize : 20,
            stepSize: props.stepSize ? props.stepSize : 20,
            beginAtZero: true,
            // max: 1000,
            min: 0,
          },
          scaleLabel: {
            display: props.yAxesLabel ? true : false,
            labelString: props.yAxesLabel,
          },
        },
      ],
    },
  };

  const vd = (canvas) => {
    const ctx = canvas.getContext("2d");
    // const gradient = ctx.createLinearGradient(0, 0, 600, 550)
    // gradient.addColorStop(0, 'yellow');
    // gradient.addColorStop(0.95, 'red');
    return {
      labels: props.labels,
      datasets: getChartData(props.datasets, canvas),
    };
  };

  return (
    <Line
      data={vd}
      height={props.height}
      width={props.width}
      options={options}
    />
  );
};
