import gql from 'graphql-tag'

export const GetPromoMemberCount = gql`
  query MemberAndProfileEngagementCount(
    $breweryId: ID!
    $startDate: String
    $endDate: String
    $mugClubIds: [String]
    ) {
    brewery(id: $breweryId) {
      success
      brewery {
        followerCount
        profileImpressions
        mugClubs {
          memberCount
          totalMemberCount(mugClubIds:$mugClubIds)
          memberStats(startDate:$startDate endDate:$endDate mugClubIds:$mugClubIds) {
          newMemberCount
          expiringMemberCount
          activeMemberCount
        }
        }
      }
    }
  }
`
