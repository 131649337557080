import gql from "graphql-tag";

export const BREWERY_PREFERENCES = gql`
  query BREWERY_PREFERENCES($breweryId: ID!) {
    brewery(id: $breweryId) {
      success
      brewery {
        aggregateFollowingConsumerBreweryPreferences {
          key
          count
        }
      }
    }
  }
`;
