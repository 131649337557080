import React, { useEffect } from 'react'
import { Box, Grid, Typography, TextField } from '@material-ui/core'
import 'date-fns'
import { MuiPickersUtilsProvider, KeyboardTimePicker, KeyboardDatePicker, TimePicker } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { makeStyles } from '@material-ui/core/styles'
import CommanClasses from '../themes/CommonClasses'
import Color from '../themes/Colors'
import moment from 'moment'

const customStyles = makeStyles(CommanClasses)
const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiInputBase-root': {
            color: Color.dark,
            width: 70,
            // backgroundColor: Color.main,
            backgroundColor: Color.white,
            '& .MuiInputAdornment-root': {
                // visibility:'hidden',
                // display: 'none',
                marginLeft: 0,
            },
        },
        '& .MuiInput-underline': {
            textDecorationColor: '#F7FAFF'
        },
        textDecorationLine: 'none'
    }
}))
function TimePickers(props) {
    const common = customStyles(CommanClasses)
    const classes = useStyles()
    const [selectedDate, setSelectedDate] = React.useState(new Date(`2020-11-08T${props.time}:00`))
    const handleDateChange = (date) => {
        setSelectedDate(date)
        props.onChange(date)
    }
    useEffect(() => {
        setSelectedDate(new Date(`2020-11-08T${props.time}:00`))
    }, [props.time])
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} >
            {/* <KeyboardTimePicker
                // value={props.time}
                value={`2020-11-08T${props.time}:00`}
                onChange={props.onChange} //define change handle method in parent
                KeyboardButtonProps={{
                    'aria-label': 'change time',
                }}
                className={[classes.root].join(' ')}
                InputProps={{
                    disableUnderline: true,
                }}
                variant='outlined'
                endAdornment={
                    <div>button</div>
                }
            /> */}
            <TimePicker
                autoOk
                // value={`2020-11-08T${props.time}:00`}
                value={selectedDate}
                // onChange={props.onChange} 
                onChange={handleDateChange}
                className={[classes.root].join(' ')}
                InputProps={{
                    disableUnderline: true,
                }}
            />
        </MuiPickersUtilsProvider>
    )
}

export default TimePickers
