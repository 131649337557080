import Colors from './Colors'
import Size from './FontSizes'
import './css/style.css'

export default (theme) => ({
  bgPrimary: {
    backgroundColor: Colors.primary,
  },
  bgSecondary: {
    backgroundColor: Colors.secondary,
  },
  bgWhite: {
    backgroundColor: Colors.white,
  },
  bgDanger: {
    backgroundColor: Colors.danger,
  },
  bgSuccess: {
    backgroundColor: Colors.success,
  },
  bgDark: {
    backgroundColor: Colors.dark,
  },
  bgLight: {
    backgroundColor: Colors.light,
  },
  bgInfo: {
    backgroundColor: Colors.info,
  },
  bginfoDark: {
    backgroundColor: Colors.infoDark,
  },
  bgExLight: {
    backgroundColor: Colors.exLight,
  },
  bgMuted: {
    backgroundColor: Colors.muted,
  },
  bgYellow: {
    backgroundColor: Colors.yellow,
  },
  bgLightRed: {
    backgroundColor: Colors.lightred,
  },
  bgPink: {
    backgroundColor: Colors.pink,
  },
  bgShadow: {
    backgroundColor: Colors.shadow,
  },
  bgAliceBlue: {
    backgroundColor: Colors.aliceBlue,
  },
  bgMain: {
    backgroundColor: Colors.main,
  },
  bgTransparent: {
    backgroundColor: 'transparent',
  },
  textPrimary: {
    color: Colors.primary,
  },
  textSecondary: {
    color: Colors.secondary,
  },
  textWhite: {
    color: Colors.white,
  },
  textDanger: {
    color: Colors.danger,
  },
  textSuccess: {
    color: Colors.success,
  },
  textDark: {
    color: Colors.dark,
  },
  textInfoDark: {
    color: Colors.infoDark
  },
  textLight: {
    color: Colors.light,
  },
  textMuted: {
    color: Colors.muted,
  },
  textInfo: {
    color: Colors.info,
  },
  textYellow: {
    color: Colors.yellow,
  },
  textLightRed: {
    color: Colors.lightred,
  },
  textPink: {
    color: Colors.pink,
  },
  textTLight: {
    color: Colors.tLight,
  },
  textTWarning: {
    color: Colors.warning,
  },
  textCardHead: {
    color: Colors.cardHeading,
  },

  textCenter: {
    textAlign: 'center !important',
  },
  textRight: {
    textAlign: 'right'
  },

  h_100: {
    height: '100%',
  },
  vh_100: {
    height: '100vh',
  },
  mh_100: {
    maxHeight: '100%',
  },
  w_100: {
    width: '100%',
  },
  mw_100: {
    maxWidth: '100%',
  },

  borderRadiusXs: {
    borderRadius: 5,
  },
  borderRadiusS: {
    borderRadius: 10,
  },
  borderRadiusM: {
    borderRadius: 15,
  },
  borderRadiusL: {
    borderRadius: 20,
  },

  textXs: {
    fontSize: Size.xs,
  },
  textS: {
    fontSize: Size.s,
  },
  textL: {
    fontSize: Size.l,
  },
  textM: {
    fontSize: Size.m,
  },
  textXl: {
    fontSize: Size.xl,
  },
  textXxl: {
    fontSize: Size.xxl,
  },
  pointer: {
    cursor: 'pointer',
  },

  HeeboMedium: {
    fontFamily: 'Heebo-Medium',
  },
  HeeboLight: {
    fontFamily: 'Heebo-Light',
  },
  HeeboRegular: {
    fontFamily: 'Heebo-Regular',
  },
  overflowX: {
    overflowX: 'hidden'
  },
  stickyPosition: {
    position: 'sticky',
    top: 0
  },
  relativePosition: {
    position: 'relative'
  },
  xsZindex: {
    zIndex: 1
  },
  smZindex: {
    // zIndex: 2
    zIndex: 1002
  },
  mdZindex: {
    // zIndex: 3
    zIndex: 1003
  },
  lgZindex: {
    zIndex: 4
  },
  xlZindex: {
    zIndex: 5
  },
  alignSelfStart: {
    alignSelf: 'self-start !important'
  },
  sm: {
    marginRight: 10
  },
  overflowWrap: {
    overflowWrap: 'anywhere'
  },
  topNavBar: {
    zIndex: 1,
    [theme.breakpoints.up("md")]: {
      zIndex: 1,
    }
  },
  popper: {
    zIndex: 2
  }
})
