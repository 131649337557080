import gql from 'graphql-tag'

export const GET_BREWERY_DETAILS = gql`
  query getBreweryDetails($breweryId: ID!) {
    brewery(id: $breweryId) {
      success
      brewery {
        id
        name
        fullAddress
        contactEmail
        contactPhone
        alternatePhoneNumber
        website
        description
        businessType
        hierarchicalLocation
        website
        hours {
          openTime
          closeTime
          dayOfWeek
          localOpenTime
          localCloseTime
        }
        isMinimalSetupDone
      }
    }
  }
`
