import gql from "graphql-tag";

export const GetMembershipCodesData = gql`
  query getMembershipCodes(
    $breweryId: ID!
    $mugClubIds: [String]
  ) {
    brewery(id: $breweryId) {
      success
      brewery {
        mugClubs(mugClubIds:$mugClubIds) {
          id
          existingMemberCodes{
            code
            oneTimeUse
            description
            membershipExpirationDate
            consumed
          }
        }
      }
    }
  }
`;
