import {
  ApolloClient,
  from,
  InMemoryCache,
} from '@apollo/client'
import { onError } from '@apollo/link-error'
import { typeDefs } from './gbLocalStore'
import { createUploadLink } from 'apollo-upload-client'
import { menu, storeAndGetClubs } from '../Services/local'

export const createApolloClient = (
  accessToken,
  enqueueSnackbar
) => {
  const cache = new InMemoryCache(
    {
      typePolicies: {
        ExistingMemberCode: {
          keyFields: ['code'],
        },
        BreweryDomainWhiteboardQueryResponseEdge: {
          keyFields: ['key'],
        },
        BreweryPhotoEdge: {
          keyFields: ['image', ['id']],
        },
        ActivityMeta: {
          keyFields: ['mpName', 'mpId', 'breweryId', 'id', 'offerName', 'memberName', 'coMemberName', 'primaryMemberName', 'fieldsUpdated', 'fieldsAdded']
        },
        Query: {
          fields: {
            menu: {
              read() {
                return menu()
              },
              storeAndGetClubs: {
                read() {
                  return storeAndGetClubs()
                }
              }
            }
          }
        }
      },
    })

  if (!process.env.REACT_APP_API_HOST) {
    throw new Error('No API configured!')
  }

  const link = from([
    onError((resp) => {
      const { operation, response } = resp
      if (response?.errors?.length) {
        response.errors = response.errors.filter((error) => {
          const context = operation.getContext()
          if (error.extensions?.exception.code === 'BREWERY_HAS_NO_MUG_CLLUB') {
            return false
          }

          // This query should only fail if I expect it to.
          if (context.allowMissing === true) {
            return error.message !== 'No user with Auth0 subject "".'
          }
        })
      }
      if (response?.errors?.length) {
        response.errors.forEach((error) => {
          enqueueSnackbar(error.message, { variant: 'error' })
        })
      }
    }),
    createUploadLink({
      headers: {
        authorization: `bearer ${accessToken}`,
      },
      uri: `${process.env.REACT_APP_API_HOST}/graphql`,
    }),
  ])

  const client = new ApolloClient({
    cache,
    connectToDevTools: process.env.NODE_ENV === 'development',
    defaultOptions: {
      query: {
        fetchPolicy: 'cache-first',
      },
    },
    link,
    typeDefs,
    resolvers: {},
  })

  client.onResetStore(async () =>
    cache.writeData({
      data: {
        myBreweryId: null,
      },
    })
  )

  return client
}
