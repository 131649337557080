import { gql } from '@apollo/client'

export const OffersIndexQueryDocument = gql`
  query OffersIndexQuery(
    $id: ID!
    $page: PaginationQuery
    $endDate: String
    $startDate: String
    $popular: Boolean
    $active: Boolean
    $membershipProgramId:String
    $membershipProgramIds: [String]
    $hostBreweries:[String]
    $order: OfferQueryOrder
    ) {
    breweryDomainWhiteboard(key: "consumerPreferenceValues") {
      edges {
        key
        value
      }
    }
    brewery(id: $id) {
      success
      brewery {
        id
        name
        thumbnail {
          url
        }
        activeOffersConnection(page: $page startDate:$startDate endDate:$endDate popular:$popular active:$active membershipProgramId:$membershipProgramId order:$order membershipProgramIds:$membershipProgramIds hostBreweries: $hostBreweries) {
          pageInfo {
            hasMore
            totalCount
          }
          edges {
            cursor
            breweryOfferCampaign {
              id
              banner {
                url
                altText
                tags
              }
              title
              subtitle
              active
              hosts
              membershipPrograms
              hostBreweries
              ctaText
              ctaLink
              expiringDate
              scheduledDate
              totalRedeemed
              possibleRedemptions
              body {
                markdown
                image {
                  url
                  altText
                  format
                  tags
                }
              }
              redeemable
              targetPreferences {
                beerTypes
                foodTypes
                venueTypes
                spiritsTypes
                additionalTypes
                coffeeTypes
                meadTypes
                wineTypes
                ciderTypes
              }
              targetClasses {
                membershipProgramMember
                membershipExpiration {
                  program
                  expiration {
                    afterDateTime
                    beforeDateTime
                    atOrAfterDateTime
                    atOrBeforeDateTime
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
