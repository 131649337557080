import React from 'react'
import { useHistory } from 'react-router'
import OffersCard from '../components/OffersCard'

export default (props) => {
  const { activeOffersCount = 0, totalRedeemed = 0, popularOffers = [] } = props || {}
  const history = useHistory()
  const routeName = history.location.pathname

  return (
    <OffersCard
      header={routeName === '/clubs' ? 'Promotions' : false}
      active={routeName === '/clubs' ? activeOffersCount : false}
      redumptions={routeName === '/clubs' ? totalRedeemed : false}
      isClub = {routeName === '/clubs' ? true : false}
      header2='Top Promotions'
      offers={popularOffers}
    />
  )
}
