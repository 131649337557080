//THIS IS DUMMY PAGE, SAVED AS A BACKUP FILE....
import React, { useState, useEffect, useCallback, useRef } from 'react'
import { Box, Grid, Typography, Hidden, IconButton, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CommanClasses from '../themes/CommonClasses'
import Fab from '@material-ui/core/Fab'
import AgButton from './AgButton'
import AgChip from './AgChip'
import TimePicker from './TimePicker'
import Color from '../themes/Colors'
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined'
import ToggleButton from '@material-ui/lab/ToggleButton'
import { pull, omit, map } from 'lodash'
import moment from 'moment'
const customStyles = makeStyles(CommanClasses)
const useStyles = makeStyles((theme) => ({
    root: {
        borderRadius: '50%',
        boxShadow: 'none',
        width: 35,
        height: 35,
        textTransform: 'none',
    },
    heading: {
        marginBottom: 10,
        zIndex: 1,
    },
    bgMain: {
        // backgroundColor: Color.main,
        backgroundColor: Color.white,
        textAlign: 'center',
    },
    table: {
        width: '100%',
        zIndex: 1,
    },
    gridMarginB: { marginBottom: 30 },
    lineHt40: { lineHeight: '20px' },
    lineHt10: { lineHeight: '5px' },
    tdBorder: { border: '1px solid #DEE2F1', borderRadius: 5 },
    roundIconButton: {
        borderRadius: '50%',
        border: `1px solid ${Color.primary}`,
        backgroundColor: Color.white,
        padding: 10
    },
}))



function WorkingHoursTwo(props) {
    const common = customStyles(CommanClasses)
    const classes = useStyles()

    const cleanedHours = map(props.hours, (hr) =>
        omit(hr, ['__typename'])
    )
    const [incomingArray, setIncommingArray] = React.useState(cleanedHours || [])
    const [arrayLength, setArrayLength] = useState(incomingArray.length)

    const weeks = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY']

    const handleTimePickerChange = (whichOne, index, date, day) => {
        let hour = `${moment(date, 'HH:mm').hours()}`
        let minute = `${moment(date, 'HH:mm').minute()}`
        hour = hour.length === 1 ? `0${hour}` : hour
        minute = minute.length === 1 ? `0${minute}` : minute
        let time = `${hour}:${minute}`

        let arr = incomingArray
        let newArr = []
        if (whichOne === 'open') {
            let i = arr.findIndex(el => el.dayOfWeek === day)
            let arri = { ...arr[i] }
            arri.localOpenTime = time
            newArr = [...incomingArray.slice(0, i), arri, ...incomingArray.slice(i + 1)]
            setIncommingArray(newArr)
            props.businessHours(newArr)
        } else {
            let i = arr.findIndex(el => el.dayOfWeek === day)
            let arri = { ...arr[i] }
            arri.localCloseTime = time
            newArr = [...incomingArray.slice(0, i), arri, ...incomingArray.slice(i + 1)]
            setIncommingArray(newArr)
            props.businessHours(newArr)
        }
    }

    const handleCopyToAll = () => {
        let arr = incomingArray
        let openTime = incomingArray[0].localOpenTime
        let closeTime = incomingArray[0].localCloseTime
        let newArr = []
        for (var i = 0; i < arrayLength; i++) {
            let arri = { ...arr[i] }
            arri.localOpenTime = openTime
            arri.localCloseTime = closeTime
            newArr = [...newArr, arri]
        }
        setIncommingArray(newArr)
        props.businessHours(newArr)
    }

    const handleDayOff = (index, action, day) => {
        // console.log(index, '--', action, '--', day)
        if (action === 'remove') {
            if (arrayLength !== 1) {
                let i = incomingArray.findIndex(el => el.dayOfWeek === day)
                let newArr = [...incomingArray.slice(0, i), ...incomingArray.slice(i + 1)]
                // console.log(newArr)
                setIncommingArray(newArr)
                setArrayLength(arrayLength - 1)
                props.businessHours(newArr)
            } else {
                console.log('cannot remove anymore')
            }
        } else {
            let newArr = [...incomingArray.slice(0, index), { openTime: '00:00', closeTime: '00:00', localOpenTime: '11:00', localCloseTime: '01:00', dayOfWeek: day }, ...incomingArray.slice(index + 0)]
            setIncommingArray(newArr)
            setArrayLength(arrayLength + 1)
            props.businessHours(newArr)
        }
    }


    return (
        <Box p={1} >
            <Box px={0} mb={1.25} className={[common.HeeboMedium, common.textDark, classes.heading, common.textXxl].join('')} >Working Hours</Box>
            <Grid container direction='column' >
                <Grid item container className={[classes.gridMarginB].join(' ')}>
                    <Grid item container justify='space-between' md={9}>
                        {map(weeks, (d, id) => (
                            incomingArray.find(h => h.dayOfWeek === d) ?
                                <ToggleButton key={id} value='check' selected={d.dayOff} className={[classes.root, common.bgPrimary, common.textWhite].join(' ')} onClick={() => handleDayOff(id, 'remove', d)}>
                                    <Typography className={[common.HeeboMedium, common.textWhite, common.textL].join(' ')}>
                                        {(`${d.slice(0, 1)}${d.slice(1).toLowerCase()}`).substring(0, 2)}
                                    </Typography>
                                </ToggleButton>
                                :
                                <ToggleButton key={id} value='check' selected={d.dayOff} className={[classes.root, common.bgMain, common.textWhite].join(' ')} onClick={() => handleDayOff(id, 'add', d)}>
                                    <Typography className={[common.HeeboMedium, common.textDark, common.textL].join(' ')}>
                                        {(`${d.slice(0, 1)}${d.slice(1).toLowerCase()}`).substring(0, 2)}
                                    </Typography>
                                </ToggleButton>
                        ))}
                    </Grid>
                    {/* <Grid item>&nbsp;</Grid> */}
                </Grid>
                <Grid item container>
                    <Grid item md={9} xs={12}>
                        <table className={[classes.table].join(' ')}>
                            {map(incomingArray, (days, i) => (
                                // weeks.find(h => h === days.dayOfWeek) &&
                                <tbody key={i}>
                                    <tr className={[classes.lineHt40].join(' ')}>
                                        <td><Box mx={0} className={[common.HeeboLight, common.textDark].join(' ')}>
                                            {`${days.dayOfWeek.slice(0, 1)}${days.dayOfWeek.slice(1).toLowerCase()}`}
                                        </Box></td>
                                        <td className={[classes.bgMain, classes.tdBorder].join(' ')}>
                                            <Box mx={0} mt={0}>
                                                <TimePicker time={days.localOpenTime} onChange={(time) => handleTimePickerChange('open', i, time, days.dayOfWeek)} />
                                            </Box>
                                        </td>
                                        <td className={classes.bgMain}><Box mx={0} className={[common.HeeboLight].join(' ')}>&nbsp;to&nbsp;</Box></td>
                                        <td className={[classes.bgMain, classes.tdBorder].join(' ')}>
                                            <Box mx={0} mt={0}>
                                                <TimePicker time={days.localCloseTime} onChange={(time) => handleTimePickerChange('close', i, time, days.dayOfWeek)} />
                                            </Box>
                                        </td>
                                        <Hidden only={['lg', 'xl']}>
                                            {i == 0 ? <td>
                                                <Box ml={1} mt={0}>
                                                    <IconButton size='small' className={[classes.roundIconButton].join(' ')} onClick={() => handleCopyToAll()}>
                                                        <FileCopyOutlinedIcon color='primary' />
                                                    </IconButton>
                                                </Box>
                                            </td> : ''}
                                        </Hidden>
                                    </tr>
                                    <tr className={[classes.lineHt10].join(' ')}><td>&nbsp;</td></tr>
                                </tbody>
                            ))}
                        </table>
                    </Grid>
                    <Hidden only={['md', 'sm', 'xs']}>
                        <Grid item>
                            <Box px={1}>
                                {/* ------------Commented to reduce button size-------------- */}
                                {/* <AgButton label='Copy to all' outlined onClick={() => handleCopyToAll()} /> */}
                                <AgChip label={<p className={[common.HeeboMedium, common.textPrimary].join(' ')}>Copy to all</p>} button onClick={() => handleCopyToAll()} />
                            </Box>
                        </Grid>
                    </Hidden>
                </Grid>
            </Grid>
        </Box>
    )
}

export default WorkingHoursTwo
