import { gql } from "@apollo/client";

export const BreweryMugClubFragmentDoc = gql`
  fragment BreweryMugClub on BreweryMembershipProgram {
    id
    name
    description
    benefits
    clubLimit
    benefitsMultiple
    badgeIconName
    badgeIconColor
    clubRenewalCadence
    description
    annualCost
    inviteCount
    photosConnection {
      edges {
        image {
          url
          altText
          format
        }
      }
    }
    breweriesConnecton {
      id
      name
    }
    memberStats {
      activeMemberCount
    }
    totalMemberCount
  }
`;
export const BreweryFragmentDoc = gql`
  fragment BreweryFragment on Brewery {
    id
    slug
    name
    description
    fullAddress
    hierarchicalLocation
    website
    status
    tags
    businessType
    hours {
      openTime
      closeTime
      dayOfWeek
    }
    contactEmail
    contactPhone
    photosConnection {
      edges {
        cursor
        tags
        image {
          id
          url
          altText
        }
        videoUrl
      }
    }
    mugClubs {
      ...BreweryMugClub
    }
    hasWriteAccess
  }
  ${BreweryMugClubFragmentDoc}
`;
