import gql from "graphql-tag";

export const UPDATE_MEMBERSHIP_CODE = gql`
  mutation updateMembershipCode(
    $programId: ID!
    $membershipExpirationDate: String
    $description: String
    $code: String!
    $oneTimeUse: Boolean
    $consumed: Boolean
  ) {
    putExistingMemberCode(
      programId: $programId
      membershipExpirationDate: $membershipExpirationDate
      description: $description
      code: $code
      oneTimeUse: $oneTimeUse
      consumed: $consumed
    ) {
      success
      existingMemberCode {
        membershipExpirationDate
        code
        description
        oneTimeUse
        consumed
      }
    }
  }
`;
