import { gql } from '@apollo/client'

export const DELETE_CLUB = gql`
	mutation updateClub(
			$id: ID!
			$status: ClubStatus
	) {
		updateBreweryMembershipProgram(
			id: $id
			status: $status
		)
			{
				success
				breweryMembershipProgram {
					id
					name
				}
			}
	}`