import React, { useRef, useState, useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'
import { map, get, flow, partialRight, keyBy, values, find, includes } from 'lodash'
import moment from 'moment'
import { GetUserFields } from '../graphql/usersGQL'
import { GetSearchedMembers } from '../graphql/searchMemberGQL'
import { getBreweryId, getBreweries } from '../Services/local'
import AllMembers from '../components/AllMembers'
export default (props) => {
  const { refreshCounts, mugClubIds, allClubs } = props
  const dateToday = new Date()
  const breweryId = getBreweryId()
  const breweries = getBreweries()
  const [users, setUsers] = useState([])
  const [selectedFilter, setSelectedFilter] = useState('All')
  const [searchText, setSearchText] = useState()
  const selectedClubBrewery = find(allClubs, c => c.id === mugClubIds[0])
  const membershipProgramId = selectedClubBrewery?.id
  const clubName = selectedClubBrewery?.name
  const [sBrewClubs, setSBrewClubs] = useState([])
  const pagination = useRef({})
  const lastUniqBy = iteratee => flow(
    partialRight(keyBy, iteratee),
    values
  )

  const findSelectedClubLocations = () => {
    let brewIds = []
    if (breweries?.length && selectedClubBrewery?.id) {
      for (let h = 0; h < breweries.length; h++) {
        let brew = breweries[h].brewery
        let bm = find(brew.mugClubs, mc => get(mc, 'id') === selectedClubBrewery.id)
        if (bm) {
          brewIds = [...brewIds, brew.id]
        }
      }
    }
    setSBrewClubs(brewIds)
    return brewIds
  }

  useEffect(() => {
    findSelectedClubLocations()
  }, [selectedClubBrewery])

  //Search
  const [
    getSearchResult,
    { error: searchResultsError, loading: searchLoading },
  ] = useLazyQuery(GetSearchedMembers, {
    fetchPolicy: 'network-only',
    onCompleted: (resp) => {
      if (!resp) return
      if (searchText?.length) {
        const edges =
          resp?.brewery?.brewery?.membersConnectionSearch?.edges || []

        setUsers(edges)
        refreshCounts()
      }
    },
    onError: (error) => {
      console.log('error', error)
    },
    notifyOnNetworkStatusChange: true,
  })

  //Get All users
  const [
    getUsersData,
    { error: userError, loading: userLoading, refetch: getRefreshedData },
  ] = useLazyQuery(GetUserFields, {
    fetchPolicy: 'network-only',
    onCompleted: (resp) => {
      if (!resp) return
      if (!searchText?.length) {
        const edges =
          resp?.brewery?.brewery?.membersConnection?.edges || []
        const hasMore =
          resp?.brewery?.brewery?.membersConnection?.pageInfo?.hasMore
        const cursor = edges.length ? edges[edges.length - 1].cursor : ''
        setUsers(lastUniqBy("communityMember.id")([...users, ...edges]))
        pagination.current = { hasMore, cursor }
        refreshCounts()
      }
    },
    onError: (error) => {
      console.log('error??', error)
    },
    notifyOnNetworkStatusChange: true,
  })

  //function for calling queries based on filter And for View More
  const getResults = () => {
    let variables = {
      breweryId,
      mugClubIds,
      page: {
        after: pagination.current?.cursor || '',
        maxResultCount: 20,
      },
    }

    if (selectedFilter === 'All') {
      getUsersData({ variables })
    } else if (selectedFilter === 'Recent') {
      getUsersData({
        variables: {
          ...variables,
          order: { orderBy: 'JOIN_DATE', order: 'DESC' },
          isActive: true,
        },
      })
    } else if (selectedFilter === 'Expiring') {
      getUsersData({
        variables: {
          ...variables,
          isActive: true,
          expirationTime: {
            afterDateTime: moment(dateToday).utc().format(),
            beforeDateTime: moment(dateToday).utc()
              .add(1, 'months')
              .format(),
          },
        },
      })
    } else if (selectedFilter === 'Expired') {
      getUsersData({
        variables: {
          ...variables,
          expirationTime: {
            beforeDateTime: moment(dateToday).utc().format(),
          },
        },
      })
    }
  }
  const getData = () => {
    pagination.current = {}
    setUsers([])
    getResults()
  }
  useEffect(() => {
    getData()
  }, [selectedFilter, mugClubIds])

  const sharedInfo = (info) => {
    for (let k = 0; k < info.length; k++) {
      let b = info[k]
      if (includes(sBrewClubs, b?.brewery?.id) && b?.sharedInfo?.length) {
        return b?.sharedInfo
      }
    }
    return
  }
  //Maping All users to get needed data
  const mapAllUsers = map(users, (res) => ({
    avatar: res?.communityMember?.photosConnection?.edges[0]?.image?.url,
    memberId: res?.memberId,
    breweryId: breweryId,
    communityMemberId: res?.communityMember?.id,
    membershipProgramId: membershipProgramId,
    name: res?.communityMember?.fullName,
    contact: res?.communityMember?.email,
    phone: res?.communityMember?.phoneNumber,
    dateOfBirth: moment(res?.communityMember?.dateOfBirth).isValid() ? res?.communityMember?.dateOfBirth : '-',
    joiningDate: res.joinDate,
    expiringDate: res?.membershipExpirationDate,
    status: res?.active,
    sharedInfo: sharedInfo(
      res?.communityMember?.brewerySharesConnection?.edges
    ),
    breweryPreferences: res?.communityMember?.consumerBreweryPreferences,
    clubName: clubName,
    notes: res?.notes,
    primaryMemberId: res?.primaryMemberId,
    primaryMember: res?.primaryMember,
  }))

  const handleSearch = (e) => {
    setSearchText(e)
    if (!e) {
      pagination.current = {}
      setUsers([])
      getResults()
    }
    if (e.length > 2) {
      pagination.current = {}
      getSearchResult({
        variables: {
          breweryId,
          mugClubIds,
          search: e,
        },
      })
    }
  }

  const checkLoading = () => {
    if (
      selectedFilter === 'All' ||
      selectedFilter === 'Recent' ||
      selectedFilter === 'Expiring' ||
      selectedFilter === 'Expired'
    ) {
      return userLoading
    } else if (searchText.length > 2) {
      return searchLoading
    } else {
      return false
    }
  }

  const checkError = () => {
    if (
      selectedFilter === 'All' ||
      selectedFilter === 'Recent' ||
      selectedFilter === 'Expiring' ||
      selectedFilter === 'Expired'
    ) {
      return userError
    } else if (searchText.length > 2) {
      return searchResultsError
    } else {
      return false
    }
  }

  return (
    <div>
      <AllMembers
        mugClubIds={mugClubIds}
        refreshuserList={() => getRefreshedData()}
        searchTextChange={(e) => handleSearch(e)}
        filterType={(a) => setSelectedFilter(a)}
        loading={checkLoading()}
        errorCheck={checkError()}
        headCells={[
          {
            id: '',
            label: '',
          },
          {
            id: 'name',
            label: 'Name',
          },
          {
            id: 'contact',
            label: 'Contact',
          },
          {
            id: 'dateOfBirth',
            label: 'Birthday',
          },
          {
            id: 'joiningDate',
            label: 'Member Since',
          },
          {
            id: 'expiringDate',
            label: 'Expiration Date',
          },
          {
            id: 'status',
            label: 'Data Shared Status',
            align: 'center',
          },
        ]}
        usersList={mapAllUsers}
        onClickViewMore={() => getResults()}
        hasMore={pagination?.current}
      />
    </div>
  )
}
