import React from 'react'
import { Grid, Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CommanClasses from '../themes/CommonClasses'
import Image from './Image'
import AGCard from './AGCard'
import { imageWidthParams } from '../Services/local'

const customStyles = makeStyles(CommanClasses)
const useStyles = makeStyles((theme) => ({
  wordBreak: {
    wordBreak: 'break-word',
  },
}))

export default (props) => {
  const common = customStyles(CommanClasses)
  const classes = useStyles()
  const { avatar, template } = props
  return (
    <Box my={1}>
      <AGCard
        secondary
        cardContent ={
        <Grid container direction='row' justify='space-between'>
          <Grid item xs>
              <Grid container direction='row'>
                <Grid item>
                  <Box pr={1}>
                    <Image src={avatar + imageWidthParams(40)} sm circle />
                  </Box>
                </Grid>
                <Grid item xs>
                  <div
                    className={[
                      common.textM,
                      common.HeeboLight,
                      common.textLight,
                      classes.wordBreak
                    ].join(' ')}
                    dangerouslySetInnerHTML={{ __html: template }}
                  />
                </Grid>
              </Grid>
          </Grid>
        </Grid>
        }
        />
    </Box>
  )
}
