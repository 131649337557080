import gql from 'graphql-tag'

export const GetExportedData = gql`
  query getdData(
    $breweryId: String
    $mugClubIds: [String]
    $typeOfExport: TypeOfExport
    ) {
        getExportedData(breweryId:$breweryId  mugClubIds: $mugClubIds typeOfExport: $typeOfExport) {
          fileUrl
          success
        }
    }
`
