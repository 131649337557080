import { gql } from '@apollo/client'

export const UPLOAD_BREWERY_LOGO = gql`
	mutation uploadBreweryLogo(
        $id:ID!
        $logo: PhotoUpload			
	) {
		updateBrewery(
            id: $id
            logo: $logo
		)
			{
				success
				
			}
	}`