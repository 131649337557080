import React, { useState, useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'
import AGCard from '../components/AGCard'
import DoughnutLabel from '../components/DoughnutLabel'
import Doughnut from '../components/DoughnutChart'
import LineChart from '../components/LineChart'
import { Box, Grid, Typography } from '@material-ui/core'
import DropDown from '../components/Dropdown'
import AGIcon from '../components/AGIcon'
import { makeStyles } from '@material-ui/core/styles'
import { GetPromoEngagementData } from '../graphql/promoEngagementGQL'
import { getOffersStatsCount } from "../graphql/offersStatsCountGQL";
import { getBreweryId } from '../Services/local'
import moment from 'moment'
import { map, isEmpty } from 'lodash'
import CommanClasses from '../themes/CommonClasses'
import updateChartData from '../utils'
const customStyles = makeStyles(CommanClasses)

const useStyles = makeStyles((theme) => ({
  prmotitle: {
    marginTop: -40,
    [theme.breakpoints.down('sm')]: {
      marginTop: 10
    },
  }
}))

export default (props) => {
  const { membershipProgramIds, selectedType, selectedBrwery, refreshData } = props
  const common = customStyles()
  const classes = useStyles()
  const breweryId = getBreweryId()
  const [timePeriod, setTimePeriod] = useState('This Year')
  const [offerStats, setOfferStats] = useState()
  const [offersCountResult, setOffersCountResult] = useState();
  const [offerStatsLifetime, setOfferStatsLifetime] = useState({})


  const [getOffersCountData, { error: offersCountError, loading: offersCountLoading }] =
  useLazyQuery(getOffersStatsCount, {
    onCompleted: (resp) => {
      if (!resp) return;
      setOffersCountResult(resp?.offersStatsCount)
    },
    onError: (error) => {
      console.log("error", error);
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache'
  });

  const requiredDate = (e) => moment(e).utc().format()

  const durationWiseDate = {
    'This Month': {
      startDate: requiredDate(moment().clone().startOf('month').format('YYYY-MM-DD hh:mm')),
      endDate: requiredDate(moment().clone().endOf('month').format('YYYY-MM-DD hh:mm')),
    },
    'Last Month': {
      startDate: requiredDate(
        moment()
          .subtract(1, 'month')
          .clone()
          .startOf('month')
          .format('YYYY-MM-DD hh:mm')
      ),
      endDate: requiredDate(
        moment()
          .subtract(1, 'month')
          .clone()
          .endOf('month')
          .format('YYYY-MM-DD hh:mm')
      ),
    },
    'Last 3 months': {
      startDate: requiredDate(
        moment()
          .subtract(3, 'month')
          .clone()
          .startOf('month')
          .format('YYYY-MM-DD hh:mm')
      ),
      endDate: requiredDate(moment().format('YYYY-MM-DD hh:mm')),
    },
    'Last 6 months': {
      startDate: requiredDate(
        moment()
          .subtract(6, 'month')
          .clone()
          .startOf('month')
          .format('YYYY-MM-DD hh:mm')
      ),
      endDate: requiredDate(moment().format('YYYY-MM-DD hh:mm')),
    },
    'This Year': {
      startDate: requiredDate(
        moment().clone().startOf('year').format('YYYY-MM-DD hh:mm')
      ),
      endDate: requiredDate(
        moment().clone().endOf('year').format('YYYY-MM-DD hh:mm')
      ),
    },
    'Last Year': {
      startDate: requiredDate(
        moment()
          .subtract(1, 'year')
          .clone()
          .startOf('year')
          .format('YYYY-MM-DD hh:mm')
      ),
      endDate: requiredDate(
        moment()
          .subtract(1, 'year')
          .clone()
          .endOf('year')
          .format('YYYY-MM-DD hh:mm')
      ),
    },
  }

  const offersCountDataCall = (e) => {
    let variables ={}
    if(selectedType?.name === 'Clubs') {
      variables = {
        membershipProgramIds,
      }
    } else if(selectedBrwery?.id) {
      variables.hostBreweries = [selectedBrwery?.id]
    }
    
    variables = {
      ...variables,
      ...durationWiseDate[e],
    }

    if(!isEmpty(variables?.hostBreweries) || !isEmpty(variables?.membershipProgramIds)){
      getOffersCountData({
        variables : variables,
      })
    }
  }

  const getData = (e) => {
    let variables = {
      breweryId: selectedBrwery?.id,
      ...durationWiseDate[e],
    }
    if(selectedType?.name === 'Clubs') {
      variables = {
        ...variables,
        membershipProgramIds,
      }
    }
    // getPromoEngagementData({
    //   variables: {
    //     ...variables
    //   },
    // })
  }

  const getLast3Months = () => {
    var monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

    let i = 0,
      j = 12

    var today = new Date()

    var neededMonths = []

    if (timePeriod === 'This Year' || timePeriod === 'Last Year') {
      return monthNames
    } else if (timePeriod === 'This Month') {
      return [monthNames[today.getMonth()]]
    } else if (timePeriod === 'Last Month') {
      if (today.getMonth() === 0) return [monthNames[11]]
      return [monthNames[today.getMonth() - 1]]
    } else if (timePeriod === 'Last 3 months') {
      j = 4
    } else if (timePeriod === 'Last 6 months') {
      j = 7
    }

    for (i; i < j; i++) {
      neededMonths[i] = monthNames[today.getMonth()]
      today.setMonth(today.getMonth() - 1)
    }

    return neededMonths.reverse();
  }

  useEffect(() => {
    getData(timePeriod)
    offersCountDataCall(timePeriod)
  }, [timePeriod, membershipProgramIds, selectedType, selectedBrwery, refreshData])

  const dataLine = [
    {
      label: 'Number of Redemptions',
      data: map(offersCountResult?.redemptionStats, data => data.totalRedeemed) || [],
      // data: updateChartData(map(offerStats, data => data.totalRedeemed || []),
      //   timePeriod == "This Year") || [],
    },
  ]

  return (
    <AGCard
      cardHeader={'Promotions Overview'}
      action={
        <DropDown
          size={'small'}
          icon={<AGIcon name={'icon-Up'} size={6} />}
          color='primary'
          variant={'outlined'}
          placement='bottom'
          initialValue={timePeriod}
          list={['This Month', 'Last Month', 'Last 3 months', 'Last 6 months', 'This Year', 'Last Year']}
          selectedValue={(e) => setTimePeriod(e)}
        />
      }
      handleAction={() => ''}
      cardContent={
        <Grid container>
          <Grid item xs={12} md={4}>
            <Grid container>
              <Grid item xs={12}>
                <Doughnut
                  labels={['Active', 'Inactive']}
                  colors={['#2290CF', '#F86A55']}
                  stepSize={20}
                  height={240}
                  width={250}
                  title='My Chart'
                  centeredText={
                    <div>
                      <Typography
                        className={[common.HeeboMedium].join(' ')}
                        variant='h4'
                      >
                        {offersCountResult?.totalOffersCount}
                      </Typography>
                      <Typography
                        className={[
                          common.textDark,
                          common.HeeboLight,
                          common.textM,
                        ].join(' ')}
                      >
                        {'Total Promos'}
                      </Typography>
                    </div>
                  }
                  datasets={[
                    offersCountResult?.activeOffersCount,
                    offersCountResult?.inActiveOffersCount
                  ]}
                />
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2} justify='center'>
                  <Grid item>
                    <DoughnutLabel
                      label={'Active'}
                      number={offersCountResult?.activeOffersCount}
                      color={'#2290CF'}
                    />
                  </Grid>
                  <Grid item>
                    <DoughnutLabel
                      label={'Inactive'}
                      number={offersCountResult?.inActiveOffersCount}
                      color={'#F86A55'}
                    />
                  </Grid>
                  {/* <Grid item>
                    <DoughnutLabel
                      label={'Completed'}
                      number={chartData.completed}
                      color={'#EBF1FB'}
                    />
                  </Grid> */}
                </Grid>
              </Grid>
            </Grid>
            <Typography style={{ marginTop: 20, textAlign: 'center', fontSize: 12 }}>
              {'* Inactive count includes scheduled promos'}
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Grid container>
              <Box className={classes.prmotitle}>
                <Typography className={
                  [common.HeeboMedium,
                  common.textDark,
                  common.textXl].join(' ')
                }>Promotions Engagement</Typography>
              </Box>
              <Grid item xs={12}>
                <Doughnut
                  labels={['Active', 'Inactive', 'Completed']}
                  colors={['#2290CF', '#F86A55', '#eeeeee']}
                  stepSize={20}
                  height={240}
                  width={250}
                  title='My Chart'
                  centeredText={
                    <div>
                      <Typography
                        className={[common.HeeboMedium].join(' ')}
                        variant='h4'
                      >
                        {offersCountResult?.totalRedeemed}
                      </Typography>
                      <Typography
                        className={[
                          common.textDark,
                          common.HeeboLight,
                          common.textM,
                        ].join(' ')}
                      >
                        {'Total Redemptions'}
                      </Typography>
                    </div>
                  }
                  datasets={[
                    // chartData.totalRedeemed,
                    // chartData.total - chartData.totalRedeemed,
                  ]}
                />
              </Grid>
              {/* <Grid item xs={12}>
                <Grid container spacing={2} justify='center'>
                  <Grid item>
                    <DoughnutLabel
                      label={'Redemed'}
                      number={chartData.totalRedeemed}
                      color={'#2290CF'}
                    />
                  </Grid>
                  <Grid item>
                    <DoughnutLabel
                      label={'Unredeemed'}
                      number={chartData.total - chartData.totalRedeemed}
                      color={'#F86A55'}
                    />
                  </Grid>
                </Grid>
              </Grid> */}
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}>
            <LineChart
              colors={['#FB6A55']}
              stepSize={20}
              height={80}
              width={100}
              yAxesLabel={'Number of Redemptions'}
              xAxesLabel={'Months'}
              labels={getLast3Months()}
              datasets={dataLine}
            />
          </Grid>
        </Grid>
      }
    />
  )
}
