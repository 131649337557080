import React from 'react'
import Color from '../themes/Colors'
import { makeStyles } from '@material-ui/core/styles'
import CommanClasses from '../themes/CommonClasses'
const customStyles = makeStyles(CommanClasses)
export default (props) =>{
    const common = customStyles()
    return (
        <span
          id ={props.id}
          className={[props.name, !props.disabled ? common.pointer : ''].join(' ')}
          onClick={props.onClick}
          style={{ fontSize: props.size + 'px', color: props.color || Color.primary }}
        />
      )
} 
