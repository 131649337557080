import React from 'react'
import { Grid, Box, Icon, Hidden } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CommanClasses from '../themes/CommonClasses'
import ProfileBox from '../containers/ProfileBox'
import DehazeIcon from '@material-ui/icons/Dehaze'
const customStyles = makeStyles(CommanClasses)
const usestyles = makeStyles(() => ({
  header: {
    position: 'sticky',
    top: 0,
    height: 60,
    maxHeight: 60,
    zIndex: 1,

  },
  buttonIcon: {
    height: 30,
    width: 30,
  },
  alignIcon: {
    margin: '3px 0 0 3px',
  },
}))
export default (props) => {
  const common = customStyles()
  const classes = usestyles()
  return (
    <Grid
      container
      alignItems='center'
      className={[classes.header, common.bgMain].join(' ')}
    >
      <Grid item xs={8} lg={9}>
        <Grid container alignItems='center'>
          <Grid item>
            <Hidden mdUp>
              <Box px={2}>
                <Icon
                  className={common.pointer}
                  onClick={() => props.drawerActon()}
                >
                  <DehazeIcon />
                </Icon>
              </Box>
            </Hidden>
          </Grid>
        </Grid>
      </Grid>
      
      <Grid item xs={4} lg={3}>
        <ProfileBox />
      </Grid>
    </Grid>
  )
}
