import React, { useState } from 'react'
import { Drawer, Grid, Typography, Divider, Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CommonClasses from '../themes/CommonClasses'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { isArray, map } from 'lodash'
import Logo from '../assets/images/logo_01.png'
import { imageWidthParams } from '../Services/local'

const customStyles = makeStyles(CommonClasses)
const useStyles = makeStyles((theme) => ({
  DrawerPaper: {
    // backgroundColor: "#ffffff",//commented for future use
  },
  drawerContentRight: {
    width: 450,
    maxWidth: 500,
    [theme.breakpoints.down('xs')]: {
      width: '100vw'
    },
  },
  drawerContentLeft: {
    marginLeft: 66,
    backgroundColor: '#171C32',
    width: 230,
    [theme.breakpoints.down('xs')]: {
      width: '100vw'
    },
    height: '100%',
  },
  logo: {
    maxWidth: 160,
    maxHeight: 50,
  },
  dividerRoot: {
    backgroundColor: '#ffffff',
    opacity: 0.1,
  },
  selected: {
    background: '#EA952D',
  },

}))

const DrawerComponent = (props) => {
  const { content, heading = 'heading', anchor = 'right', open, closeDrawer, disableBackdropClick } = props || {}

  const classes = useStyles()
  const common = customStyles()

  const [listIndx, setListIndx] = useState(null)

  const list = () => (
    <Box p={2}>
      <Grid container>
        <Grid item xs={12}>
          <img src={Logo + imageWidthParams(160)} className={classes.logo} />
        </Grid>
        <Grid item xs={12}><Divider className={classes.dividerRoot} /></Grid>
        <Grid item xs={12}>
          <List>
            <Typography className={common.textYellow}>{`| ${heading}`}</Typography>
            {isArray(content) ?
              map(content, (list, i) => (
                <ListItem className={i === listIndx ? classes.selected : ''} key={i}>
                  <ListItemText>
                    <Typography className={common.textWhite} onClick={() => setListIndx(i)}>{list}</Typography>
                  </ListItemText>
                </ListItem>
              ))
              : null}
          </List>
        </Grid>
      </Grid>
    </Box>
  )

  return (
    <div>
      <React.Fragment>
        <Drawer
          anchor={anchor}
          elevation={16}
          open={open}
          onClose={closeDrawer}
          className={classes.DrawerPaper}
          // disableBackdropClick
        >
          <div className={anchor === 'left' ? classes.drawerContentLeft : classes.drawerContentRight} /*onClick={handleDrawer}*/>
            {anchor === 'left' ?
              list()
              :
              content
            }
          </div>
        </Drawer>
      </React.Fragment>
    </div>
  )
}

export default DrawerComponent
