import { gql } from '@apollo/client'
import { MerchantUserFragmentDoc } from './getMe'

export const putMerchantUserGql = gql`
mutation putMerchantUser(
	$auth0Sub: String!
	$email: String!
	$phoneNumber: String
) {
	putMerchantUser(auth0Sub: $auth0Sub email: $email phoneNumber: $phoneNumber) {
		success
		merchantUser {
      ...MerchantUser
    }
	}
}
${MerchantUserFragmentDoc}`