import { gql } from '@apollo/client'

export const typeDefs = gql`
	extend type Query {
		refreshToken: String!
		credentials: Credentials!
	}

	extend type Credentials {
		accessToken: String
		idToken: String
		refreshToken: String
		updatedOn: Int
	}

	extend type UserData {
		sub: String
		aud: String
		email: String
		email_verified: Boolean
		exp: Int
		iat: Int
		iss: String
		name: String
		nickname: String
		picture: String
		sub: String
		updated_at: String
	}

	# extend type Mutation {
	#   storeLoginData(credentials: Credentials!): Boolean! {
	#     Credentials
	#   }
	# }
`

export const resolvers = {}
