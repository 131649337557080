import React from 'react'
import Icon from '@mdi/react'
import * as MdiIcons from '@mdi/js'

const MdiIcon = (props) => {
	const { name = 'mdiCircleSmall', color='#000'} = props
	return (
		<Icon path={MdiIcons[name]}
			size={1}
			horizontal
			vertical
			rotate={180}
			color={color} />
	)
}

export default MdiIcon
