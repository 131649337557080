import gql from "graphql-tag";

export const DELETE_MEMBERSHIP_CODE = gql`
  mutation deleteMembershipCode($programId: ID!, $code: String!) {
    deleteExistingMemberCode(programId: $programId, code: $code) {
      success
      updated
    }
  }
`;
