import gql from "graphql-tag";

export const activities = gql`
  query activities(
    $breweryId: String!
    $activityTarget: String!
    $page: PaginationQuery
    $membershipProgram: String
    $userId: String
    $mugClubIds: [String]
  ) {
    activity(
      activityTarget: $activityTarget
      breweryId: $breweryId
      membershipProgram: $membershipProgram
      userId: $userId
      page: $page
      mugClubIds: $mugClubIds
    ) # page: $page
    {
      success
      pageInfo {
        hasMore
        totalCount
      }
      edges {
        cursor
        activity {
          typeOfActivity
          meta {
            mpName
            mpId
            breweryId
            id
            fieldsUpdated
            offerName
            memberName
            coMemberName
            primaryMemberName
            fieldsAdded
          }
          userInfo {
            id
            fullName
            email
            photosConnection {
              edges {
                image {
                  url
                  altText
                  tags
                }
              }
            }
          }
          activityDate
        }
      }
    }
  }
`;
