import React, { useState } from 'react'
import { Route, Switch, Router } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { Hidden } from '@material-ui/core'
import { createBrowserHistory } from 'history'
import { GetClubDetails } from '../graphql/clubInfoGQL'
import { lessMenuArr, getBreweryId, menu } from '../Services/local'
import LeftSideBar from '../components/LeftSideBar'
import TopHeader from '../components/TopHeader'
import Club from '../Screens/Club'
import Users from '../Screens/Users'
import Promotions from '../Screens/Promotions'
import Dashboard from '../Screens/Dashboard'
import Settings from '../container/settings/Settings'
import { isEmpty } from 'lodash'

const history = createBrowserHistory()

const pages = [
  {
    exact: true,
    path: '/',
    component: Dashboard,
  },
  {
    exact: true,
    path: '/dashboard',
    component: Dashboard,
  },
  {
    exact: true,
    path: '/clubs',
    component: Club,
  },
  {
    exact: true,
    path: '/user',
    component: Users,
  },
  {
    exact: true,
    path: '/promotions',
    component: Promotions,
  },
  {
    exact: true,
    path: '/settings',
    component: Settings,
  },
]



export const AuthenticatedContainer = ({
  user,
}) => {
	console.log('Authenticated Container....')
  const [drawerState, setDrawerState] = useState(true)

  const breweryId = getBreweryId()

  const {
    data: clubData
  } = useQuery(GetClubDetails, {
    variables: {
      breweryId,
    },
    onCompleted: (resp) => {
      if (!resp) {
        return
      }

      //do the operation
      // if (!isEmpty(resp?.brewery?.brewery) && !resp?.brewery?.brewery?.isMinimalSetupDone) {
      //   history.push('/settings')
      // }

      //resetting menu items
      // if (!resp?.brewery?.brewery?.mugClub?.id) {
      //   menu(lessMenuArr)
      // }
    }
  })

  return (
    <>
      <Router history={history}>
        <Switch>
          <React.Fragment>
            <div className='appbody'>
              <div className={drawerState ? 'leftwraper' : null}>
                <Route
                  path='/'
                  render={(props) => <LeftSideBar drawerActon={() => setDrawerState(!drawerState)} history={history} />}

                />
              </div>
              <div className='rightWraper'>
                <Hidden mdUp>
                  <Route
                    path='/'
                    render={(props) => <TopHeader drawerActon={() => setDrawerState(!drawerState)} {...props} />}
                  />
                </Hidden >
                {pages.map((data, index) => (
                  <Route
                    key={index}
                    exact={data.exact}
                    path={data.path}
                    component={data.component}
                  />
                ))}
              </div>
            </div>
          </React.Fragment>
        </Switch>
      </Router>
    </>
  )
}
