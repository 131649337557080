import React, { useEffect } from "react";
import { GetFeedbackFields } from "../graphql/dashboardFileds";
import { useQuery, useLazyQuery } from "@apollo/client";
import { filter, map } from "lodash";
import moment from "moment";
import UserList from "../components/Members";

export default (props) => {
  const { breweryId, selectedFeedbackValue } = props;
  const [feedbacks, { data: feedBackData }] = useLazyQuery(GetFeedbackFields, {
    variables: {
      breweryId,
    },
  });

  useEffect(() => {
    feedbacks({
      variables: {
        breweryId,
      },
    });
  }, [breweryId]);

  const feedBacks = feedBackData?.brewery?.brewery?.feedbackConnection?.edges;
  console.log(feedBacks,'feedBacks>>>')
  console.log(feedBackData,'feedBacks>>>')
  const dateFormated = (date) => {
    if (
      moment(new Date()).format("YYYY-MM-DD") ===
      moment(date).format("YYYY-MM-DD")
    ) {
      return "Today";
    } else {
      return moment(date).format("MMM Do");
    }
  };
  const getFeedbacks = () => {
    let data = map(feedBacks, (op) => ({
      avatar:
        op?.feedback?.sourceCommunityMember?.photosConnection?.edges[0]?.image
          ?.url,
      name: op?.feedback?.sourceCommunityMember?.fullName
        ? op?.feedback?.sourceCommunityMember?.fullName
        : "Unknown",
      time: dateFormated(op?.feedback?.postedAt),
      type: op?.feedback?.rating
        ? op?.feedback?.rating === "THUMBS_UP"
          ? "Up"
          : "Down"
        : "Up",
      discription: op?.feedback?.text,
    }));
    if(selectedFeedbackValue === 'Up'){
      data = filter(data, d => d.type === 'Up')
    } else if(selectedFeedbackValue === 'Down') {
      data = filter(data, d => d.type === 'Down')
    }

    return data;
  };

  return (
    <UserList
      secondary
      feedback
      fullHeight={true}
      feedBackSection
      cardTitle={props.cardTitle}
      dataKeys={["name", "time", "discription", "type"]}
      users={getFeedbacks()}
      selectedFeedbackValue={selectedFeedbackValue}
    />
  );
};
