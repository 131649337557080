import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import {
  Box,
  Typography,
  Popper,
  Paper,
  Fade,
  List,
  ListItem,
  Divider,
} from '@material-ui/core'
import CommanClasses from '../themes/CommonClasses'
import Color from '../themes/Colors'
import { isEmpty } from 'lodash'

export default (props) => {
  const { data = [], ...otherProps } = props || {}

  const common = customStyles(CommanClasses)
  const classes = useStyles()

  const [anchorPopper, setAnchorPopper] = useState(null)
  const [openPopper, setOpenPopper] = useState(false)

  const handlePopperAction = (action, e) => {
    e.stopPropagation()
    otherProps.selectedValue(action)
    setOpenPopper(false)
  }

  const handleClickPopper = (e) => (event) => {
    event.stopPropagation();
    setAnchorPopper(event.currentTarget)
    setOpenPopper(!openPopper)
  }

  return (
    <Box>
      <button
        style={{ all: 'unset' }}
        onClick={handleClickPopper()}
      >
        {otherProps?.action}
      </button>
      <Popper
        open={openPopper}
        anchorEl={anchorPopper}
        placement={otherProps?.placement}
        style={{ zIndex: 2 }}
        transition
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={() => setOpenPopper(false)}>
            <Fade {...TransitionProps} timeout={450}>
              <Paper
                elevation={1}
                className={common.borderRadiusM}
                classes={{ elevation1: classes.elevation1 }}
              >
                <List>
                  {isEmpty(data) ?
                    otherProps?.list.map((action, idx) => (
                      <Box key={idx}>
                        <ListItem
                          dense
                          button
                          onClick={(e) => handlePopperAction(action, e)}
                        >
                          <Typography className={common.textM}>{action}</Typography>
                        </ListItem>
                        {otherProps?.list.length !== idx + 1 ? (
                          <Divider variant='middle' />
                        ) : null}
                      </Box>
                    ))
                    :
                    data.map((child, idx) => {
                      if (child?.name) {
                        return (
                          <Box key={idx}>
                            <ListItem
                              dense
                              button
                              onClick={() => child?.onClick()}
                            >
                              <Typography className={common.textM}>{child?.name}</Typography>
                            </ListItem>
                            {data?.length !== idx + 1 ? (
                              <Divider variant='middle' />
                            ) : null}
                          </Box>
                        )
                      }
                    })}
                </List>
              </Paper>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
    </Box>
  )
}

const customStyles = makeStyles(CommanClasses)

const useStyles = makeStyles({
  elevation1: {
    boxShadow: `0px 3px 6px ${Color.lightShadow}`,
  },
})