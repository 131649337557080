import React from 'react'
import { Box, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CommanClasses from '../themes/CommonClasses'
const customStyles = makeStyles(CommanClasses)
export default (data) => {
    const common = customStyles()
  return (
    <Grid container alignItems='center'>
      <Grid item>
        <Box
          style={{
            width: 14,
            height: 14,
            borderRadius: 7,
            backgroundColor: data.color,
          }}
        />
      </Grid>
      <Grid item>
        <Box component='span' ml={0.5}>
          <Typography component='span' className={common.HeeboMedium}>
            {data.number}
          </Typography>
        </Box>
        <Box component='span' ml={0.3}>
          <Typography component='span' className={[common.HeeboLight, common.textM].join(' ')}>{data.label}</Typography>
        </Box>
      </Grid>
    </Grid>
  )
}
