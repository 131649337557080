import { gql } from '@apollo/client'

export const CREATE_OFFER = gql`
	mutation createOffer(
		$hostBreweries: [String]
		$membershipPrograms: [String]
		$scheduledDate: String
		$expiringDate: String
		$title: String!
		$subtitle: String
		$body: [TextComponentInput!]
		$ctaLink: String
		$ctaText: String
		$possibleRedemptions: Int
		$totalRedeemed: Int
		$redeemable: Boolean!
		$targetPreferences: ConsumerBreweryPreferencesInput
		$targetClasses: [TargetClassInput!]
		$active: Boolean!
		$banner: PhotoUpload
	) {
		createOffer(
		hostBreweries: $hostBreweries
		membershipPrograms: $membershipPrograms
		scheduledDate: $scheduledDate
		expiringDate: $expiringDate
		title: $title
		subtitle: $subtitle
		body: $body
		ctaLink: $ctaLink
		ctaText: $ctaText
		possibleRedemptions: $possibleRedemptions
		totalRedeemed: $totalRedeemed
		redeemable: $redeemable
		targetPreferences: $targetPreferences
		targetClasses: $targetClasses
		active: $active
		banner: $banner
		)
			{
				success
			}
	}`